import { Box, Typography } from '@mui/material';
import { useField } from 'formik';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedDataTable } from 'Components/Generic';
import RandomEventObjectAdd from './RandomEventObjectAdd';
import { randomEventObjectColumns } from './utils';

interface Props {
  id: string;
}

export default function RandomEventObjectTable({ id }: Props) {
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField(id);
  return (
    <EnhancedDataTable
      columns={randomEventObjectColumns(id)}
      data={value}
      options={
        {
          customToolbar: () => (
            <RandomEventObjectAdd onConfirm={(newValue) => setValue([...value, newValue])} />
          ),
          pagination: false,
          setTableProps: () => ({ style: { minHeight: '165px' } }),
          sort: false,
        } as MUIDataTableOptions
      }
      title={
        <Box display="flex" justifyContent="space-between">
          <Typography>{t('randomEvent.object.table')}</Typography>
        </Box>
      }
    />
  );
}
