import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { MortgageRefinanceSchema } from 'Config/Validations';
import {
  BasicAccountSelector,
  CheckboxFormik,
  LabeledText,
  Section,
  TextFieldFormik,
} from 'Components';
import { formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import useTimespacePersonFinancialInfo from 'Lib/Hooks/useTimespacePersonFinancialInfo';
import {
  FinancialObject,
  TimespacePerson,
  TimespacePersonFinancialObject,
  namedOperations,
  useGetMortgageFixationEndItemQuery,
  useMortgageFixationRefinanceMutation,
} from 'Generated/graphql-hooks';
import { Constants, TimespacePaths } from 'Config';
import MortgagePersonSection from 'Components/Mortgage/PersonSection';
import MortgageRefinanceSection from 'Components/Mortgage/RefinanceSection';
import MortgageOptionsTable from 'Components/Mortgage/OptionsTable';
import { useErrorHandler } from 'Lib/Hooks';
import { MortgageRefinanceInput } from 'Models';
import { calculateMonthlyPayment } from 'Components/Mortgage/utils';

export interface FormValues {
  accountFinancialObject?: TimespacePersonFinancialObject | null;
  livingWage: number;
  maturityPeriod: number;
  maxMonthlyPayment: number;
  monthlyIncome: number;
  monthlyPayment: number;
  monthlyPayments: number;
  mortgageBalance: number;
  mortgageFinancialObject: FinancialObject | null;
  payment: number;
  propertyPrice: number;
  realizationDay: number;
  refinance: boolean;
  timespacePerson?: TimespacePerson | null;
}

export default function MortgageRefinance() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  const { timespaceId, timespacePersonFinancialObjectId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const [mortgageRequestParams, setMortgageRequestParams] = useState<{
    amount: number;
    maturityPeriod: number;
  }>();

  const {
    data: { livingWage, monthlyIncome, monthlyPayments, partnerIncome },
    loading: userFinancialDataLoading,
  } = useTimespacePersonFinancialInfo({
    timespaceId,
    timespacePerson,
  });

  const mortgageFixationEndItemQuery = useGetMortgageFixationEndItemQuery({
    variables: {
      timespacePersonFinancialObjectId: Number(timespacePersonFinancialObjectId),
    },
  });

  const day = mortgageFixationEndItemQuery.data?.getMortgageFixationEndItem?.realizationDay ?? 0;
  const oldMonthlyPayment =
    mortgageFixationEndItemQuery.data?.getMortgageFixationEndItem?.oldMonthlyPayment ?? 0;
  const propertyPrice =
    mortgageFixationEndItemQuery.data?.getMortgageFixationEndItem?.objectPrice ?? 0;
  const actualValue =
    (mortgageFixationEndItemQuery.data?.getMortgageFixationEndItem?.actualValue ?? 0) * -1;
  const newMonthlyPayment =
    mortgageFixationEndItemQuery.data?.getMortgageFixationEndItem?.newMonthlyPayment ?? 0;
  const familyIncome = monthlyIncome + partnerIncome;

  const maxMonthlyPayment =
    (familyIncome - livingWage) * 0.6 - (monthlyPayments - oldMonthlyPayment);

  const monthlyPaymentsWithoutCurrentMortgage = monthlyPayments - oldMonthlyPayment;

  const [mortgageFixationRefinanceMutation] = useMortgageFixationRefinanceMutation();
  const handleFormikSubmit = async ({
    accountFinancialObject,
    maturityPeriod,
    monthlyPayment,
    mortgageBalance,
    mortgageFinancialObject,
    payment,
    refinance,
  }: FormValues) => {
    try {
      if (refinance && !mortgageFinancialObject) {
        throw new Error('Financial object not set');
      }

      let finalMonthlyPayment = monthlyPayment;
      const finalMortgageBalance = mortgageBalance - payment;

      if (refinance && mortgageFinancialObject) {
        finalMonthlyPayment = calculateMonthlyPayment({
          financialObject: mortgageFinancialObject,
          maturityPeriod: maturityPeriod * 12,
          mortgageAmount: finalMortgageBalance,
          timespace: timespacePerson.timespace,
        });
      }

      if (refinance && finalMonthlyPayment > maxMonthlyPayment) {
        enqueueSnackbar(t('mortgage.refinance.maxMonthlyPaymentLimit'), { variant: 'error' });
        return;
      }

      const { data, errors } = await mortgageFixationRefinanceMutation({
        awaitRefetchQueries: true,
        refetchQueries: [namedOperations.Query.TimespacePersonRandomEvents],
        variables: {
          accountFinancialObjectId: Number(accountFinancialObject?.id),
          financialObjectId: Number(mortgageFinancialObject?.id),
          maturityPeriod: maturityPeriod * 12,
          monthlyPayment: finalMonthlyPayment,
          mortgageBalance: finalMortgageBalance,
          mortgageFinancialObjectId: Number(timespacePersonFinancialObjectId),
          payment,
          refinance,
        },
      });

      if (data?.mortgageFixationRefinance) {
        enqueueSnackbar(t('randomEvent.mortgageUserInput.success'), { variant: 'success' });
        navigate(TimespacePaths.Management);
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('mortgage.sign.failed'), { variant: 'error' });
    }
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Section title={t('mortgage.refinance.title')}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box>
                <Typography>{t('mortgage.refinance.titleDescription')}</Typography>
                <Box py={4}>
                  <LabeledText
                    label={t('mortgage.refinance.description')}
                    value={formatPrice(actualValue ?? 0)}
                  />
                </Box>
                {userFinancialDataLoading ? (
                  <Box display="flex" justifyContent="center" py={5}>
                    <CircularProgress size={30} />
                  </Box>
                ) : (
                  <Formik<FormValues>
                    enableReinitialize
                    validateOnMount
                    initialTouched={{ maturityPeriod: true }}
                    initialValues={
                      new MortgageRefinanceInput(
                        timespacePerson,
                        livingWage,
                        propertyPrice,
                        familyIncome,
                        maxMonthlyPayment,
                        newMonthlyPayment,
                        monthlyPaymentsWithoutCurrentMortgage,
                        actualValue,
                        day,
                      )
                    }
                    isInitialValid={false}
                    onSubmit={handleFormikSubmit}
                    validationSchema={MortgageRefinanceSchema}>
                    {({
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      setFieldValue,
                      submitForm,
                      values,
                    }) => {
                      const isAgeConditionFulfilled =
                        timespacePerson.age &&
                        timespacePerson.age / 12 + values.maturityPeriod <
                          Constants.MORTGAGE_MAX_AGE;

                      return (
                        <Box component="form" onSubmit={handleSubmit}>
                          <Box mb={4}>
                            <LabeledText
                              label={t('mortgage.ownFunds')}
                              value={
                                <TextFieldFormik
                                  InputProps={{
                                    endAdornment: Constants.CURRENCY,
                                    inputProps: { max: values.mortgageBalance, min: 0 },
                                  }}
                                  id="payment"
                                  type="number"
                                />
                              }
                            />
                            <LabeledText
                              label={t('mortgage.paymentSource')}
                              value={<BasicAccountSelector id="accountFinancialObject" />}
                            />
                          </Box>
                          <Box display="flex" justifyContent="flex-end" mt={2}>
                            <FormControlLabel
                              control={<CheckboxFormik id="refinance" />}
                              label={t('mortgage.refinance.attr.refinance') as string}
                            />
                          </Box>
                          {values.refinance ? (
                            <>
                              <MortgagePersonSection />
                              <MortgageRefinanceSection />
                              <Button
                                disabled={!isAgeConditionFulfilled}
                                onClick={() => {
                                  setFieldValue('mortgageFinancialObject', null);
                                  setMortgageRequestParams({
                                    amount: values.mortgageBalance - values.payment,
                                    maturityPeriod: values.maturityPeriod,
                                  });
                                }}
                                sx={{ marginY: 4 }}
                                variant="outlined">
                                {t('mortgage.calculateMortgages')}
                              </Button>
                              {mortgageRequestParams !== undefined ? (
                                <MortgageOptionsTable
                                  maturityPeriod={mortgageRequestParams.maturityPeriod}
                                  maxMonthlyPayment={values.maxMonthlyPayment}
                                  mortgageAmount={mortgageRequestParams.amount}
                                  onRowSelected={(item) => {
                                    setFieldValue(
                                      'mortgageFinancialObject',
                                      item?.financialObject ?? null,
                                    );
                                  }}
                                  refinance={true}
                                  value={values.mortgageFinancialObject}
                                />
                              ) : null}
                            </>
                          ) : (
                            <></>
                          )}
                          <Box display="flex" justifyContent="flex-end" mt={3}>
                            <Button
                              onClick={() => navigate(TimespacePaths.Management)}
                              sx={{ marginRight: 2 }}
                              variant="outlined">
                              {t('global.actions.cancel')}
                            </Button>
                            <LoadingButton
                              disabled={
                                !isValid || (values.refinance && !values.mortgageFinancialObject)
                              }
                              loading={isSubmitting}
                              onClick={submitForm}
                              variant="contained">
                              <span>{t('mortgage.actions.sign')}</span>
                            </LoadingButton>
                          </Box>
                        </Box>
                      );
                    }}
                  </Formik>
                )}
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </>
  );
}
