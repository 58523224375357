import omit from 'lodash/omit';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import {
  RandomEventObjectCreateInput as IRandomEventObjectCreateInput,
  InputMaybe,
  RandomEventObject,
  RandomEventObjectAttributeRelateToOneForCreateInput,
  RandomEventObjectObjectStatusType,
  Scalars,
} from 'Generated/graphql-hooks';

export class RandomEventObjectCreateInput implements IRandomEventObjectCreateInput {
  constructor(item: RandomEventObject) {
    item = mapValuesDeep(item, (v) => (v === '' ? null : v));
    //TODO: Refactor to separate constructor
    this.actualValue = {
      create: omit(item.actualValue, 'id'),
    };
    //TODO: Refactor to separate constructor
    this.assetsImpactMaxRounds = {
      create: omit(item.assetsImpactMaxRounds, 'id'),
    };
    //TODO: Refactor to separate constructor
    this.assetsImpactPeriodicalActive = {
      create: omit(item.assetsImpactPeriodicalActive, 'id'),
    };
    //TODO: Refactor to separate constructor
    this.assetsImpactPeriodicalPassive = {
      create: omit(item.assetsImpactPeriodicalPassive, 'id'),
    };
    this.lifeQualityImpactMaxCount = {
      create: omit(item.lifeQualityImpactMaxCount, 'id'),
    };
    this.lifeQualityImpactPeriodical = {
      create: omit(item.lifeQualityImpactPeriodical, 'id'),
    };
    this.description = item.description ?? ''; //TODO: tmp fix for BE
    this.objectStatus = item.objectStatus;
    this.objectsAffected = item.objectsAffected;
    this.objectsAffectedType = item.objectsAffectedType;
    this.objectsDescriptionModification = item.objectsDescriptionModification ?? ''; //TODO: fix for be
    this.timeRequired = item.timeRequired;
    this.utilisationMaxRounds = {
      create: omit(item.utilisationMaxRounds, 'id'),
    };
    this.utilisationMinRounds = {
      create: omit(item.utilisationMinRounds, 'id'),
    };
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  actualValue?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  assetsImpactMaxRounds?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  assetsImpactOneTime?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  assetsImpactPeriodicalActive?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  assetsImpactPeriodicalPassive?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  lifeQualityImpactMaxCount?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  lifeQualityImpactPeriodical?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  objectStatus?: InputMaybe<RandomEventObjectObjectStatusType>;
  objectsAffected?: InputMaybe<Scalars['String']['input']>;
  objectsAffectedType?: InputMaybe<Scalars['Int']['input']>;
  objectsDescriptionModification?: InputMaybe<Scalars['String']['input']>;
  timeRequired?: InputMaybe<Scalars['Int']['input']>;
  utilisationMaxRounds?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  utilisationMinRounds?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
}
