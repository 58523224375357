import { WarningSharp } from '@mui/icons-material';
import { Icon, Tooltip } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { amber } from '@mui/material/colors';

export default function AgeLimitIconButton({ ageMin }: { ageMin: number }) {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="right"
      sx={{ pointerEvents: 'all' }}
      title={t('object.options.disabled', {
        replace: {
          value: ageMin ?? 0,
        },
      }).toString()}>
      <Icon>
        <WarningSharp fontSize="small" style={{ color: amber['200'] }} />
      </Icon>
    </Tooltip>
  );
}
