import { useField } from 'formik';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  OrderDirection,
  TimespacePersonFinancialObject,
  TimespacePersonFinancialObjectObjectStatusType,
  TimespacePersonFinancialObjectWhereInput,
  useTimespacePersonFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import { Select } from 'Components';
import { GameContext } from 'Context';
import { FinancialObjectCodeEnum } from 'Types/Global';
import type { ExtendedSelectProps } from 'Components/Input/Select';

export interface Props
  extends Omit<ExtendedSelectProps<TimespacePersonFinancialObject>, 'options'> {
  code?: string | null;
  filter?: (options: TimespacePersonFinancialObject[]) => TimespacePersonFinancialObject[];
  id: string;
  noDataPlaceholder?: string;
  redText?: boolean;
  where?: TimespacePersonFinancialObjectWhereInput;
}

export default function FinancialObjectSelector({
  id,
  filter = (options) => options,
  noDataPlaceholder,
  where,
  redText = false,
  ...props
}: Props) {
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const [, { value }, { setValue }] = useField<TimespacePersonFinancialObject | undefined>(id);
  const { data, loading } = useTimespacePersonFinancialObjectsQuery({
    onCompleted: (result) => {
      if (!value) {
        setValue(
          filter(result?.timespacePersonFinancialObjects || []).find((item) =>
            timespacePerson?.defaultPaymentMethod?.id
              ? item.id === timespacePerson?.defaultPaymentMethod?.id
              : true,
          ),
        );
      }
    },
    variables: {
      orderBy: [{ name: OrderDirection.Desc }],
      where: {
        AND: [
          { timespacePerson: { id: { equals: timespacePerson.id } } },
          { timespace: { id: { equals: timespaceId } } },
          {
            objectStatus: {
              equals: TimespacePersonFinancialObjectObjectStatusType.Active,
            },
          },
          {
            OR: [
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount },
                },
              },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.Cash },
                },
              },
            ],
          },
          ...(where ? [where] : []),
        ],
      },
    },
  });

  return (
    <>
      {loading ? (
        <Skeleton height={52} variant="text" width="100%" />
      ) : data?.timespacePersonFinancialObjects?.length ? (
        <Select<TimespacePersonFinancialObject>
          {...props}
          fullWidth
          onChange={({ target }) => {
            setValue(target.value as TimespacePersonFinancialObject);
          }}
          options={filter(data?.timespacePersonFinancialObjects || [])}
          renderValue={(item) => item.displayName}
          value={value}
        />
      ) : (
        <Typography
          color={(theme) => (redText ? theme.palette.error.main : theme.palette.text.primary)}
          sx={{ py: 1 }}>
          {noDataPlaceholder ?? t('bank.no.basic.account')}
        </Typography>
      )}
    </>
  );
}
