import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { LabeledText } from 'Components';
import { formatAge, formatPrice } from 'Lib/Helpers/Number';
import { FormData } from './OverviewModal';

export default function MortgagePersonSection() {
  const { t } = useTranslation();
  const {
    values: { livingWage, maxMonthlyPayment, monthlyIncome, monthlyPayments, timespacePerson },
  } = useFormikContext<FormData>();

  return (
    <>
      <Typography fontWeight="bold" variant="subtitle1">
        {t('mortgage.user.profile.title')}
      </Typography>
      <Box pl={4}>
        <LabeledText label={t('player.attr.age')} value={formatAge(timespacePerson?.age)} />
        <LabeledText label={t('mortgage.user.profile.salary')} value={formatPrice(monthlyIncome)} />
        <LabeledText
          label={t('mortgage.user.profile.payments')}
          value={formatPrice(monthlyPayments)}
        />
        <LabeledText
          label={t('mortgage.user.profile.livingWage')}
          value={formatPrice(livingWage)}
        />
        <LabeledText
          label={t('mortgage.maxMonthlyPayment')}
          value={formatPrice(maxMonthlyPayment)}
        />
      </Box>
    </>
  );
}
