import { t } from 'i18next';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import {
  Accessibility,
  AutoAwesome,
  ChevronRight,
  Groups,
  Inventory2Outlined,
  Money,
  MoreTime,
  Savings,
  SportsEsports,
} from '@mui/icons-material';
import { EnhancedListItemButton, SkeletonLoader } from 'Components/Generic';
import { useTimespacePersonHoldingsQuery } from 'Generated/graphql-hooks';
import { TimespacePaths } from 'Config';
import theme from 'Theme';
import { formatAge, formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';

const PrimaryText = ({ text }: { text?: string | number }) => (
  <Typography
    color="black"
    component="span"
    fontWeight="bold"
    gutterBottom={false}
    lineHeight={1}
    mb={0}
    mt={0.5}
    variant="subtitle2"
    whiteSpace="normal">
    {text}
  </Typography>
);

const LabelText = ({ text }: { text?: string | number }) => (
  <Typography
    component="div"
    gutterBottom={false}
    lineHeight={1.2}
    variant="caption"
    whiteSpace="normal">
    {text}
  </Typography>
);

export default function TimespaceMenuSection() {
  const { timespaceId = '' } = useParams();
  const { isHouseholdSectionAvailable, loading, timespacePerson } = useContext(GameContext);

  const { data: holdingsData } = useTimespacePersonHoldingsQuery({
    skip: timespacePerson ? !timespacePerson.timespace?.id : false,
    variables: { timespacePersonId: Number(timespacePerson ? timespacePerson.id : 0) },
  });

  const { financialAssetsValue, movablePropertiesValue, realEstatesValue } = holdingsData ?? {};
  const totalHoldings =
    (movablePropertiesValue ?? 0) + (realEstatesValue ?? 0) + (financialAssetsValue ?? 0);
  const liabilities = holdingsData?.liabilitiesValue ?? 0;

  return (
    <Box
      border={1.5}
      borderColor={theme.palette.primary.main}
      borderRadius={2}
      mb={2}
      mt={1}
      mx={2}
      sx={{ backgroundColor: theme.palette.primary.main }}>
      {loading ? (
        <Box height={420}>
          <SkeletonLoader height={44} rows={8} />
        </Box>
      ) : (
        <>
          <EnhancedListItemButton exactMatch path={`${TimespacePaths.Root}/${timespaceId}`}>
            <ListItemText>
              <Box
                sx={{
                  color: 'white',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {timespacePerson?.timespace?.name}
              </Box>
            </ListItemText>
          </EnhancedListItemButton>
          <ListItem className="list-item__white-bg">
            <ListItemIcon>
              <Accessibility />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('player.attr.age')}`} />}
              secondary={<PrimaryText text={formatAge(timespacePerson?.age)} />}
              sx={{ m: 0 }}
            />
          </ListItem>
          <ListItem className="list-item__white-bg">
            <ListItemIcon>
              <SportsEsports />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('game.menu.section.round')}`} />}
              secondary={<PrimaryText text={timespacePerson?.timespace?.round || '0'} />}
              sx={{ m: 0 }}
            />
          </ListItem>
          <EnhancedListItemButton
            exactMatch
            className="list-item__white-bg"
            path={TimespacePaths.LifeQuality}>
            <ListItemIcon>
              <AutoAwesome />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('game.menu.section.lifeQuality')}`} />}
              secondary={
                <PrimaryText text={`${Math.round(Number(timespacePerson?.lifeQuality))} b`} />
              }
              sx={{ m: 0 }}
            />
            <ChevronRight fontSize="small" sx={{ color: 'primary.light', mr: -1.5 }} />
          </EnhancedListItemButton>
          <ListItem className="list-item__white-bg">
            <ListItemIcon>
              <Money />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('holdings.clear')}`} />}
              secondary={
                <PrimaryText
                  text={`${formatPrice(totalHoldings - Math.abs(liabilities ?? 0), false)}`}
                />
              }
              sx={{ m: 0 }}
            />
          </ListItem>
          <EnhancedListItemButton
            exactMatch
            className="list-item__white-bg"
            path={TimespacePaths.TimeManagement}>
            <ListItemIcon>
              <MoreTime />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('game.menu.section.timeAvailable')}`} />}
              secondary={<PrimaryText text={`${timespacePerson?.timeAvailable ?? 0} hod.`} />}
              sx={{ m: 0 }}
            />
            <ChevronRight fontSize="small" sx={{ color: 'primary.light', mr: -1.5 }} />
          </EnhancedListItemButton>
          <EnhancedListItemButton
            exactMatch
            className="list-item__white-bg"
            path={TimespacePaths.MyStuff}>
            <ListItemIcon>
              <Inventory2Outlined />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('game.menu.section.mystuff')}`} />}
              sx={{ m: 0 }}
            />
            <ChevronRight fontSize="small" sx={{ color: 'primary.light', mr: -1.5 }} />
          </EnhancedListItemButton>
          <EnhancedListItemButton
            exactMatch
            className="list-item__white-bg"
            path={TimespacePaths.PersonalBudget}>
            <ListItemIcon>
              <Savings />
            </ListItemIcon>
            <ListItemText
              primary={<LabelText text={`${t('game.menu.section.budget')}`} />}
              sx={{ m: 0 }}
            />
            <ChevronRight fontSize="small" sx={{ color: 'primary.light', mr: -1.5 }} />
          </EnhancedListItemButton>
          {isHouseholdSectionAvailable ? (
            <EnhancedListItemButton
              exactMatch
              className="list-item__white-bg"
              path={TimespacePaths.Household}>
              <ListItemIcon>
                <Groups />
              </ListItemIcon>
              <ListItemText
                primary={<LabelText text={`${t('game.menu.section.household')}`} />}
                sx={{ m: 0 }}
              />
              <ChevronRight fontSize="small" sx={{ color: 'primary.light', mr: -1.5 }} />
            </EnhancedListItemButton>
          ) : null}
        </>
      )}
    </Box>
  );
}
