export const livingWageSums = {
  ADULT: 234.42,
  CHILD: 107.03,
  PARTNER_OR_ADULT_CHILD: 163.53,
};

export const realizationDayLimits = {
  max: 30,
  min: 1,
};

export const mortgageMaturityPeriodLimits = {
  max: 30,
  min: 4,
};
