import React, { useContext, useEffect } from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EnhancedListItemButton, SkeletonLoader } from 'Components/Generic';
import { TimespacePaths } from 'Config';
import {
  MutualFundInvestorStatusFragment,
  OrderDirection,
  useMutualFundInvestorStatusesQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';

interface Props {
  object: MutualFundInvestorStatusFragment;
}

function InvestmentsCategoryListItem({ object }: Props) {
  const { timespaceId } = useParams();

  return (
    <>
      <ListItemButton
        component={EnhancedListItemButton}
        path={`${TimespacePaths.Investments}/${timespaceId}/${object.id}`}
        sx={{
          whiteSpace: 'normal',
        }}>
        <ListItemText
          onMouseEnter={() => {
            console.log(object);
          }}
          primary={object?.mutualFundStatus?.fund?.name}
          sx={{ ml: 2 }}
        />
      </ListItemButton>
    </>
  );
}

export default function InvestmentsCategories() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const navigate = useNavigate();

  const { data, loading } = useMutualFundInvestorStatusesQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        person: { id: { equals: timespacePerson?.id } },
      },
    },
  });

  useEffect(() => {
    if (data?.mutualFundInvestorStatuses?.length) {
      navigate(data?.mutualFundInvestorStatuses[0]?.id, { replace: true });
    } else {
      navigate('create', { replace: true });
    }
  }, [data?.mutualFundInvestorStatuses]);

  return (
    <List>
      {data?.mutualFundInvestorStatuses?.length ? (
        <EnhancedListItemButton
          exactMatch={false}
          path={`${TimespacePaths.Investments}/${timespaceId}/my-investments`}
          sx={{
            whiteSpace: 'normal',
          }}>
          <ListItemText primary={t('investments.my.mutual.funds')}></ListItemText>
        </EnhancedListItemButton>
      ) : null}

      {loading ? (
        <SkeletonLoader height={40} />
      ) : (
        data?.mutualFundInvestorStatuses?.map((object, index) => (
          <InvestmentsCategoryListItem key={`category_${index}`} object={object} />
        ))
      )}
      <EnhancedListItemButton
        exactMatch={false}
        path={`${TimespacePaths.Investments}/${timespaceId}/create`}
        sx={{
          marginTop: data?.mutualFundInvestorStatuses?.length ? 4 : 0,
          whiteSpace: 'normal',
        }}>
        <ListItemText primary={t('investments.choose.mutual.fund')}></ListItemText>
      </EnhancedListItemButton>
    </List>
  );
}
