import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { Formik } from 'formik';
import { grey } from '@mui/material/colors';
import { ResetPasswordSchema } from 'Config/Validations';
import useQuery from 'Lib/Hooks/useQuery';
import { PasswordFormik } from 'Components';
import { useErrorHandler } from 'Lib/Hooks';
import { useRedeemUserPasswordResetTokenMutation } from 'Generated/graphql-hooks';
import { ScreenPaths } from 'Config';

export default function ResetPassword() {
  const navigate = useNavigate();
  const query = useQuery(),
    token = query.get('token') || '',
    identity = query.get('identity') || '';
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [redeemPassword, { loading }] = useRedeemUserPasswordResetTokenMutation();
  const handleSubmitFormik = (values: any) => {
    redeemPassword({
      variables: {
        nickName: identity,
        password: values.password,
        token,
      },
    })
      .then(({ data }) => {
        if (data?.redeemUserPasswordResetToken === null) {
          enqueueSnackbar(t('forgotPassword.reset.success'), { variant: 'success' });
          navigate(ScreenPaths.Login);
        } else if (data?.redeemUserPasswordResetToken?.code === 'TOKEN_REDEEMED') {
          enqueueSnackbar(t('forgotPassword.reset.redeemed'), { variant: 'warning' });
        } else if (data?.redeemUserPasswordResetToken?.code === 'FAILURE') {
          enqueueSnackbar(t('forgotPassword.reset.invalid'), { variant: 'warning' });
        }
      })
      .catch(handleError);
  };

  return (
    <>
      <Box alignItems="center" display="flex" mb={4}>
        <Avatar sx={{ bgcolor: grey[200], mr: 2 }}>
          <LockOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('forgotPassword.reset.title')}
        </Typography>
      </Box>
      <Formik
        initialValues={{ confirmation: '', nickName: '', password: '' }}
        onSubmit={handleSubmitFormik}
        validationSchema={ResetPasswordSchema}>
        {({ handleSubmit }) => (
          <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PasswordFormik required id="password" label={t('user.password')} size="medium" />
              </Grid>
              <Grid item xs={12}>
                <PasswordFormik
                  required
                  id="confirmPassword"
                  label={t('user.confirmPassword')}
                  size="medium"
                />
              </Grid>
            </Grid>

            <LoadingButton
              fullWidth
              loading={loading}
              size="large"
              sx={{ mb: 2, mt: 3 }}
              type="submit"
              variant="contained">
              <span>{t('global.actions.confirm')}</span>
            </LoadingButton>
            <Grid container justifyContent="center">
              <Grid item>
                <Link component={RouterLink} to={ScreenPaths.Login} underline="hover">
                  {t('login.backToLogin')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
}
