import React from 'react';
import Grid from '@mui/material/Grid';
import { MUIDataTableOptions } from 'mui-datatables';
import { useMeQuery, useTimespacesQuery } from 'Generated/graphql-hooks';
import { EnhancedDataTable } from 'Components/Generic';
import SelectTemplateModalButton from './SelectTemplateModalButton';
import { overviewColumns } from './utils';
import TimespaceJoinModal from './JoinTimespaceModal';

export default function TimespaceTable() {
  const { data: userData } = useMeQuery();

  const { called, data, loading } = useTimespacesQuery({
    skip: !userData?.me?.id,
    variables: {
      where: {
        AND: [
          { isPrototype: { equals: false } },
          {
            timespacePersons: {
              some: { user: { id: { equals: userData?.me?.id } } },
            },
          },
        ],
      },
    },
  });

  return (
    <>
      <Grid item display="flex" justifyContent="space-between" xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <TimespaceJoinModal />
          </Grid>
          <Grid item>
            <SelectTemplateModalButton />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <EnhancedDataTable
          hideToolbar
          columns={overviewColumns}
          data={data?.timespaces ?? []}
          loading={loading || !called}
          options={
            {
              serverSide: false,
              sortOrder: {
                direction: 'desc',
                name: 'timespacePersonLastVisitedDate',
              },
            } as MUIDataTableOptions
          }
          title={''}
        />
      </Grid>
    </>
  );
}
