import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { Link } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { useApolloClient } from '@apollo/client';
import useAuth from 'Lib/Hooks/useAuth';
import { ScreenPaths } from 'Config';
import { LoginInput } from 'Models';
import { PasswordFormik, TextFieldFormik } from 'Components';
import { LoginSchema } from 'Config/Validations';
import { GetUserNicknameDocument } from 'Generated/graphql-hooks';
import { setFromUrl } from 'Lib/Helpers/Session';

interface LocationState {
  fromUrl?: string;
}

export default function Login() {
  const { loading, login } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const location = useLocation();

  useEffect(() => {
    const locationState = (location.state || {}) as LocationState;

    if (locationState?.fromUrl && locationState.fromUrl !== ScreenPaths.Logout) {
      setFromUrl(locationState?.fromUrl);
    }
  }, [location?.state?.fromUrl]);

  const handleSubmitFormik = useCallback(
    /**
     *
     * @param nickName could be nickName or email
     * @param password
     * @returns {Promise<void>}
     */
    async ({ nickName, password }) => {
      try {
        const { data: userNicknameData } = await client.query({
          query: GetUserNicknameDocument,
          variables: { identifier: nickName },
        });

        if (!userNicknameData.getUserNickname) {
          throw new Error();
        }

        await login(userNicknameData.getUserNickname, password);
      } catch (e: any) {
        if (e.message === 'User not verified' || e.message.includes('UserNotVerifiedError')) {
          enqueueSnackbar(t('errors.user.notVerified'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('errors.login'), { variant: 'error' });
        }
      }
    },
    [t, login, enqueueSnackbar],
  );

  return (
    <Box>
      <Box alignItems="center" display="flex" mb={4}>
        <Avatar sx={{ bgcolor: grey[200], mr: 2 }}>
          <LockOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('login.signIn')}
        </Typography>
      </Box>
      <Formik
        initialValues={new LoginInput()}
        onSubmit={handleSubmitFormik}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={LoginSchema}>
        {({ handleSubmit }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextFieldFormik
                autoFocus
                fullWidth
                required
                autoComplete="nickname"
                id="nickName"
                label={t('user.nickNameOrEmail')}
                size="medium"
              />
              <PasswordFormik
                fullWidth
                required
                autoComplete="current-password"
                id="password"
                label={t('user.password')}
                size="medium"
                type="password"
              />
              <LoadingButton
                fullWidth
                loading={loading}
                size="large"
                sx={{ mb: 2, mt: 4 }}
                type="submit"
                variant="contained">
                <span>{t('login.signIn')}</span>
              </LoadingButton>
            </Box>
          );
        }}
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to={ScreenPaths.ForgotPassword} underline="hover">
            {t('login.forgotPassword')}
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={ScreenPaths.Register} underline="hover">
            {t('login.register')}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
