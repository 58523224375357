import { CheckCircle, HighlightOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumberSchema } from 'yup';
import * as Yup from 'yup';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import { Constants } from 'Config';
import { getUserInputHelperText } from 'Components/RandomEvent/utils';
import { TextFieldFormik } from 'Components/Input';
import {
  TimespacePersonRandomEvent,
  namedOperations,
  useUpdateTimespacePersonRandomEventMutation,
} from 'Generated/graphql-hooks';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePersonRandomEvent: TimespacePersonRandomEvent;
}

export default function RandomEventUserInputModal({
  onClose,
  open,
  timespacePersonRandomEvent,
}: Props) {
  const { t } = useTranslation();
  const [updateTimespacePersonRandomEvent, { loading }] =
    useUpdateTimespacePersonRandomEventMutation();

  const handleOnSubmit = useCallback(
    (values) => {
      const multiplyer = timespacePersonRandomEvent?.randomEvent?.randomEventAcquiredObject
        ?.isAssetsImpactPositive
        ? 1
        : -1;
      values = mapValuesDeep(values, (v) => (v === '' ? null : v));
      return updateTimespacePersonRandomEvent({
        awaitRefetchQueries: true,
        refetchQueries: [namedOperations.Query.TimespacePersonRandomEvents],
        variables: {
          data: {
            ...values,
            executionUserValue: values.executionUserValue
              ? values.executionUserValue * multiplyer
              : values.executionUserValue,
          },
          where: {
            id: timespacePersonRandomEvent?.id,
          },
        },
      })
        .then((x) => {
          console.log(x);
          onClose();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [timespacePersonRandomEvent, timespacePersonRandomEvent, onClose],
  );

  if (!timespacePersonRandomEvent || !timespacePersonRandomEvent.randomEvent) {
    return null;
  }

  const { randomEvent } = timespacePersonRandomEvent;

  const userInputBounds = getUserInputHelperText(randomEvent?.randomEventAcquiredObject);
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('randomEvent.userInput.title')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik
        initialValues={{
          executionUserAcceptance: randomEvent.executionUserAcceptance ? undefined : true,
          executionUserAcceptanceProcessed: randomEvent.executionUserAcceptance,
          executionUserValue: undefined,
        }}
        isInitialValid={false}
        onSubmit={handleOnSubmit}
        validationSchema={Yup.object({
          executionUserAcceptance: randomEvent.executionUserAcceptance
            ? Yup.boolean().required('validations.required')
            : Yup.mixed().nullable(),
          executionUserValue:
            randomEvent.randomEventAcquiredObject?.assetsImpactOneTime?.expectedUserInput &&
            userInputBounds
              ? Yup.number().when(
                  'executionUserAcceptance',
                  (acceptance: boolean, schema: NumberSchema) =>
                    acceptance
                      ? //TODO: add human messages
                        schema
                          .min(userInputBounds.from, 'Zadaná hodnota nespĺňa minimum')
                          .max(userInputBounds.to, 'Zadaná hodnota nespĺňa maximum')
                          .required('')
                      : schema.nullable(),
                )
              : Yup.mixed(),
        })}>
        {({ handleSubmit, isValid, setFieldValue, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{randomEvent?.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{randomEvent?.description}</Typography>
                  </Grid>
                  {randomEvent.executionUserAcceptance && (
                    <Grid container item alignItems="center" justifyContent="start" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1">Ako zareagujete na túto udalosť ?</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          color="success"
                          onClick={() => setFieldValue('executionUserAcceptance', true)}
                          startIcon={<CheckCircle />}
                          variant={
                            values.executionUserAcceptance === true ? 'contained' : 'outlined'
                          }>
                          Prijať
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="error"
                          onClick={() => setFieldValue('executionUserAcceptance', false)}
                          startIcon={<HighlightOff />}
                          variant={
                            values.executionUserAcceptance === false ? 'contained' : 'outlined'
                          }>
                          Odmietnuť
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {randomEvent.randomEventAcquiredObject?.assetsImpactOneTime?.expectedUserInput &&
                    (randomEvent.executionUserAcceptance
                      ? values.executionUserAcceptance
                      : true) && (
                      <Grid item xs={12}>
                        <TextFieldFormik
                          helperText={
                            userInputBounds &&
                            t('randomEvent.userInput.helperText', {
                              replace: {
                                currency: Constants.CURRENCY,
                                ...userInputBounds,
                              },
                            })
                          }
                          id="executionUserValue"
                          label="Odpoveď"
                          type="number"
                        />
                      </Grid>
                    )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                <LoadingButton
                  disabled={!isValid}
                  loading={loading}
                  type="submit"
                  variant="contained">
                  <span>{t('global.actions.confirm')}</span>
                </LoadingButton>
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
