import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { investlandLogoPng } from 'Assets';
import ModalCloseButton from './ModalCloseButton';

interface Props {
  name: string;
  onClose: () => void;
  open: boolean;
  url: string;
}

export default function ImgPreviewModal({ name, onClose, open, url }: Props) {
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <ModalCloseButton onClose={onClose} />
      <img
        alt={name ?? 'image'}
        height="auto"
        onError={(e: any) => {
          e.currentTarget.src = investlandLogoPng;
        }}
        src={url}
      />
    </Dialog>
  );
}
