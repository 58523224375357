import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Paper } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GameContext } from 'Context';
import { Constants } from 'Config';
import LabeledText from 'Components/Generic/LabeledText';
import { SelectFormik, TextFieldFormik } from 'Components/Input';
import {
  OrderDirection,
  TimespacePersonFinancialObject,
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
  useBankAccountTransactionMutation,
  useTimespacePersonFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import TransferSchema from 'Config/Validations/TransferSchema';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { useErrorHandler } from 'Lib/Hooks';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function SavingsTransferToAccount({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { timespaceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);

  const { data } = useTimespacePersonFinancialObjectsQuery({
    skip: !timespacePerson?.id,
    variables: {
      orderBy: { name: OrderDirection.Desc },
      where: {
        AND: [
          { timespacePerson: { id: { equals: timespacePerson?.id } } },
          { timespace: { id: { equals: timespaceId } } },
          { financialObject: { code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount } } },
          { objectStatus: { equals: TimespacePersonFinancialObjectObjectStatusType.Active } },
        ],
      },
    },
  });

  const [bankTransactionMutation, { loading }] = useBankAccountTransactionMutation();

  const handleFormikSubmit = async ({ amount, toFinancialObject }: any) => {
    try {
      const { data: responseData, errors } = await bankTransactionMutation({
        variables: {
          amount: amount,
          fromTimespacePersonFinancialObjectId: Number(timespacePersonFinancialObject!.id),
          toTimespacePersonFinancialObjectId: Number(toFinancialObject.id),
        },
      });

      if (responseData?.bankAccountTransaction) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Formik
        enableReinitialize
        initialValues={{
          amount: undefined,
          financialObjects: data?.timespacePersonFinancialObjects ?? [],
          toFinancialObject: data?.timespacePersonFinancialObjects?.[0] ?? null,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={TransferSchema}>
        {({ handleSubmit, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <LabeledText
                label={t('bank.payment.transfer_amount')}
                value={
                  <TextFieldFormik
                    InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                    id="amount"
                    placeholder={t('global.placeholder.amount')}
                    type="number"
                  />
                }
              />
              <LabeledText
                label={t('bank.payment.transfer_to')}
                value={
                  <SelectFormik<TimespacePersonFinancialObject>
                    fullWidth
                    id="toFinancialObject"
                    options={values.financialObjects}
                    renderValue={(item) => item.displayName}
                  />
                }
              />
              <Box display="flex" justifyContent="end" pt={2}>
                <LoadingButton loading={loading} type="submit" variant="contained">
                  <span>{t('bank.payment.transfer_sign')}</span>
                </LoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Paper>
  );
}
