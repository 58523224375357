import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { namedOperations, useTimespaceLeaveMutation } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { ScreenPaths } from 'Config';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  timespaceId: string;
}

export default function ExitTimespaceModal({ timespaceId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { handleClose, handleOpen, isOpen } = useModal();
  const [timespaceLeave, { loading }] = useTimespaceLeaveMutation({
    refetchQueries: [namedOperations.Query.Timespaces],
    variables: { id: Number(timespaceId) },
  });

  const handleOnClick = useCallback(
    () =>
      timespaceLeave()
        .then(({ data }) => {
          if (data?.timespaceLeave) {
            enqueueSnackbar(t('timespace.leave.success'), { variant: 'success' });
            handleClose();
            navigate(ScreenPaths.Timespaces);
          }
        })
        .catch(() => {
          enqueueSnackbar(t('errors.generic'), { variant: 'error' });
        }),
    [timespaceLeave],
  );
  return (
    <>
      <Dialog fullWidth onClose={handleClose} open={isOpen}>
        <DialogTitle>
          {t('timespace.leave.title')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Typography>{t('timespace.leave.description')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.no')}
          </Button>
          <LoadingButton loading={loading} onClick={handleOnClick} variant="contained">
            <span>{t('global.actions.yes')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        aria-label="delete"
        color="error"
        onClick={handleOpen}
        startIcon={<Delete />}
        variant="contained">
        {t('timespace.leave.button')}
      </Button>
    </>
  );
}
