import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';
import { ListAlt, ShoppingCart } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SellIcon from '@mui/icons-material/Sell';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Container } from '@mui/material';
import { CircularLoader, ErrorFallbackPlaceholder, NavTabs } from 'Components';
import {
  MutualFundInvestorStatusDetail,
  MutualFundTransactionBuy,
  MutualFundTransactionPendingTable,
  MutualFundTransactionSell,
  MutualFundTransactionTable,
} from 'Components/Investments';
import { useMutualFundInvestorStatusQuery } from 'Generated/graphql-hooks';

export default function InvestmentsDetail() {
  const { t } = useTranslation();
  const { objectId } = useParams();
  const { data, error, loading } = useMutualFundInvestorStatusQuery({
    variables: {
      where: { id: objectId },
    },
  });

  return (
    <>
      {loading ? (
        <CircularLoader />
      ) : (
        <ErrorFallbackPlaceholder
          data={data?.mutualFundInvestorStatus}
          error={error}
          loading={loading}>
          <Container maxWidth="md" sx={{ py: 4 }}>
            <NavTabs
              tabs={[
                {
                  href: ``,
                  icon: <ListAlt />,
                  label: t('investments.transactions'),
                },
                {
                  href: `instructions`,
                  icon: <MenuBookIcon />,
                  label: t('investments.instructions'),
                },
                {
                  href: `buy`,
                  icon: <ShoppingCart />,
                  label: t('investments.buy'),
                },
                {
                  href: `sell`,
                  icon: <SellIcon />,
                  label: t('investments.sell'),
                },
                {
                  href: `detail`,
                  icon: <VisibilityIcon />,
                  label: t('investments.details'),
                },
              ]}
            />
            <Routes>
              <Route
                key="transactions"
                element={
                  <MutualFundTransactionTable
                    mutualFundInvestorStatus={data?.mutualFundInvestorStatus}
                  />
                }
                path="/"
              />
              <Route
                element={
                  <MutualFundTransactionPendingTable
                    mutualFundInvestorStatus={data?.mutualFundInvestorStatus}
                  />
                }
                path="instructions"
              />
              <Route
                element={
                  <MutualFundTransactionBuy
                    mutualFundInvestorStatus={data?.mutualFundInvestorStatus}
                  />
                }
                path="buy"
              />
              <Route
                element={
                  <MutualFundTransactionSell
                    mutualFundInvestorStatus={data?.mutualFundInvestorStatus}
                  />
                }
                path="sell"
              />
              <Route
                key="detail"
                element={
                  <MutualFundInvestorStatusDetail
                    mutualFundInvestorStatus={data?.mutualFundInvestorStatus}
                    mutualFundStatus={data?.mutualFundInvestorStatus?.mutualFundStatus}
                  />
                }
                path="detail"
              />
            </Routes>
          </Container>
        </ErrorFallbackPlaceholder>
      )}
    </>
  );
}
