import React, { useCallback, useContext, useMemo } from 'react';
import { Container, TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MUIDataTableOptions } from 'mui-datatables';
import { EnhancedDataTable, Error } from 'Components';
import { GameContext } from 'Context';
import {
  OrderDirection,
  useMutualFundInvestorStatusesQuery,
  useMutualFundStatusesQuery,
  useMutualFundTransactionsQuery,
} from 'Generated/graphql-hooks';
import { formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { calculatePriceChange, fundsColumns } from './utils';

export default function MyInvestmentsTable() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);

  const { data, error, loading } = useMutualFundInvestorStatusesQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        person: { id: { equals: timespacePerson?.id } },
      },
    },
  });

  const { data: statusesData, loading: statusesLoading } = useMutualFundStatusesQuery({
    variables: {
      orderBy: [{ round: OrderDirection.Desc }],
      where: {
        OR: [
          {
            round: { equals: (timespacePerson.timespace?.round ?? 0) - 1 },
          },
          {
            round: { equals: (timespacePerson.timespace?.round ?? 0) - 2 },
          },
        ],
        timespace: { id: { equals: timespaceId } },
      },
    },
  });

  // $TODO: get amount from backend
  const { data: transactionsData, loading: transactionsLoading } = useMutualFundTransactionsQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        person: { id: { equals: timespacePerson?.id } },
      },
    },
  });

  const preparedData = useMemo(
    () =>
      data?.mutualFundInvestorStatuses?.map((item) => {
        const lastFundStatuses = statusesData?.mutualFundStatuses?.filter(
          ({ fund }) => fund?.id === item.mutualFundStatus?.fund?.id,
        );

        const currentFundValue =
          (item.finalVolume ?? 0) * (item?.mutualFundStatus?.sharePrice ?? 0);

        const totalAmount =
          transactionsData?.mutualFundTransactions
            ?.filter(
              ({ mutualFundStatus }) =>
                mutualFundStatus?.fund?.code === item.mutualFundStatus?.fund?.code,
            )
            .reduce((acc, curr) => acc + (curr.amount ?? 0), 0) ?? 0;

        return {
          ...item,
          currentFundValue,
          mutualFundInvestorStatus: item,
          priceChange: calculatePriceChange(lastFundStatuses),
          totalAmount,
          totalGain: totalAmount === 0 ? 0 : currentFundValue / totalAmount - 1,
        };
      }) ?? [],
    [
      data?.mutualFundInvestorStatuses,
      statusesData?.mutualFundStatuses,
      transactionsData?.mutualFundTransactions,
    ],
  );

  const summary = useMemo(
    () =>
      preparedData.reduce(
        (acc, { currentFundValue, totalAmount }) => ({
          currentFundValue: acc.currentFundValue + currentFundValue,
          totalAmount: acc.totalAmount + totalAmount,
        }),
        { currentFundValue: 0, totalAmount: 0 },
      ),
    [preparedData],
  );

  const renderFooterCellValue = useCallback(
    (columnName: string) => {
      switch (columnName) {
        case 'mutualFundInvestorStatus':
          return t('budget.total.label');
        case 'totalAmount':
          return formatPrice(summary.totalAmount);
        case 'currentFundValue':
          return formatPrice(summary.currentFundValue);
        case 'totalGain':
          return formatPercent(summary.currentFundValue / summary.totalAmount - 1, {
            abs: false,
            maximumFractionDigits: 2,
          });
        default:
          return null;
      }
    },
    [summary],
  );

  if (error) {
    return <Error />;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 2 }}>
      <EnhancedDataTable
        columns={fundsColumns(timespaceId)}
        data={preparedData}
        loading={loading || statusesLoading || transactionsLoading}
        options={
          {
            customTableBodyFooterRender: ({ columns }) => {
              return (
                <>
                  {preparedData.length > 0 && (
                    <TableFooter>
                      <TableRow>
                        {columns.map(({ display, name }, index) => {
                          if (display === 'true') {
                            return (
                              <TableCell
                                key={index}
                                sx={{
                                  textAlign: index === 1 ? 'left' : 'right',
                                  whiteSpace: 'nowrap',
                                }}>
                                <Typography color="black" fontWeight="bold" variant="body2">
                                  {renderFooterCellValue(name)}
                                </Typography>
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    </TableFooter>
                  )}
                </>
              );
            },
            pagination: false,
            responsive: 'simple',
            sort: false,
          } as MUIDataTableOptions
        }
        title={<Typography variant="h6">{t('investments.my.mutual.funds')}</Typography>}
      />
    </Container>
  );
}
