import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import {
  Object,
  namedOperations,
  useCreateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { TimespacePersonObject, TimespacePersonObjectCreateInput } from 'Models';
import { GameContext } from 'Context';
import { useErrorHandler } from 'Lib/Hooks';
import { GlobalObjectCodeEnum } from 'Types/Global';
import { ModalCloseButton } from 'Components/Generic';
import ObjectTreeView from '../TreeView/ObjectTreeView';

export default function EducationButtonModal() {
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<Object[]>([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [createTimespacePersonObject, { loading }] = useCreateTimespacePersonObjectMutation();

  useEffect(() => {
    if (!open) {
      setSelectedValues([]);
    }
  }, [open]);

  const handleSubmit = useCallback(() => {
    createTimespacePersonObject({
      refetchQueries: [
        namedOperations.Query.TimespacePersonEducationObjects,
        namedOperations.Query.MyTimespacePerson,
      ],
      variables: {
        data: new TimespacePersonObjectCreateInput(
          new TimespacePersonObject(selectedValues[0]),
          timespaceId,
          timespacePerson.id,
        ),
      },
    })
      .then(({ data, errors }) => {
        if (data?.createTimespacePersonObject) {
          enqueueSnackbar(t('game.study.success'), { variant: 'success' });
          handleClose();
        } else {
          handleError(errors, { ageMin: selectedValues[0].ageMin });
        }
      })
      .catch(() => {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      });
  }, [selectedValues, t, handleError]);

  return (
    <>
      <Button onClick={handleClickOpen} startIcon={<Add />} variant="outlined">
        {t('game.study.start')}
      </Button>
      <Dialog fullWidth onClose={handleClose} open={open}>
        <DialogTitle>
          {t('game.study.start')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <ObjectTreeView
            baseCodeLength={4}
            disabledFunction={(item) => item?.isAbstract}
            onChange={(object, checked) => {
              setSelectedValues(checked ? [object] : []);
            }}
            values={selectedValues || []}
            where={{
              AND: [{ code: { startsWith: GlobalObjectCodeEnum.Education } }],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.cancel')}
          </Button>
          <LoadingButton
            disabled={selectedValues.length === 0}
            loading={loading}
            onClick={handleSubmit}
            variant="contained">
            <span>{t('global.actions.confirm')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
