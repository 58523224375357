import { Collapse, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import List from '@mui/material/List';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  FinancialObject,
  FinancialObjectFragment,
  StandingOrderFragment,
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
  useBankCategoriesQuery,
} from 'Generated/graphql-hooks';
import { TimespacePaths } from 'Config';
import { EnhancedListItemButton, SkeletonLoader } from 'Components/Generic';
import { useToggle } from 'Lib/Hooks';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { GameContext } from 'Context';
import { createFinancialObjectsTree } from './utils';

type TreeObject = (
  | TimespacePersonFinancialObjectFragment
  | FinancialObjectFragment
  | StandingOrderFragment
) & {
  codeLength?: number | null;
  displayName?: string | null;
  financialObject?: FinancialObject | null;
  items?: TreeObject[];
  objectStatus?: TimespacePersonFinancialObjectObjectStatusType | null;
};

interface Props {
  object: TreeObject;
  path?: string;
}

function BankCategoryListItem({ object, path }: Props) {
  const { timespaceId } = useParams();
  const [open, handleToggle] = useToggle();

  const hasItems = !!object.items?.length;

  return (
    <>
      <ListItemButton
        component={hasItems ? ListItemButton : EnhancedListItemButton}
        onClick={handleToggle}
        path={path ?? `${TimespacePaths.Bank}/${timespaceId}/${object.id}`}
        sx={{
          whiteSpace: 'normal',
        }}>
        <ListItemText
          onMouseEnter={() => {
            console.log(object);
          }}
          primary={object.displayName ? object.displayName : object.name}
          sx={{ ml: object.codeLength === 4 || object.financialObject?.codeLength === 4 ? 1 : 3 }}
        />
        {hasItems ? open ? <ExpandLess /> : <ExpandMore /> : null}
        {object.objectStatus === TimespacePersonFinancialObjectObjectStatusType.Discarded ? (
          <CloseIcon style={{ fill: '#E58A9E' }} />
        ) : null}
      </ListItemButton>
      <Collapse unmountOnExit in={open} timeout="auto">
        <List disablePadding component="div">
          {object.items?.map((item, index) => {
            return <BankCategoryListItem key={index} object={item} />;
          })}
        </List>
      </Collapse>
    </>
  );
}

export default function BankCategories() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const [open, handleToggle] = useToggle();
  const navigate = useNavigate();

  const { data, loading } = useBankCategoriesQuery({
    skip: !timespacePerson?.id,
    variables: {
      timespacePersonId: timespacePerson?.id,
    },
  });

  const foTree = createFinancialObjectsTree(
    data?.financialObjects,
    data?.timespacePersonFinancialObjects,
  );

  const cashObject = data?.timespacePersonFinancialObjects?.find(
    ({ financialObject }) => financialObject?.code === FinancialObjectCodeEnum.Cash,
  );

  useEffect(() => {
    if (cashObject) {
      navigate(cashObject.id, { replace: true });
    }
  }, [cashObject?.id]);

  return (
    <List>
      <ListItem>
        <ListItemText primary={t('bank.my_products')} />
      </ListItem>
      {loading ? (
        <SkeletonLoader height={40} />
      ) : (
        <>
          {cashObject ? <BankCategoryListItem object={cashObject} /> : null}

          {foTree?.map((object, index) => (
            <BankCategoryListItem key={`category_${index}`} object={object} />
          ))}

          {data?.standingOrders?.length ? (
            <>
              <ListItemButton
                component={ListItemButton}
                onClick={handleToggle}
                sx={{
                  whiteSpace: 'normal',
                }}>
                <ListItemText primary={t('bank.standing.orders')} sx={{ ml: 1 }} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse unmountOnExit in={open} timeout="auto">
                <List disablePadding component="div">
                  {data?.standingOrders?.map((item, index) => {
                    return (
                      <BankCategoryListItem
                        key={index}
                        object={item}
                        path={`${TimespacePaths.Bank}/${timespaceId}/standing-order/${item.id}`}
                      />
                    );
                  })}
                </List>
              </Collapse>
            </>
          ) : null}
        </>
      )}

      <ListItem sx={{ marginTop: 4 }}>
        <ListItemText primary={t('game.menu.bank')} />
      </ListItem>

      <EnhancedListItemButton
        exactMatch={false}
        path={`${TimespacePaths.Bank}/${timespaceId}/create-savings-account`}
        sx={{
          whiteSpace: 'normal',
        }}>
        <ListItemText primary={t('savings.account.create')} sx={{ ml: 2 }} />
      </EnhancedListItemButton>
      <EnhancedListItemButton
        exactMatch={false}
        path={`${TimespacePaths.Bank}/${timespaceId}/create-term-account`}
        sx={{
          whiteSpace: 'normal',
        }}>
        <ListItemText primary={t('term.account.create')} sx={{ ml: 2 }} />
      </EnhancedListItemButton>
      <EnhancedListItemButton
        exactMatch={false}
        path={`${TimespacePaths.Bank}/${timespaceId}/create-standing-order`}
        sx={{
          whiteSpace: 'normal',
        }}>
        <ListItemText primary={t('bank.create.standingOrder')} sx={{ ml: 2 }} />
      </EnhancedListItemButton>
      <EnhancedListItemButton
        exactMatch={false}
        path={`${TimespacePaths.Bank}/${timespaceId}/create-consumer-credit`}
        sx={{
          whiteSpace: 'normal',
        }}>
        <ListItemText sx={{ ml: 2 }}>
          <Typography variant="body1">{t('game.ask.for')}</Typography>
          <Typography variant="body1">{t('bank.account.consumer.credit')}</Typography>
        </ListItemText>
      </EnhancedListItemButton>
    </List>
  );
}
