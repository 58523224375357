import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validateAcquiredObject } from 'Components/RandomEvent/AcquiredObject/utils';
import { RandomEventAcquiredObjectInput } from 'Models/RandomEventAcquiredObjectInput';
import ObjectTreeView from 'Components/TreeView/ObjectTreeView';
import { RandomEventAcquiredObject } from 'Generated/graphql-hooks';
import RandomEventAttributeRow from 'Components/RandomEvent/RandomEventAttributeRow';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  onClose: () => void;
  onSubmit?: (values: RandomEventAcquiredObject) => Promise<any> | void;
  open: boolean;
  value?: RandomEventAcquiredObject;
}

export default function RandomEventAcquiredObjectModal({
  onClose,
  onSubmit = onClose,
  open,
  value,
}: Props) {
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (values: RandomEventAcquiredObject | RandomEventAcquiredObjectInput) => {
      if (values.assetsImpactOneTime) {
        values.isAssetsImpactPositive = Object.values(values.assetsImpactOneTime)
          .filter((x) => typeof x === 'number')
          .every((x) => x >= 0);
      }
      onSubmit(values as RandomEventAcquiredObject);
      onClose();
    },
    [onSubmit, onClose],
  );

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open={open}>
      <DialogTitle>
        {t('randomEvent.acquiredObject.add')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik
        initialValues={value ?? new RandomEventAcquiredObjectInput()}
        onSubmit={handleOnSubmit}
        validate={validateAcquiredObject}>
        {({ errors, handleSubmit, setFieldValue, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <ObjectTreeView
                  disableDetail
                  disabledFunction={(item) => item?.isAbstract || values.object?.code === item.code}
                  onChange={(object, checked) => {
                    setFieldValue('object', checked ? object : null);
                  }}
                  values={values.object ? [values.object] : []}
                />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  textAlign="center"
                  wrap="nowrap">
                  <Grid item flex={1} />
                  <Grid item xs={1.2}>
                    <Typography variant="caption">{t('randomEvent.attr.coeffFrom')}</Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography variant="caption">{t('randomEvent.attr.coeffTo')}</Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography variant="caption">{t('randomEvent.attr.absFrom')}</Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography variant="caption">{t('randomEvent.attr.absTo')}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="caption">
                      {t('randomEvent.attr.expectedUserInput')}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography variant="caption">{t('randomEvent.attr.default')}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <RandomEventAttributeRow userInput attributePrefix="assetsImpactOneTime" />
                </Grid>
                <Grid item justifySelf="end" xs={12}></Grid>
              </DialogContent>
              <DialogActions>
                {errors.assetsImpactOneTime && (
                  <Box color="red" pr={4}>
                    <Typography>{t('validations.signMismatch')}</Typography>
                  </Box>
                )}

                <Button color="secondary" type="submit" variant="contained">
                  {t('global.actions.save')}
                </Button>
                <Button onClick={onClose}> {t('global.actions.cancel')}</Button>
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
