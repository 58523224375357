import { Add } from '@mui/icons-material';
import * as React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RandomEventObjectInput } from 'Models/RandomEventObjectInput';
import { useModal } from 'Lib/Hooks';
import RandomEventObjectModal from './RandomEventObjectModal';

interface Props {
  onConfirm: (values: RandomEventObjectInput) => void;
}

export default function RandomEventObjectAdd({ onConfirm }: Props) {
  const { t } = useTranslation();
  const { handleClose, handleOpen, isOpen } = useModal();

  return (
    <>
      <Tooltip title={t('global.actions.detail').toString()}>
        <Button onClick={() => handleOpen()} startIcon={<Add />} variant="outlined">
          {t('global.actions.add')}
        </Button>
      </Tooltip>
      <RandomEventObjectModal onClose={handleClose} onSubmit={onConfirm} open={isOpen} />
    </>
  );
}
