import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { TimespacePersonFinancialObject, TimespacePersonObject } from 'Models';
import { ModalCloseButton } from 'Components';

interface Props {
  ageConflictingObjects?: TimespacePersonObject[] | TimespacePersonFinancialObject[] | null;
  isSubmitting: boolean;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

export default function AgeConflictModal({
  ageConflictingObjects,
  isSubmitting,
  onClose: handleClose,
  onConfirm,
  open,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle>
        {t('global.warning')}
        <ModalCloseButton onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Typography>{t('object.age.conflicting.objects')}</Typography>
        {ageConflictingObjects?.map((object) => {
          return (
            <Typography key={object.id} pl={2}>
              {object?.name}
            </Typography>
          );
        })}
        <Typography></Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {t('object.back.to.edits')}
        </Button>
        <LoadingButton color="error" loading={isSubmitting} onClick={onConfirm} variant="contained">
          <span>{t('object.delete.conflicted.confirmation')}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
