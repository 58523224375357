import formatDate from 'date-fns/format';
import { Constants } from 'Config';

export function getTimespaceYearFromRound(round?: number | null) {
  const roundMod = (round ?? 1) % 12;

  return Constants.START_YEAR + Math.floor((round ?? 0) / 12) - (roundMod === 0 ? 1 : 0);
}

export function getHumanDateFromRound(round: number, day?: string) {
  const month = round % 12;
  const yearAddition = Math.floor(round / 12);
  const date = new Date(Constants.START_YEAR + yearAddition, month - 1, Number(day || 1));
  return formatDate(date, 'dd.MM.yyyy');
}

export function getObjectYearFromRound(
  procurementRound?: number | null,
  timespaceRound?: number | null,
) {
  const ageInRounds = (timespaceRound ?? 0) - (procurementRound ?? 0);

  return ageInRounds / 12;
}
