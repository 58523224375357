import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  MutualFundTransaction,
  namedOperations,
  useDeleteMutualFundTransactionMutation,
} from 'Generated/graphql-hooks';
import { SafeDeleteModal } from 'Components/Generic';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  text?: string;
  transaction: MutualFundTransaction;
}

export default function MutualFundTransactionDeleteModal({
  isOpen,
  onClose,
  text,
  transaction,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteTransaction, { loading }] = useDeleteMutualFundTransactionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [namedOperations.Query.MutualFundTransactions],
    variables: {
      where: { id: transaction.id },
    },
  });

  const handleConfirm = useCallback(async () => {
    try {
      const { data } = await deleteTransaction();
      if (data?.deleteMutualFundTransaction) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        onClose();
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [onClose]);

  return (
    <>
      <SafeDeleteModal
        loading={loading}
        onClose={onClose}
        onConfirm={handleConfirm}
        open={isOpen}
        text={text ?? `${t('global.delete.confirm')} ?`}
      />
    </>
  );
}
