import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import {
  TimespacePerson,
  namedOperations,
  useDeleteTimespacePersonMutation,
} from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { ScreenPaths } from 'Config';
import useAuth from 'Lib/Hooks/useAuth';
import { getPersonFullName } from 'Lib/Helpers/TimespacePersonObject';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  onClose?: () => void;
  timespacePerson: TimespacePerson;
}

export default function RemovePlayerModal({ onClose, timespacePerson }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { handleClose, handleOpen, isOpen } = useModal();
  const { user } = useAuth();
  const { id: playerId } = timespacePerson;
  const playerName = user ? getPersonFullName(user) : '';
  const [deleteTimespacePerson, { loading }] = useDeleteTimespacePersonMutation({
    refetchQueries: [namedOperations.Query.TimespacePeople],
    variables: { where: { id: playerId } },
  });

  const handleOnClick = useCallback(async () => {
    try {
      await deleteTimespacePerson();
      if (user?.id === timespacePerson.user?.id) {
        navigate(ScreenPaths.Timespaces);
      } else {
        handleClose();
        onClose?.();
      }
      enqueueSnackbar(t('player.delete.success'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [deleteTimespacePerson, onClose, user, timespacePerson]);
  return (
    <>
      <Dialog fullWidth onClose={handleClose} open={isOpen}>
        <DialogTitle>
          {t('player.delete.title')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t('player.delete.description', {
              replace: { value: playerName },
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.no')}
          </Button>
          <LoadingButton loading={loading} onClick={handleOnClick} variant="contained">
            <span>{t('global.actions.yes')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        aria-label="delete"
        color="error"
        onClick={handleOpen}
        startIcon={<Delete />}
        variant="contained">
        {t('player.delete.action')}
      </Button>
    </>
  );
}
