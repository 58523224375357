import React, { memo, useCallback, useContext } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { FinancialObject, OrderDirection, useFinancialObjectsQuery } from 'Generated/graphql-hooks';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { GameContext } from 'Context';
import { EnhancedDataTable, SkeletonLoader } from 'Components/Generic';
import { calculateConsumerCreditMonthlyPayment, consumerCreditTableColumns } from './utils';

interface Props {
  onRowSelected: (financialObject: FinancialObject) => void;
  value: FinancialObject | null;
}

export default memo(function ConsumerCreditTable({ onRowSelected, value }: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { data, loading } = useFinancialObjectsQuery({
    variables: {
      orderBy: [{ name: OrderDirection.Asc }],
      where: {
        AND: [
          { code: { startsWith: FinancialObjectCodeEnum.ConsumerLoan } },
          { isAbstract: { equals: false } },
        ],
      },
    },
  });

  const handleRowSelectionChange = useCallback(
    (_, __, selectedRows: any[]) => {
      if (data?.financialObjects) {
        onRowSelected(data.financialObjects?.[selectedRows[0]]);
      }
    },
    [data?.financialObjects, onRowSelected],
  );

  const prepareData = useCallback(
    () =>
      data?.financialObjects?.map((item) => ({
        ...item,
        monthlyPayment: calculateConsumerCreditMonthlyPayment({
          financialObject: item,
          maturityPeriod: item?.maturityPeriod ?? 0,
          requestedAmount: Math.abs(item?.principalMin ?? 0),
          timespace: timespacePerson?.timespace,
        }),
      })) ?? [],
    [data?.financialObjects, timespacePerson],
  );

  const selectedRowIndex = data?.financialObjects?.findIndex(({ id }) => id === value?.id) ?? -1;

  return (
    <EnhancedDataTable
      hideToolbar
      columns={consumerCreditTableColumns}
      data={prepareData()}
      options={
        {
          onRowSelectionChange: handleRowSelectionChange,
          rowsSelected: selectedRowIndex > -1 ? [selectedRowIndex] : undefined,
          selectableRows: 'single',
          selectableRowsOnClick: true,
          textLabels: {
            body: {
              noMatch: loading ? (
                <SkeletonLoader height={63} rows={4} />
              ) : (
                t('global.placeholder.empty')
              ),
            },
          },
        } as MUIDataTableOptions
      }
      title={''}
    />
  );
});
