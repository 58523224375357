import { Add } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from 'Lib/Hooks/useAuth';
import { OrderDirection, useTimespacesQuery } from 'Generated/graphql-hooks';
import { ScreenPaths } from 'Config';
import { EnhancedDataTable } from 'Components/Generic';
import { usePagination } from 'Lib/Hooks';
import { templateColumns } from './utils';

export default function TemplateTable() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { skip, take, ...pagination } = usePagination();
  const { data, loading } = useTimespacesQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      skip,
      take,
      where: {
        OR: [
          { isPublic: { equals: true } },
          {
            AND: [{ isPublic: { equals: false } }, { owner: { id: { equals: user?.id } } }],
          },
        ],
        isPrototype: { equals: true },
      },
    },
  });

  return (
    <>
      <Grid item display="flex" justifyContent="space-between" xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              component={RouterLink}
              startIcon={<Add />}
              to={ScreenPaths.Create}
              variant="outlined">
              {t('timespace.create.template')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <EnhancedDataTable
          columns={templateColumns(user?.id)}
          data={data?.timespaces ?? []}
          loading={loading}
          options={{
            ...pagination,
            count: data?.count ?? 0,
          }}
          title={''}
        />
      </Grid>
    </>
  );
}
