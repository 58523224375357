import React, { useMemo, useState } from 'react';
import { ShoppingCart } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { Object } from 'Generated/graphql-hooks';
import { formatPrice } from 'Lib/Helpers/Number';
import { IMAGE_STORAGE_URL } from 'Config/Constants';
import { investlandLogoPng } from 'Assets';
import { useModal } from 'Lib/Hooks';
import { ImgPreviewModal } from 'Components';

interface Props {
  item: Object;
  loading: boolean;
  onBuyClick: (item: Object) => void;
}

export default React.memo(function EshopItemCard({ item, loading, onBuyClick }: Props) {
  const { t } = useTranslation();
  const [imgLoaded, setImgLoaded] = useState(false);
  const {
    handleClose: handleImagePreviewClose,
    handleOpen: handleImagePreviewOpen,
    isOpen: isImagePreviewOpen,
  } = useModal();

  const url = useMemo(
    () => `${IMAGE_STORAGE_URL}${item.code}.jpg?t=${new Date().getTime()}`,
    [item.id],
  );

  if (loading) {
    return <Skeleton height={300} sx={{ borderRadius: 2 }} variant="rectangular" />;
  }

  return (
    <Card
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}
      variant="elevation">
      <CardMedia
        alt={item.name ?? 'image'}
        component="img"
        height="180"
        image={url}
        onError={(e: any) => {
          e.currentTarget.src = investlandLogoPng;
        }}
        onLoad={() => setImgLoaded(true)}
      />
      {imgLoaded ? (
        <IconButton
          onClick={handleImagePreviewOpen}
          sx={{ color: 'white', position: 'absolute', right: 0, top: 0 }}>
          <SearchIcon color="primary" />
        </IconButton>
      ) : null}
      <CardContent sx={{ flex: 1 }}>
        <Typography gutterBottom component="div" fontWeight="bold" variant="body1">
          {item.name}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '4',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="body2">
          {item.description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}>
        <div>
          {item.assetsImpactOneTime && Number(item.assetsImpactOneTime) !== 0 ? (
            <Typography noWrap lineHeight={1} pb={1} pl={1} variant="h6">
              <Typography display="block" variant="caption">
                {t('eshop.price')}:
              </Typography>
              {formatPrice(item.assetsImpactOneTime)}
            </Typography>
          ) : null}
          {item.assetsImpactPeriodicalActive && Number(item.assetsImpactPeriodicalActive) !== 0 ? (
            <Typography noWrap lineHeight={1} pl={1} variant="body2">
              <Typography display="block" variant="caption">
                {t('eshop.monthly')}:
              </Typography>
              {formatPrice(item.assetsImpactPeriodicalActive)}
            </Typography>
          ) : null}
        </div>
        <Button
          onClick={() => onBuyClick(item)}
          size="small"
          startIcon={<ShoppingCart />}
          variant="outlined">
          {t('global.actions.buy')}
        </Button>
      </CardActions>
      <ImgPreviewModal
        name={item.name ?? ''}
        onClose={handleImagePreviewClose}
        open={isImagePreviewOpen}
        url={url}
      />
    </Card>
  );
});
