import React, { useContext } from 'react';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { TimespacePersonFinancialObjectFragment } from 'Generated/graphql-hooks';
import { LabeledText } from 'Components/Generic';
import { TextFieldFormik } from 'Components/Input';
import { BankDetailSchema } from 'Config/Validations';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { useTimespacePersonFinancialObjectUpdate } from 'Lib/Hooks';
import { FormValues } from 'Components/Bank/CurrentAccountDetail';
import { GameContext } from 'Context';
import { formatDuration, formatPercent, formatPrice } from 'Lib/Helpers/Number';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function MortgageAccountDetail({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const [handleFormikSubmit, { loading }] = useTimespacePersonFinancialObjectUpdate(
    timespacePersonFinancialObject?.id,
  );
  const mortgageEndRound =
    (timespacePersonFinancialObject?.procurementRound ?? timespacePerson.timespace?.round ?? 1) +
    (timespacePersonFinancialObject?.maturityPeriod ?? 1);

  return timespacePersonFinancialObject ? (
    <Paper sx={{ p: 2 }}>
      <Formik<FormValues>
        initialValues={{
          customName: timespacePersonFinancialObject.displayName ?? '',
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={BankDetailSchema}>
        {({ handleSubmit }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <LabeledText
                label={t('bank.account.mortgage')}
                value={<TextFieldFormik id="customName" type="string" />}
              />
              <LabeledText
                label={t('mortgage.initial.date')}
                sx={{ py: 1 }}
                value={getHumanDateFromRound(
                  timespacePersonFinancialObject?.procurementRound ??
                    timespacePerson.timespace?.round ??
                    1,
                  timespacePersonFinancialObject?.realizationDay?.toString() || '1',
                )}
              />
              <LabeledText
                label={t('mortgage.monthly.payment.amount')}
                sx={{ py: 1 }}
                value={formatPrice(timespacePersonFinancialObject?.monthlyPayment)}
              />
              <LabeledText
                label={t('mortgage.table.attr.interest')}
                sx={{ py: 1 }}
                value={
                  timespacePersonFinancialObject.interestNegative
                    ? `${formatPercent(timespacePersonFinancialObject.interestNegative)} p.a.`
                    : '-'
                }
              />
              <LabeledText
                label={t('mortgage.table.attr.fixation')}
                sx={{ py: 1 }}
                value={
                  timespacePersonFinancialObject.fixationPeriod
                    ? `${formatDuration(timespacePersonFinancialObject.fixationPeriod / 12)}`
                    : '-'
                }
              />
              <LabeledText
                label={t('mortgage.table.attr.fixationEnd')}
                sx={{ py: 1 }}
                value={
                  timespacePersonFinancialObject.nextFixation
                    ? `${getHumanDateFromRound(
                        timespacePersonFinancialObject.nextFixation ??
                          timespacePerson.timespace?.round ??
                          1,
                        timespacePersonFinancialObject?.realizationDay?.toString() || '1',
                      )}`
                    : '-'
                }
              />
              <LabeledText
                label={t('mortgage.table.attr.mortgageEnd')}
                sx={{ py: 1 }}
                value={`${getHumanDateFromRound(
                  mortgageEndRound,
                  timespacePersonFinancialObject?.realizationDay?.toString() || '1',
                )}`}
              />
              <Box display="flex" justifyContent="end" pt={2}>
                <LoadingButton loading={loading} type="submit" variant="contained">
                  <span>{t('global.actions.update')}</span>
                </LoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Paper>
  ) : null;
}
