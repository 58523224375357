import * as React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function InvestmentsOverview() {
  const { t } = useTranslation();
  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Grid
        container
        spacing={4}
        sx={(theme) => ({
          paddingTop: theme.spacing(4),
        })}>
        <Grid item xs={12}>
          <Box alignItems="center" display="flex" height="50%" justifyContent="center">
            <Typography variant="body2">{t('investments.overview.title')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
