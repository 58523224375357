import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHoldingsDashboardQuery } from 'Generated/graphql-hooks';
import { formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { CircularLoader, Section } from 'Components';

export default function HoldingsOverview() {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);

  const { data, loading } = useHoldingsDashboardQuery({
    variables: { timespacePersonId: parseInt(timespacePerson.id) },
  });

  if (loading) {
    return <CircularLoader />;
  }

  const total =
    (data?.financialAssetsValue ?? 0) +
    (data?.realEstatesValue ?? 0) +
    (data?.movablePropertiesValue ?? 0);
  const clear = total - Math.abs(data?.liabilitiesValue ?? 0);
  return (
    <Section title={t('holdings.overview')}>
      <Box px={8}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography fontWeight="bold">{t('holdings.clear')}</Typography>
          <Box alignItems="center" display="flex" justifyContent="space-between" pr={1}>
            <Box mr={2}>{formatPrice(clear, false)}</Box>
            <Tooltip placement="right" title={t('holdings.tooltip.clear').toString()}>
              <InfoOutlined />
            </Tooltip>
          </Box>
        </Box>

        <Box alignItems="center" display="flex" justifyContent="space-between" pt={4}>
          <Typography fontWeight="bold">{t('holdings.total')}</Typography>
          <Box mr={6}>{formatPrice(total, false)}</Box>
        </Box>

        <Box ml={4} pt={2}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography>{t('holdings.goods')}</Typography>
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Box mr={6}>{formatPrice(data?.movablePropertiesValue, false)}</Box>
            </Box>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography>{t('holdings.properties')}</Typography>
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Box mr={6}>{formatPrice(data?.realEstatesValue, false)}</Box>
            </Box>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography>{t('holdings.financial')}</Typography>
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Box mr={6}>{formatPrice(data?.financialAssetsValue, false)}</Box>
            </Box>
          </Box>
        </Box>

        <Box alignItems="center" display="flex" justifyContent="space-between" pt={4}>
          <Typography fontWeight="bold">{t('holdings.debts')}</Typography>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Box mr={6}>{formatPrice(data?.liabilitiesValue, false)}</Box>
          </Box>
        </Box>
      </Box>
    </Section>
  );
}
