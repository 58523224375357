import { Edit } from '@mui/icons-material';
import { useField } from 'formik';
import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useModal } from 'Lib/Hooks';
import RandomEventFinancialObjectModal from './RandomEventFinancialObjectModal';

interface Props {
  id: string;
}

export default function RandomEventFinancialObjectEdit({ id }: Props) {
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField(id);

  const { handleClose, handleOpen, isOpen } = useModal();
  return (
    <>
      <Tooltip title={t('global.actions.edit').toString()}>
        <IconButton color="warning" onClick={() => handleOpen()}>
          <Edit />
        </IconButton>
      </Tooltip>

      <RandomEventFinancialObjectModal
        onClose={handleClose}
        onSubmit={(newValue) => setValue(newValue)}
        open={isOpen}
        value={value}
      />
    </>
  );
}
