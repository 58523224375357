import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScreenPaths } from 'Config';
import {
  User,
  UserAuthenticationWithPasswordSuccess,
  UserVerificationStatusType,
  useAuthenticateUserWithPasswordMutation,
  useLogoutMutation,
  useMeLazyQuery,
} from 'Generated/graphql-hooks';
import {
  clearFromUrl,
  clearOwnerToken,
  clearSessionToken,
  getOwnerToken,
  getSessionToken,
  setOwnerToken,
  setSessionToken,
} from 'Lib/Helpers/Session';

interface AuthContextType {
  error?: any;
  isLoggedIn: any;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  setOwnerToken: (ownerToken: string) => void;
  setToken: (sessionToken: string) => void;
  signUp: (email: string, name: string, password: string) => void;
  user?: User;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [token, setToken] = useState(getSessionToken());
  const [ownerToken, setOwnerTokenState] = useState(getOwnerToken());
  const [user, setUser] = useState<User>();

  // const location = useLocation();
  const navigate = useNavigate();
  const [logoutMutation] = useLogoutMutation();
  const [loadMe, { client }] = useMeLazyQuery();
  const [loginMutation, authMutationState] = useAuthenticateUserWithPasswordMutation();

  useEffect(() => {
    if (token && !pathname.endsWith(ScreenPaths.Logout)) {
      setSessionToken(token);
      loadMe()
        .then(async (res) => {
          if (res.data?.me) {
            await client.clearStore();
            setUser(res.data?.me);
          } else {
            throw new Error();
          }
        })
        .catch(setError)
        .finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
  }, [token]);

  useEffect(() => {
    if (ownerToken) {
      setOwnerToken(ownerToken);
    } else {
      setLoadingInitial(false);
    }
  }, [ownerToken]);

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  async function login(email: string, password: string) {
    const { data } = await loginMutation({
      variables: { nickName: email?.toString()?.toLowerCase(), password },
    });

    const verificationStatus = (
      data?.authenticateUserWithPassword as UserAuthenticationWithPasswordSuccess
    ).item.verificationStatus;
    const sessionToken = (
      data?.authenticateUserWithPassword as UserAuthenticationWithPasswordSuccess
    ).sessionToken;

    // TODO: tmp solution, BE should return an error message, not an invalid session token
    if (verificationStatus === UserVerificationStatusType.Pending) {
      throw new Error('User not verified');
    } else if (sessionToken) {
      setSessionToken(sessionToken);
      setOwnerToken(sessionToken);
      setToken(sessionToken);
      setOwnerTokenState(sessionToken);
    } else {
      throw new Error();
    }
  }

  function signUp(email: string, name: string, password: string) {
    setLoading(true);

    // usersApi.signUp({ email, name, password })
    //   .then((user) => {
    //     setUser(user);
    //     history.push("/");
    //   })
    //   .catch((error) => setError(error))
    //   .finally(() => setLoading(false));
  }

  function logout() {
    logoutMutation().finally(async () => {
      clearSessionToken();
      clearOwnerToken();
      setToken(null);
      setOwnerTokenState(null);
      clearFromUrl();
      setUser(undefined);
      // NOTE: clearStore clears the store but does not refetch active queries
      await client.clearStore();
      navigate(ScreenPaths.Login);
    });
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoValue = useMemo(
    () =>
      ({
        error,
        isLoggedIn: Boolean(user) && Boolean(token),
        loading: loading || authMutationState.loading,
        login,
        logout,
        setOwnerToken: setOwnerTokenState,
        setToken,
        signUp,
        user,
      } as AuthContextType),
    [user, loading, error, token, ownerToken, authMutationState],
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoValue}>{!loadingInitial && children}</AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
