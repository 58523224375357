import { Grid } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TimespaceTable } from 'Components';
import { clearFromUrl, getFromUrl } from 'Lib/Helpers/Session';

export default function TimespaceOverview() {
  const navigate = useNavigate();

  useEffect(() => {
    const fromUrl = getFromUrl();
    if (fromUrl) {
      navigate(fromUrl, { replace: true });
      clearFromUrl();
    }
  }, []);

  return (
    <Grid container spacing={4}>
      <TimespaceTable />
    </Grid>
  );
}
