import { Delete } from '@mui/icons-material';
import { useField } from 'formik';
import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
}

export default function RandomEventAcquiredObjectDelete({ id }: Props) {
  const { t } = useTranslation();
  const [, , { setValue }] = useField(id);
  return (
    <Tooltip placement="top" title={t('global.actions.delete').toString()}>
      <IconButton onClick={() => setValue(undefined)}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
}
