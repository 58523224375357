import { MUIDataTableColumnDef } from 'mui-datatables';
import { t } from 'i18next';
// @ts-expect-error does not have type definitions
import pmt from 'formula-pmt';
import { formatDuration, formatPercent, formatPrice, pv } from 'Lib/Helpers/Number';
import { FinancialObjectFragment, Timespace } from 'Generated/graphql-hooks';
import { calculateInterest } from '../Mortgage/utils';

export const consumerCreditTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('bank.account.name'),
    name: 'name',
  },
  {
    label: t('object.attr.interestPositive'),
    name: 'interestNegative',
    options: {
      customBodyRender: (value: number) => formatPercent(value),
      setCellProps: () => ({
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
  },
  {
    label: t('consumerCredit.maximal.time'),
    name: 'maturityPeriod',
    options: {
      customBodyRender: (value: number) => formatDuration(value / 12),
    },
  },
  {
    label: t('consumerCredit.maxCredit.amount'),
    name: 'principalMin',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
      setCellProps: () => ({
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
  },
  {
    label: t('mortgage.table.attr.monthlyPayment'),
    name: 'monthlyPayment',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
    },
  },
  {
    label: t('mortgage.table.attr.startingFee'),
    name: 'feesOneTime',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
    },
  },
];

export const calculateConsumerCreditMonthlyPayment = ({
  financialObject,
  maturityPeriod,
  requestedAmount,
  timespace,
}: {
  financialObject: FinancialObjectFragment;
  // in months
  maturityPeriod: number;
  requestedAmount: number;
  timespace?: Timespace | null;
}) => {
  const { feesPeriodical } = financialObject;
  const interest = calculateInterest(financialObject, timespace);

  const result =
    pmt((interest ?? 0) / 100 / 12, maturityPeriod ?? 0, -requestedAmount) + (feesPeriodical ?? 0);

  return Number.isNaN(result) ? null : result;
};

export function calculateMaxLoan({
  financialObject,
  maxMonthlyPayment,
}: {
  financialObject: FinancialObjectFragment;
  maxMonthlyPayment: number;
}) {
  const principalMin = financialObject.principalMin ?? 0;

  if (maxMonthlyPayment < 0) {
    return Math.abs(principalMin);
  }

  const mvp = principalMin * -1;
  const pvValue = pv(
    (financialObject.interestNegative ?? 0) / 12,
    financialObject.maturityPeriod ?? 0,
    maxMonthlyPayment - (financialObject.feesPeriodical ?? 0),
  );
  const mvo = isNaN(pvValue) ? Infinity : pvValue;

  return Math.max(Math.min(mvp, mvo), 0);
}

export function calculateMaxMonthlyPayment({
  livingWage,
  monthlyIncome,
  monthlyPayments,
  partnerIncome,
}: {
  livingWage: number;
  monthlyIncome: number;
  monthlyPayments: number;
  partnerIncome: number;
}) {
  return (monthlyIncome + partnerIncome - livingWage) * 0.6 - monthlyPayments;
}
