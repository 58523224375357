export const getObjectCodeFilterRecursive = (str?: string | null): any => {
  const result: Array<any> = [];
  if (!str) return { OR: result };

  for (let i = str.length; i > 1; i -= 2) {
    result.push({ objectCode: { equals: str.slice(0, i) } });
  }
  return { OR: result };
};

export const getCodeFilterRecursive = (str?: string | null): any => {
  const result: Array<any> = [];
  if (!str) return { OR: result };

  for (let i = str.length; i > 1; i -= 2) {
    result.push({ code: { equals: str.slice(0, i) } });
  }
  return { OR: result };
};
