import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
// eslint-disable-next-line import/named
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import TextFieldFormik from './TextFieldFormik';

export default React.memo(function PasswordFormik(props: TextFieldProps): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <TextFieldFormik
      {...props}
      InputProps={{
        ...(props.InputProps ?? {}),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  );
});
