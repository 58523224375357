import React from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/named
import { ListItemButton, ListItemButtonProps } from '@mui/material';

export default React.forwardRef(function EnhancedListItemButton(
  {
    children,
    exactMatch = false,
    path,
    ...restProps
  }: ListItemButtonProps & { exactMatch?: boolean; path: string },
  ref,
) {
  const navigate = useNavigate();
  return (
    <ListItemButton
      {...restProps}
      onClick={() => navigate(path)}
      selected={exactMatch ? location.pathname === path : location.pathname.indexOf(path) !== -1}>
      {children}
    </ListItemButton>
  );
});
