import React, { useContext } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Section } from 'Components';
import { namedOperations, useCreateStandingOrderMutation } from 'Generated/graphql-hooks';
import { Constants } from 'Config';
import { StandingOrder } from 'Models';
import { GameContext } from 'Context';
import { ExpirationTypeEnum, FinancialObjectCodeEnum } from 'Types/Global';
import { getHumanDateFromRound, getTimespaceYearFromRound } from 'Lib/Helpers/Date';
import { getNextInterestPeriod } from 'Lib/Helpers/TimespacePersonObject';
import StandingOrderForm, { FormValues } from './Form';

export default function StandingOrderCreate() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [createStandingOrder] = useCreateStandingOrderMutation();

  const handleFormikSubmit = async ({
    beneficiaryAccount,
    expirationDate,
    expirationOnExecutionCount,
    expirationType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id,
    initialDate,
    payersAccount,
    ...values
  }: FormValues) => {
    try {
      const initialRound =
        ((initialDate?.year ?? Constants.START_YEAR) - Constants.START_YEAR) * 12 +
        (initialDate?.month ?? 0);

      // NOTE: must be validated here, backend cannot send the date
      if (
        beneficiaryAccount?.financialObject?.code?.startsWith(
          FinancialObjectCodeEnum.FixedTermDepositAccount,
        ) &&
        (initialRound - (beneficiaryAccount?.procurementRound ?? 0)) %
          (beneficiaryAccount?.interestPeriod ?? 0) !==
          0
      ) {
        enqueueSnackbar(
          t('bank.term.error.nextPeriod', {
            replace: {
              date: getHumanDateFromRound(
                getNextInterestPeriod(beneficiaryAccount),
                beneficiaryAccount?.realizationDay?.toString() || '1',
              ),
            },
          }),
          { variant: 'error' },
        );
        return;
      }

      const { data: responseData } = await createStandingOrder({
        refetchQueries: [
          namedOperations.Query.BankCategories,
          namedOperations.Query.StandingOrders,
        ],
        variables: {
          data: {
            ...values,
            beneficiaryAccount: {
              connect: { id: beneficiaryAccount?.id },
            },
            expirationDay:
              expirationType === ExpirationTypeEnum.DATE ? expirationDate?.day ?? 1 : undefined,
            expirationOnExecutionCount:
              expirationType === ExpirationTypeEnum.COUNT ? expirationOnExecutionCount : undefined,
            expirationRound:
              expirationType === ExpirationTypeEnum.DATE
                ? ((expirationDate?.year ?? Constants.START_YEAR) - Constants.START_YEAR) * 12 +
                  (expirationDate?.month ?? 0)
                : undefined,
            initialDay: initialDate?.day ?? 1,
            initialRound,
            payersAccount: {
              connect: {
                id: payersAccount?.id,
              },
            },
          },
        },
      });

      if (responseData?.createStandingOrder) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  };

  const currentYearRound = (timespacePerson?.timespace?.round ?? 1) % 12 || 12;
  const currentYear = getTimespaceYearFromRound(timespacePerson?.timespace?.round);

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('bank.create.standingOrder')}>
        <StandingOrderForm
          formType="CREATE"
          initialValues={{
            ...new StandingOrder(),
            expirationDate: null,
            expirationType: ExpirationTypeEnum.TERMINATION as keyof typeof ExpirationTypeEnum,
            initialDate: { day: 1, month: currentYearRound, year: currentYear },
          }}
          onSubmit={handleFormikSubmit}
        />
      </Section>
    </Container>
  );
}
