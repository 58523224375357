import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { OrderDirection, useRandomEventsQuery } from 'Generated/graphql-hooks';
import { EnhancedDataTable } from 'Components/Generic';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { randomEventColumns } from './utils';

export default function RandomEventTable() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();

  const { data, loading } = useRandomEventsQuery({
    skip: !timespaceId,
    variables: {
      orderBy: [{ id: OrderDirection.Asc }],
      where: {
        NOT: [
          {
            description: {
              startsWith: FinancialObjectCodeEnum.MortgageLoan,
            },
          },
        ],
        timespace: { id: { equals: timespaceId } },
      },
    },
  });

  return (
    <Box>
      <EnhancedDataTable
        hideToolbar
        columns={randomEventColumns}
        data={data?.randomEvents || []}
        loading={loading}
        options={
          {
            setTableProps: () => ({ style: { minHeight: '165px' } }),
            sort: false,
          } as MUIDataTableOptions
        }
        title=""
      />
      <Box display="flex" justifyContent="end" py={2}>
        <Button component={RouterLink} startIcon={<Add />} to={`create`} variant="contained">
          {t('global.actions.add')}
        </Button>
      </Box>
    </Box>
  );
}
