import { Typography } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EnhancedDataTable, Error, SkeletonLoader } from 'Components';
import {
  OrderDirection,
  TimespaceFinancialTransactionStatusType,
  useTimespaceFinancialTransactionsQuery,
} from 'Generated/graphql-hooks';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { formatPrice } from 'Lib/Helpers/Number';
import { usePagination } from 'Lib/Hooks';

export default function TransactionsTable() {
  const { t } = useTranslation();
  const { objectId } = useParams();
  const { skip, take, ...pagination } = usePagination();

  const { data, error, loading } = useTimespaceFinancialTransactionsQuery({
    variables: {
      orderBy: [
        { realizedAt: OrderDirection.Desc },
        { round: OrderDirection.Desc },
        { day: OrderDirection.Desc },
        { priority: OrderDirection.Desc },
        { id: OrderDirection.Desc },
      ],
      skip,
      take,
      where: {
        AND: [
          {
            OR: [
              { amount: { lt: 0 } },
              {
                AND: [
                  {
                    status: {
                      not: { equals: TimespaceFinancialTransactionStatusType.RejectedLackOfFunds },
                    },
                  },
                ],
              },
            ],
          },
          { financialObject: { id: { equals: objectId } } },
          { amount: { not: { equals: 0 } } },
        ],
      },
    },
  });

  const preparedData = useMemo(
    () =>
      data?.timespaceFinancialTransactions?.map((transaction) => {
        return {
          ...transaction,
          financialObjectName: transaction?.relatedFinancialObject?.displayName
            ? transaction?.relatedFinancialObject?.displayName
            : transaction?.relatedMutualFund?.name,
        };
      }) ?? [],
    [data?.timespaceFinancialTransactions],
  );

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'round',
      options: { display: 'excluded', sort: false },
    },
    {
      label: t('bank.transaction.attr.day'),
      name: 'day',
      options: {
        customBodyRender: (day: string, meta: MUIDataTableMeta) => (
          <Typography variant="body2">{getHumanDateFromRound(meta.rowData[0], day)}</Typography>
        ),

        sort: false,
      },
    },
    {
      label: t('bank.transaction.attr.relatedObject'),
      name: 'relatedObject.name',
      options: {
        customBodyRender: (name: string) => name || '-',
        sort: false,
      },
    },
    {
      label: t('bank.transaction.attr.relatedFinancialObject'),
      name: 'financialObjectName',
      options: {
        customBodyRender: (name: string) => name || '-',

        sort: false,
      },
    },
    {
      label: t('bank.transaction.attr.type'),
      name: 'type',
      options: {
        customBodyRender: (value: string) => t(`enums.${value}`),
      },
    },
    {
      label: t('bank.transaction.attr.status'),
      name: 'status',
      options: {
        customBodyRender: (value: string) => t(`enums.${value}`),

        sort: false,
      },
    },
    {
      label: t('bank.transaction.attr.amount'),
      name: 'amount',
      options: {
        customBodyRender: (value: string) => {
          return (
            value && (
              <Typography noWrap color={Number(value) > 0 ? 'green' : 'error'} variant="body2">
                {formatPrice(value, false)}
              </Typography>
            )
          );
        },

        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
    {
      label: t('bank.transaction.attr.currentBalance'),
      name: 'currentBalance',
      options: {
        customBodyRender: (value: number) => {
          return (
            <Typography noWrap variant="body2">
              {value || value === 0 ? formatPrice(value, false) : '-'}
            </Typography>
          );
        },

        setCellProps: ({ props }: any) => {
          return { align: props.children === '-' ? 'center' : 'right' };
        },
        sort: false,
      },
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <EnhancedDataTable
      columns={columns}
      data={preparedData}
      options={
        {
          ...pagination,
          count: data?.count,
          setRowProps: (row, dataIndex) => {
            return {
              onMouseEnter: () => {
                console.log(
                  data?.timespaceFinancialTransactions &&
                    data.timespaceFinancialTransactions[dataIndex],
                );
              },
            };
          },
          textLabels: {
            body: {
              noMatch: loading ? <SkeletonLoader /> : t('bank.transaction.placeholder'),
            },
          },
        } as MUIDataTableOptions
      }
      title={<Typography variant="h6">{t('bank.transaction.list')}</Typography>}
    />
  );
}
