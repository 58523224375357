import { Close, Delete, Pending } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SafeDeleteModal from 'Components/Generic/SafeDeleteModal';
import { Section } from 'Components/index';
import { GameContext } from 'Context';
import {
  TimespacePersonObject,
  TimespacePersonObjectStatusType,
  namedOperations,
  useTimespacePersonWorkObjectsQuery,
  useUpdateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { getTimespaceYearFromRound } from 'Lib/Helpers/Date';
import { useModal } from 'Lib/Hooks';
import WorkButtonModal from './WorkButtonModal';

export default function WorkSection() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasAvailableWorkObjects, timespacePerson } = useContext(GameContext);
  const { handleClose, handleOpen, isOpen, selected } = useModal<TimespacePersonObject>();

  const { data } = useTimespacePersonWorkObjectsQuery({
    variables: { timespacePersonId: timespacePerson.id },
  });

  const [updateObject, { loading }] = useUpdateTimespacePersonObjectMutation({
    refetchQueries: [
      namedOperations.Query.MyTimespacePerson,
      namedOperations.Query.TimespacePersonObjects,
    ],
    variables: {
      data: { status: TimespacePersonObjectStatusType.Discarded },
      where: { id: selected?.id },
    },
  });

  const handleOnConfirm = useCallback(() => {
    updateObject()
      .then(() => {
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      });
  }, []);

  return (
    <>
      {hasAvailableWorkObjects || data?.timespacePersonObjects?.length ? (
        <Section title={t('game.work.section')}>
          <Grid container spacing={2}>
            {data?.timespacePersonObjects && data.timespacePersonObjects.length > 0 ? (
              data.timespacePersonObjects.map((item, index) => {
                const hasProcurementRound = !isNil(item.procurementRound);
                const hasEndRound =
                  !isNil(item.fullyUtilisedRound) || !isNil(item.discardmentRound);
                return (
                  <Grid
                    key={index}
                    container
                    item
                    alignItems="center"
                    display="flex"
                    onMouseEnter={() => {
                      console.log(item);
                    }}
                    spacing={1}
                    xs={12}>
                    <Grid item mt={0.5}>
                      {item.status === TimespacePersonObjectStatusType.Discarded && (
                        <Close color="error" />
                      )}
                      {(item.status === TimespacePersonObjectStatusType.Active ||
                        item.status === TimespacePersonObjectStatusType.Passive ||
                        item.status === TimespacePersonObjectStatusType.Procured) && (
                        <Pending color="disabled" />
                      )}
                    </Grid>
                    <Grid item>
                      {(hasProcurementRound || hasEndRound) && '('}
                      {hasProcurementRound
                        ? `${t('global.from')} ${getTimespaceYearFromRound(item.procurementRound)}`
                        : null}
                      {hasProcurementRound && hasEndRound && ' - '}
                      {hasEndRound
                        ? getTimespaceYearFromRound(
                            item.fullyUtilisedRound || item.discardmentRound,
                          )
                        : null}
                      {(hasProcurementRound || hasEndRound) && ') '}
                      {item.name}
                    </Grid>

                    <Grid item>
                      {item.status !== TimespacePersonObjectStatusType.Discarded &&
                        item.status !== TimespacePersonObjectStatusType.FullyUtilised && (
                          <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                            <IconButton
                              aria-label={t('global.actions.delete').toString()}
                              color="error"
                              onClick={() => handleOpen(item)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Grid>
                  </Grid>
                );
              })
            ) : hasAvailableWorkObjects ? (
              <Typography variant="caption">{t('game.work.placeholder')}</Typography>
            ) : null}
            {hasAvailableWorkObjects ? (
              <Grid item justifyItems="flex-end" xs={12}>
                <WorkButtonModal />
              </Grid>
            ) : (
              <Typography>{t('game.work.not.available')}</Typography>
            )}
          </Grid>
          <SafeDeleteModal
            loading={loading}
            onClose={handleClose}
            onConfirm={handleOnConfirm}
            open={isOpen}
            text={`${t('time_management.delete.confirm')} ${selected?.name} ?`}
          />
        </Section>
      ) : null}
    </>
  );
}
