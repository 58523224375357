import React, { useCallback } from 'react';
import { Container, Grid } from '@mui/material';
import { HouseholdSection } from 'Components/Household';
import { ConditionalGameScreenWrapper } from 'Components';

export default function Household() {
  const conditionCallback = useCallback(
    ({ isHouseholdSectionAvailable }) => !isHouseholdSectionAvailable,
    [],
  );

  return (
    <ConditionalGameScreenWrapper hideScreen={conditionCallback}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HouseholdSection />
          </Grid>
        </Grid>
      </Container>
    </ConditionalGameScreenWrapper>
  );
}
