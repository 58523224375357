import React, { useCallback, useContext } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent } from '@mui/material';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import {
  TimespacePersonFinancialObjectDocument,
  TimespacePersonFinancialObjectFragment,
  useTerminateBankAccountMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import SavingsAccountPostponedDiscardSchema from 'Config/Validations/SavingsAccountPostponedDiscardSchema';
import { getNextInterestPeriod } from 'Lib/Helpers/TimespacePersonObject';
import { BasicAccountSelector } from 'Components/FinancialObject';
import { ModalCloseButton } from 'Components/Generic';
import { DiscardTypeEnum } from './DiscardModal';

interface Props {
  discardType: DiscardTypeEnum;
  onClose: () => void;
  open: boolean;
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function SavingsAccountPostponedDiscardModal({
  discardType,
  onClose: handleClose,
  open,
  timespacePersonFinancialObject,
}: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const [discardAccount] = useTerminateBankAccountMutation();

  const handleSubmitFormik = useCallback(
    async ({ toFinancialObject }) => {
      try {
        const { data: responseData } = await discardAccount({
          refetchQueries: [
            {
              query: TimespacePersonFinancialObjectDocument,
              variables: { where: { id: Number(timespacePersonFinancialObject!.id) } },
            },
          ],
          variables: {
            discardmentRound:
              discardType === DiscardTypeEnum.NOW
                ? timespacePerson.timespace?.round ?? 0
                : getNextInterestPeriod(timespacePersonFinancialObject),
            fromTimespacePersonFinancialObjectId: Number(timespacePersonFinancialObject!.id),
            terminateImmediately: discardType === DiscardTypeEnum.NOW,
            toTimespacePersonFinancialObjectId: Number(toFinancialObject!.id),
          },
        });
        if (responseData?.terminateBankAccount) {
          handleClose();
          enqueueSnackbar(t('bank.savings.cancel_success'), { variant: 'success' });
        } else {
          throw new Error();
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      }
    },
    [timespacePersonFinancialObject, discardAccount, discardType, enqueueSnackbar],
  );

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle sx={{ mr: 2 }}>
        {t('bank.savings.discard.choose_account', {
          replace: {
            value:
              discardType === DiscardTypeEnum.NOW
                ? t('global.immediately')
                : getHumanDateFromRound(
                    getNextInterestPeriod(timespacePersonFinancialObject),
                    timespacePersonFinancialObject?.realizationDay?.toString() || '1',
                  ),
          },
        })}
        <ModalCloseButton onClose={handleClose} />
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          toFinancialObject: null,
        }}
        onSubmit={handleSubmitFormik}
        validationSchema={SavingsAccountPostponedDiscardSchema}>
        {({ handleSubmit }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <BasicAccountSelector id="toFinancialObject" />
              </DialogContent>
              <DialogActions>
                <LoadingButton type="submit" variant="contained">
                  <span>{t('global.actions.ok')}</span>
                </LoadingButton>
                <LoadingButton onClick={handleClose} variant="outlined">
                  <span>{t('global.actions.cancel')}</span>
                </LoadingButton>
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
