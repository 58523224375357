import { Object as IObject } from 'Generated/graphql-hooks';
import { formatPrice } from 'Lib/Helpers/Number';

/* eslint-disable */
export const pickSortedObjectProperties = (({
 lifeQualityImpactAbsence,
 lifeQualityImpactOneTime,
 lifeQualityImpactPeriodical,
 lifeQualityImpactMaxCount,
 lifeQualityImpactMaxRounds,
 timeRequired,
 assetsImpactOneTime,
 assetsImpactPeriodicalPassive,
 assetsImpactPeriodicalActive,
 utilisationMinRounds,
 utilisationMaxRounds,
 assetsImpactMaxRounds,
}: IObject) => ({
  lifeQualityImpactAbsence,
  lifeQualityImpactOneTime,
  lifeQualityImpactPeriodical,
  lifeQualityImpactMaxCount,
  lifeQualityImpactMaxRounds,
  timeRequired,
  assetsImpactOneTime : formatPrice(assetsImpactOneTime),
  assetsImpactPeriodicalPassive : formatPrice(assetsImpactPeriodicalPassive),
  assetsImpactPeriodicalActive : formatPrice(assetsImpactPeriodicalActive),
  utilisationMinRounds,
  utilisationMaxRounds,
  assetsImpactMaxRounds,
}));
