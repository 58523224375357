import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import {
  FinancialObject,
  FinancialObjectWhereInput,
  OrderDirection,
  TimespacePersonFinancialObject,
  useFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import { NoData, SkeletonLoader } from 'Components/Generic';
import { FinancialObjectModal } from '../FinancialObject';
import FinancialObjectTreeItem from './FinancialObjectTreeItem';
import TreeViewCustomized from './TreeViewCustomized';

interface Props {
  disableDetail?: boolean;
  disabledFunction?: (item: FinancialObject) => boolean | undefined | null;
  hideDisabledCheckbox?: boolean;
  initialAge?: number;
  onChange: (event: FinancialObject, checked: boolean) => void;
  values: FinancialObject[];
  where?: FinancialObjectWhereInput;
}

export default function FinancialObjectTreeView({
  initialAge,
  where = { codeLength: { equals: 4 } },
  ...restProps
}: Props) {
  const [detailedItem, setDetailedItem] = useState<TimespacePersonFinancialObject | null>();
  const { data, error, loading } = useFinancialObjectsQuery({
    variables: {
      orderBy: [{ name: OrderDirection.Desc }],
      where,
    },
  });

  if (loading) {
    return (
      <Box maxWidth={300}>
        <SkeletonLoader height={30} />
      </Box>
    );
  }
  if (!data?.financialObjects || error) {
    return <NoData />;
  }

  return (
    <>
      <FinancialObjectModal
        readonly
        onClose={() => setDetailedItem(undefined)}
        open={Boolean(detailedItem)}
        timespaceFinancialObject={detailedItem}
      />
      <TreeViewCustomized<FinancialObject>
        {...restProps}
        data={data.financialObjects || []}
        onDetailClick={setDetailedItem}
        renderItem={(object) => (
          <FinancialObjectTreeItem key={object.code} initialAge={initialAge ?? 0} object={object} />
        )}
      />
    </>
  );
}
