import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Object, OrderDirection } from 'Generated/graphql-hooks';
import { useGetTimespaceAvailableObjects } from 'Lib/Hooks';
import { GameContext } from 'Context';
import { LivingObjectCodeEnum } from 'Types/Global';

interface LivingContextType {
  categories?: Object[] | null;
  categoryCode?: string;
  loading?: boolean;
  selectedCategoryCode?: string;
  setSelectedCategoryCode: (code: string) => void;
}

export const LivingContext = React.createContext<LivingContextType>({
  setSelectedCategoryCode: () => null,
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function LivingContextProvider({ children }: Props) {
  const [categoryCode, setCategoryCode] = useState<string>();
  const { loading: gameContextLoading } = useContext(GameContext);
  const { data, loading } = useGetTimespaceAvailableObjects({
    skip: gameContextLoading,
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      skip: 0,
      take: 1000,
      where: {
        AND: [
          { code: { startsWith: LivingObjectCodeEnum.Living } },
          { codeLength: { equals: 4 } },
          { isAbstract: { equals: true } },
        ],
      },
    },
  });

  useEffect(() => {
    // set default category
    if (data?.objects?.length && data?.objects?.[0]?.code) {
      setCategoryCode(data.objects[0].code);
    }
  }, [data?.objects?.length]);

  const memoValue = useMemo(
    () => ({
      categories: data?.objects,
      loading,
      selectedCategoryCode: categoryCode,
      setSelectedCategoryCode: setCategoryCode,
    }),
    [data?.objects?.length, loading, categoryCode],
  );

  return <LivingContext.Provider value={memoValue}>{children}</LivingContext.Provider>;
}

export function useLivingContext() {
  return useContext(LivingContext);
}
