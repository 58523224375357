import * as Yup from 'yup';
// TODO: we should create an event listener for language change
// https://dev.to/codedivoire/how-to-internationalize-a-yup-validation-schema-in-a-react-formik-and-react-i18next-app-cj7
import { t } from 'i18next';
import { formatPrice } from 'Lib/Helpers/Number';

export default Yup.object({
  amountRequested: Yup.number()
    .min(0, t('validations.positiveNumber'))
    .test('amountRequested', function test(amountRequested = 0, { createError, options }) {
      // @ts-expect-error not typed yet
      const maxLoan = options.parent?.maxLoanAmount ?? 0;

      return amountRequested <= maxLoan
        ? true
        : createError({
            message: t('consumerCredit.amountRequested.limitExceeded', {
              replace: {
                value: formatPrice(maxLoan),
              },
            }),
          });
    })
    .nullable()
    .required(t('validations.required')),
  day: Yup.number().required('validations.required'),
  financialObject: Yup.object().required('validations.required').nullable(),
  maturityPeriod: Yup.number()
    .test(
      'maturityPeriod',
      'consumerCredit.maturityPeriod.limitExceeded',
      function test(maturityPeriod = 0) {
        return (
          // @ts-expect-error not typed yet
          maturityPeriod <= (this.options.parent?.financialObject?.maturityPeriod ?? 0) / 12
        );
      },
    )
    .min(1, 'validations.moreThen0')
    .nullable()
    .required('validations.required'),
  monthlyPayment: Yup.number()
    .test('monthlyPayment', function test(monthlyPayment = 0, { createError, options }) {
      // @ts-expect-error not typed yet
      const maxMonthlyPayment = options.parent?.maxMonthlyPayment ?? 0;
      return !monthlyPayment || (maxMonthlyPayment > 0 && monthlyPayment <= maxMonthlyPayment)
        ? true
        : createError({
            message: t('consumerCredit.monthlyPayment.limitExceeded', {
              replace: {
                value: maxMonthlyPayment > 0 ? formatPrice(maxMonthlyPayment) : formatPrice(0),
              },
            }),
          });
    })
    .nullable(),
  payersAccount: Yup.object().required('validations.required').nullable(),
});
