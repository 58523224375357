import React, { useContext } from 'react';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { useLivingContext } from 'Context/Living';
import { SkeletonLoader } from 'Components/Generic';
import { GameContext } from 'Context';

export default function LivingCategories() {
  const { t } = useTranslation();
  const { loading: gameContextLoading } = useContext(GameContext);
  const { categories, loading, selectedCategoryCode, setSelectedCategoryCode } = useLivingContext();

  return (
    <List>
      <ListItem>
        <ListItemText primary={t('game.menu.living')} />
      </ListItem>

      {loading || gameContextLoading ? (
        <SkeletonLoader height={40} />
      ) : (
        <>
          {categories?.map(({ code, name }, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                if (code) {
                  setSelectedCategoryCode(code);
                }
              }}
              selected={code === selectedCategoryCode}
              sx={{
                whiteSpace: 'normal',
              }}>
              <ListItemText primary={name} sx={{ ml: 2 }} />
            </ListItemButton>
          ))}
        </>
      )}
    </List>
  );
}
