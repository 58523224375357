import React from 'react';
import { Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BasicAccountSelector,
  CheckboxFormik,
  LabeledText,
  SelectFormik,
  TextFieldFormik,
} from 'Components';
import { Constants } from 'Config';
import { TimespacePersonFinancialObject } from 'Generated/graphql-hooks';
import { MutualFundTransactionBuySchema } from 'Config/Validations';

export type FormValues = {
  amountRequested?: number | null;
  day?: number | null;
  investmentRepeat?: boolean;
  relatedBankAccount?: TimespacePersonFinancialObject | null;
  repeatRounds?: number | null;
};

interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<any>;
}

export default function MutualFundTransactionBuyForm({ initialValues, onSubmit }: Props) {
  const { t } = useTranslation();

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={MutualFundTransactionBuySchema}>
      {({ handleSubmit, isSubmitting, isValid, submitForm, values }) => {
        return (
          <Box noValidate component="form" onSubmit={handleSubmit}>
            <LabeledText
              label={t('investments.invest.amount')}
              value={
                <TextFieldFormik
                  InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                  id="amountRequested"
                  placeholder={t('global.placeholder.amount')}
                  type="number"
                />
              }
            />
            <LabeledText
              label={t('investments.invest.day.buy')}
              value={
                <SelectFormik
                  fullWidth
                  id="day"
                  options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
                />
              }
            />
            <LabeledText
              label={t('object.attr.relatedFinancialObject')}
              value={<BasicAccountSelector id="relatedBankAccount" />}
            />
            <Box display="flex" justifyContent="flex-end">
              <FormControlLabel
                control={<CheckboxFormik id="investmentRepeat" />}
                label={t('investments.repeat.investment') as string}
              />
            </Box>
            {values.investmentRepeat ? (
              <LabeledText
                label={t('investments.repeat.count')}
                value={
                  <TextFieldFormik
                    InputProps={{
                      inputProps: { min: 1 },
                    }}
                    id="repeatRounds"
                    type="number"
                  />
                }
              />
            ) : null}

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <LoadingButton
                disabled={!isValid}
                loading={isSubmitting}
                onClick={submitForm}
                variant="contained">
                <span>{t('investments.actions.buy')}</span>
              </LoadingButton>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
