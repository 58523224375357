import { Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import * as React from 'react';

export default function LabeledText({
  dense,
  endAdorment,
  fixedLabel,
  label,
  sx,
  value,
}: {
  dense?: boolean;
  endAdorment?: string;
  fixedLabel?: boolean;
  label: string;
  sx?: SxProps<Theme>;
  value?: string | number | null | React.ReactNode;
}) {
  return (
    <Box alignItems="center" display="flex" sx={sx}>
      <Box flex={3} sx={fixedLabel ? { alignSelf: 'flex-start', paddingTop: 2 } : undefined}>
        {label}:
      </Box>
      <Box display="flex" flex={dense ? 1 : 2} justifyContent="end">
        {value ?? '-'}
        {endAdorment && ` ${endAdorment}`}
      </Box>
    </Box>
  );
}
