/* eslint-disable @typescript-eslint/member-ordering */
import { groupBy, isEmpty, isNil } from 'lodash';
import {
  TimespaceUpdateInput as ITimespaceUpdateInput,
  InputMaybe,
  RandomEventRelateToManyForUpdateInput,
  Scalars,
  Timespace,
  TimespaceAvailableObjectRelateToManyForUpdateInput,
  TimespacePersonFinancialObjectRelateToManyForUpdateInput,
  TimespacePersonFinancialObjectRelateToOneForUpdateInput,
  TimespacePersonObjectRelateToManyForUpdateInput,
  UserRelateToOneForUpdateInput,
} from 'Generated/graphql-hooks';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import {
  RandomEventCreateInput,
  TimespacePersonFinancialObjectCreateInput,
  TimespacePersonObjectCreateInput,
} from 'Models/index';

export default class TimespaceUpdateInput implements ITimespaceUpdateInput {
  constructor(timespace: Timespace) {
    timespace = mapValuesDeep(timespace, (v) => (v === '' ? null : v));
    this.description = timespace.description;
    this.goalsDescription = timespace.goalsDescription;
    this.isPrototype = timespace.isPrototype;
    this.isPublic = timespace.isPublic;
    this.name = timespace.name;
    this.personInitialAge = timespace?.personInitialAge || 0;
    this.round = timespace.round;
    this.roundsPerStep = timespace.roundsPerStep;
    this.defaultPaymentMethod = timespace?.defaultPaymentMethod?.id
      ? { connect: { id: timespace?.defaultPaymentMethod?.id } }
      : undefined;

    const groupedAvailableObjects = groupBy(timespace.timespaceAvailableObjects, (x) =>
      isEmpty(x.id) || isNil(x.id) ? 'create' : 'set',
    );

    this.timespaceAvailableObjects = {
      create: groupedAvailableObjects.create?.map(({ objectCode }) => ({
        objectCode,
      })),
      set: groupedAvailableObjects.set?.map(({ id }) => ({ id })) || [],
    };

    const groupedFinancialObjects = groupBy(timespace.timespacePersonFinancialObjects, (x) =>
      isEmpty(x.id) || isNil(x.id) ? 'create' : 'set',
    );
    this.timespacePersonFinancialObjects = {
      create: groupedFinancialObjects.create?.map(
        (item) => new TimespacePersonFinancialObjectCreateInput(item, timespace.id),
      ),
      set: groupedFinancialObjects.set?.map(({ id }) => ({ id })) || [],
    };

    const groupedObjects = groupBy(timespace.timespacePersonObjects, (x) =>
      isEmpty(x.id) || isNil(x.id) ? 'create' : 'set',
    );
    this.timespacePersonObjects = {
      create: groupedObjects.create?.map(
        (item) => new TimespacePersonObjectCreateInput(item, timespace.id),
      ),
      set: groupedObjects.set?.map(({ id }) => ({ id })) || [],
    };

    const groupedRandomEvents = groupBy(timespace.randomEvents, (x) =>
      isEmpty(x.id) || isNil(x.id) ? 'create' : 'set',
    );
    this.randomEvents = {
      create: groupedRandomEvents.create?.map(
        (item) => new RandomEventCreateInput(item, timespace.id),
      ),
      set: groupedRandomEvents.set?.map(({ id }) => ({ id })) || [],
    };
  }

  defaultPaymentMethod?: InputMaybe<TimespacePersonFinancialObjectRelateToOneForUpdateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  goalsDescription?: InputMaybe<Scalars['String']['input']>;
  isPrototype?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<UserRelateToOneForUpdateInput>;
  personInitialAge?: InputMaybe<Scalars['Int']['input']>;
  round?: InputMaybe<Scalars['Int']['input']>;
  roundsPerStep?: InputMaybe<Scalars['Int']['input']>;
  timespaceAvailableObjects?: InputMaybe<TimespaceAvailableObjectRelateToManyForUpdateInput>;
  timespacePersonFinancialObjects?: InputMaybe<TimespacePersonFinancialObjectRelateToManyForUpdateInput>;
  timespacePersonObjects?: InputMaybe<TimespacePersonObjectRelateToManyForUpdateInput>;
  randomEvents?: InputMaybe<RandomEventRelateToManyForUpdateInput>;
}
