import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { pick } from 'lodash';
import { Formik } from 'formik';
import { FormValues } from 'Components/Bank/CurrentAccountDetail';
import { BankDetailSchema } from 'Config/Validations';
import { TextFieldFormik } from 'Components/Input';
import { TimespacePersonFinancialObjectFragment } from 'Generated/graphql-hooks';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { useModal, useTimespacePersonFinancialObjectUpdate } from 'Lib/Hooks';
import { LabeledText } from 'Components/Generic';
import {
  getNextInterestPeriod,
  isObjectDiscarded,
  willObjectBeDiscarded,
} from 'Lib/Helpers/TimespacePersonObject';
import { formatPercent } from 'Lib/Helpers/Number';
import SavingsAccountDiscardModal from './DiscardModal';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function SavingsAccountDetail({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const {
    handleClose: handleDiscardSavingsAccountClose,
    handleOpen: handleDiscardSavingsAccountOpen,
    isOpen: isDiscardSavingsAccountOpen,
  } = useModal();
  const [handleFormikSubmit, { loading }] = useTimespacePersonFinancialObjectUpdate(
    timespacePersonFinancialObject?.id,
  );

  const isDiscarded = isObjectDiscarded(pick(timespacePersonFinancialObject, 'objectStatus'));
  const willBeDiscarded = willObjectBeDiscarded(
    pick(timespacePersonFinancialObject, ['objectStatus', 'discardmentRound']),
  );

  return timespacePersonFinancialObject ? (
    <Box component={Paper} p={2}>
      {isDiscarded || willBeDiscarded ? (
        <Box mb={2} p={1}>
          <Typography gutterBottom color={(theme) => theme.palette.error.main}>
            {t(
              isDiscarded
                ? 'bank.savings.discarded'
                : willBeDiscarded
                ? 'bank.account.willBeDiscarded'
                : '',
            )}
          </Typography>
        </Box>
      ) : null}
      <Formik<FormValues>
        initialValues={{
          customName: timespacePersonFinancialObject.displayName ?? '',
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={BankDetailSchema}>
        {({ handleSubmit }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <LabeledText
                label={t('bank.account.savings')}
                sx={{ py: 1 }}
                value={
                  isDiscarded || willBeDiscarded ? (
                    timespacePersonFinancialObject?.name
                  ) : (
                    <TextFieldFormik id="customName" type="string" />
                  )
                }
              />
              <LabeledText
                label={t('bank.savings.realization.day')}
                sx={{ py: 1 }}
                value={getHumanDateFromRound(
                  timespacePersonFinancialObject?.procurementRound || 0,
                  timespacePersonFinancialObject?.realizationDay?.toString() || '1',
                )}
              />
              <LabeledText
                label={t('bank.savings.interest.period')}
                sx={{ py: 1 }}
                value={
                  timespacePersonFinancialObject?.interestPeriod
                    ? `${timespacePersonFinancialObject.interestPeriod} m.`
                    : '-'
                }
              />
              <LabeledText
                label={t('bank.savings.next.interest')}
                sx={{ py: 1 }}
                value={
                  isDiscarded
                    ? '-'
                    : getHumanDateFromRound(
                        getNextInterestPeriod(timespacePersonFinancialObject),
                        timespacePersonFinancialObject?.realizationDay?.toString() || '1',
                      )
                }
              />
              <LabeledText
                label={t('bank.savings.interest.positive')}
                sx={{ py: 1 }}
                value={
                  timespacePersonFinancialObject?.interestPositive
                    ? `${formatPercent(timespacePersonFinancialObject?.interestPositive)} p.a.`
                    : '-'
                }
              />
              {!isDiscarded && !willBeDiscarded ? (
                <Box display="flex" justifyContent="end" pt={2}>
                  <Button
                    aria-label="delete"
                    color="error"
                    onClick={handleDiscardSavingsAccountOpen}
                    startIcon={<Delete />}
                    sx={{ mr: '5px' }}
                    variant="contained">
                    {t('bank.savings.cancel')}
                  </Button>
                  <LoadingButton loading={loading} type="submit" variant="contained">
                    <span>{t('global.actions.update')}</span>
                  </LoadingButton>
                </Box>
              ) : null}
            </Box>
          );
        }}
      </Formik>
      <SavingsAccountDiscardModal
        onClose={handleDiscardSavingsAccountClose}
        open={isDiscardSavingsAccountOpen}
        timespacePersonFinancialObject={timespacePersonFinancialObject}
      />
    </Box>
  ) : null;
}
