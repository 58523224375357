import { Maybe, Object, RandomEventAcquiredObject, Scalars } from 'Generated/graphql-hooks';
import { RandomEventObjectAttributeInput } from 'Models/RandomEventObjectAttributeInput';

export class RandomEventAcquiredObjectInput implements DeepNullable<RandomEventAcquiredObject> {
  __typename?: DeepNullable<'RandomEventAcquiredObject' | undefined> | null;
  assetsImpactOneTime?: RandomEventObjectAttributeInput = new RandomEventObjectAttributeInput();
  id: string | null | undefined;
  isAssetsImpactPositive?: Maybe<Scalars['Boolean']['input']> = false;
  object?: Maybe<Object> | undefined;
}
