import { Skeleton, TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'Lib/Helpers/Number';
import { EnhancedDataTable } from 'Components/Generic';
import { getPersonalBudgetTableColumns } from './utils';

interface Props {
  data: { name: string; sum: number }[];
  loading: boolean;
  name: string;
  sum: number;
}

export default function PersonalBudgetTable({ data, loading, name, sum }: Props) {
  const { t } = useTranslation();

  if (loading) {
    return <Skeleton height="400px" variant="rectangular" />;
  }

  return (
    <EnhancedDataTable
      columns={getPersonalBudgetTableColumns()}
      data={data}
      options={
        {
          count: data?.length,
          customFooter: () => (
            <TableFooter>
              <TableRow>
                <TableCell>
                  <Typography
                    color="black"
                    component="span"
                    fontWeight="bold"
                    textTransform="uppercase"
                    variant="body2">
                    {t('budget.total.label')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={sum > 0 ? 'green' : sum < 0 ? 'red' : 'black'}
                    component="div"
                    fontWeight="bold"
                    textAlign="right"
                    variant="h6">
                    {formatPrice(sum, false)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          ),
          pagination: false,
          responsive: 'simple',
          textLabels: {
            body: {
              noMatch: '',
            },
          },
        } as MUIDataTableOptions
      }
      title={<Typography fontWeight="bold">{name}</Typography>}
    />
  );
}
