import { Box } from '@mui/material';
import { t } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { RandomEventAcquiredObject } from 'Generated/graphql-hooks';
import RandomEventAcquiredObjectDelete from 'Components/RandomEvent/AcquiredObject/RandomEventAcquiredObjectDelete';
import RandomEventAcquiredObjectEdit from './RandomEventAcquiredObjectEdit';

export const acquiredObjectColumns = (id: string): MUIDataTableColumnDef[] => [
  { name: 'id', options: { display: 'excluded' } },
  {
    label: t('object.attr.name'),
    name: 'object.name',
  },
  {
    label: t('object.attr.description'),
    name: 'object.description',
  },
  {
    label: t('object.attr.lifeQualityImpactOneTime'),
    name: 'object.lifeQualityImpactOneTime',
  },
  {
    label: t('object.attr.lifeQualityImpactPeriodical'),
    name: 'object.lifeQualityImpactPeriodical',
  },
  {
    label: t('object.attr.timeRequired'),
    name: 'object.timeRequired',
  },
  {
    label: t('global.table.actions'),
    name: 'actions',
    options: {
      customBodyRender: () => {
        return (
          <Box display="flex">
            <RandomEventAcquiredObjectEdit id={id} />
            <RandomEventAcquiredObjectDelete id={id} />
          </Box>
        );
      },
      sort: false,
    },
  },
];

export function validateAcquiredObject(values: DeepNullable<RandomEventAcquiredObject>) {
  const errors = {} as any;
  if (!values.assetsImpactOneTime) {
    return errors;
  }
  const array = Object.entries(values.assetsImpactOneTime).filter(
    ([, val]) => typeof val === 'number',
  );
  if (
    !(
      array.every(([, val]) => !Number.isNaN(val) && val! >= 0) ||
      array.every(([, val]) => !Number.isNaN(val) && val! <= 0)
    )
  ) {
    errors.assetsImpactOneTime = Object.fromEntries(array.map(([key]) => [key, '']));
  }
  return errors;
}
