import * as Yup from 'yup';
import { UserParticipantTypeType } from 'Generated/graphql-hooks';

export default Yup.object({
  confirmPassword: Yup.string()
    .min(8, 'validations.password')
    .oneOf([Yup.ref('password')], 'validations.passwordMatch')
    .required('validations.required'),
  email: Yup.string().trim().email('validations.invalid.email').required('validations.required'),
  firstName: Yup.string().required('validations.required'),
  grade: Yup.string().when('participantType', {
    is: (value: UserParticipantTypeType) => value === UserParticipantTypeType.Student,
    then: Yup.string().required('validations.required'),
  }),
  lastName: Yup.string().required('validations.required'),
  nickName: Yup.string()
    .matches(/^[a-zA-Z0-9_-]*$/, 'register.nickName.hint')
    .required('validations.required'),
  password: Yup.string().min(8, 'validations.password').required('validations.required'),
  schoolName: Yup.string().required('validations.required').nullable(),
  teacherFirstName: Yup.string().when('participantType', {
    is: (value: UserParticipantTypeType) => value === UserParticipantTypeType.Student,
    then: Yup.string().required('validations.required'),
  }),
  teacherLastName: Yup.string().when('participantType', {
    is: (value: UserParticipantTypeType) => value === UserParticipantTypeType.Student,
    then: Yup.string().required('validations.required'),
  }),
});
