import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { TimespacePersonObject, TimespacePersonObjectCreateInput } from 'Models';
import { ErrorFallbackPlaceholder, ModalCloseButton, TextFieldFormik } from 'Components';
import {
  OrderDirection,
  namedOperations,
  useCreateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { useErrorHandler, useGetTimespaceAvailableObjects } from 'Lib/Hooks';
import { HouseholdPartnerSchema } from 'Config/Validations';
import { adultAgeRange } from 'Config/Constants';
import { HouseholdObjectCodes } from 'Types/Global';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function HouseholdPartnerAddModal({ onClose, open }: Props) {
  const { handleError } = useErrorHandler();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    data: abstractObjectData,
    error,
    loading,
  } = useGetTimespaceAvailableObjects({
    skip: !open,
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      where: {
        AND: [
          { code: { equals: HouseholdObjectCodes.PARTNER } },
          { isAbstract: { equals: false } },
        ],
      },
    },
  });

  const [createTimespacePersonObject] = useCreateTimespacePersonObjectMutation();

  const handleFormikSubmit = useCallback(
    async ({ procurementRound, ...values }: TimespacePersonObject) => {
      const { data, errors } = await createTimespacePersonObject({
        refetchQueries: [namedOperations.Query.TimespacePersonHouseholdObjects],
        variables: {
          data: new TimespacePersonObjectCreateInput(
            {
              ...values,
              assetsImpactOneTime: 0,
              procurementRound:
                (timespacePerson.timespace?.round ?? 0) - (procurementRound ?? 0) * 12,
            },
            timespaceId,
            timespacePerson.id,
          ),
        },
      });

      if (data?.createTimespacePersonObject) {
        enqueueSnackbar(t('household.partner.added'), { variant: 'success' });
        onClose();
      } else {
        handleError(errors, { ageMin: values?.ageMin });
      }
    },
    [onClose, enqueueSnackbar, t],
  );

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('household.actions.partner.add')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik<TimespacePersonObject>
        enableReinitialize
        initialValues={{
          ...new TimespacePersonObject(abstractObjectData?.objects?.[0]),
          customName: '',
          procurementRound: 18,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={HouseholdPartnerSchema}>
        {({ handleSubmit, isSubmitting, submitForm }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <ErrorFallbackPlaceholder
                  data={abstractObjectData?.objects}
                  error={error}
                  loading={loading}>
                  {loading ? (
                    <Box display="flex" justifyContent="center" py={5}>
                      <CircularProgress size={30} />
                    </Box>
                  ) : (
                    <>
                      <Grid item xs={4}>
                        {t('player.attr.name')}:
                      </Grid>
                      <Grid item xs={8}>
                        <TextFieldFormik
                          id="customName"
                          inputProps={{ maxLength: 12 }}
                          placeholder={t('household.partner.placeholder.name')}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {t('player.attr.age')}:
                      </Grid>
                      <Grid item xs={8}>
                        <TextFieldFormik
                          InputProps={{
                            inputProps: { max: adultAgeRange.to, min: adultAgeRange.from },
                          }}
                          id="procurementRound"
                          placeholder={t('global.placeholder.age')}
                          type="number"
                        />
                      </Grid>
                    </>
                  )}
                </ErrorFallbackPlaceholder>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                {!error ? (
                  <LoadingButton loading={isSubmitting} onClick={submitForm} variant="contained">
                    <span>{t('global.actions.save')}</span>
                  </LoadingButton>
                ) : null}
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
