import { Add } from '@mui/icons-material';
import * as React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RandomEventAcquiredObject } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import RandomEventAcquiredObjectModal from './RandomEventAcquiredObjectModal';

interface Props {
  onConfirm: (values: RandomEventAcquiredObject) => void;
}

export default function RandomEventAcquiredObjectAdd({ onConfirm }: Props) {
  const { t } = useTranslation();
  const { handleClose, handleOpen, isOpen } = useModal();

  return (
    <>
      <Tooltip title={t('global.actions.detail').toString()}>
        <Button onClick={() => handleOpen()} startIcon={<Add />} variant="outlined">
          {t('global.actions.add')}
        </Button>
      </Tooltip>

      <RandomEventAcquiredObjectModal onClose={handleClose} onSubmit={onConfirm} open={isOpen} />
    </>
  );
}
