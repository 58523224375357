export default class MutualFundTransactionCreateInput implements MutualFundTransactionCreateInput {
  amount = undefined;
  amountRequested = undefined;
  day = 1;
  fee = undefined;
  fund = undefined;
  person = undefined;
  relatedBankAccount = null;
  repeatRounds = undefined;
  round = undefined;
  sharePrice = undefined;
  volume = undefined;
}
