import {
  Box,
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  // eslint-disable-next-line import/named
  SelectProps,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ExtendedSelectProps<T> extends SelectProps<T> {
  displayEmpty?: boolean;
  filterOptions?: any[];
  options: any[];
}

export default function Select<T>({
  displayEmpty = false,
  fullWidth,
  placeholder,
  options = [],
  label,
  value,
  id,
  renderValue = (item?: T) => item,
  ...restProps
}: ExtendedSelectProps<T>): JSX.Element {
  const { t } = useTranslation();

  const labelId = useMemo(() => Date.now(), []).toString();

  return (
    <Box
      component={FormControl}
      fullWidth={fullWidth}
      margin="dense"
      mb={0.5}
      minWidth="20%"
      mt={1}
      size="small"
      width={fullWidth ? '100%' : undefined}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <MUISelect
        displayEmpty={displayEmpty}
        error={false}
        id={id}
        label={label}
        labelId={labelId}
        // defaultValue={displayEmpty ? '' : undefined}
        margin="dense"
        renderValue={renderValue}
        size="small"
        variant="outlined"
        {...restProps}
        value={value || ('' as any)}>
        {displayEmpty && (
          <MenuItem value="">
            <em>{placeholder ?? t('global.empty')}</em>
          </MenuItem>
        )}
        {options &&
          options.map((item, index) => (
            <MenuItem key={index} value={item}>
              {renderValue(item)}
            </MenuItem>
          ))}
      </MUISelect>
    </Box>
  );
}
