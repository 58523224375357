import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import {
  Object,
  ObjectWhereInput,
  OrderDirection,
  useObjectsWithChildrenFlagQuery,
} from 'Generated/graphql-hooks';
import { TimespacePersonObject } from 'Models';
import { GameContext } from 'Context';
import { NoData, SkeletonLoader } from 'Components/Generic';
import { getCodeFilterRecursive } from 'Lib/Helpers/Code';
import { ObjectModal } from '../Object';
import TreeViewCustomized from './TreeViewCustomized';
import ObjectTreeItem from './ObjectTreeItem';

interface Props {
  availableOnly?: boolean;
  baseCodeLength?: number;
  disableDetail?: boolean;
  disabledFunction?: (item: Object) => boolean | undefined | null;
  hideDisabledCheckbox?: boolean;
  initialAge?: number;
  onChange: (object: Object, checked: boolean) => void;
  onDataLoaded?: (objects: Object[]) => void;
  onToggleAll?: (objects: Object[], allSelected: boolean) => void;
  values: Object[];
  where?: ObjectWhereInput;
}

export default function ObjectTreeView({
  availableOnly,
  baseCodeLength = 2,
  initialAge,
  onDataLoaded,
  onToggleAll,
  values,
  where = { codeLength: { equals: 2 } },
  ...restProps
}: Props) {
  const [detailedItem, setDetailedItem] = useState<TimespacePersonObject | null>();
  const { timespacePerson } = useContext(GameContext) ?? {};
  const { data, error, loading, previousData } = useObjectsWithChildrenFlagQuery({
    fetchPolicy: 'cache-first',
    variables: {
      onlyAvailable: availableOnly,
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      ...(timespacePerson?.id && availableOnly
        ? { timespacePersonId: Number(timespacePerson.id) }
        : {}),
      where: {
        AND: [
          where || {},
          { codeLength: { equals: baseCodeLength } },
          {
            OR: [
              ...(timespacePerson?.timespace?.timespaceAvailableObjects?.map(({ objectCode }) =>
                getCodeFilterRecursive(objectCode),
              ) || []),
              ...(timespacePerson?.timespace?.timespaceAvailableObjects?.map(({ objectCode }) => ({
                code: { startsWith: objectCode },
              })) || []),
            ],
          },
        ],
      },
    },
  });

  const objects = useMemo(
    () =>
      data?.getObjects?.filter(({ hasChildren, isAbstract }) => !isAbstract || hasChildren) ?? [],
    [data?.getObjects],
  );

  const allSelected = useMemo(
    () => objects.length === values.filter(({ code }) => code?.length === baseCodeLength).length,
    [baseCodeLength, objects, values],
  );

  useEffect(() => {
    if (!loading && previousData === undefined) {
      onDataLoaded?.(objects);
    }
  }, [loading, objects, previousData]);

  const renderItem = useCallback(
    (object) => (
      <ObjectTreeItem
        key={object.code}
        availableOnly={availableOnly}
        initialAge={initialAge ?? 0}
        object={object}
      />
    ),
    [availableOnly],
  );

  const handleToggleAll = useCallback(() => {
    onToggleAll?.(allSelected ? [] : objects ?? [], allSelected);
  }, [allSelected, onToggleAll, objects]);

  if (loading) {
    return (
      <Box maxWidth={300}>
        <SkeletonLoader height={30} />
      </Box>
    );
  }

  if (!objects || objects.length === 0 || error) {
    return <NoData />;
  }

  return (
    <>
      <ObjectModal
        readonly
        onClose={() => setDetailedItem(undefined)}
        open={Boolean(detailedItem)}
        timespaceObject={detailedItem}
      />
      {onToggleAll && (
        <Button onClick={handleToggleAll} variant="outlined">
          {allSelected ? 'Odznačiť všetky' : 'Vybrať všetky'}
        </Button>
      )}
      <TreeViewCustomized<Object>
        {...restProps}
        data={objects}
        onDetailClick={setDetailedItem}
        renderItem={renderItem}
        values={values}
      />
    </>
  );
}
