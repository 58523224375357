import { Container, Grid } from '@mui/material';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Object, OrderDirection } from 'Generated/graphql-hooks';
import {
  BuyModal,
  CarshopItemCard,
  ConditionalGameScreenWrapper,
  ErrorFallbackPlaceholder,
} from 'Components';
import { useGetTimespaceAvailableObjects, useModal } from 'Lib/Hooks';

export default function CarshopDetail() {
  const { t } = useTranslation();
  const { code } = useParams();
  const { handleClose, handleOpen, isOpen, selected } = useModal<Object>();
  const { data, error, loading } = useGetTimespaceAvailableObjects({
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      where: {
        AND: [{ code: { startsWith: code } }, { isAbstract: { equals: false } }],
      },
    },
  });
  const conditionCallback = useCallback(
    ({ hasAvailableCarshopObjects }) => !hasAvailableCarshopObjects,
    [],
  );

  return (
    <ConditionalGameScreenWrapper hideScreen={conditionCallback}>
      <ErrorFallbackPlaceholder
        data={data?.objects}
        error={error}
        loading={loading}
        placeholder={t('eshop.placeholder.empty')}>
        <Container maxWidth="md" sx={{ pb: 3 }}>
          <Grid
            container
            spacing={4}
            sx={(theme) => ({
              paddingTop: theme.spacing(4),
            })}>
            {(loading ? new Array(8).fill(null) : data?.objects)?.map((item, index) => (
              <Grid key={index} item lg={4} sm={6} xs={12}>
                <CarshopItemCard item={item} loading={loading} onBuyClick={handleOpen} />
              </Grid>
            ))}
          </Grid>
        </Container>
        <BuyModal item={selected} onClose={handleClose} open={isOpen} />
      </ErrorFallbackPlaceholder>
    </ConditionalGameScreenWrapper>
  );
}
