import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Paper } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GameContext } from 'Context';
import { Constants } from 'Config';
import LabeledText from 'Components/Generic/LabeledText';
import { TextFieldFormik } from 'Components/Input';
import {
  TimespacePersonFinancialObjectFragment,
  useBankAccountTransactionMutation,
  useTimespacePersonFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import { BankAccountTransactionSchema } from 'Config/Validations';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { BasicAccountSelector } from 'Components/FinancialObject';
import { useErrorHandler } from 'Lib/Hooks';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function BankCashTransfer({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { timespaceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);

  const cashQuery = useTimespacePersonFinancialObjectsQuery({
    skip: !timespacePerson?.id,
    variables: {
      where: {
        AND: [
          { financialObject: { code: { equals: FinancialObjectCodeEnum.Cash } } },
          { timespacePerson: { id: { equals: timespacePerson?.id } } },
          { timespace: { id: { equals: timespaceId } } },
        ],
      },
    },
  });
  const [bankTransactionMutation, { loading }] = useBankAccountTransactionMutation();

  const handleFormikSubmit = ({ amount, toFinancialObject }: any) => {
    toFinancialObject ||= cashQuery.data?.timespacePersonFinancialObjects?.[0];
    bankTransactionMutation({
      variables: {
        amount: amount,
        fromTimespacePersonFinancialObjectId: Number(timespacePersonFinancialObject!.id),
        toTimespacePersonFinancialObjectId: Number(toFinancialObject.id),
      },
    })
      .then((res) => {
        if (res.data?.bankAccountTransaction) {
          enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        } else {
          handleError(res.errors);
        }
      })
      .catch(() => {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      });
  };
  return (
    <Paper sx={{ p: 2 }}>
      <Formik
        initialValues={{
          amount: undefined,
          isCash:
            timespacePersonFinancialObject?.financialObject?.code === FinancialObjectCodeEnum.Cash,
          toFinancialObject: undefined,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={BankAccountTransactionSchema}>
        {({ handleSubmit, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <LabeledText
                label={t(
                  values.isCash ? 'bank.payment.deposit_amount' : 'bank.payment.withdraw_amount',
                )}
                value={
                  <TextFieldFormik
                    InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                    id="amount"
                    placeholder={t('global.placeholder.amount')}
                    type="number"
                  />
                }
              />
              {values.isCash ? (
                <LabeledText
                  label={t('bank.payment.account')}
                  value={<BasicAccountSelector id="toFinancialObject" />}
                />
              ) : null}
              <Box display="flex" justifyContent="end" pt={2}>
                <LoadingButton loading={loading} type="submit" variant="contained">
                  <span>{t('global.actions.confirm')}</span>
                </LoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Paper>
  );
}
