import { Grid } from '@mui/material';
import * as React from 'react';
import { TemplateTable } from 'Components';

export default function TimespaceOverview() {
  return (
    <Grid container spacing={4}>
      <TemplateTable />
    </Grid>
  );
}
