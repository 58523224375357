import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  data: any;
  error: any;
  loading: boolean;
  placeholder?: string | JSX.Element;
}

export default function ErrorFallbackPlaceholder({
  children,
  data,
  error,
  loading,
  placeholder,
}: Props): JSX.Element | null {
  if (!loading) {
    if (error) {
      return (
        <Box alignItems="center" display="flex" height="50%" justifyContent="center">
          {error}
        </Box>
      );
    } else if (placeholder && (!data || (Array.isArray(data) && data.length === 0))) {
      return (
        <Box alignItems="center" display="flex" height="50%" justifyContent="center" pt={2}>
          {placeholder}
        </Box>
      );
    }
    return <>{children}</>;
  }
  return null;
}
