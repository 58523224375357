import { Container, Grid } from '@mui/material';
import * as React from 'react';
import { useContext, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  OrderDirection,
  TimespacePersonObject,
  TimespacePersonObjectStatusType,
  TimespacePersonObjectUpdateArgs,
  namedOperations,
  useTimespacePersonObjectsQuery,
  useUpdateTimespacePersonObjectsMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { Constants } from 'Config';
import { Section, SkeletonLoader, TimeManagementTable } from 'Components';

const reorder = (list: TimespacePersonObject[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export default function TimeManagement() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const [localData, setLocalData] = useState<TimespacePersonObject[]>([]);

  const [updateObjects] = useUpdateTimespacePersonObjectsMutation();
  const { data, loading } = useTimespacePersonObjectsQuery({
    onCompleted: (result) => {
      setLocalData(result.timespacePersonObjects ?? []);
    },
    variables: {
      orderBy: { priority: OrderDirection.Asc },
      where: {
        AND: [
          {
            timespacePerson: { id: { equals: timespacePerson.id } },
          },
          { timespace: { id: { equals: timespaceId } } },
          { timeRequired: { gt: 0 } },
          {
            OR: [
              { status: { equals: TimespacePersonObjectStatusType.Active } },
              { status: { equals: TimespacePersonObjectStatusType.Passive } },
            ],
          },
        ],
      },
    },
  });

  const handleChange = (newList: TimespacePersonObject[]) => {
    setLocalData(newList);
    updateObjects({
      refetchQueries: [
        namedOperations.Query.TimespacePersonObjects,
        namedOperations.Query.MyTimespacePerson,
      ],
      variables: {
        data: (data?.timespacePersonObjects || []).map((item, index) => ({
          data: { priority: item.priority },
          where: { id: newList[index].id },
        })) as TimespacePersonObjectUpdateArgs[],
      },
    }).catch(() => {
      setLocalData(data?.timespacePersonObjects ?? []);
    });
  };

  const percentage = (
    ((timespacePerson.timeAvailable ?? 0) / Constants.TIME_AVAILABLE) *
    100
  ).toFixed(1);

  const onDragEnd = ({ destination, source }: any) =>
    destination && handleChange(reorder(localData, source.index, destination.index));

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided) => {
          const consumedTime = 0;
          return (
            <Container ref={droppableProvided.innerRef} maxWidth="lg" sx={{ py: 4 }}>
              <Section title={t('game.menu.time_management')}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {t('time_management.time.total', {
                      replace: { value: Constants.TIME_AVAILABLE },
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    {t('time_management.time.available', {
                      replace: { unit: percentage, value: timespacePerson.timeAvailable },
                    })}
                  </Grid>

                  <Grid item xs={12}>
                    {loading ? (
                      <SkeletonLoader />
                    ) : (
                      <TimeManagementTable
                        consumedTime={consumedTime}
                        data={localData}
                        droppableProvided={droppableProvided}
                        onChange={handleChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Section>
            </Container>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
