import * as Yup from 'yup';

export default Yup.object({
  description: Yup.string().required('validations.required'),
  executionMaxCount: Yup.number().required('validations.required'),
  executionMonthPeriodical: Yup.number().nullable(),
  executionProbability: Yup.number().required('validations.required'),
  executionRoundFrom: Yup.number().nullable(),
  executionRoundTo: Yup.number().nullable(),
  executionUserAcceptance: Yup.bool(),
  executionYearPeriodical: Yup.number().required('validations.required'),
  name: Yup.string().required('validations.required'),
});
