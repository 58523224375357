import * as React from 'react';
import { BarChart, House, MoneyOff, Payments, ShoppingCart } from '@mui/icons-material';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { NavTabs } from 'Components';

export default function Overview() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <NavTabs
        tabs={[
          {
            href: '',
            icon: <BarChart />,
            label: t('holdings.overview'),
          },
          {
            href: 'goods',
            icon: <ShoppingCart />,
            label: t('holdings.goods'),
          },
          {
            href: 'properties',
            icon: <House />,
            label: t('holdings.properties'),
          },
          {
            href: 'financial',
            icon: <Payments />,
            label: t('holdings.financial'),
          },
          {
            href: 'debts',
            icon: <MoneyOff />,
            label: t('holdings.debts'),
          },
        ]}
      />
      <Outlet />
    </Container>
  );
}
