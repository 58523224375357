import React, { useCallback } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import {
  OrderDirection,
  TimespaceFragment,
  useMeQuery,
  useTimespacesQuery,
} from 'Generated/graphql-hooks';
import { EnhancedDataTable } from 'Components/Generic';
import { usePagination } from 'Lib/Hooks';
import { templateModalColumns } from './utils';

interface Props {
  onRowSelected: (timespace: TimespaceFragment) => void;
}

export default function TemplateTableModal({ onRowSelected }: Props) {
  const { skip, take, ...pagination } = usePagination();
  const { data: userData } = useMeQuery();
  const { data, loading } = useTimespacesQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      skip,
      take,
      where: {
        OR: [
          { isPublic: { equals: true } },
          {
            AND: [{ isPublic: { equals: false } }, { owner: { id: { equals: userData?.me?.id } } }],
          },
        ],
        isPrototype: { equals: true },
      },
    },
  });

  const handleRowSelectionChange = useCallback(
    (_, __, rowsSelected: any[]) => {
      if (data?.timespaces) {
        onRowSelected(data.timespaces[rowsSelected[0]]);
      }
    },
    [data?.timespaces],
  );

  return (
    <EnhancedDataTable
      columns={templateModalColumns}
      data={data?.timespaces ?? []}
      loading={loading}
      options={
        {
          ...pagination,
          count: data?.count,
          onRowSelectionChange: handleRowSelectionChange,
          selectableRows: 'single',
          selectableRowsHideCheckboxes: true,
          selectableRowsOnClick: true,
        } as MUIDataTableOptions
      }
      title={''}
    />
  );
}
