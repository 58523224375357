/* eslint-disable @typescript-eslint/member-ordering */
import {
  FinancialObject,
  TimespacePersonFinancialObject as ITimespacePersonFinancialObject,
  Maybe,
  Scalars,
  Timespace,
  TimespacePerson,
} from 'Generated/graphql-hooks';

export default class TimespacePersonFinancialObject implements ITimespacePersonFinancialObject {
  constructor(object?: FinancialObject) {
    this.id = '';
    this.actualValue = 0;
    this.ageMax = object?.ageMax;
    this.ageMin = object?.ageMin;
    this.name = object?.name;
    this.customName = '';
    this.description = object?.description;
    this.customDescription = '';
    this.feesOneTime = object?.feesOneTime;
    this.feesOneTimePercent = object?.feesOneTimePercent;
    this.feesPeriodical = object?.feesPeriodical;
    this.feesPeriodicalPercent = object?.feesPeriodicalPercent;
    this.financialObject = object;
    this.fixationPeriod = object?.fixationPeriod;
    this.interestNegative = object?.interestNegative;
    this.interestPositive = object?.interestPositive;
    this.interestSanction = object?.interestSanction;
    this.interestSurplus = object?.interestSurplus;
    this.interestPeriod = object?.interestPeriod;
    this.maturityPeriod = object?.maturityPeriod;
    this.monthlyPayment = 0;
    this.nextFixation = 0; //TODO: investigate attribute meaning;
    this.principalMin = object?.principalMin;
    this.realizationDay = 1;
  }

  actualValue?: Maybe<Scalars['Float']['input']>;
  ageMax?: Maybe<Scalars['Int']['input']>;
  ageMin?: Maybe<Scalars['Int']['input']>;
  customDescription?: Maybe<Scalars['String']['input']>;
  customName?: Maybe<Scalars['String']['input']>;
  description?: Maybe<Scalars['String']['input']>;
  feesOneTime?: Maybe<Scalars['Float']['input']>;
  feesOneTimePercent?: Maybe<Scalars['Float']['input']>;
  feesPeriodical?: Maybe<Scalars['Float']['input']>;
  feesPeriodicalPercent?: Maybe<Scalars['Float']['input']>;
  financialObject?: Maybe<FinancialObject>;
  fixationPeriod?: Maybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  interestNegative?: Maybe<Scalars['Float']['input']>;
  interestPositive?: Maybe<Scalars['Float']['input']>;
  interestSanction?: Maybe<Scalars['Float']['input']>;
  interestSurplus?: Maybe<Scalars['Float']['input']>;
  interestPeriod?: Maybe<Scalars['Int']['input']>;
  maturityPeriod?: Maybe<Scalars['Int']['input']>;
  monthlyPayment?: Maybe<Scalars['Float']['input']>;
  name?: Maybe<Scalars['String']['input']>;
  nextFixation?: Maybe<Scalars['Int']['input']>;
  principalMin?: Maybe<Scalars['Float']['input']>;
  realizationDay?: Maybe<Scalars['Int']['input']>;
  timespace?: Maybe<Timespace>;
  timespacePerson?: Maybe<TimespacePerson>;
}
