import React, { useContext } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { FormValues } from 'Components/Bank/CurrentAccountDetail';
import { BankDetailSchema } from 'Config/Validations';
import { TextFieldFormik } from 'Components/Input';
import {
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
} from 'Generated/graphql-hooks';
import { useModal, useTimespacePersonFinancialObjectUpdate } from 'Lib/Hooks';
import { LabeledText } from 'Components/Generic';
import { formatDuration, formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import ConsumerCreditEarlyRepaymentModal from './EarlyRepaymentModal';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function ConsumerCreditDetail({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const {
    timespacePerson: { timespace },
  } = useContext(GameContext);
  const {
    handleClose: handleEarlyRepaymentClose,
    handleOpen: handleEarlyRepaymentOpen,
    isOpen: isEarlyRepaymentOpen,
  } = useModal();
  const [handleFormikSubmit, { loading }] = useTimespacePersonFinancialObjectUpdate(
    timespacePersonFinancialObject?.id,
  );

  const repayed =
    timespacePersonFinancialObject?.objectStatus ===
    TimespacePersonFinancialObjectObjectStatusType.Discarded;
  const willBeDiscarded = !!timespacePersonFinancialObject?.discardmentRound;

  return timespacePersonFinancialObject ? (
    <Box component={Paper} p={2}>
      {repayed ? (
        <Box mb={2} p={1}>
          <Typography gutterBottom color={(theme) => theme.palette.error.main}>
            {t('consumerCredit.loan.repayed')}
          </Typography>
        </Box>
      ) : willBeDiscarded ? (
        <Box mb={2} p={1}>
          <Typography gutterBottom color={(theme) => theme.palette.error.main}>
            {t('consumerCredit.loan.willBeDiscarded')}
          </Typography>
        </Box>
      ) : null}
      <Formik<FormValues>
        initialValues={{
          customName: timespacePersonFinancialObject.displayName ?? '',
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={BankDetailSchema}>
        {({ handleSubmit }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <Box alignItems="center" display="flex" justifyContent="space-between" pt={2}>
                <Typography fontWeight="bold">{t('investments.main.information')}</Typography>
              </Box>

              <Box ml={4} pt={2}>
                <LabeledText
                  label={t('bank.account.consumer.credit')}
                  sx={{ py: 1 }}
                  value={
                    <TextFieldFormik
                      id="customName"
                      readOnly={repayed || willBeDiscarded}
                      type="string"
                    />
                  }
                />
                <LabeledText
                  label={t('mortgage.table.attr.interest')}
                  sx={{ py: 1 }}
                  value={formatPercent(timespacePersonFinancialObject?.interestNegative)}
                />
                <LabeledText
                  label={t('mortgage.table.attr.maturityPeriod')}
                  sx={{ py: 1 }}
                  value={formatDuration((timespacePersonFinancialObject.maturityPeriod ?? 0) / 12)}
                />
                <LabeledText
                  label={t('mortgage.table.attr.monthlyPayment')}
                  sx={{ mb: 3, py: 1 }}
                  value={formatPrice(timespacePersonFinancialObject?.monthlyPayment)}
                />
                <LabeledText
                  label={t('investments.entry.fee')}
                  sx={{ py: 1 }}
                  value={formatPrice(timespacePersonFinancialObject?.feesOneTime)}
                />
                <LabeledText
                  label={t('investments.exit.fee')}
                  sx={{ py: 1 }}
                  value={formatPercent(timespacePersonFinancialObject?.financialObject?.exitFee)}
                />
                <LabeledText
                  label={t('consumerCredit.periodical.fee')}
                  sx={{ py: 1 }}
                  value={formatPrice(timespacePersonFinancialObject?.feesPeriodical)}
                />
              </Box>
              <Box alignItems="center" display="flex" justifyContent="space-between" pt={2}>
                <Typography fontWeight="bold">{t('consumerCredit.account.status')}</Typography>
              </Box>

              <Box ml={4} pt={2}>
                <LabeledText
                  label={t('consumerCredit.actual.value')}
                  sx={{ py: 1 }}
                  value={formatPrice(
                    timespacePersonFinancialObject.actualValue ??
                      timespacePersonFinancialObject?.initialValue,
                  )}
                />
                <LabeledText
                  label={t('consumerCredit.realized.installments')}
                  sx={{ py: 1 }}
                  value={
                    repayed && timespacePersonFinancialObject?.discardmentRound
                      ? timespacePersonFinancialObject.discardmentRound -
                        (timespacePersonFinancialObject.procurementRound ?? 0) -
                        1
                      : repayed
                      ? timespacePersonFinancialObject.maturityPeriod
                      : timespacePersonFinancialObject?.procurementRound
                      ? (timespace?.round ?? 0) -
                        timespacePersonFinancialObject.procurementRound -
                        // do not count the payment in the established round
                        1
                      : 0
                  }
                />
                <LabeledText
                  label={t('consumerCredit.installments.left')}
                  sx={{ py: 1 }}
                  value={
                    repayed
                      ? 0
                      : timespacePersonFinancialObject?.procurementRound
                      ? (timespacePersonFinancialObject?.maturityPeriod ?? 0) -
                        ((timespace?.round ?? 0) -
                          timespacePersonFinancialObject?.procurementRound) +
                        // add the payment in the established round
                        1
                      : timespacePersonFinancialObject?.maturityPeriod
                  }
                />
                <LabeledText
                  label={t('consumerCredit.last.installment.date')}
                  sx={{ py: 1 }}
                  value={
                    timespacePersonFinancialObject.procurementRound
                      ? getHumanDateFromRound(
                          (timespacePersonFinancialObject?.procurementRound ?? 0) +
                            (timespacePersonFinancialObject?.maturityPeriod ?? 0),
                          (timespacePersonFinancialObject?.realizationDay ?? 0).toString(),
                        )
                      : getHumanDateFromRound(
                          (timespace?.round ?? 0) +
                            (timespacePersonFinancialObject?.maturityPeriod ?? 0),
                          (timespacePersonFinancialObject?.realizationDay ?? 0).toString(),
                        )
                  }
                />
              </Box>

              {!repayed && !willBeDiscarded ? (
                <Box display="flex" justifyContent="end" pt={2}>
                  <Button onClick={handleEarlyRepaymentOpen} sx={{ mr: '5px' }} variant="contained">
                    {t('consumerCredit.repay.loan')}
                  </Button>
                  <LoadingButton loading={loading} type="submit" variant="outlined">
                    <span>{t('global.actions.update')}</span>
                  </LoadingButton>
                </Box>
              ) : null}
            </Box>
          );
        }}
      </Formik>
      <ConsumerCreditEarlyRepaymentModal
        onClose={handleEarlyRepaymentClose}
        open={isEarlyRepaymentOpen}
        timespacePersonFinancialObject={timespacePersonFinancialObject}
      />
    </Box>
  ) : null;
}
