import { Components } from '@mui/material/styles/components';

export default {
  MUIDataTable: {
    styleOverrides: {
      liveAnnounce: {
        display: 'none',
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      root: {
        '&.MuiBadge-pulseAnimate .MuiBadge-badge': {
          '@keyframes pulse': {
            '0%': { transform: 'scale(1) translate(50%, -50%)' },
            '70%': { transform: 'scale(1) translate(50%, -50%)' },
            '85%': { transform: 'scale(1.35) translate(50%, -50%)' },
            '100%': { transform: 'scale(1) translate(50%, -50%)' },
          },
          animation: 'pulse 3s infinite ease',
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: 'black !important',
        },
      },
    },
  },
} as Components;
