import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Login } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ObjectFragment, namedOperations, useTimespaceJoinMutation } from 'Generated/graphql-hooks';
import { ModalCloseButton } from 'Components/Generic';
import { TextFieldFormik } from '../Input';

export default function TimespaceJoinModal() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [joinTimespace] = useTimespaceJoinMutation();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmitFormik = useCallback(
    ({ code }) =>
      joinTimespace({
        refetchQueries: [namedOperations.Query.Timespaces],
        variables: { code },
      })
        .then((result) => {
          if (result?.data?.timespaceJoin) {
            handleClose();
            enqueueSnackbar(t('timespace.join.success'), { variant: 'success' });
          } else {
            enqueueSnackbar(t('timespace.join.error'), {
              preventDuplicate: false,
              variant: 'warning',
            });
          }
        })
        .catch((e) => {
          console.log(e);
        }),
    [joinTimespace, enqueueSnackbar, t],
  );

  return (
    <>
      <Button onClick={handleClickOpen} startIcon={<Login />} variant="outlined">
        {t('timespace.join.button')}
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ mr: 3 }}>
          {t('timespace.join.title')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <Formik<Partial<ObjectFragment>> initialValues={{ code: '' }} onSubmit={handleSubmitFormik}>
          {({ handleSubmit, isSubmitting, values: { code } }) => {
            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <DialogContent>
                  <TextFieldFormik
                    autoFocus
                    fullWidth
                    id="code"
                    label={t('timespace.attr.code')}
                    size="medium"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="outlined">
                    {t('global.actions.cancel')}
                  </Button>
                  <LoadingButton
                    color="primary"
                    disabled={(code ?? '').toString().length < 5}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained">
                    <span>{t('timespace.join.action')}</span>
                  </LoadingButton>
                </DialogActions>
              </Box>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
