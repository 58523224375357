import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { Box, Button, Container, Grid, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import { useErrorHandler } from 'Lib/Hooks';
import { RandomEventSchema } from 'Config/Validations';
import { extractRandomEventAttributeEntities } from 'Lib/Helpers/RandomEventObjectAttributes';
import { TimespacePaths } from 'Config';
import {
  RandomEvent,
  useCreateRandomEventMutation,
  useRandomEventQuery,
  useUpdateNestedRandomEventMutation,
  useUpdateRandomEventMutation,
} from 'Generated/graphql-hooks';
import { RandomEventCreateInput, RandomEventUpdateInput } from 'Models';
import { RandomEventInput } from 'Models/RandomEventInput';
import { CheckboxFormik, Section, TextFieldFormik } from 'Components';
import RandomEventObjectTable from 'Components/RandomEvent/Object/RandomEventObjectTable';
import {
  RandomEventAcquiredObjectTable,
  RandomEventFinancialObjectTable,
} from 'Components/RandomEvent';

interface Props {
  create?: boolean;
}

export default function RandomEventDetail({ create }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eventId, timespaceId } = useParams();
  const { handleError } = useErrorHandler();
  const redirectPath = `${TimespacePaths.Root}/${timespaceId}/${TimespacePaths.Management}`;
  const { data, error, loading } = useRandomEventQuery({
    skip: !eventId,
    variables: {
      where: {
        id: eventId,
      },
    },
  });
  const [createRandomEvent, createMutationState] = useCreateRandomEventMutation();
  const [updateRandomEvent, updateMutationState] = useUpdateRandomEventMutation();
  const [updateNestedRandomEvent] = useUpdateNestedRandomEventMutation();

  const onSubmit = (values: RandomEventInput) => {
    const randomEvent = values as RandomEvent;
    const { randomEventAcquiredObject, randomEventFinancialObjects, randomEventObjects } =
      randomEvent;
    return values.id
      ? updateRandomEvent({
          variables: {
            data: new RandomEventUpdateInput(randomEvent, timespaceId),
            where: {
              id: eventId,
            },
          },
        })
          .then((response) => {
            if (response.data?.updateRandomEvent) {
              updateNestedRandomEvent({
                variables: {
                  acquiredObjects: randomEventAcquiredObject
                    ? [
                        {
                          data: {
                            isAssetsImpactPositive:
                              randomEventAcquiredObject.isAssetsImpactPositive,
                          },
                          where: { id: randomEventAcquiredObject.id },
                        },
                      ]
                    : [],
                  financialObjects:
                    randomEventFinancialObjects?.map((x) => ({
                      data: {
                        description: x.description,
                        objectsAffected: x.objectsAffected,
                        objectsAffectedType: x.objectsAffectedType,
                      },
                      where: { id: x.id },
                    })) || [],
                  objectAttributes: extractRandomEventAttributeEntities(randomEvent).map(
                    ({ id, ...restValues }) => ({
                      data: mapValuesDeep(restValues, (v) => (v === '' ? null : v)),
                      where: { id },
                    }),
                  ),
                  objects:
                    randomEventObjects?.map((x) => ({
                      data: {
                        description: x.description,
                        objectStatus: x.objectStatus,
                        objectsAffected: x.objectsAffected,
                        objectsAffectedType: x.objectsAffectedType,
                        objectsDescriptionModification: x.objectsDescriptionModification,
                        timeRequired: x.timeRequired,
                      },
                      where: { id: x.id },
                    })) || [],
                },
              }).then(() => {
                enqueueSnackbar(t('randomEvent.message.updated'), { variant: 'success' });
                navigate(redirectPath);
              });
            } else {
              enqueueSnackbar(t('errors.generic'), { variant: 'error' });
            }
          })
          .catch(handleError)
      : createRandomEvent({
          variables: {
            data: new RandomEventCreateInput(randomEvent, timespaceId),
          },
        })
          .then((response) => {
            if (response.data?.createRandomEvent) {
              enqueueSnackbar(t('randomEvent.message.created'), { variant: 'success' });
              navigate(redirectPath);
            } else {
              enqueueSnackbar(t('errors.generic'), { variant: 'error' });
            }
          })
          .catch(handleError);
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {loading ? (
        <Box>
          <Skeleton height="90vh" variant="rectangular" />
        </Box>
      ) : error || (eventId && !create && !data?.randomEvent) ? (
        <div>{error ?? t('errors.generic')}</div>
      ) : (
        <Formik<RandomEventInput>
          initialValues={new RandomEventInput(data?.randomEvent)}
          onSubmit={onSubmit}
          validationSchema={RandomEventSchema}>
          {({ handleSubmit }) => {
            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <Section
                  action={
                    <Box>
                      <Button component={RouterLink} to={redirectPath} variant="outlined">
                        {t('global.actions.cancel')}
                      </Button>
                      <LoadingButton
                        loading={createMutationState.loading || updateMutationState.loading}
                        sx={{ ml: 1 }}
                        type="submit"
                        variant="contained">
                        <span>{t(create ? 'global.actions.create' : 'global.actions.save')}</span>
                      </LoadingButton>
                    </Box>
                  }
                  title={t(create ? 'randomEvent.create' : 'randomEvent.detail')}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldFormik id="name" label={t('randomEvent.attr.name')} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldFormik
                        multiline
                        id="description"
                        label={t('randomEvent.attr.description')}
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldFormik
                        InputProps={{ endAdornment: t('global.units.round') }}
                        helperText={t('global.attribute.optional')}
                        id="executionRoundFrom"
                        label={t('randomEvent.attr.executionRoundFrom')}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldFormik
                        InputProps={{ endAdornment: t('global.units.round') }}
                        helperText={t('global.attribute.optional')}
                        id="executionRoundTo"
                        label={t('randomEvent.attr.executionRoundTo')}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldFormik
                        InputProps={{ endAdornment: t('global.units.month') }}
                        helperText={t('global.attribute.optional')}
                        id="executionMonthPeriodical"
                        label={t('randomEvent.attr.executionPeriodical')}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldFormik
                        InputProps={{ endAdornment: t('global.units.year') }}
                        id="executionYearPeriodical"
                        label={t('randomEvent.attr.executionPeriodical')}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldFormik
                        InputProps={{ endAdornment: '%' }}
                        id="executionProbability"
                        inputProps={{ step: 1 }}
                        label={t('randomEvent.attr.executionProbability')}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldFormik
                        id="executionMaxCount"
                        label={t('randomEvent.attr.executionMaxCount')}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxFormik
                        id="executionUserAcceptance"
                        label={t('randomEvent.attr.executionUserAcceptance').toString()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RandomEventObjectTable id="randomEventObjects" />
                    </Grid>
                    <Grid item xs={12}>
                      <RandomEventFinancialObjectTable id="randomEventFinancialObjects" />
                    </Grid>
                    <Grid item xs={12}>
                      <RandomEventAcquiredObjectTable id="randomEventAcquiredObject" />
                    </Grid>
                  </Grid>
                </Section>
              </Box>
            );
          }}
        </Formik>
      )}
    </Container>
  );
}
