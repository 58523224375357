import { Event, Loop, Person } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCallback, useContext, useState } from 'react';
import * as React from 'react';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { NavTabs, Section } from 'Components';
import {
  IsTimespaceRoundRunningDocument,
  useTimespaceNextRoundMutation,
  useUpdateTimespaceMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { TimespacePaths } from 'Config';
import useSessionStorageItem from 'Lib/Hooks/useSessionStorageItem';
import useQuery from 'Lib/Hooks/useQuery';

export default function GameManagement() {
  const {
    isOwner,
    isRoundRunning,
    timespacePerson: { timespace },
  } = useContext(GameContext);
  const [rounds, setRounds] = useState<number | undefined | null>(timespace?.roundsPerStep);
  const query = useQuery(),
    modifyLifeQualityQueryParam = query.get('modifyLifeQuality') || '',
    modifyLevelQueryParam = query.get('modifyLevel') || '';
  const [modifyLifeQuality, setModifyLifeQuality] = useSessionStorageItem('modifyLifeQuality');
  const [modifyLevel, setModifyLevel] = useSessionStorageItem('modifyLevel');
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();
  const [updateTimespace] = useUpdateTimespaceMutation({
    variables: { data: { roundsPerStep: Number(rounds) }, where: { id: timespaceId } },
  });
  const [nextRoundMutation] = useTimespaceNextRoundMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: IsTimespaceRoundRunningDocument,
        variables: {
          where: {
            id: timespaceId,
          },
        },
      },
    ],
    variables: {
      rounds: Number(rounds),
      timespaceId: Number(timespaceId),
      useExperienceScore: modifyLevelQueryParam === 'true' || modifyLevel === 'true' ? true : false,
      useLifeQuality:
        modifyLifeQualityQueryParam === 'true' || modifyLifeQuality === 'true' ? true : false,
    },
  });
  const handleNextRoundClick = useCallback(
    () => Promise.all([updateTimespace(), nextRoundMutation()]),
    [],
  );

  return !isOwner ? (
    <Navigate to={`${TimespacePaths.Root}/${timespaceId}`} />
  ) : (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Section title={t('timespace.management')}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box alignItems="center" display="flex">
              <Typography>{t('game.management.next_round.prefix')}</Typography>
              <Box
                required
                component={TextField}
                margin="dense"
                mx={2}
                onChange={(e: any) => {
                  setRounds(e.target.value);
                }}
                size="small"
                type="number"
                value={rounds ?? ''}
                variant="standard"
                width={80}
              />
              <Box component={Typography} mr={3}>
                {t('game.management.next_round.suffix')}
              </Box>
              <LoadingButton
                endIcon={<Loop />}
                loading={isRoundRunning}
                onClick={handleNextRoundClick}
                variant="contained">
                <span>{t('game.management.next_round.button')}</span>
              </LoadingButton>
              <Box
                border={1}
                borderColor={(theme) => theme.palette.error.main}
                borderRadius={1.5}
                ml={3}
                px={1}>
                <FormControlLabel
                  control={<Checkbox checked={modifyLifeQuality === 'true'} size="small" />}
                  label={<Typography variant="body2">{t('user.attr.lifeQuality')}</Typography>}
                  onChange={(_, checked) => setModifyLifeQuality(checked.toString())}
                  sx={{ color: 'red' }}
                />
                <FormControlLabel
                  control={<Checkbox checked={modifyLevel === 'true'} size="small" />}
                  label={<Typography variant="body2">{t('user.attr.overall')}</Typography>}
                  onChange={(_, checked) => setModifyLevel(checked.toString())}
                  sx={{ color: 'red' }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <NavTabs
              tabs={[
                { href: ``, icon: <Event />, label: t('timespace.randomEvents') },
                {
                  href: `players`,
                  icon: <Person />,
                  label: t('timespace.players'),
                },
                {
                  href: `other`,
                  icon: <SettingsIcon />,
                  label: t('timespace.other.settings'),
                },
              ]}
            />
            <Outlet />
          </Grid>
        </Grid>
      </Section>
    </Container>
  );
}
