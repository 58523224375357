import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import type { TextFieldProps } from '@mui/material';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type TextFieldHFProps = TextFieldProps &
  UseControllerProps<any> & { disableTranslation?: boolean; readOnly?: boolean };

export default function TextFieldHF({
  control,
  disableTranslation,
  helperText,
  name,
  readOnly,
  ...restProps
}: TextFieldHFProps): JSX.Element {
  if (!control || !name) {
    throw new Error('Control and name must be defined');
  }
  const { t } = useTranslation();
  const {
    field: { onBlur, onChange, value },
    fieldState: { error, isTouched },
  } = useController<FieldValues>({ control, name });

  const textFieldRef = useRef<any>(null);
  useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault();
    textFieldRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      textFieldRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const errorMessage =
    disableTranslation && error ? error?.message : error?.message ? t(error.message) : undefined;

  return readOnly ? (
    <Typography
      component={Box}
      display="flex"
      justifyContent="space-between"
      mb={1}
      mt={2}
      textAlign="center">
      <Typography component={Box} pr={1}>
        {value ?? ''}
      </Typography>
    </Typography>
  ) : (
    <TextField
      ref={textFieldRef}
      fullWidth
      error={isTouched && !!errorMessage}
      helperText={isTouched && error ? errorMessage : helperText}
      margin="dense"
      size="small"
      variant="outlined"
      {...restProps}
      InputProps={{
        ...restProps?.InputProps,
      }}
      onBlur={onBlur}
      onChange={onChange}
      value={value ?? ''}
    />
  );
}
