export function calculateLevelFromExperienceScore(experienceScore: number) {
  let level = 0;

  while (experienceScore >= calculateLevelThreshold(level)) {
    level++;
  }

  return level;
}

export function calculateLevelThreshold(level: number) {
  return Math.pow(2, level) * 1000;
}
