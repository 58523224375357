import * as React from 'react';
import { useCallback } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ConditionalGameScreenWrapper } from 'Components';

export default function EshopOverview() {
  const conditionCallback = useCallback(
    ({ hasAvailableEshopObjects }) => !hasAvailableEshopObjects,
    [],
  );

  return (
    <ConditionalGameScreenWrapper hideScreen={conditionCallback}>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <Grid
          container
          spacing={4}
          sx={(theme) => ({
            paddingTop: theme.spacing(4),
          })}>
          <Grid item xs={12}>
            <Box alignItems="center" display="flex" height="50%" justifyContent="center">
              <Typography variant="body2">Vyberte si zo zoznamu kategóriu</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ConditionalGameScreenWrapper>
  );
}
