import * as React from 'react';
import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExitTimespaceModalButton from 'Components/Timespace/ExitTimespaceModal';
import { useTimespaceQuery } from 'Generated/graphql-hooks';
import { Section } from 'Components';

export default function GameOverview() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { data } = useTimespaceQuery({ variables: { where: { id: timespaceId } } });
  if (!timespaceId) {
    return null;
  }
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section title={t('timespace.description')}>
            <span>{data?.timespace?.description}</span>
          </Section>
        </Grid>
        <Grid item xs={12}>
          <Section title={t('timespace.goalsDescription')}>
            <span>{data?.timespace?.goalsDescription}</span>
          </Section>
        </Grid>
        <Grid item textAlign="end" xs={12}>
          <ExitTimespaceModalButton timespaceId={timespaceId} />
        </Grid>
      </Grid>
    </Container>
  );
}
