import { createTheme } from '@mui/material';
import palette from './Palette';
import components from './Components';
import typography from './Typography';
import shadows from './Shadows';

export default createTheme({
  components,
  palette,
  shadows,
  typography,
});
