import React from 'react';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APP_VERSION } from 'Config/Constants';
import { useBackendVersionQuery } from 'Generated/graphql-hooks';
import LabeledText from './LabeledText';
import ModalCloseButton from './ModalCloseButton';

interface Props {
  onClose: () => void;
  open: boolean;
}

function getVersionWithoutCommitId(versionString: string) {
  const version = versionString.match(/\d(\.\d+){2}/g)?.[0];
  const releaseStage = versionString.match(/-.*$/g)?.[0];

  return version ? version + (releaseStage ?? '') : '';
}

export default function ProjectVersionModal({ onClose, open }: Props) {
  const { t } = useTranslation();
  const { data, loading } = useBackendVersionQuery();

  const serverVersion = data?.appVersion;

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle>
        {t('global.project_version')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" py={3}>
            <CircularProgress size={30} />
          </Box>
        ) : (
          <>
            {APP_VERSION ? (
              <LabeledText
                label={t('global.app_version')}
                value={getVersionWithoutCommitId(APP_VERSION)}
              />
            ) : null}
            {serverVersion ? (
              <LabeledText
                label={t('global.server_version')}
                value={getVersionWithoutCommitId(serverVersion)}
              />
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
