import { inRange, isEmpty, isNil } from 'lodash';
import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useCallback } from 'react';
import { FieldArray, Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SelectFormik } from 'Components/Input/SelectFormik';
import { extractRandomEventAttributeEntities } from 'Lib/Helpers/RandomEventObjectAttributes';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import TimespaceRandomEventTable from 'Components/Timespace/RandomEvent/TimespaceRandomEventTable';
import { useGenerateTimespaceInput } from 'Lib/Hooks/useGenerateTimespaceInput';
import {
  Object,
  RandomEvent,
  TimespacePersonFinancialObject,
  namedOperations,
  useMeQuery,
  useUpdateNestedRandomEventMutation,
  useUpdateRandomEventsMutation,
  useUpdateTimespaceMutation,
  useUpdateTimespacePersonFinancialObjectsMutation,
  useUpdateTimespacePersonObjectsMutation,
} from 'Generated/graphql-hooks';
import {
  AppBar,
  LabeledText,
  Section,
  TextFieldFormik,
  TimespacePersonFinancialObjectTable,
  TimespacePersonObjectTable,
} from 'Components';
import {
  RandomEventUpdateInput,
  Timespace,
  TimespacePersonFinancialObjectCreateInput,
  TimespacePersonObject,
  TimespacePersonObjectUpdateInput,
  TimespaceUpdateInput,
} from 'Models';
import { ScreenPaths } from 'Config';
import { TimespaceSchema } from 'Config/Validations';
import ObjectTreeView from 'Components/TreeView/ObjectTreeView';
import { useModal } from 'Lib/Hooks';
import { AgeConflictModal } from 'Screens';
import { getAgeConflictingObjects } from './utils';

interface FormikData extends Timespace {
  randomEvents: RandomEvent[];
  timespacePersonFinancialObjects: TimespacePersonFinancialObject[];
  timespacePersonObjects: TimespacePersonObject[];
}

export default function TemplateEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userQuery = useMeQuery();
  const { error, input, loading } = useGenerateTimespaceInput();
  const {
    handleClose: handleAgeConflictModalClose,
    handleOpen: handleAgeConflictModalOpen,
    isOpen: isAgeConflictModalOpen,
    selected: selectedAgeConflictingObjects,
  } = useModal<TimespacePersonObject[] | TimespacePersonFinancialObject[]>();

  const [updateTimespace] = useUpdateTimespaceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [namedOperations.Query.Timespace, namedOperations.Query.Timespaces],
  });
  const [updateTimespacePersonObjects] = useUpdateTimespacePersonObjectsMutation();
  const [updateTimespacePersonFinancialObjects] =
    useUpdateTimespacePersonFinancialObjectsMutation();
  const [updateRandomEvents] = useUpdateRandomEventsMutation();
  const [updateNestedRandomEvent] = useUpdateNestedRandomEventMutation();

  const handleRemoveConflictingAndSubmit = (
    { timespacePersonFinancialObjects, timespacePersonObjects, ...values }: FormikData,
    formikHelpers: Pick<FormikHelpers<FormikData>, 'setSubmitting'>,
  ) => {
    formikHelpers.setSubmitting(true);
    handleSubmitFormik(
      {
        ...values,
        timespacePersonFinancialObjects: timespacePersonFinancialObjects?.filter((item) =>
          inRange(values?.personInitialAge, item?.ageMin ?? 0, item?.ageMax ?? Infinity),
        ),
        timespacePersonObjects: timespacePersonObjects?.filter((item) =>
          inRange(values?.personInitialAge, item?.ageMin ?? 0, item?.ageMax ?? Infinity),
        ),
      },
      formikHelpers,
    );
  };

  const handleSubmitFormik = useCallback(
    async (
      values: FormikData,
      { setSubmitting }: Pick<FormikHelpers<FormikData>, 'setSubmitting'>,
    ) => {
      const objectPriorities: number[] = [];
      const timespacePersonObjectsWithPriority = values.timespacePersonObjects
        .sort(
          (a, b) =>
            (a?.priority && a.priority.toString().length !== 0 ? a.priority : Infinity) -
            (b?.priority && b.priority.toString().length !== 0 ? b.priority : Infinity),
        )
        .map(({ priority, ...item }) => {
          //TODO: refactor cond
          priority =
            (priority?.toString() === '' ? undefined : priority) ?? objectPriorities.length + 1;
          while (objectPriorities.indexOf(priority) !== -1) {
            priority++;
          }
          objectPriorities.push(priority);

          return { ...item, priority };
        });
      try {
        const ageConflictingObjects = getAgeConflictingObjects(
          values?.personInitialAge,
          values?.timespacePersonObjects,
          values?.timespacePersonFinancialObjects,
        );

        if (ageConflictingObjects?.length) {
          handleAgeConflictModalOpen(ageConflictingObjects);
          return;
        }

        const { data } = await updateTimespace({
          variables: {
            data: new TimespaceUpdateInput({
              ...values,
              timespacePersonObjects: timespacePersonObjectsWithPriority,
            } as Timespace),
            where: { id: values.id },
          },
        });

        const { id: newId } = data?.updateTimespace ?? {};
        if (newId) {
          await updateTimespacePersonObjects({
            variables: {
              data: (() => {
                return (
                  timespacePersonObjectsWithPriority
                    //TODO: BUG: this causes duplicate priorities
                    ?.filter((x) => !(isEmpty(x.id) || isNil(x.id)))
                    .map((item) => {
                      return {
                        data: new TimespacePersonObjectUpdateInput(
                          // NOTE: disable max age for template objects
                          { ...item, ageMax: null },
                          newId,
                        ),
                        where: { id: item.id },
                      };
                    })
                );
              })(),
            },
          });
          await updateTimespacePersonFinancialObjects({
            variables: {
              data: values.timespacePersonFinancialObjects
                ?.filter((x) => !(isEmpty(x.id) || isNil(x.id)))
                .map((item) => ({
                  data: new TimespacePersonFinancialObjectCreateInput(item, newId),
                  where: { id: item.id },
                })),
            },
          });
          const { data: randomEventData } = await updateRandomEvents({
            variables: {
              data: values.randomEvents
                .filter((x) => !(isEmpty(x.id) || isNil(x.id)))
                .map((item) => ({
                  data: new RandomEventUpdateInput(item, newId),
                  where: { id: item.id },
                })),
            },
          });
          if (randomEventData?.updateRandomEvents) {
            values.randomEvents
              .filter((x) => !(isEmpty(x.id) || isNil(x.id)))
              .forEach((item) => {
                const {
                  randomEventAcquiredObject,
                  randomEventFinancialObjects,
                  randomEventObjects,
                } = item;
                updateNestedRandomEvent({
                  variables: {
                    acquiredObjects: randomEventAcquiredObject
                      ? [
                          {
                            data: {
                              isAssetsImpactPositive:
                                randomEventAcquiredObject.isAssetsImpactPositive,
                            },
                            where: { id: randomEventAcquiredObject.id },
                          },
                        ]
                      : [],
                    financialObjects:
                      randomEventFinancialObjects?.map((x) => ({
                        data: {
                          description: x.description,
                          objectsAffected: x.objectsAffected,
                          objectsAffectedType: x.objectsAffectedType,
                        },
                        where: { id: x.id },
                      })) || [],
                    objectAttributes: extractRandomEventAttributeEntities(item).map(
                      ({ id, ...restValues }) => ({
                        data: mapValuesDeep(restValues, (v) => (v === '' ? null : v)),
                        where: { id },
                      }),
                    ),
                    objects:
                      randomEventObjects?.map((x) => ({
                        data: {
                          description: x.description,
                          objectStatus: x.objectStatus,
                          objectsAffected: x.objectsAffected,
                          objectsAffectedType: x.objectsAffectedType,
                          objectsDescriptionModification: x.objectsDescriptionModification,
                          timeRequired: x.timeRequired,
                        },
                        where: { id: x.id },
                      })) || [],
                  },
                });
              });
          }
        }

        if (data?.updateTimespace?.id) {
          enqueueSnackbar(t('timespace.template.updated'), { variant: 'success' });
          navigate(ScreenPaths.Templates);
        } else {
          throw new Error();
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
    [
      t,
      updateTimespace,
      updateTimespacePersonObjects,
      updateTimespacePersonFinancialObjects,
      updateRandomEvents,
      enqueueSnackbar,
      userQuery,
    ],
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" pt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box display="flex" justifyContent="center" pt={4}>
        <Typography color="error">{error ?? t('errors.generic')}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Formik<FormikData>
        enableReinitialize
        initialValues={input}
        onSubmit={handleSubmitFormik}
        validateOnBlur={false}
        validationSchema={TimespaceSchema}>
        {({ handleSubmit, isSubmitting, setFieldValue, setSubmitting, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <AppBar
                right={
                  <Grid container display="flex" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        color="secondary"
                        component={RouterLink}
                        to={ScreenPaths.Templates}
                        variant="outlined">
                        {t('global.actions.cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        color="secondary"
                        loading={isSubmitting}
                        type="submit"
                        variant="contained">
                        <span>{t('global.actions.save')}</span>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                }
              />
              <Section title={t('timespace.detail')}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextFieldFormik id="name" label={t('timespace.name')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      multiline
                      id="description"
                      label={t('timespace.description')}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      multiline
                      id="goalsDescription"
                      label={t('timespace.goalsDescription')}
                      rows={4}
                    />
                  </Grid>

                  <Grid item alignItems="center" display="flex" xs={6}>
                    <span>{t('timespace.roundsPerStep.prefix')}</span>
                    <Box
                      component={TextFieldFormik}
                      fullWidth={false}
                      id="roundsPerStep"
                      px={2}
                      type="number"
                      variant="standard"
                      width="20%"
                    />
                    <span>{t('timespace.roundsPerStep.suffix')}</span>
                  </Grid>

                  <Grid item alignItems="center" display="flex" xs={6}>
                    <span>{t('timespace.personInitialAge.prefix')}</span>
                    <Box
                      component={TextFieldFormik}
                      fullWidth={false}
                      id="personInitialAge"
                      px={2}
                      type="number"
                      variant="standard"
                      width="20%"
                    />
                    <span>{t('timespace.personInitialAge.suffix')}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <TimespaceRandomEventTable id="randomEvents" />
                  </Grid>
                  <Grid item xs={12}>
                    <TimespacePersonObjectTable
                      id="timespacePersonObjects"
                      initialAge={values?.personInitialAge}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TimespacePersonFinancialObjectTable
                      id="timespacePersonFinancialObjects"
                      initialAge={values?.personInitialAge}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabeledText
                      label={t('timespace.defaultPaymentMethod')}
                      value={
                        <SelectFormik<TimespacePersonFinancialObject>
                          fullWidth
                          id="defaultPaymentMethod"
                          options={values.timespacePersonFinancialObjects}
                          renderValue={(item) => item.name}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t('timespace.timespaceAvailableObjects')}
                    </Typography>
                    <FieldArray name="timespaceAvailableObjects">
                      {({ push, remove }) => (
                        <ObjectTreeView
                          hideDisabledCheckbox
                          disabledFunction={({ code }) => {
                            return code
                              ? values.timespaceAvailableObjects?.some(({ objectCode }) =>
                                  objectCode && objectCode.length !== code.length
                                    ? code.startsWith(objectCode)
                                    : false,
                                )
                              : false;
                          }}
                          onChange={(object, checked) => {
                            if (checked) {
                              push({ objectCode: object.code });
                            } else if (values.timespaceAvailableObjects) {
                              remove(
                                values.timespaceAvailableObjects.findIndex(
                                  (obj) => obj.objectCode === object.code,
                                ),
                              );
                            }
                          }}
                          onToggleAll={(data) =>
                            setFieldValue(
                              'timespaceAvailableObjects',
                              data.map((object) => ({ objectCode: object.code })),
                            )
                          }
                          values={(values.timespaceAvailableObjects ?? []).map(
                            (x) => ({ code: x.objectCode } as Object),
                          )}
                        />
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Section>
              {selectedAgeConflictingObjects ? (
                <AgeConflictModal
                  ageConflictingObjects={selectedAgeConflictingObjects}
                  isSubmitting={isSubmitting}
                  onClose={handleAgeConflictModalClose}
                  onConfirm={() => {
                    handleRemoveConflictingAndSubmit(values, { setSubmitting });
                  }}
                  open={isAgeConflictModalOpen}
                />
              ) : null}
            </Box>
          );
        }}
      </Formik>
    </Container>
  );
}
