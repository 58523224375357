import React, { useContext } from 'react';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LabeledText } from 'Components';
import {
  MutualFundInvestorStatusFragment,
  MutualFundStatusFragment,
  OrderDirection,
  useMutualFundStatusesQuery,
} from 'Generated/graphql-hooks';
import { formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { MutualFundStatusInfoSection } from '../MutualFundStatus';

interface Props {
  mutualFundInvestorStatus?: MutualFundInvestorStatusFragment | null;
  mutualFundStatus?: MutualFundStatusFragment | null;
}

export default function MutualFundInvestorStatusDetail({
  mutualFundInvestorStatus,
  mutualFundStatus,
}: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { data, loading } = useMutualFundStatusesQuery({
    variables: {
      orderBy: { id: OrderDirection.Desc },
      where: {
        fund: {
          code: {
            equals: mutualFundStatus?.fund?.code,
          },
        },
        round: {
          equals: Math.max((timespacePerson.timespace?.round ?? 0) - 12, 0),
        },
        timespace: { id: { equals: timespacePerson.timespace?.id } },
      },
    },
  });

  const currentSharePrice = mutualFundStatus?.sharePrice ?? 0;
  const startOfYearSharePrice = data?.mutualFundStatuses?.[0]?.sharePrice ?? 1;
  const currentEfficiency = currentSharePrice / startOfYearSharePrice - 1;

  return (
    <Box component={Paper} p={2}>
      {loading ? (
        <Box display="flex" justifyContent="center" py={5}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          <MutualFundStatusInfoSection
            currentEfficiency={currentEfficiency}
            mutualFundStatus={mutualFundStatus}
          />
          <Box alignItems="center" display="flex" justifyContent="space-between" pt={2}>
            <Typography fontWeight="bold">{t('investments.my.investment')}</Typography>
          </Box>
          <Box ml={4} pt={2}>
            <LabeledText
              label={t('investments.average.purchase.price')}
              sx={{ py: 1 }}
              value={formatPrice(mutualFundInvestorStatus?.averagePurchasePrice)}
            />
            <LabeledText
              label={t('investments.final.volume')}
              sx={{ py: 1 }}
              value={mutualFundInvestorStatus?.finalVolume?.toLocaleString('sk')}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
