import {
  Maybe,
  RandomEventObject,
  RandomEventObjectAttribute,
  RandomEventObjectObjectStatusType,
} from 'Generated/graphql-hooks';
import { RandomEventObjectAttributeInput } from 'Models/RandomEventObjectAttributeInput';

export class RandomEventObjectInput implements DeepNullable<RandomEventObject> {
  __typename?: DeepNullable<'RandomEventObject' | undefined> | null;
  actualValue?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  assetsImpactMaxRounds?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  assetsImpactPeriodicalActive?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  assetsImpactPeriodicalPassive?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  description?: DeepNullable<Maybe<string> | undefined>;
  id: string | null | undefined;
  lifeQualityImpactMaxCount?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  lifeQualityImpactPeriodical?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  objectStatus?: DeepNullable<Maybe<RandomEventObjectObjectStatusType> | undefined> = undefined;
  objectsAffected?: DeepNullable<Maybe<string> | undefined>;
  objectsAffectedType?: DeepNullable<Maybe<number> | undefined> = -1;
  objectsDescriptionModification?: DeepNullable<Maybe<string> | undefined>;
  timeRequired?: DeepNullable<Maybe<number> | undefined> = 0;
  utilisationMaxRounds?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  utilisationMinRounds?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
}
