import React, { useCallback, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import {
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AccountBalance,
  ArrowBack,
  DirectionsCar,
  House,
  Paid,
  School,
  Settings,
  ShoppingCart,
} from '@mui/icons-material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useTranslation } from 'react-i18next';
import TimespaceMenuSection from 'Components/Timespace/Menu/TimespaceMenuSection';
import { ScreenPaths, TimespacePaths } from 'Config';
import { GameContext, GameContextProvider } from 'Context';
import AppBar from 'Components/AppBar';
import { CircularLoader, EnhancedListItemButton } from 'Components/Generic';
import { Error, MenuButton } from 'Components';
import './game.css';
import { isInterpersonalizedView } from 'Lib/Helpers/Session';

const drawerWidth = 240;

export default function GameLayout() {
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = useCallback(() => setDrawerOpen(false), []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <GameContextProvider setLastVisited>
        <GameContext.Consumer>
          {({
            hasAvailableCarshopObjects,
            hasAvailableEducationObjects,
            hasAvailableEshopObjects,
            hasAvailableLivingObjects,
            hasAvailableWorkObjects,
            hasEducationOrWork,
            isOwner,
          }) => {
            return (
              <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
                <AppBar
                  left={
                    isMobile ? (
                      <MenuButton drawerOpen={drawerOpen} onDrawerOpen={setDrawerOpen} />
                    ) : undefined
                  }
                  sx={{ zIndex: theme.zIndex.drawer + 1 }}
                />
                <Drawer
                  anchor="left"
                  onClose={closeDrawer}
                  open={drawerOpen}
                  sx={{
                    '& .MuiDrawer-paper': {
                      boxSizing: 'border-box',
                      width: drawerWidth,
                    },
                    flexShrink: 0,
                    width: drawerWidth,
                  }}
                  variant={isMobile ? undefined : 'permanent'}>
                  <Toolbar />
                  <Box sx={{ overflow: 'auto' }}>
                    {!isInterpersonalizedView() ? (
                      <ListItemButton component={Link} to={`${ScreenPaths.Root}`}>
                        <ListItemIcon>
                          <ArrowBack />
                        </ListItemIcon>
                        <ListItemText>{t('timespace.overview')}</ListItemText>
                      </ListItemButton>
                    ) : null}
                    <Divider />
                    <List>
                      <>
                        <TimespaceMenuSection />
                        {isOwner ? (
                          <>
                            <EnhancedListItemButton path={`${TimespacePaths.Management}/other`}>
                              <ListItemIcon>
                                <Settings />
                              </ListItemIcon>
                              <ListItemText primary={t('game.menu.management')} />
                            </EnhancedListItemButton>
                            <Divider />
                          </>
                        ) : null}
                        {hasAvailableWorkObjects ||
                        hasAvailableEducationObjects ||
                        hasEducationOrWork ? (
                          <>
                            <EnhancedListItemButton path={TimespacePaths.EducationAndWork}>
                              <ListItemIcon>
                                <School />
                              </ListItemIcon>
                              <ListItemText primary={t('game.menu.work_and_study')} />
                            </EnhancedListItemButton>
                            <Divider />
                          </>
                        ) : null}
                        {hasAvailableLivingObjects ? (
                          <>
                            <EnhancedListItemButton
                              path={`${TimespacePaths.Living}/${timespaceId}`}>
                              <ListItemIcon>
                                <House />
                              </ListItemIcon>
                              <ListItemText primary={t('game.menu.living')} />
                            </EnhancedListItemButton>
                            <Divider />
                          </>
                        ) : null}
                        <EnhancedListItemButton path={TimespacePaths.Holdings}>
                          <ListItemIcon>
                            <Paid />
                          </ListItemIcon>
                          <ListItemText primary={t('game.menu.holdings')} />
                        </EnhancedListItemButton>
                        <Divider />
                        <EnhancedListItemButton path={`${TimespacePaths.Bank}/${timespaceId}`}>
                          <ListItemIcon>
                            <AccountBalance />
                          </ListItemIcon>
                          <ListItemText primary={t('game.menu.bank')} />
                        </EnhancedListItemButton>
                        <Divider />
                        <EnhancedListItemButton
                          path={`${TimespacePaths.Investments}/${timespaceId}`}>
                          <ListItemIcon>
                            <TrendingUpIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('game.menu.investments')} />
                        </EnhancedListItemButton>
                        {hasAvailableEshopObjects ? (
                          <>
                            <Divider />
                            <EnhancedListItemButton path={`${TimespacePaths.Eshop}/${timespaceId}`}>
                              <ListItemIcon>
                                <ShoppingCart />
                              </ListItemIcon>
                              <ListItemText primary={t('game.menu.eshop')} />
                            </EnhancedListItemButton>
                          </>
                        ) : null}
                        {hasAvailableCarshopObjects ? (
                          <>
                            <Divider />

                            <EnhancedListItemButton
                              path={`${TimespacePaths.Carshop}/${timespaceId}`}>
                              <ListItemIcon>
                                <DirectionsCar />
                              </ListItemIcon>
                              <ListItemText primary={t('game.menu.carshop')} />
                            </EnhancedListItemButton>
                          </>
                        ) : null}
                      </>
                    </List>
                  </Box>
                </Drawer>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    mt: 8,
                    overflow: 'auto',
                  }}>
                  <GameContext.Consumer>
                    {({ error, loading }) =>
                      loading ? <CircularLoader /> : error ? <Error /> : <Outlet />
                    }
                  </GameContext.Consumer>
                </Box>
              </Box>
            );
          }}
        </GameContext.Consumer>
      </GameContextProvider>
    </Box>
  );
}
