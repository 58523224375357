import { useState } from 'react';

export const usePagination = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  return {
    onChangePage: setPage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    page,
    rowsPerPage,
    serverSide: true,
    skip: page * rowsPerPage,
    take: rowsPerPage,
  };
};
