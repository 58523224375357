import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  Object,
  namedOperations,
  useCreateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { TimespacePersonObject, TimespacePersonObjectCreateInput } from 'Models';
import { useErrorHandler, useToggle } from 'Lib/Hooks';
import { HouseholdObjectCodes, OcupationObjectCodeEnum } from 'Types/Global';
import theme from 'Theme';
import { ModalCloseButton } from 'Components/Generic';
import ObjectTreeView from '../TreeView/ObjectTreeView';

export default function WorkButtonModal() {
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<Object[]>([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showAvailableOnly, toggleShowAvailableOnly] = useToggle();
  const [createTimespacePersonObject, { loading }] = useCreateTimespacePersonObjectMutation();

  useEffect(() => {
    if (!open) {
      setSelectedValues([]);
    }
  }, [open]);

  useEffect(() => {
    setSelectedValues([]);
  }, [showAvailableOnly]);

  const handleSubmit = useCallback(async () => {
    try {
      const { data, errors } = await createTimespacePersonObject({
        refetchQueries: [
          namedOperations.Query.TimespacePersonWorkObjects,
          namedOperations.Query.MyTimespacePerson,
        ],
        variables: {
          data: new TimespacePersonObjectCreateInput(
            new TimespacePersonObject(selectedValues[0]),
            timespaceId,
            timespacePerson.id,
          ),
        },
      });

      if (data?.createTimespacePersonObject) {
        enqueueSnackbar(t('game.work.success'), { variant: 'success' });
        handleClose();
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [selectedValues, t]);

  return (
    <>
      <Button onClick={handleClickOpen} startIcon={<Add />} variant="outlined">
        {t('game.work.start')}
      </Button>
      <Dialog fullWidth onClose={handleClose} open={open}>
        <DialogTitle>
          {t('game.work.start')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <FormControlLabel
          control={<Checkbox checked={showAvailableOnly} size="small" />}
          label={
            <Typography color={theme.palette.primary.main} variant="body2">
              {t('game.jobs.available')}
            </Typography>
          }
          onChange={() => {
            toggleShowAvailableOnly();
          }}
          sx={{ pl: 3 }}
        />
        <DialogContent>
          <ObjectTreeView
            availableOnly={showAvailableOnly}
            baseCodeLength={4}
            disabledFunction={(item) => item?.isAbstract}
            onChange={(object, checked) => {
              setSelectedValues(checked ? [object] : []);
            }}
            values={selectedValues || []}
            where={{
              AND: [
                { code: { startsWith: OcupationObjectCodeEnum.Ocupation } },
                { code: { not: { startsWith: HouseholdObjectCodes.EMPLOYMENT } } },
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.cancel')}
          </Button>
          <LoadingButton
            disabled={selectedValues.length === 0}
            loading={loading}
            onClick={handleSubmit}
            variant="contained">
            <span>{t('global.actions.confirm')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
