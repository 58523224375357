import * as React from 'react';
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { RandomEventInput } from 'Models/RandomEventInput';
import TimespaceRandomEventModal from 'Components/Timespace/RandomEvent/TimespaceRandomEventModal';
import { useModal } from 'Lib/Hooks';

interface Props {
  data: RandomEventInput;
  onSubmit: (values: RandomEventInput) => Promise<any> | void;
}

export default function TimespaceRandomEventDetail({ data, onSubmit }: Props) {
  const { handleClose, handleOpen, isOpen } = useModal();

  return (
    <>
      <Tooltip placement="top" title={t('global.actions.edit').toString()}>
        <IconButton aria-label="edit" color="warning" onClick={handleOpen}>
          <Edit />
        </IconButton>
      </Tooltip>
      <TimespaceRandomEventModal
        onClose={handleClose}
        onSubmit={onSubmit}
        open={isOpen}
        value={data}
      />
    </>
  );
}
