import { FieldArray } from 'formik';
import { t } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import RandomEventObjectEdit from './RandomEventObjectEdit';

export const randomEventObjectColumns = (id: string): MUIDataTableColumnDef[] => [
  { name: 'id', options: { display: 'excluded' } },
  {
    label: t('randomEvent.object.attr.description'),
    name: 'description',
  },
  {
    label: t('randomEvent.object.attr.objectsAffected'),
    name: 'objectsAffected',
  },
  {
    label: t('randomEvent.object.attr.objectsAffectedType'),
    name: 'objectsAffectedType',
  },
  {
    label: t('randomEvent.object.attr.objectStatus'),
    name: 'objectStatus',
  },
  {
    label: t('randomEvent.object.attr.timeRequired'),
    name: 'timeRequired',
  },
  {
    label: t('global.table.actions'),
    name: 'actions',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        return (
          <FieldArray
            name={id}
            render={(arrayHelpers) => (
              <>
                <RandomEventObjectEdit id={`${id}.${rowIndex}`} />
                <Tooltip title={t('global.actions.edit').toString()}>
                  <IconButton color="error" onClick={() => arrayHelpers.remove(rowIndex)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </>
            )}
          />
        );
      },
      sort: false,
    },
  },
];
