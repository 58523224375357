/* eslint-disable @typescript-eslint/member-ordering */
import {
  Timespace as ITimespace,
  TimespaceAvailableObject,
  TimespaceFragment,
  TimespacePersonFinancialObject,
} from 'Generated/graphql-hooks';

export default class Timespace implements ITimespace {
  constructor(timespace?: TimespaceFragment | null) {
    this.id = timespace?.id ?? '';
    this.name = timespace?.name;
    this.isPrototype = timespace?.isPrototype ?? false;
    this.isPublic = timespace?.isPublic;
    this.description = timespace?.description;
    this.goalsDescription = timespace?.goalsDescription;
    this.personInitialAge = timespace?.personInitialAge || 20;
    this.round = timespace?.round ?? 1;
    this.roundsPerStep = timespace?.roundsPerStep ?? 1;
    this.timespaceAvailableObjects = timespace?.timespaceAvailableObjects ?? [];
    this.timespacePersons = null;
    this.defaultPaymentMethod = timespace?.defaultPaymentMethod;
  }
  id;
  description;
  defaultPaymentMethod?: TimespacePersonFinancialObject | null;
  timespaceAvailableObjects: Array<TimespaceAvailableObject> = [];
  isPrototype;
  isPublic;
  goalsDescription;
  name;
  personInitialAge;
  round;
  roundsPerStep;
  timespacePersons = null;
}
