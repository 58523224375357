import React from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes, useParams } from 'react-router-dom';
import { useTimespacePersonFinancialObjectQuery } from 'Generated/graphql-hooks';
import { CircularLoader, ErrorFallbackPlaceholder, NavTabs } from 'Components';
import { getAccountConfig } from './utils';

export default function BankDetail() {
  const { t } = useTranslation();
  const { objectId } = useParams();
  const { data, error, loading } = useTimespacePersonFinancialObjectQuery({
    variables: {
      where: { id: objectId },
    },
  });

  const { routes, tabs } = getAccountConfig(data?.timespacePersonFinancialObject);

  return (
    <>
      {loading ? (
        <CircularLoader />
      ) : (
        <ErrorFallbackPlaceholder
          data={data?.timespacePersonFinancialObject}
          error={error}
          loading={loading}>
          <Container maxWidth="md" sx={{ pt: 2 }}>
            <NavTabs
              tabs={tabs.map(({ labelKey, ...item }) => ({
                ...item,
                label: t(labelKey),
              }))}
            />
            <Routes>{routes}</Routes>
          </Container>
        </ErrorFallbackPlaceholder>
      )}
    </>
  );
}
