import React, { useRef } from 'react';
import { Button, Typography } from '@mui/material';
import { useIsOverflow } from 'Lib/Hooks/useIsOverflow';
import { useToggle } from 'Lib/Hooks';

interface Props {
  maxLineCount?: number;
  text: string;
}

export default function ShowMoreTypography({ maxLineCount = 3, text }: Props) {
  const ref = useRef<HTMLSpanElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const [isExpanded, toggleExpanded] = useToggle();

  return (
    <>
      <Typography
        ref={ref}
        sx={
          !isExpanded
            ? {
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: maxLineCount,
                display: '-webkit-box',
                overflow: 'hidden',
              }
            : undefined
        }
        variant="body2">
        {text}
      </Typography>
      {isOverflow ? (
        <Button
          onClick={toggleExpanded}
          size="small"
          sx={{ marginTop: 1, padding: 0 }}
          variant="text">
          {isExpanded ? 'Zobraziť menej' : 'Zobraziť viac'}
        </Button>
      ) : null}
    </>
  );
}
