import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FormikHelpers } from 'formik';
import {
  useMutualFundTransactionQuery,
  useUpdateMutualFundTransactionMutation,
} from 'Generated/graphql-hooks';
import { ErrorFallbackPlaceholder, Section } from 'Components';
import { Constants, TimespacePaths } from 'Config';
import { useErrorHandler } from 'Lib/Hooks';
import MutualFundTransactionBuyForm, { FormValues as BuyFormValues } from './BuyForm';
import MutualFundTransactionSellForm, { FormValues as SellFormValues } from './SellForm';

type FormValues = BuyFormValues & SellFormValues;

export default function MutualFundTransactionEdit() {
  const { objectId, timespaceId, transactionId } = useParams();
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [updateTransaction] = useUpdateMutualFundTransactionMutation();
  const { data, error, loading } = useMutualFundTransactionQuery({
    variables: {
      where: {
        id: transactionId,
      },
    },
  });

  const handleSubmit = async (
    {
      amountRequested,
      day,
      investmentRepeat,
      relatedBankAccount,
      repeatRounds,
      volume,
    }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    try {
      if (
        amountRequested &&
        (amountRequested ?? 0) <
          (data?.mutualFundTransaction?.mutualFundStatus?.fund?.minimalDeposit ?? 0)
      ) {
        enqueueSnackbar(
          t('investments.mutualFund.validation.minimalDeposit', {
            replace: {
              currency: Constants.CURRENCY,
              value: data?.mutualFundTransaction?.mutualFundStatus?.fund?.minimalDeposit ?? 0,
            },
          }),
          { variant: 'error' },
        );
        return;
      }

      const { data: responseData, errors } = await updateTransaction({
        variables: {
          data: {
            amountRequested: amountRequested ? Math.round(amountRequested * 100) / 100 : undefined,
            day: day ?? 1,
            fee: amountRequested
              ? amountRequested *
                (data?.mutualFundTransaction?.mutualFundStatus?.fund?.entryFee ?? 0)
              : undefined,
            relatedBankAccount: {
              connect: {
                id: relatedBankAccount?.id,
              },
            },
            repeatRounds: investmentRepeat ? (repeatRounds ?? 0) + 1 : 1,
            volume,
          },
          where: {
            id: transactionId,
          },
        },
      });
      if (responseData?.updateMutualFundTransaction) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        navigate(`${TimespacePaths.Investments}/${timespaceId}/${objectId}/instructions`);
      } else if (errors?.some((x) => ~x.message.indexOf('NotEnoughVolumeError'))) {
        const volumeError = errors?.find((x) => ~x.message.indexOf('NotEnoughVolumeError'));
        // @ts-expect-error not typed
        const value = volumeError?.extensions?.exception?.thrownValue?.cause?.actualVolume;
        enqueueSnackbar(
          t('errors.error.not-enough-volume', {
            replace: {
              value,
            },
          }),
          { variant: 'error' },
        );
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const isBuy = data?.mutualFundTransaction?.amountRequested;

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section
        title={t(
          isBuy
            ? 'investments.mutualFund.transaction.buy.edit.title'
            : 'investments.mutualFund.transaction.sell.edit.title',
        )}>
        <ErrorFallbackPlaceholder
          data={data?.mutualFundTransaction}
          error={error || !data?.mutualFundTransaction}
          loading={loading}
          placeholder={t('eshop.placeholder.empty')}>
          {!data?.mutualFundTransaction ? null : isBuy ? (
            <MutualFundTransactionBuyForm
              initialValues={{
                ...data.mutualFundTransaction,
                investmentRepeat: (data.mutualFundTransaction.repeatRounds ?? 1) > 1,
              }}
              onSubmit={handleSubmit}
            />
          ) : (
            <MutualFundTransactionSellForm
              fundFinancialObject={data.mutualFundTransaction.mutualFundStatus?.fund}
              initialValues={data.mutualFundTransaction}
              onSubmit={handleSubmit}
            />
          )}
        </ErrorFallbackPlaceholder>
      </Section>
    </Container>
  );
}
