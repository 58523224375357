import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TimespacePersonObject,
  TimespacePersonObjectStatusType,
  namedOperations,
  useUpdateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { SafeDeleteModal } from 'Components/Generic';

interface Props {
  isOpen: boolean;
  object: TimespacePersonObject;
  onClose: () => void;
  text?: string;
}

export default function HouseholdObjectDiscardModal({ isOpen, object, onClose, text }: Props) {
  const { t } = useTranslation();

  const [updateObject, { loading }] = useUpdateTimespacePersonObjectMutation({
    refetchQueries: [
      namedOperations.Query.MyTimespacePerson,
      namedOperations.Query.TimespacePersonHouseholdObjects,
    ],
    variables: {
      data: { status: TimespacePersonObjectStatusType.Discarded },
      where: { id: object?.id },
    },
  });

  const handleConfirm = useCallback(async () => {
    await updateObject();
    onClose();
  }, [onClose]);

  return (
    <>
      <SafeDeleteModal
        loading={loading}
        onClose={onClose}
        onConfirm={handleConfirm}
        open={isOpen}
        text={text ?? `${t('global.delete.confirm')} ${object?.name} ?`}
      />
    </>
  );
}
