import React from 'react';
import { ListAlt, Payments } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { Route } from 'react-router-dom';
import {
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
} from 'Generated/graphql-hooks';
import {
  BankCashTransfer,
  BankTransfer,
  CurrentAccountDetail,
  MortgageAccountDetail,
  SavingsAccountDetail,
  SavingsTransferToAccount,
  TermAccountDetail,
  Transactions,
} from 'Components';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { ConsumerCreditDetail } from 'Components/ConsumerCredit';

const transactionsTabConfig = {
  href: ``,
  icon: <ListAlt />,
  labelKey: 'bank.account.transactions',
};

const detailTabConfig = {
  href: `detail`,
  icon: <VisibilityIcon />,
  labelKey: 'bank.account.detail',
};

export function getAccountConfig(tpfo?: TimespacePersonFinancialObjectFragment | null) {
  const code = tpfo?.financialObject?.code?.substring(0, 4);
  const discarded = tpfo?.objectStatus === TimespacePersonFinancialObjectObjectStatusType.Discarded;

  const accountTypeConfig = {
    // bezny ucet
    [FinancialObjectCodeEnum.BasicBankAccount]: {
      routes: [
        <Route
          key="transactions"
          element={<Transactions timespacePersonFinancialObject={tpfo} />}
          path="/"
        />,
        <Route
          key="payment"
          element={<BankCashTransfer timespacePersonFinancialObject={tpfo} />}
          path="/payment"
        />,
        <Route
          key="transfer"
          element={<BankTransfer timespacePersonFinancialObject={tpfo} />}
          path="/transfer"
        />,
        <Route
          key="detail"
          element={<CurrentAccountDetail timespacePersonFinancialObject={tpfo} />}
          path="/detail"
        />,
      ],
      tabs: [
        transactionsTabConfig,
        {
          href: `payment`,
          icon: <Payments />,
          labelKey: 'bank.payment.withdraw',
        },
        {
          href: `transfer`,
          icon: <MoveDownIcon />,
          labelKey: 'bank.bank_payment',
        },
        detailTabConfig,
      ],
    },
    // hotovost
    [FinancialObjectCodeEnum.Cash]: {
      routes: [
        <Route
          key="transactions"
          element={<Transactions timespacePersonFinancialObject={tpfo} />}
          path="/"
        />,
        <Route
          key="payment"
          element={<BankCashTransfer timespacePersonFinancialObject={tpfo} />}
          path="/payment"
        />,
      ],
      tabs: [
        transactionsTabConfig,
        {
          href: `payment`,
          icon: <Payments />,
          labelKey: 'bank.payment.deposit',
        },
      ],
    },
    // hypotekarny ucet
    [FinancialObjectCodeEnum.MortgageLoan]: {
      routes: [
        <Route
          key="transactions"
          element={<Transactions timespacePersonFinancialObject={tpfo} />}
          path="/"
        />,
        <Route
          key="detail"
          element={<MortgageAccountDetail timespacePersonFinancialObject={tpfo} />}
          path="/detail"
        />,
      ],
      tabs: [transactionsTabConfig, detailTabConfig],
    },
    // sporiaci ucet
    [FinancialObjectCodeEnum.SavingAccount]: {
      routes: [
        <Route
          key="transactions"
          element={<Transactions timespacePersonFinancialObject={tpfo} />}
          path="/"
        />,
        <Route
          key="transfer"
          element={<SavingsTransferToAccount timespacePersonFinancialObject={tpfo} />}
          path="/transfer"
        />,
        <Route
          key="detail"
          element={<SavingsAccountDetail timespacePersonFinancialObject={tpfo} />}
          path="/detail"
        />,
      ],
      tabs: [
        transactionsTabConfig,
        ...(!discarded
          ? [
              {
                href: `transfer`,
                icon: <MoveDownIcon />,
                labelKey: 'bank.transfer',
              },
            ]
          : []),
        detailTabConfig,
      ],
    },

    // terminovany ucet
    [FinancialObjectCodeEnum.FixedTermDepositAccount]: {
      routes: [
        <Route
          key="transactions"
          element={<Transactions timespacePersonFinancialObject={tpfo} />}
          path="/"
        />,
        <Route
          key="detail"
          element={<TermAccountDetail timespacePersonFinancialObject={tpfo} />}
          path="/detail"
        />,
      ],
      tabs: [transactionsTabConfig, detailTabConfig],
    },

    // spotrebitelsky uver
    [FinancialObjectCodeEnum.ConsumerLoan]: {
      routes: [
        <Route
          key="transactions"
          element={<Transactions timespacePersonFinancialObject={tpfo} />}
          path="/"
        />,
        <Route
          key="detail"
          element={<ConsumerCreditDetail timespacePersonFinancialObject={tpfo} />}
          path="/detail"
        />,
      ],
      tabs: [transactionsTabConfig, detailTabConfig],
    },
  };

  return code
    ? accountTypeConfig?.[code as keyof typeof accountTypeConfig]
    : { routes: [], tabs: [] };
}
