import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent } from '@mui/material';
import { formatPrice } from 'Lib/Helpers/Number';
import { LabeledText, ModalCloseButton } from 'Components/Generic';

interface Props {
  householdIncome?: number;
  livingWage?: number;
  monthlyPayments?: number;
  onClose: () => void;
  open: boolean;
}

export default function MaxCreditCalculationModal({
  householdIncome,
  livingWage,
  monthlyPayments,
  onClose: handleClose,
  open,
}: Props) {
  const { t } = useTranslation();

  const difference = (householdIncome ?? 0) - (livingWage ?? 0);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle>
        {t('consumerCredit.maxCredit.calculation')}
        <ModalCloseButton onClose={handleClose} />
      </DialogTitle>
      <Box pl={4}>
        <DialogContent>
          <Box mb={3}>
            <LabeledText
              label={t('consumerCredit.modal.income.total')}
              value={formatPrice(householdIncome)}
            />
            <LabeledText
              label={t('consumerCredit.modal.houshold.living.wage')}
              value={formatPrice(livingWage)}
            />
            <LabeledText
              label={t('consumerCredit.modal.income/wage.difference')}
              value={formatPrice(difference, false)}
            />
          </Box>
          <Box mb={3}>
            <LabeledText
              label={t('consumerCredit.modal.reserve')}
              value={formatPrice(difference * 0.4, false)}
            />
            <LabeledText
              label={t('consumerCredit.modal.amount.left')}
              value={formatPrice(Math.max(difference * 0.6, 0))}
            />
          </Box>
          <LabeledText
            label={t('consumerCredit.modal.other.monthly.payments')}
            value={formatPrice(monthlyPayments)}
          />
          <LabeledText
            label={t('consumerCredit.modal.maximal.repayment.amount')}
            sx={{ fontWeight: 'bold' }}
            value={formatPrice(Math.max(difference * 0.6 - (monthlyPayments ?? 0), 0))}
          />
        </DialogContent>
      </Box>
      <Box p={2}>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            {t('global.actions.ok')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
