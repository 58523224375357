// eslint-disable-next-line import/named
import { FormikHelpers } from 'formik';
import * as React from 'react';
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { FinancialObjectModal } from 'Components';
import { TimespacePersonFinancialObject } from 'Generated/graphql-hooks';

interface Props {
  data?: TimespacePersonFinancialObject;
  onSubmit?: (
    values: TimespacePersonFinancialObject,
    formikHelpers: FormikHelpers<TimespacePersonFinancialObject>,
  ) => Promise<any> | void;
}

export default function TimespacePersonFinancialObjectDetailModal({ data, onSubmit }: Props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip placement="top" title={t('global.actions.edit').toString()}>
        <IconButton aria-label="edit" color="warning" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      </Tooltip>
      <FinancialObjectModal
        onClose={handleClose}
        onSubmit={(values, helpers) => {
          handleClose();
          return onSubmit && onSubmit(values, helpers);
        }}
        open={open}
        timespaceFinancialObject={data}
      />
    </>
  );
}
