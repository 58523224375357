import { useCallback, useState } from 'react';

export function useToggle(): [open: boolean, handleToggle: () => void] {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen((previousOpen) => !previousOpen);
  }, []);

  return [open, handleToggle];
}
