import * as Yup from 'yup';
import { adultAgeRange } from 'Config/Constants';

export default Yup.object({
  customName: Yup.string().trim().required('validations.required'),
  procurementRound: Yup.number()
    .min(adultAgeRange.from, 'validations.age.adult')
    .max(adultAgeRange.to, 'validations.age.adult')
    .required('validations.required'),
});
