import { Grid } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxFormik, TextFieldFormik } from 'Components/Input';

interface Props {
  attributePrefix: string;
  label?: string;
  userInput?: boolean;
}

export default function RandomEventAttributeRow({
  attributePrefix,
  label,
  userInput = false,
}: Props) {
  const { t } = useTranslation();
  const getPrefixedId = (name: string) => `${attributePrefix}.${name}`;
  const [expectedUserInput] = useField(getPrefixedId('expectedUserInput'));

  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item flex={1}>
        {label ?? t(`object.attr.${attributePrefix}`)}
      </Grid>
      <Grid item xs={1.2}>
        <TextFieldFormik id={getPrefixedId('coeffFrom')} type="number" />
      </Grid>
      <Grid item xs={1.2}>
        <TextFieldFormik id={getPrefixedId('coeffTo')} type="number" />
      </Grid>
      <Grid item xs={1.2}>
        <TextFieldFormik id={getPrefixedId('absFrom')} type="number" />
      </Grid>
      <Grid item xs={1.2}>
        <TextFieldFormik id={getPrefixedId('absTo')} type="number" />
      </Grid>
      {userInput && (
        <>
          <Grid item textAlign="center" xs={2}>
            <CheckboxFormik id={getPrefixedId('expectedUserInput')} />
          </Grid>
          <Grid item xs={1.2}>
            <TextFieldFormik
              disabled={!expectedUserInput.value}
              id={getPrefixedId('defaultValue')}
              type="number"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
