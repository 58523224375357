import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import type { MutationResult } from '@apollo/client';
import {
  UpdateTimespacePersonFinancialObjectMutation,
  useUpdateTimespacePersonFinancialObjectMutation,
} from 'Generated/graphql-hooks';

interface FormValues {
  customName: string;
}

export default function useTimespacePersonFinancialObjectUpdate(
  id?: string,
): [
  handleFormikSubmit: (args: FormValues) => Promise<void>,
  mutationMetaData: MutationResult<UpdateTimespacePersonFinancialObjectMutation>,
] {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateTimespacePersonFinancialObject, mutationMetaData] =
    useUpdateTimespacePersonFinancialObjectMutation();

  const handleFormikSubmit = useCallback(
    async ({ customName }: FormValues) => {
      try {
        if (!id) {
          throw new Error();
        }

        const { data: responseData } = await updateTimespacePersonFinancialObject({
          variables: {
            data: {
              customName,
            },
            where: { id },
          },
        });

        if (responseData?.updateTimespacePersonFinancialObject) {
          enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        } else {
          throw new Error();
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      }
    },
    [id],
  );

  return [handleFormikSubmit, mutationMetaData];
}
