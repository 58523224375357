import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { LabeledText, TextFieldFormik } from 'Components';
import { mortgageMaturityPeriodLimits } from 'Config';
import { formatPrice } from 'Lib/Helpers/Number';
import { FormValues } from 'Screens/Game/RandomEvent/MortgageRefinance';

export default function MortgageRefinanceSection() {
  const { t } = useTranslation();
  const {
    values: { mortgageBalance, payment, propertyPrice, realizationDay },
  } = useFormikContext<FormValues>();

  return (
    <>
      <Typography fontWeight="bold" sx={{ marginTop: 4 }} variant="subtitle1">
        {t('mortgage.property.title')}
      </Typography>
      <Box pl={4}>
        <Box marginBottom={1}>
          <LabeledText label={t('mortgage.property.price')} value={formatPrice(propertyPrice)} />
        </Box>
        <Box marginBottom={1}>
          <LabeledText
            label={t('mortgage.refinance.oldMortgageAmount')}
            value={formatPrice(mortgageBalance)}
          />
        </Box>
        <Box marginBottom={1}>
          <LabeledText label={t('mortgage.ownFunds')} value={formatPrice(payment)} />
        </Box>
        <Box marginBottom={1}>
          <LabeledText
            label={t('mortgage.refinance.newMortgageAmount')}
            value={formatPrice(mortgageBalance - payment)}
          />
        </Box>
        <Box marginBottom={1}>
          <LabeledText label={t('mortgage.realizationDay')} value={realizationDay} />
        </Box>
        <LabeledText
          label={t('mortgage.maturityPeriod')}
          value={
            <TextFieldFormik
              InputProps={{
                endAdornment: t('global.units.years.basic'),
                inputProps: mortgageMaturityPeriodLimits,
              }}
              id="maturityPeriod"
              type="number"
            />
          }
        />
      </Box>
    </>
  );
}
