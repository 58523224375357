import { useSnackbar } from 'notistack';
import React from 'react';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { SafeDeleteModal } from 'Components';
import { namedOperations, useDeleteRandomEventMutation } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';

interface Props {
  id: string;
}

export default function RandomEventDeleteModal({ id }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, handleOpen, isOpen } = useModal();
  const [deleteRandomEvent, { loading }] = useDeleteRandomEventMutation({
    refetchQueries: [namedOperations.Query.RandomEvents],
    variables: { where: { id } },
  });

  const handleOnConfirm = () =>
    deleteRandomEvent()
      .then((response) => {
        if (response.data?.deleteRandomEvent) {
          handleClose();
          enqueueSnackbar(t('randomEvent.message.deleted'), { variant: 'success' });
        }
      })
      .catch(() => {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      });

  return (
    <>
      <Tooltip placement="top" title={t('global.actions.delete').toString()}>
        <IconButton onClick={() => handleOpen(id)}>
          <Delete />
        </IconButton>
      </Tooltip>
      <SafeDeleteModal
        loading={loading}
        onClose={handleClose}
        onConfirm={handleOnConfirm}
        open={isOpen}
      />
    </>
  );
}
