import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'Lib/i18n';
// import ReactGA from 'react-ga4';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { APP_VERSION } from 'Config/Constants';
import App from './App';
import theme from './Theme';

// process.env.REACT_APP_RELEASE_STAGE === 'production'
//   ? ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '')
//   : null;

Bugsnag.start({
  apiKey: '6087d7d810a3933ddd639aad5f2a45ac',
  enabledReleaseStages: ['development', 'test', 'staging', 'production'],
  metadata: {
    appVersion: APP_VERSION,
  },
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
});
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);
