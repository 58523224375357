import { RandomEvent } from 'Generated/graphql-hooks';

export const extractRandomEventAttributeEntities = (randomEvent: RandomEvent) =>
  [
    ...(randomEvent.randomEventObjects || []),
    ...(randomEvent?.randomEventFinancialObjects || []),
    ...(randomEvent?.randomEventAcquiredObject ? [randomEvent.randomEventAcquiredObject] : []),
  ].flatMap((acqObject) =>
    Object.values(acqObject).filter(
      (x) => typeof x === 'object' && x?.__typename === 'RandomEventObjectAttribute' && x.id,
    ),
  );
