import { MUIDataTableColumnDef } from 'mui-datatables';
import { t } from 'i18next';
import { formatDuration, formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { Timespace, TimespacePersonFinancialObjectFragment } from 'Generated/graphql-hooks';

export const termAccountTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('timespace.attr.name'),
    name: 'name',
  },
  {
    label: t('object.attr.interest.pa'),
    name: 'interestPositive',
    options: {
      customBodyRender: (value: number) => formatPercent(value),
      setCellProps: () => ({
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
  },
  {
    label: t('object.attr.interestPeriod'),
    name: 'interestPeriod',
    options: {
      customBodyRender: (value: number) => (value ? `${value} m.` : '-'),
    },
  },
  {
    label: t('object.attr.principalMin'),
    name: 'principalMin',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
      setCellProps: () => ({
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
  },
  {
    label: t('object.attr.maturityPeriod'),
    name: 'maturityPeriod',
    options: {
      customBodyRender: (value) => formatDuration(value),
    },
  },
];

export function getNextPossibleDiscardRound(
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null,
  timespace?: Timespace | null,
) {
  const round = (timespace?.round ?? 0) - (timespacePersonFinancialObject?.procurementRound ?? 0);

  return (
    (timespace?.round ?? 0) -
    (round % (timespacePersonFinancialObject?.maturityPeriod ?? 0)) +
    (timespacePersonFinancialObject?.maturityPeriod ?? 0)
  );
}
