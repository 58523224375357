import React, { useContext } from 'react';
import type { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Section from 'Components/Section';
import MutualFundTransactionCreateInput from 'Models/MutualFundTransactionCreateInput';
import {
  MutualFundInvestorStatusFragment,
  namedOperations,
  useCreateMutualFundInvestorTransactionMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import MutualFundTransactionSellForm from './SellForm';
import type { FormValues } from './SellForm';

interface Props {
  mutualFundInvestorStatus?: MutualFundInvestorStatusFragment | null;
}

export default function MutualFundTransactionSell({ mutualFundInvestorStatus }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [createMutualFundInvestorTransactionMutation] =
    useCreateMutualFundInvestorTransactionMutation();

  const handleSubmit = async (
    { day, relatedBankAccount, volume }: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    try {
      const { data: responseData, errors } = await createMutualFundInvestorTransactionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [namedOperations.Query.MutualFundTransactions],
        variables: {
          amountRequested: null,
          day: day ?? 1,
          mutualFundStatusId: Number(mutualFundInvestorStatus?.mutualFundStatus?.id),
          relatedBankAccountId: Number(relatedBankAccount?.id),
          repeatRounds: 1,
          timespacePersonId: Number(timespacePerson.id),
          volume,
        },
      });
      if (responseData?.createMutualFundInvestorTransaction) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        resetForm();
      } else if (errors?.some((x) => ~x.message.indexOf('NotEnoughVolumeError'))) {
        const error = errors?.find((x) => ~x.message.indexOf('NotEnoughVolumeError'));
        // @ts-expect-error not typed
        const value = error?.extensions?.exception?.thrownValue?.cause?.actualVolume ?? 0;
        enqueueSnackbar(
          t('errors.error.not-enough-volume', {
            replace: {
              value,
            },
          }),
          { variant: 'error' },
        );
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('investments.mutualFund.transaction.sell.title')}>
        <MutualFundTransactionSellForm
          fundFinancialObject={mutualFundInvestorStatus?.mutualFundStatus?.fund}
          initialValues={new MutualFundTransactionCreateInput()}
          onSubmit={handleSubmit}
        />
      </Section>
    </Container>
  );
}
