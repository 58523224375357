import React from 'react';
// eslint-disable-next-line import/named
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

export interface AutocompleteHFProps
  extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'>,
    UseControllerProps<any, string> {
  label?: string;
}

export default React.memo(function AutocompleteHF({
  control,
  label,
  name,
  ...restProps
}: AutocompleteHFProps): JSX.Element {
  if (!control || !name) {
    throw new Error('Control and name must be defined');
  }
  const { t } = useTranslation();
  const {
    field: { onBlur, onChange, value },
    fieldState: { error, isTouched },
  } = useController<FieldValues>({ control, name });

  return (
    <Autocomplete
      {...restProps}
      noOptionsText={t('global.placeholder.empty')}
      onBlur={onBlur}
      onChange={(_, data) => onChange(data)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={isTouched && error !== undefined && error.message !== ''}
          helperText={error && isTouched ? t(`${error.message}`) : null}
          label={label}
          margin="dense"
          size="small"
        />
      )}
      value={value ?? ''}
    />
  );
});
