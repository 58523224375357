import React, { useContext } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Section } from 'Components';
import { GameContext } from 'Context';
import { useTimespacePersonHouseholdObjectsQuery } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { HouseholdObjectCodes } from 'Types/Global';
import HouseholdPartnerAddModal from './PartnerAddModal';
import HouseholdChildAddModal from './ChildAddModal';
import HouseholdPartnerItem from './PartnerItem';
import HouseholdChildItem from './ChildItem';

export default function HouseholdSection() {
  const { t } = useTranslation();
  const { loading: timespacePersonLoading, timespacePerson } = useContext(GameContext);
  const {
    handleClose: handleAddPartnerModalClose,
    handleOpen: handleAddPartnerModalOpen,
    isOpen: isAddPartnerModalOpen,
  } = useModal();
  const {
    handleClose: handleAddChildModalClose,
    handleOpen: handleAddChildModalOpen,
    isOpen: isAddChildModalOpen,
  } = useModal();

  const { data, loading } = useTimespacePersonHouseholdObjectsQuery({
    variables: { timespacePersonId: timespacePerson.id },
  });

  const partnerAvailable = timespacePerson?.timespace?.timespaceAvailableObjects?.some(
    (object) =>
      object?.objectCode === HouseholdObjectCodes.PARTNER ||
      object?.objectCode === HouseholdObjectCodes.FAMILYMEMBERS ||
      object?.objectCode?.startsWith(HouseholdObjectCodes.ADULTFAMILYMEMBERS),
  );

  const childrenAvailable = timespacePerson?.timespace?.timespaceAvailableObjects?.some(
    (object) =>
      object?.objectCode === HouseholdObjectCodes.CHILD ||
      object?.objectCode === HouseholdObjectCodes.FAMILYMEMBERS ||
      object?.objectCode?.startsWith(HouseholdObjectCodes.CHILDFAMILYMEMBERS),
  );

  const partner = data?.timespacePersonObjects?.find(
    ({ object }) => object?.code === HouseholdObjectCodes.PARTNER,
  );

  const children =
    data?.timespacePersonObjects
      ?.filter(({ object }) => object?.code === HouseholdObjectCodes.CHILD)
      .sort(({ procurementRound }, { procurementRound: procurementRound2 }) =>
        !procurementRound || !procurementRound2 ? 1 : procurementRound - procurementRound2,
      ) ?? [];

  const partnerEmployments = data?.timespacePersonObjects?.filter(({ object }) =>
    object?.code?.startsWith(HouseholdObjectCodes.EMPLOYMENT),
  );

  return (
    <Section title={t('game.menu.section.household')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading || timespacePersonLoading ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <>
              {partner ? (
                <HouseholdPartnerItem
                  employmentObjects={partnerEmployments}
                  personObject={partner}
                />
              ) : partnerAvailable ? (
                <>
                  <Box>{t('object.single')}</Box>
                  <Button
                    onClick={handleAddPartnerModalOpen}
                    startIcon={<Add />}
                    sx={{ marginTop: 2 }}
                    variant="outlined">
                    {t('household.actions.partner.add')}
                  </Button>
                </>
              ) : null}
              {children.length || childrenAvailable ? (
                <Box mt={4}>{t('household.children')}:</Box>
              ) : null}
              {children.length ? (
                <Box pl={4}>
                  {children.map((item, index) => (
                    <HouseholdChildItem key={`child_${index}`} object={item} />
                  ))}
                </Box>
              ) : null}
              {childrenAvailable ? (
                <Button
                  onClick={handleAddChildModalOpen}
                  startIcon={<Add />}
                  sx={{ marginTop: 2 }}
                  variant="outlined">
                  {t('household.actions.child.add')}
                </Button>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
      <HouseholdPartnerAddModal onClose={handleAddPartnerModalClose} open={isAddPartnerModalOpen} />
      <HouseholdChildAddModal onClose={handleAddChildModalClose} open={isAddChildModalOpen} />
    </Section>
  );
}
