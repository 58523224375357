import { UserGradeType } from 'Generated/graphql-hooks';

export const schoolAutocompleteUrl = 'https://www.indicia.sk/api/v1/schools/autocomplete';

export const orderedUserGradeTypes = [
  UserGradeType.PrimarySchoolFifthYear,
  UserGradeType.PrimarySchoolSixthYear,
  UserGradeType.PrimarySchoolSeventhYear,
  UserGradeType.PrimarySchoolEighthYear,
  UserGradeType.PrimarySchoolNinthYear,
  UserGradeType.MiddleSchoolFirstYear,
  UserGradeType.MiddleSchoolSecondYear,
  UserGradeType.MiddleSchoolThirdYear,
  UserGradeType.MiddleSchoolFourthYear,
];
