import { UserCreateInput, UserParticipantTypeType } from 'Generated/graphql-hooks';

export default class RegisterInput implements Nullable<UserCreateInput> {
  confirmPassword = undefined;
  dateOfBirth = undefined;
  email = undefined;
  firstName = undefined;
  grade = undefined;
  lastName = undefined;
  nickName = undefined;
  participantType = UserParticipantTypeType.Student;
  password = undefined;
  schoolName = undefined;
  teacherFirstName = undefined;
  teacherLastName = undefined;
}
