import { useContext } from 'react';
import * as React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GameContext } from 'Context';
import { DeleteTimespaceModalButton } from 'Components';

export default function OtherSettings() {
  const { t } = useTranslation();
  const {
    timespacePerson: { timespace },
  } = useContext(GameContext);

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <Typography component="span">{t('timespace.code')}</Typography>
            <Typography component="span" fontWeight="bold">
              : {timespace?.code}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gap={1} justifyContent="end" pt={2}>
            {/* <CreateFromTimespaceModalButton timespaceId={timespace?.id} /> */}
            <DeleteTimespaceModalButton timespaceId={timespace?.id} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
