import React, { useCallback, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import {
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TimespacePaths } from 'Config';
import { GameContext, GameContextProvider } from 'Context';
import { BankCategories, CircularLoader, Error, MenuButton, ProfileMenuItem } from 'Components';
import AppBar from 'Components/AppBar';
import InterpersonalizeMenuItem from 'Components/InterpersonalizeMenuItem';

const drawerWidth = 240;

export default function BankLayout() {
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = useCallback(() => setDrawerOpen(false), []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <AppBar
        left={
          isMobile ? <MenuButton drawerOpen={drawerOpen} onDrawerOpen={setDrawerOpen} /> : undefined
        }
        position="fixed"
        right={
          <Box alignItems="center" display="flex">
            <ProfileMenuItem />
            <InterpersonalizeMenuItem />
          </Box>
        }
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
        title={t('game.menu.bank')}
      />
      <GameContextProvider>
        <Box sx={{ display: 'flex' }}>
          <Drawer
            anchor="left"
            onClose={closeDrawer}
            open={drawerOpen}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
              flexShrink: 0,
              width: drawerWidth,
            }}
            variant={isMobile ? undefined : 'permanent'}>
            <Box>
              <Toolbar />
              <ListItemButton component={Link} to={`${TimespacePaths.Root}/${timespaceId}`}>
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText>{t('timespace.label')}</ListItemText>
              </ListItemButton>
              <Divider />
              <Box sx={{ maxHeight: '90vh' }}>
                <BankCategories />
              </Box>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Toolbar />
            <GameContext.Consumer>
              {({ error, loading }) =>
                loading ? <CircularLoader /> : error ? <Error /> : <Outlet />
              }
            </GameContext.Consumer>
          </Box>
        </Box>
      </GameContextProvider>
    </>
  );
}
