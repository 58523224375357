import { Shadows } from '@mui/material/styles/shadows';

const shadowSmall = '0px 3px 6px rgba(0, 0, 0, 0.12)';
const shadowDefault = '0px 6px 18px rgba(0,0,0,0.12)';
const shadowLarge = '0px 12px 36px rgba(0, 0, 0, 0.12)';

export default [
  'none',
  shadowSmall,
  shadowSmall,
  shadowSmall,
  shadowSmall,
  shadowSmall,
  shadowSmall,
  shadowSmall,
  shadowDefault,
  shadowDefault,
  shadowDefault,
  shadowDefault,
  shadowDefault,
  shadowDefault,
  shadowDefault,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
  shadowLarge,
] as Shadows;
