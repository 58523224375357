import { Edit, TransferWithinAStation } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import formatDate from 'date-fns/format';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import PlayerModal from 'Components/Player/Detail';
import { useErrorHandler, useModal, usePagination } from 'Lib/Hooks';
import {
  GetUserTokenDocument,
  OrderDirection,
  TimespacePerson,
  UserFragment,
  useTimespacePeopleQuery,
} from 'Generated/graphql-hooks';
import { EnhancedDataTable } from 'Components/Generic';
import { calculateLevelFromExperienceScore } from 'Lib/Helpers/Level';
import { TimespacePaths } from 'Config';
import { setInterpersonalizedPerson, setSessionToken, setUser } from 'Lib/Helpers/Session';
import useAuth from 'Lib/Hooks/useAuth';
import { getPersonFullName } from 'Lib/Helpers/TimespacePersonObject';

export default function PlayersTable() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { timespaceId } = useParams();
  const { handleClose, handleOpen, isOpen, selected } = useModal<TimespacePerson>();
  const { skip, take, ...pagination } = usePagination();

  const { data, loading } = useTimespacePeopleQuery({
    skip: !timespaceId,
    variables: {
      orderBy: [{ id: OrderDirection.Asc }],
      skip,
      take,
      where: {
        timespace: { id: { equals: timespaceId } },
      },
    },
  });

  const handleInterpersonalize = useCallback(
    async (timespacePerson) => {
      try {
        const { data: userToken, errors } = await client.query({
          query: GetUserTokenDocument,
          variables: {
            timespaceId: Number(timespaceId),
            timespacePersonId: Number(timespacePerson.id),
          },
        });

        if (userToken.getUserToken) {
          await client.resetStore();
          setUser(user ? getPersonFullName(user) : '');
          setInterpersonalizedPerson(
            timespacePerson.user ? getPersonFullName(timespacePerson.user) : '',
          );

          setSessionToken(userToken.getUserToken);
          enqueueSnackbar(t('user.interpersonalize.success'), { variant: 'success' });
          await client.refetchQueries({
            include: 'active',
          });
        } else {
          handleError(errors);
        }
      } catch (e: any) {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      } finally {
        navigate(`${TimespacePaths.Root}/${timespaceId}`);
      }
    },
    [timespaceId, enqueueSnackbar, navigate],
  );

  const playerActions = useCallback(
    (rowIndex) => {
      return (
        <>
          <Tooltip placement="top" title={t('global.actions.edit').toString()}>
            <IconButton
              color="warning"
              onClick={() => handleOpen(data?.timespacePeople?.[rowIndex])}>
              <Edit />
            </IconButton>
          </Tooltip>
          {data?.timespacePeople?.[rowIndex]?.user?.id !== user?.id ? (
            <Tooltip placement="top" title={t('global.actions.interpersonalize').toString()}>
              <IconButton
                color="warning"
                onClick={() => handleInterpersonalize(data?.timespacePeople?.[rowIndex])}>
                <TransferWithinAStation />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      );
    },
    [data, handleOpen, handleInterpersonalize],
  );

  return (
    <Box>
      <EnhancedDataTable
        columns={[
          {
            name: 'id',
            options: {
              display: 'excluded',
            },
          },
          {
            label: t('user.attr.nickName'),
            name: 'user.nickNameDisplay',
          },
          {
            label: t('user.attr.fullName'),
            name: 'user',
            options: {
              customBodyRender: (value: UserFragment) =>
                `${value.firstName || ''} ${value.lastName || ''}`,
            },
          },
          {
            label: t('user.attr.lastVisitedDate'),
            name: 'lastVisitedDate',
            options: {
              customBodyRender: (value) => formatDate(new Date(value), 'dd.MM.yyyy HH:mm:ss'),
            },
          },
          {
            label: t('user.attr.lifeQuality'),
            name: 'lifeQuality',
            options: {
              customBodyRender: (value) => Math.round(value),
            },
          },
          {
            label: t('user.attr.overall'),
            name: 'user.experienceScore',
            options: {
              customBodyRender: (value) => calculateLevelFromExperienceScore(value) + 1,
            },
          },
          {
            label: t('global.table.actions'),
            name: 'actions',
            options: {
              customBodyRender: (_, { rowIndex }) => playerActions(rowIndex),
            },
          },
        ]}
        data={data?.timespacePeople ?? []}
        loading={loading}
        options={
          {
            ...pagination,
            count: data?.count,
            setTableProps: () => ({ style: { minHeight: '165px' } }),
            sort: false,
          } as MUIDataTableOptions
        }
        title=""
      />
      {selected ? (
        <PlayerModal onClose={handleClose} open={isOpen} timespacePerson={selected} />
      ) : null}
    </Box>
  );
}
