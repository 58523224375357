import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import * as React from 'react';
import ModalCloseButton from './ModalCloseButton';

interface Props {
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  text?: string;
}

export default function SafeDeleteModal({
  loading,
  onClose,
  onConfirm,
  open,
  text = t('global.placeholder.delete'),
}: Props) {
  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle>
        {t('global.warning')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {t('global.actions.cancel')}
        </Button>
        <LoadingButton loading={loading} onClick={onConfirm} variant="contained">
          <span>{t('global.actions.confirm')}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
