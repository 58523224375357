import React, { memo, useCallback } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import {
  FinancialObject,
  OrderDirection,
  useFinancialObjectsListQuery,
} from 'Generated/graphql-hooks';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { EnhancedDataTable, SkeletonLoader } from 'Components/Generic';
import { termAccountTableColumns } from './utils';

interface Props {
  onRowSelected: (financialObject: FinancialObject) => void;
  value: FinancialObject | null;
}

export default memo(function TermAccountTable({ onRowSelected, value }: Props) {
  const { t } = useTranslation();
  const { data, loading } = useFinancialObjectsListQuery({
    variables: {
      orderBy: [{ name: OrderDirection.Asc }],
      where: {
        AND: [
          { code: { startsWith: FinancialObjectCodeEnum.FixedTermDepositAccount } },
          { isAbstract: { equals: false } },
        ],
      },
    },
  });

  const handleRowSelectionChange = useCallback(
    (_, __, selectedRows: any[]) => {
      if (data?.financialObjects) {
        onRowSelected(data.financialObjects?.[selectedRows[0]]);
      }
    },
    [data?.financialObjects],
  );

  const prepareData = useCallback(
    () =>
      data?.financialObjects?.map((item) => ({
        ...item,
        maturityPeriod: (item.maturityPeriod ?? 0) / 12,
      })) ?? [],
    [data?.financialObjects],
  );

  const selectedRowIndex = data?.financialObjects?.findIndex(({ id }) => id === value?.id) ?? -1;

  return (
    <EnhancedDataTable
      hideToolbar
      columns={termAccountTableColumns}
      data={prepareData()}
      options={
        {
          onRowSelectionChange: handleRowSelectionChange,
          rowsSelected: selectedRowIndex > -1 ? [selectedRowIndex] : undefined,
          selectableRows: 'single',
          selectableRowsOnClick: true,
          textLabels: {
            body: {
              noMatch: loading ? <SkeletonLoader height={33} /> : t('global.placeholder.empty'),
            },
          },
        } as MUIDataTableOptions
      }
      title={''}
    />
  );
});
