import * as React from 'react';
import { TimespacePersonFinancialObject, TimespacePersonObject } from 'Models';
import { FinancialObject, Object } from 'Generated/graphql-hooks';

export default React.createContext<{
  disableDetail?: boolean;
  disabledFunction?: (item: Object | FinancialObject) => boolean | undefined | null;
  hideDisabledCheckbox?: boolean;
  isItemIndeterminent: (item: Object | FinancialObject, childrenCount?: number) => boolean;
  isItemSelected: (item: Object | FinancialObject, childrenCount?: number) => boolean;
  onChange: (object: Object | FinancialObject, checked: boolean) => void;
  onDetailClick: (item: TimespacePersonObject | TimespacePersonFinancialObject) => void;
  onItemExpanded: (item: (Object | FinancialObject)[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(null!);
