import React from 'react';
import { t } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TimespacePaths } from 'Config';
import { formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { MutualFundStatus } from 'Generated/graphql-hooks';

export const fundsColumns = (timespaceId?: string): MUIDataTableColumnDef[] => [
  {
    name: 'round',
    options: { display: 'excluded', sort: false },
  },
  {
    label: t('investments.mutual.fund'),
    name: 'mutualFundInvestorStatus',
    options: {
      customBodyRender: (fund) => {
        return (
          <Link
            component={RouterLink}
            to={`${TimespacePaths.Investments}/${timespaceId}/${fund.id}`}
            underline="always">
            {fund.mutualFundStatus.fund.name}
          </Link>
        );
      },
    },
  },
  {
    label: t('investments.investVolume.count'),
    name: 'finalVolume',
    options: {
      customBodyRender: (value) => value || '-',
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('investments.averagePurchasePrice'),
    name: 'averagePurchasePrice',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('investments.sharePrice'),
    name: 'mutualFundStatus.sharePrice',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('investments.price.change'),
    name: 'priceChange',
    options: {
      customBodyRender: (value) => formatPercent(value, { abs: false, maximumFractionDigits: 2 }),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('investments.investAmount'),
    name: 'totalAmount',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('investments.current.fund.value'),
    name: 'currentFundValue',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('investments.total.profit'),
    name: 'totalGain',
    options: {
      customBodyRender: (value) => formatPercent(value, { abs: false, maximumFractionDigits: 2 }),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
];

export function calculatePriceChange(lastFundStatuses?: MutualFundStatus[]) {
  return lastFundStatuses?.[1]
    ? (lastFundStatuses?.[0]?.sharePrice ?? 0) / (lastFundStatuses?.[1]?.sharePrice ?? 0) - 1
    : 0;
}
