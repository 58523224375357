import { Box, Checkbox, Grid, Paper, Typography } from '@mui/material';
import { useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  TimespacePersonFinancialObjectFragment,
  useUpdateTimespacePersonMutation,
} from 'Generated/graphql-hooks';
import { formatPrice } from 'Lib/Helpers/Number';
import TransactionsTable from 'Components/Bank/TransactionsTable';
import { GameContext } from 'Context';
import { FinancialObjectCodeEnum } from 'Types/Global';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function Transactions({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = React.useContext(GameContext);
  const [updateTimespacePerson, { loading }] = useUpdateTimespacePersonMutation();
  const couldBeDefaultPaymentMethod =
    timespacePersonFinancialObject?.financialObject?.code?.startsWith(
      FinancialObjectCodeEnum.BasicBankAccount,
    ) ||
    timespacePersonFinancialObject?.financialObject?.code?.startsWith(FinancialObjectCodeEnum.Cash);

  const handleSetDefaultPaymentMethod = useCallback(
    () =>
      updateTimespacePerson({
        variables: {
          data: { defaultPaymentMethod: { connect: { id: timespacePersonFinancialObject?.id } } },
          where: { id: timespacePerson?.id },
        },
      }),
    [updateTimespacePerson, timespacePersonFinancialObject, timespacePerson],
  );

  return timespacePersonFinancialObject ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ minHeight: '100%', p: 2 }}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography>
              {t('bank.account.totalValue', {
                replace: {
                  value: formatPrice(timespacePersonFinancialObject?.actualValue, false),
                },
              })}
            </Typography>
            {timespacePerson.defaultPaymentMethod?.financialObject?.code ===
            timespacePersonFinancialObject?.financialObject?.code ? (
              <Box>
                <span>{t('timespace.defaultPaymentMethod')}</span>
                <Checkbox checked disabled />
              </Box>
            ) : couldBeDefaultPaymentMethod ? (
              <LoadingButton
                loading={loading}
                onClick={handleSetDefaultPaymentMethod}
                variant="contained">
                <span>{t('timespace.setDefaultPaymentMethod')}</span>
              </LoadingButton>
            ) : null}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TransactionsTable />
      </Grid>
    </Grid>
  ) : null;
}
