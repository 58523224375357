import * as Yup from 'yup';

export default Yup.object({
  amountRequested: Yup.number()
    .min(0, 'validations.positiveNumber')
    .required('validations.required'),
  day: Yup.number().required('validations.required'),
  investmentRepeat: Yup.boolean(),
  relatedBankAccount: Yup.object().required('validations.required').nullable(),
  repeatRounds: Yup.number().when('investmentRepeat', {
    is: (value: boolean) => value === true,
    then: Yup.number().min(1, 'validations.moreThen1').required('validations.required'),
  }),
});
