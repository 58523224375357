import * as React from 'react';
import formatDate from 'date-fns/format';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LabeledText, ModalCloseButton } from 'Components';
import { visibleDateFormat } from 'Config/Constants';
import { TimespacePerson, useTimespacePersonHoldingsQuery } from 'Generated/graphql-hooks';
import { getPersonFullName } from 'Lib/Helpers/TimespacePersonObject';
import { formatAge, formatPrice } from 'Lib/Helpers/Number';
import RemovePlayerModalButton from './RemovePlayerModal';

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePerson: TimespacePerson;
}

export default function PlayerModal({ onClose, open, timespacePerson }: Props) {
  const { t } = useTranslation();
  const { data: holdingsData, loading: holdingsDataLoading } = useTimespacePersonHoldingsQuery({
    skip: !timespacePerson.timespace?.id,
    variables: { timespacePersonId: Number(timespacePerson.id) },
  });

  if (!timespacePerson) {
    return null;
  }

  const { age, lastVisitedDate, lifeQuality, timeAvailable, user } = timespacePerson;
  const { financialAssetsValue, movablePropertiesValue, realEstatesValue } = holdingsData ?? {};
  const totalHoldings =
    (movablePropertiesValue ?? 0) + (realEstatesValue ?? 0) + (financialAssetsValue ?? 0);
  const liabilities = holdingsData?.liabilitiesValue ?? 0;

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('player.detail')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LabeledText label={t('player.attr.nickName')} value={user?.nickNameDisplay} />
            <LabeledText
              label={t('player.attr.name')}
              value={user ? getPersonFullName(user) : ''}
            />
            <LabeledText label={t('player.attr.email')} value={user?.email} />
            <LabeledText label={t('player.attr.overall')} value={undefined} />
            <Box my={3}>
              <LabeledText
                label={t('player.attr.lastVisitedDate')}
                value={formatDate(new Date(lastVisitedDate), visibleDateFormat)}
              />
            </Box>
            <LabeledText
              label={t('player.attr.lifeQuality')}
              value={lifeQuality ? Math.round(lifeQuality) : null}
            />
            <LabeledText label={t('player.attr.age')} value={formatAge(age)} />
            <LabeledText
              label={t('player.attr.timeAvailable')}
              value={`${timeAvailable} ${t('global.units.hourDuration')}`}
            />
          </Grid>
          <Grid item sx={{ marginTop: 4 }} xs={12}>
            <Typography fontWeight="bold" variant="subtitle1">
              {t('player.attr.holdings')}
            </Typography>
            {holdingsDataLoading ? (
              <Box display="flex" justifyContent="center" py={5}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Box pl={4}>
                <LabeledText
                  label={t('holdings.clear')}
                  value={formatPrice(totalHoldings - Math.abs(liabilities ?? 0), false)}
                />
                <LabeledText
                  label={t('holdings.total')}
                  value={formatPrice(totalHoldings, false)}
                />
                <Box pl={4}>
                  <LabeledText
                    label={t('holdings.goods')}
                    value={formatPrice(movablePropertiesValue, false)}
                  />
                  <LabeledText
                    label={t('holdings.properties')}
                    value={formatPrice(realEstatesValue, false)}
                  />
                  <LabeledText
                    label={t('holdings.financial')}
                    value={formatPrice(financialAssetsValue, false)}
                  />
                </Box>
                <LabeledText label={t('holdings.debts')} value={formatPrice(liabilities, false)} />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <RemovePlayerModalButton onClose={onClose} timespacePerson={timespacePerson} />
        <Button onClick={onClose} variant="outlined">
          {t('global.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
