import omit from 'lodash/omit';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import {
  RandomEventFinancialObjectCreateInput as IRandomEventFinancialObjectCreateInput,
  InputMaybe,
  RandomEventFinancialObject,
  RandomEventObjectAttributeRelateToOneForCreateInput,
  Scalars,
} from 'Generated/graphql-hooks';

export class RandomEventFinancialObjectCreateInput
  implements IRandomEventFinancialObjectCreateInput
{
  constructor(item: RandomEventFinancialObject) {
    item = mapValuesDeep(item, (v) => (v === '' ? null : v));
    this.actualValue = {
      create: omit(item.actualValue, 'id'),
    };
    this.description = item.description ?? '';
    this.objectsAffected = item.objectsAffected;
    this.objectsAffectedType = item.objectsAffectedType ?? -1;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  actualValue?: InputMaybe<RandomEventObjectAttributeRelateToOneForCreateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  objectsAffected?: InputMaybe<Scalars['String']['input']>;
  objectsAffectedType?: InputMaybe<Scalars['Int']['input']>;
}
