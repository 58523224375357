import { MUIDataTableColumnDef } from 'mui-datatables';
import { t } from 'i18next';
import { formatPercent } from 'Lib/Helpers/Number';

export const savingsAccountTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('timespace.attr.name'),
    name: 'name',
  },
  {
    label: t('object.attr.interest.pa'),
    name: 'interestPositive',
    options: {
      customBodyRender: (value: number) => formatPercent(value),
      setCellProps: () => ({
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
  },
  {
    label: t('object.attr.interestPeriod'),
    name: 'interestPeriod',
    options: {
      customBodyRender: (value: number) => (value ? `${value} m.` : '-'),
    },
  },
];
