import { useCallback } from 'react';
import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  TimespacePersonRandomEvent,
  namedOperations,
  useGetMortgageFixationEndItemQuery,
  useMortgageFixationAcceptConditionsMutation,
  useTimespacePersonFinancialObjectQuery,
} from 'Generated/graphql-hooks';
import { ModalCloseButton } from 'Components/Generic';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { Constants, TimespacePaths } from 'Config';
import { useErrorHandler } from 'Lib/Hooks';
import { formatPercent, formatPrice } from 'Lib/Helpers/Number';

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePersonRandomEvent: TimespacePersonRandomEvent;
}

export default function MortgageRefinanceUserInputModal({
  onClose: handleClose,
  open,
  timespacePersonRandomEvent,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();

  const [mortgageFixationAcceptConditionsMutation] = useMortgageFixationAcceptConditionsMutation();

  const randomEvent = timespacePersonRandomEvent.randomEvent ?? null;
  const randomFinancialObjects = randomEvent?.randomEventFinancialObjects ?? [];
  const mortgageId = randomFinancialObjects?.[0]?.objectsAffectedType;

  const { data: mortgageData } = useTimespacePersonFinancialObjectQuery({
    skip: !mortgageId,
    variables: {
      where: { id: mortgageId?.toString() },
    },
  });

  const mortgageFinancialObject = mortgageData?.timespacePersonFinancialObject;

  const mortgageFixationEndItemQuery = useGetMortgageFixationEndItemQuery({
    skip: !mortgageFinancialObject?.id,
    variables: {
      timespacePersonFinancialObjectId: Number(mortgageFinancialObject?.id),
    },
  });

  const monthlyPayment =
    mortgageFixationEndItemQuery.data?.getMortgageFixationEndItem?.newMonthlyPayment;

  const handleOnPayment = useCallback(async () => {
    handleClose();
    navigate(
      `${TimespacePaths.RandomEvent}/${timespacePersonRandomEvent?.randomEvent?.id}/mortgage-refinance/${mortgageFinancialObject?.id}`,
    );
  }, [timespacePersonRandomEvent, mortgageFinancialObject, enqueueSnackbar]);

  const handleOnAccept = useCallback(async () => {
    try {
      const { data, errors } = await mortgageFixationAcceptConditionsMutation({
        awaitRefetchQueries: true,
        refetchQueries: [namedOperations.Query.TimespacePersonRandomEvents],
        variables: {
          newPayment: Number(monthlyPayment),
          timespacePersonFinancialObjectId: Number(mortgageFinancialObject!.id),
        },
      });
      if (data?.mortgageFixationAcceptConditions) {
        handleClose();
        enqueueSnackbar(t('randomEvent.mortgageUserInput.success'), { variant: 'success' });
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [
    monthlyPayment,
    mortgageFinancialObject,
    mortgageFixationAcceptConditionsMutation,
    enqueueSnackbar,
  ]);

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          pb: 4,
        }}>
        {t('randomEvent.mortgageUserInput.title')}
        <ModalCloseButton onClose={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          px: 6,
        }}>
        {t('randomEvent.mortgageUserInput.descriptionStart', {
          replace: {
            date: getHumanDateFromRound(
              mortgageFinancialObject?.nextFixation ?? 0,
              mortgageFinancialObject?.realizationDay?.toString() || '1',
            ),
          },
        })}
        <div style={{ paddingLeft: 24 }}>
          <ListItem
            sx={{
              pb: 0,
            }}>
            <ListItemText
              primary={
                <strong>
                  {t('randomEvent.mortgageUserInput.interest', {
                    replace: {
                      interest: formatPercent(
                        (mortgageFinancialObject?.interestNegative ?? 0) +
                          Constants.REFINANCE_INTEREST_CHANGE,
                      ),
                    },
                  })}
                </strong>
              }
              sx={{
                display: 'list-item',
              }}
            />
          </ListItem>
          <ListItem
            sx={{
              pt: 0,
            }}>
            <ListItemText
              primary={
                <strong>
                  {t('randomEvent.mortgageUserInput.monthlyPayment', {
                    replace: {
                      monthlyPayment: formatPrice(Number(monthlyPayment)),
                    },
                  })}
                </strong>
              }
              sx={{
                display: 'list-item',
              }}
            />
          </ListItem>
        </div>

        {t('randomEvent.mortgageUserInput.descriptionEnd', {
          replace: {
            date: getHumanDateFromRound(
              mortgageFinancialObject?.nextFixation ?? 0,
              mortgageFinancialObject?.realizationDay?.toString() || '1',
            ),
          },
        })}
      </DialogContent>
      <DialogActions
        sx={{
          px: 6,
          py: 4,
        }}>
        <Button onClick={handleOnPayment} variant="contained">
          {t('randomEvent.mortgageUserInput.actions.refinance')}
        </Button>
        <Button onClick={handleOnAccept} variant="contained">
          {t('randomEvent.mortgageUserInput.actions.accept')}
        </Button>
        <Button onClick={handleClose} variant="outlined">
          {t('randomEvent.mortgageUserInput.actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
