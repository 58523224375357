export const ExpirationTypeEnum = {
  COUNT: 'COUNT',
  DATE: 'DATE',
  TERMINATION: 'TERMINATION',
};

export type Date = {
  day: number;
  month: number;
  year: number;
};
// eslint-disable-next-line no-shadow
export enum FinancialObjectCodeEnum {
  Cash = 'BKHO',
  BasicBankAccount = 'BKBU',
  CreditCard = 'BKKK',
  SavingAccount = 'BKSC',
  FixedTermDepositAccount = 'BKTV',
  MortgageLoan = 'BKHU',
  ConsumerLoan = 'BKSU',
  MutualFund = 'BKPF',
}
// eslint-disable-next-line no-shadow
export enum GlobalObjectCodeEnum {
  Education = 'ED',
  RandomEvent = 'NU',
}
// eslint-disable-next-line no-shadow
export enum GoodsObjectCodeEnum {
  HouseholdEquipment = 'ZD',
  FreeTimeEquipment = 'VV',
  FreeTimeActivity = 'VC',
  Car = 'AU',
  Clothing = 'OB',
  Electronics = 'EL',
  FinalGoods = 'SV',
  Food = 'ST',
}
// eslint-disable-next-line no-shadow
export enum LivingObjectCodeEnum {
  FlatOwnership = 'BYVL',
  Living = 'BY',
}
// eslint-disable-next-line no-shadow
export enum OcupationObjectCodeEnum {
  Ocupation = 'ZM',
}

// eslint-disable-next-line no-shadow
export enum HouseholdObjectCodes {
  ADULTFAMILYMEMBERS = 'RPDO',
  CHILDFAMILYMEMBERS = 'RPDT',
  FAMILYMEMBERS = 'RP',
  PARTNER = 'RPDOMM',
  CHILD = 'RPDT02',
  EMPLOYMENT = 'ZMMA',
}
