import { CheckBox, CheckBoxOutlineBlank, Edit } from '@mui/icons-material';
import { t } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { RandomEventAcquiredObject } from 'Generated/graphql-hooks';
import RandomEventDeleteModal from './RandomEventDeleteModal';

export const getUserInputHelperText = (
  randomEventAcquiredObject: RandomEventAcquiredObject | undefined | null,
) => {
  if (
    !randomEventAcquiredObject?.assetsImpactOneTime ||
    !randomEventAcquiredObject.object?.assetsImpactOneTime
  ) {
    return null;
  }
  const { assetsImpactOneTime, object } = randomEventAcquiredObject;
  let from =
    assetsImpactOneTime?.coeffFrom && object.assetsImpactOneTime
      ? object.assetsImpactOneTime * assetsImpactOneTime?.coeffFrom
      : 0;
  let to = (object.assetsImpactOneTime ?? 0) * (assetsImpactOneTime?.coeffTo ?? 1);

  //TODO: refactor when absFrom or Abs to are required
  if (assetsImpactOneTime.absFrom) {
    from = (randomEventAcquiredObject.isAssetsImpactPositive ? Math.max : Math.min)(
      assetsImpactOneTime.absFrom,
      from,
    );
  }
  if (assetsImpactOneTime.absTo) {
    to = (randomEventAcquiredObject.isAssetsImpactPositive ? Math.min : Math.max)(
      assetsImpactOneTime.absTo,
      to,
    );
  }

  return { from: Math.abs(from), to: Math.abs(to) };
};

export const randomEventColumns: MUIDataTableColumnDef[] = [
  { name: 'id', options: { display: 'excluded' } },
  {
    label: t('randomEvent.attr.name'),
    name: 'name',
  },
  {
    label: t('randomEvent.attr.executionRoundFrom'),
    name: 'executionRoundFrom',
  },
  {
    label: t('randomEvent.attr.executionRoundTo'),
    name: 'executionRoundTo',
  },
  {
    label: t('randomEvent.attr.executionMonthPeriodical'),
    name: 'executionMonthPeriodical',
  },
  {
    label: t('randomEvent.attr.executionYearPeriodical'),
    name: 'executionYearPeriodical',
  },
  {
    label: '%',
    name: 'executionProbability',
    options: {
      customBodyRender: (value) => (value ? Math.round(value * 100) : '-'),
    },
  },
  {
    label: t('randomEvent.attr.executionCount'),
    name: 'executionCount',
  },
  {
    label: 'Max',
    name: 'executionMaxCount',
  },
  {
    label: `${t('global.actions.yes')}/${t('global.actions.no')}`,
    name: 'executionUserAcceptance',
    options: {
      customBodyRender: (value) =>
        value ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank color="primary" />,
    },
  },
  {
    label: t('global.table.actions'),
    name: 'actions',
    options: {
      customBodyRender: (_, { rowData }) => {
        return (
          <Box display="flex">
            <Tooltip placement="top" title={t('global.actions.edit').toString()}>
              <IconButton color="warning" component={RouterLink} to={`${rowData[0]}`}>
                <Edit />
              </IconButton>
            </Tooltip>
            <RandomEventDeleteModal id={rowData[0]} />
          </Box>
        );
      },
      sort: false,
    },
  },
];
