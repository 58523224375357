/* eslint-disable @typescript-eslint/member-ordering */
import {
  TimespacePersonObjectCreateInput as ITimespaceObjectCreateInput,
  ImpactTypeRelateToOneForCreateInput,
  InputMaybe,
  ObjectRelateToOneForCreateInput,
  Scalars,
  TimespacePersonFinancialObjectRelateToOneForCreateInput,
  TimespacePersonObjectAttributeRelateToManyForCreateInput,
  TimespacePersonObjectStatusType,
  TimespacePersonRelateToOneForCreateInput,
  TimespaceRelateToOneForCreateInput,
} from 'Generated/graphql-hooks';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import { TimespacePersonObject } from 'Models';

export default class TimespacePersonObjectCreateInput implements ITimespaceObjectCreateInput {
  constructor(
    timespaceObject: TimespacePersonObject,
    timespaceId?: string,
    timespacePersonId?: string,
  ) {
    timespaceObject = mapValuesDeep(timespaceObject, (v) => (v === '' ? null : v));
    this.actualValue = timespaceObject.actualValue;
    this.ageMax = timespaceObject.ageMax;
    this.ageMin = timespaceObject.ageMin;
    this.name = timespaceObject.name;
    this.customName = timespaceObject.customName ?? '';
    this.description = timespaceObject.description ?? ''; // TODO: tmp fix for BE
    this.customDescription = timespaceObject.customDescription ?? '';
    this.utilisationMaxRounds = timespaceObject.utilisationMaxRounds;
    this.utilisationMinRounds = timespaceObject.utilisationMinRounds;
    this.priority = timespaceObject.priority;
    this.lifeQuality = timespaceObject.lifeQualityImpactOneTime;
    this.lifeQualityImpactOneTime = timespaceObject.lifeQualityImpactOneTime;
    this.lifeQualityImpactPeriodical = timespaceObject.lifeQualityImpactPeriodical;
    this.lifeQualityImpactMaxCount = timespaceObject.lifeQualityImpactMaxCount;
    this.lifeQualityImpactType = timespaceObject.lifeQualityImpactType && {
      connect: { id: timespaceObject.lifeQualityImpactType.id },
    };
    this.lifeQualityImpactMaxRounds = timespaceObject.lifeQualityImpactMaxRounds;
    this.lifeQualityImpactTypeRounds = timespaceObject.lifeQualityImpactTypeRounds && {
      connect: { id: timespaceObject.lifeQualityImpactTypeRounds.id },
    };
    this.timeRequired = timespaceObject.timeRequired;
    this.assetsImpactOneTime = timespaceObject.assetsImpactOneTime;
    this.assetsImpactPeriodicalPassive = timespaceObject.assetsImpactPeriodicalPassive;
    this.assetsImpactPeriodicalActive = timespaceObject.assetsImpactPeriodicalActive;
    this.assetsImpactMaxRounds = timespaceObject.assetsImpactMaxRounds;
    this.realizationDay = timespaceObject.realizationDay;
    this.initialValue = timespaceObject.initialValue;
    this.utilisationRounds = timespaceObject.utilisationRounds;
    this.procurementRound = timespaceObject.procurementRound;
    this.fullyUtilisedRound = timespaceObject.fullyUtilisedRound;
    this.discardmentRound = timespaceObject.discardmentRound;
    this.status = timespaceObject.status;

    this.assetsImpactType = timespaceObject.assetsImpactType && {
      connect: { id: timespaceObject.assetsImpactType.id },
    };
    this.object = { connect: { id: timespaceObject.object?.id } };
    this.timespace = timespaceId ? { connect: { id: timespaceId } } : undefined;
    this.timespacePersonObjectAttributes =
      timespaceObject.timespacePersonObjectAttributes &&
      timespaceObject.timespacePersonObjectAttributes.length > 0
        ? {
            create: timespaceObject.timespacePersonObjectAttributes.map(({ objectAttribute }) => ({
              objectAttribute: { connect: { id: objectAttribute?.id } },
            })),
          }
        : undefined;
    this.financialObjectOneTime = timespaceObject.financialObjectOneTime && {
      connect: { id: timespaceObject.financialObjectOneTime.id },
    };
    this.financialObjectPeriodical = timespaceObject.financialObjectOneTime && {
      //TODO: switch to periodical
      connect: { id: timespaceObject.financialObjectOneTime.id },
    };
    this.timespacePerson = timespacePersonId
      ? {
          connect: { id: timespacePersonId },
        }
      : undefined;
  }
  actualValue?: InputMaybe<Scalars['Float']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  assetsImpactMaxRounds?: InputMaybe<Scalars['Int']['input']>;
  assetsImpactOneTime?: InputMaybe<Scalars['Float']['input']>;
  assetsImpactPeriodicalActive?: InputMaybe<Scalars['Float']['input']>;
  assetsImpactPeriodicalPassive?: InputMaybe<Scalars['Float']['input']>;
  assetsImpactType?: InputMaybe<ImpactTypeRelateToOneForCreateInput>;
  customDescription?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discardmentRound?: InputMaybe<Scalars['Int']['input']>;
  financialObjectOneTime?: InputMaybe<TimespacePersonFinancialObjectRelateToOneForCreateInput>;
  financialObjectPeriodical?: InputMaybe<TimespacePersonFinancialObjectRelateToOneForCreateInput>;
  fullyUtilisedRound?: InputMaybe<Scalars['Int']['input']>;
  initialValue?: InputMaybe<Scalars['Float']['input']>;
  lifeQuality?: InputMaybe<Scalars['Float']['input']>;
  lifeQualityImpactMaxCount?: InputMaybe<Scalars['Int']['input']>;
  lifeQualityImpactMaxRounds?: InputMaybe<Scalars['Int']['input']>;
  lifeQualityImpactOneTime?: InputMaybe<Scalars['Float']['input']>;
  lifeQualityImpactPeriodical?: InputMaybe<Scalars['Float']['input']>;
  lifeQualityImpactType?: InputMaybe<ImpactTypeRelateToOneForCreateInput>;
  lifeQualityImpactTypeRounds?: InputMaybe<ImpactTypeRelateToOneForCreateInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<ObjectRelateToOneForCreateInput>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  procurementRound?: InputMaybe<Scalars['Int']['input']>;
  realizationDay?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TimespacePersonObjectStatusType>;
  timeRequired?: InputMaybe<Scalars['Int']['input']>;
  timespace?: InputMaybe<TimespaceRelateToOneForCreateInput>;
  timespacePerson?: InputMaybe<TimespacePersonRelateToOneForCreateInput>;
  timespacePersonObjectAttributes?: InputMaybe<TimespacePersonObjectAttributeRelateToManyForCreateInput>;
  utilisationMaxRounds?: InputMaybe<Scalars['Int']['input']>;
  utilisationMinRounds?: InputMaybe<Scalars['Int']['input']>;
  utilisationRounds?: InputMaybe<Scalars['Int']['input']>;
}
