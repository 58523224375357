import { t } from 'i18next';
import { Constants } from 'Config';

export const formatPrice = (str?: string | number | null, abs = true) =>
  Number.isNaN(Number(str))
    ? '-'
    : // ignore numbers between -0.01 and 0.01 - they will get rounded to 0, but the minus sign stays
    Number(str) < 0.01 && Number(str) > -0.01
    ? `0,00 ${Constants.CURRENCY}`
    : `${(abs ? Math.abs : Number)(Number(str)).toLocaleString('sk', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} ${Constants.CURRENCY}`;

export const formatAge = (age?: number | null) => {
  if (age === null || age === undefined) {
    return '-';
  }
  const years = Math.floor(age / 12);
  const months = age % 12;

  return t('global.units.ageInYearsAndMonths', { replace: { months, years } });
};

export const formatDuration = (value?: number | null, period = 'years') => {
  if (value === null || value === undefined) {
    return '-';
  }

  const path = (type: string) =>
    t(`global.units.${period}.${type}`, { replace: { period: value } });

  if (value > 4 || value === 0) {
    return path('plural2');
  } else if (value > 1) {
    return path('plural');
  } else {
    return path('singular');
  }
};

interface FormatPercentOptions {
  abs?: boolean;
  maximumFractionDigits?: number;
  multiply?: boolean;
}

export const formatPercent = (str?: string | number | null, options?: FormatPercentOptions) => {
  const { abs = true, maximumFractionDigits = 4, multiply = true } = options ?? {};

  return Number.isNaN(Number(str))
    ? '-'
    : `${(abs ? Math.abs : Number)(Number(str) * (multiply ? 100 : 1)).toLocaleString('sk', {
        maximumFractionDigits,
        minimumFractionDigits: 2,
      })} %`;
};

export const roundFloat = (value: number) => Math.round(value * 100) / 100;

export function pv(rate: number, nper: number, pmt: number) {
  return (pmt / rate) * (1 - Math.pow(1 + rate, -nper));
}
