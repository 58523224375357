import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useField } from 'formik';
import { t } from 'i18next';
import { inRange } from 'lodash';
import theme from 'Theme';
import { TimespacePersonFinancialObject } from 'Models';
import FinancialObjectTreeView from 'Components/TreeView/FinancialObjectTreeView';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  id: string;
  initialAge?: number | null;
}

export default function TimespacePersonFinancialObjectAddModal({ id, initialAge }: Props) {
  const [{ value }, , { setValue }] = useField<Partial<TimespacePersonFinancialObject>[]>(id);
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Partial<TimespacePersonFinancialObject>[]>(
    [],
  );

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSelectedValues([]);
  };

  const handleSubmit = () => {
    setValue([...value, ...selectedValues]);
    handleClose();
  };
  const parsedValues = [...value, ...selectedValues].map((x) => x.financialObject!);
  return (
    <>
      <Box display="flex" justifyContent="end">
        <Button color="primary" onClick={handleClickOpen} variant="outlined">
          {t('object.financial.add')}
        </Button>
      </Box>

      <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
        <DialogTitle>
          {t('object.financial.add')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FinancialObjectTreeView
            disableDetail
            disabledFunction={(item) =>
              item?.isAbstract ||
              value.some((x) => x.financialObject?.code === item?.code) ||
              (initialAge ?? 0) < (item?.ageMin ?? 0)
            }
            initialAge={initialAge ?? 0}
            onChange={(object, checked) => {
              let newSelectedValues = [...selectedValues];
              if (checked) {
                newSelectedValues.push(new TimespacePersonFinancialObject(object));
              } else {
                newSelectedValues = newSelectedValues.filter(
                  (x) => x.financialObject?.code !== object.code,
                );
              }
              setSelectedValues(newSelectedValues);
            }}
            values={parsedValues || []}
            where={{
              AND: [
                { codeLength: { equals: 4 } },
                {
                  OR: [
                    { code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount } },
                    { code: { startsWith: FinancialObjectCodeEnum.Cash } },
                  ],
                },
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.cancel')}
          </Button>
          <LoadingButton color="secondary" onClick={handleSubmit} variant="contained">
            <span>{t('global.actions.add')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
