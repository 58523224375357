import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function NotFound() {
  const { t } = useTranslation();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <Box component={Paper} p={4}>
        <Box alignItems="center" display="flex" gap={1}>
          <ErrorOutlineIcon />
          <Typography variant="h6">{t('errors.query.couldNotLoad')}</Typography>
        </Box>
        <Box display="flex" justifyContent="end" pt={4}>
          <LoadingButton onClick={refreshPage} type="submit" variant="contained">
            <span>{t('global.try.again')}</span>
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
