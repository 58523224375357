import { FinancialObject, TimespacePersonFinancialObject } from 'Generated/graphql-hooks';

export function createFinancialObjectsTree(
  financialObjects?: FinancialObject[] | null,
  timespacePersonFinancialObjects?: TimespacePersonFinancialObject[] | null,
) {
  return financialObjects
    ?.map((fo) => {
      const items = timespacePersonFinancialObjects?.filter(({ financialObject }) =>
        financialObject?.code?.startsWith(fo.code ?? '-'),
      );

      return {
        ...fo,
        items:
          // NOTE: sort by displayName -> displayName is a local attr
          items?.sort(
            (a, b) =>
              a.objectStatus?.localeCompare(b.objectStatus ?? '') ||
              a.displayName?.localeCompare(b.displayName ?? '') ||
              0,
          ) ?? [],
      };
    })
    .filter(({ items }) => !!items?.length);
}
