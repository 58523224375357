import { GoodsObjectCodeEnum, LivingObjectCodeEnum } from 'Types/Global';

// eslint-disable-next-line no-shadow
export enum Constants {
  TIME_AVAILABLE = 480,
  CURRENCY = ' $', // NOTE: $ is replaced with Iuro, using custom font
  START_YEAR = 2000,
  CENTRAL_INTEREST_PERCENT = 0.02,
  DAYS_IN_MONTH = 30,
  REFINANCE_INTEREST_CHANGE = 0.002,
  MORTGAGE_MAX_AGE = 65,
}

export const IMAGE_STORAGE_URL = 'https://d2as2y7ozvc2q9.cloudfront.net/images/';

export const ABOUT_PROJECT_URL = 'https://www.indicia.sk/aktivity/investland-2-0';

export const visibleDateFormat = 'dd.MM.yyyy';
export const visibleDateTimeFormat = 'dd.MM.yyyy HH:mm:ss';

export const backgroundMutations = ['UpdateTimespacePerson'];
export const logoutMutations = ['LogoutInterpersonalizedUser', 'Logout'];

export const adultAgeRange = {
  from: 18,
  to: 99,
};

export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

export const sellableObjectCodes = [
  LivingObjectCodeEnum.FlatOwnership,
  GoodsObjectCodeEnum.HouseholdEquipment,
  GoodsObjectCodeEnum.FreeTimeEquipment,
  GoodsObjectCodeEnum.Car,
  GoodsObjectCodeEnum.Clothing,
  GoodsObjectCodeEnum.FinalGoods,
  GoodsObjectCodeEnum.Electronics,
];
