import { Tab } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  icon?: string | React.ReactElement;
  iconPosition?: 'top' | 'bottom' | 'start' | 'end';
  label?: string;
  to: string;
  value?: string;
}

export default function LinkTab(props: Props) {
  return <Tab {...props} component={Link} sx={{ minHeight: 'inherit' }} />;
}
