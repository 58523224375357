import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { FormikHelpers } from 'formik';
import Section from 'Components/Section';
import MutualFundTransactionCreateInput from 'Models/MutualFundTransactionCreateInput';
import {
  MutualFundInvestorStatusFragment,
  namedOperations,
  useCreateMutualFundInvestorTransactionMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { Constants } from 'Config';
import { useErrorHandler } from 'Lib/Hooks';
import MutualFundTransactionBuyForm from './BuyForm';
import type { FormValues } from './BuyForm';

interface Props {
  mutualFundInvestorStatus?: MutualFundInvestorStatusFragment | null;
}

export default function MutualFundTransactionBuy({ mutualFundInvestorStatus }: Props) {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [createMutualFundInvestorTransactionMutation] =
    useCreateMutualFundInvestorTransactionMutation();

  const handleSubmit = async (
    { amountRequested, day, investmentRepeat, relatedBankAccount, repeatRounds }: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    try {
      const { data: responseData, errors } = await createMutualFundInvestorTransactionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [namedOperations.Query.MutualFundTransactions],
        variables: {
          amountRequested: Math.round((amountRequested ?? 0) * 100) / 100,
          day: day ?? 1,
          fee:
            (amountRequested ?? 0) *
            (mutualFundInvestorStatus?.mutualFundStatus?.fund?.entryFee ?? 0),
          mutualFundStatusId: Number(mutualFundInvestorStatus?.mutualFundStatus?.id),
          relatedBankAccountId: Number(relatedBankAccount?.id),
          repeatRounds: investmentRepeat ? (repeatRounds ?? 0) + 1 : 1,
          timespacePersonId: Number(timespacePerson.id),
        },
      });
      if (responseData?.createMutualFundInvestorTransaction) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        resetForm();
      } else if (errors?.some((x) => ~x.message.indexOf('MinimalDepositError'))) {
        const error = errors?.find((x) => ~x.message.indexOf('MinimalDepositError'));
        // @ts-expect-error not typed
        const value = error?.extensions?.exception?.thrownValue?.cause?.minimalDeposit ?? 0;
        enqueueSnackbar(
          t('investments.mutualFund.validation.minimalDeposit', {
            replace: {
              currency: Constants.CURRENCY,
              value,
            },
          }),
          { variant: 'error' },
        );
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('investments.mutualFund.transaction.buy.title')}>
        <MutualFundTransactionBuyForm
          initialValues={new MutualFundTransactionCreateInput()}
          onSubmit={handleSubmit}
        />
      </Section>
    </Container>
  );
}
