import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  height?: string;
}

export default function CircularLoader({ height }: Props) {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height={height ?? '91vh'}
      justifyContent="center">
      <CircularProgress />
      <Typography sx={{ marginTop: 2 }}>{t('global.loading')}</Typography>
    </Box>
  );
}
