import { TimespacePersonFinancialObject, TimespacePersonObject } from 'Models';

export const getAgeConflictingObjects = (
  initialAge: number,
  timespacePersonObjects: TimespacePersonObject[],
  timespacePersonFinancialObjects: TimespacePersonFinancialObject[],
) => {
  return timespacePersonObjects
    ?.filter((item) => (initialAge ?? 0) < (item?.ageMin ?? 0))
    .concat(
      timespacePersonFinancialObjects?.filter((item) => (initialAge ?? 0) < (item?.ageMin ?? 0)),
    );
};
