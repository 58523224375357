import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  OrderDirection,
  TimespacePersonObjectStatusType,
  useMeQuery,
  useTimespacePersonObjectsListQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { GoodsObjectCodeEnum } from 'Types/Global';
import { Section } from 'Components';
import { EnhancedDataTable } from 'Components/Generic';
import { propertyAndGoodsColumns } from './utils';

export default function GoodsTable() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { data: userData } = useMeQuery();

  const { data, loading } = useTimespacePersonObjectsListQuery({
    skip: !userData?.me?.id,
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        AND: [
          {
            OR: [
              { object: { code: { startsWith: GoodsObjectCodeEnum.HouseholdEquipment } } },
              { object: { code: { startsWith: GoodsObjectCodeEnum.Car } } },
              { object: { code: { startsWith: GoodsObjectCodeEnum.Clothing } } },
              { object: { code: { startsWith: GoodsObjectCodeEnum.Electronics } } },
              { object: { code: { startsWith: GoodsObjectCodeEnum.FreeTimeEquipment } } },
            ],
          },
          {
            OR: [
              { status: { equals: TimespacePersonObjectStatusType.Active } },
              { status: { equals: TimespacePersonObjectStatusType.Passive } },
            ],
          },
          { timespace: { id: { equals: timespaceId } } },
          { timespacePerson: { id: { equals: timespacePerson.id } } },
        ],
      },
    },
  });

  return (
    <Section title={t('holdings.goods')}>
      <EnhancedDataTable
        hideToolbar
        columns={propertyAndGoodsColumns}
        data={data?.timespacePersonObjects ?? []}
        loading={loading}
        options={
          {
            setRowProps: (row, dataIndex) => {
              return {
                onMouseEnter: () => {
                  console.log(
                    data?.timespacePersonObjects && data.timespacePersonObjects[dataIndex],
                  );
                },
              };
            },
            sort: false,
          } as MUIDataTableOptions
        }
        title={''}
      />
    </Section>
  );
}
