import * as Yup from 'yup';

export default Yup.object({
  amount: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'validations.positiveNumber')
    .required('validations.required'),
  realizationDay: Yup.number().required('validations.required'),
  realizationMonth: Yup.number().when('realizationDay', {
    is: (value: number) => value !== -1,
    then: Yup.number()
      .min(1, 'validations.between1and12')
      .max(12, 'validations.between1and12')
      .test(
        'realizationMonth',
        'validations.realizationMonth',
        function test(realizationMonth = 0) {
          // @ts-expect-error not typed yet
          const { currentYear, realizationYear } = this.options.parent ?? {};
          if (realizationYear > currentYear) {
            return true;
          }

          // @ts-expect-error not typed yet
          return realizationMonth >= this.options.parent.currentYearRound;
        },
      )
      .required('validations.required'),
  }),
  realizationYear: Yup.number().when('realizationDay', {
    is: (value: number) => value !== -1,
    then: Yup.number().required('validations.required'),
  }),
  toFinancialObject: Yup.object().required('validations.required').nullable(),
});
