import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useField } from 'formik';
import { t } from 'i18next';
import { getObjectCodeFilterRecursive } from 'Lib/Helpers/Code';
import { TimespaceObjectAttribute, TimespacePersonObject } from 'Models';
import {
  OrderDirection,
  TimespacePersonObjectStatusType,
  useObjectAttributesLazyQuery,
} from 'Generated/graphql-hooks';
import { ModalCloseButton } from 'Components/Generic';
import ObjectTreeView from 'Components/TreeView/ObjectTreeView';

interface Props {
  id: string;
  initialAge?: number | null;
}

export default function TimespacePersonObjectAddModal({ id, initialAge }: Props) {
  const [{ value }, , { setValue }] = useField<Partial<TimespacePersonObject>[]>(id);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValues, setSelectedValues] = useState<Partial<TimespacePersonObject>[]>([]);
  const [loadAttributes, { loading }] = useObjectAttributesLazyQuery({
    variables: {
      orderBy: { id: OrderDirection.Desc },
      where: {
        ...getObjectCodeFilterRecursive(selectedValues[0]?.object?.code),
        AND: [{ isDefaultAttributeValue: { equals: true } }],
      },
    },
  });

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedValues([]);
  };

  const handleSubmit = () => {
    return loadAttributes()
      .then(({ data }) => {
        setValue([
          ...value,
          ...selectedValues.map((item) => {
            //TODO: handle attributes assign when multiselect
            item.timespacePersonObjectAttributes = data?.objectAttributes?.map(
              (objectAttribute) => new TimespaceObjectAttribute(objectAttribute),
            );
            return item;
          }),
        ]);
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      });
  };
  const parsedValues = selectedValues.map((x) => x.object!);

  return (
    <>
      <Box display="flex" justifyContent="end">
        <Button color="primary" onClick={handleClickOpen} variant="outlined">
          {t('object.add')}
        </Button>
      </Box>

      <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
        <DialogTitle>
          {t('object.add')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <ObjectTreeView
            disableDetail
            disabledFunction={(item) => item?.isAbstract || (initialAge ?? 0) < (item?.ageMin ?? 0)}
            initialAge={initialAge ?? 0}
            onChange={(object, checked) => {
              let newSelectedValues = [...selectedValues];
              if (checked) {
                newSelectedValues.push(
                  new TimespacePersonObject(object, TimespacePersonObjectStatusType.Active),
                );
              } else {
                newSelectedValues = newSelectedValues.filter((x) => x.object?.code !== object.code);
              }
              setSelectedValues(newSelectedValues);
            }}
            values={parsedValues || []}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.cancel')}
          </Button>
          <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
            <span>{t('global.actions.add')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
