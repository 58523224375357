import { groupBy, isEmpty, isNil } from 'lodash';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import {
  RandomEventUpdateInput as IRandomEventUpdateInput,
  InputMaybe,
  RandomEvent,
  RandomEventAcquiredObjectRelateToOneForUpdateInput,
  RandomEventFinancialObjectRelateToManyForUpdateInput,
  RandomEventObjectRelateToManyForUpdateInput,
  Scalars,
  TimespaceRelateToOneForUpdateInput,
} from 'Generated/graphql-hooks';
import { RandomEventFinancialObjectCreateInput } from './RandomEventFinancialObjectCreateInput';
import { RandomEventObjectCreateInput } from './RandomEventObjectCreateInput';

export default class RandomEventUpdateInput implements IRandomEventUpdateInput {
  constructor(randomEvent: RandomEvent, timespaceId?: string) {
    randomEvent = mapValuesDeep(randomEvent, (v) => (v === '' ? null : v)) as RandomEvent;
    this.description = randomEvent.description;
    this.executionMaxCount = randomEvent.executionMaxCount;
    this.executionMonthPeriodical = randomEvent.executionMonthPeriodical;
    this.executionProbability =
      randomEvent.executionProbability && !Number.isNaN(randomEvent.executionProbability)
        ? Number(randomEvent.executionProbability) / 100
        : 1;
    this.executionRoundFrom = randomEvent.executionRoundFrom;
    this.executionRoundTo = randomEvent.executionRoundTo;
    this.executionUserAcceptance = randomEvent.executionUserAcceptance;
    this.executionYearPeriodical = randomEvent.executionYearPeriodical;
    this.name = randomEvent.name;

    this.randomEventAcquiredObject = randomEvent.randomEventAcquiredObject
      ? randomEvent.randomEventAcquiredObject.id
        ? {
            connect: { id: randomEvent.randomEventAcquiredObject.id },
          }
        : {
            create: {
              isAssetsImpactPositive: randomEvent.randomEventAcquiredObject.isAssetsImpactPositive,
              object: {
                connect: { id: randomEvent.randomEventAcquiredObject?.object?.id },
              },
            },
          }
      : undefined;
    const groupedFinancialObjects = groupBy(randomEvent.randomEventFinancialObjects, (x) =>
      isEmpty(x.id) || isNil(x.id) ? 'create' : 'set',
    );

    this.randomEventFinancialObjects = {
      create: groupedFinancialObjects.create?.map(
        (item) => new RandomEventFinancialObjectCreateInput(item),
      ),
      set: groupedFinancialObjects.set?.map(({ id }) => ({ id })) || [],
    };
    const groupedObjects = groupBy(randomEvent.randomEventObjects, (x) =>
      isEmpty(x.id) || isNil(x.id) ? 'create' : 'set',
    );

    this.randomEventObjects = {
      create: groupedObjects.create?.map((item) => new RandomEventObjectCreateInput(item)),
      set: groupedObjects.set?.map(({ id }) => ({ id })) || [],
    };
    this.timespace = { connect: { id: timespaceId } };
  }
  description?: InputMaybe<Scalars['String']['input']>;
  executionCount?: InputMaybe<Scalars['Int']['input']>;
  executionLastRound?: InputMaybe<Scalars['Int']['input']>;
  executionMaxCount?: InputMaybe<Scalars['Int']['input']>;
  executionMonthPeriodical?: InputMaybe<Scalars['Int']['input']>;
  executionProbability?: InputMaybe<Scalars['Float']['input']>;
  executionRoundFrom?: InputMaybe<Scalars['Int']['input']>;
  executionRoundTo?: InputMaybe<Scalars['Int']['input']>;
  executionUserAcceptance?: InputMaybe<Scalars['Boolean']['input']>;
  executionYearPeriodical?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  randomEventAcquiredObject?: InputMaybe<RandomEventAcquiredObjectRelateToOneForUpdateInput>;
  randomEventFinancialObjects?: InputMaybe<RandomEventFinancialObjectRelateToManyForUpdateInput>;
  randomEventObjects?: InputMaybe<RandomEventObjectRelateToManyForUpdateInput>;
  timespace?: InputMaybe<TimespaceRelateToOneForUpdateInput>;
}
