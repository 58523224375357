import React, { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import { ChevronRight, ExpandMore, Visibility } from '@mui/icons-material';
// eslint-disable-next-line import/named
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  FinancialObject,
  Object,
  OrderDirection,
  useObjectsWithChildrenFlagQuery,
} from 'Generated/graphql-hooks';
import { TimespacePersonObject } from 'Models';
import theme from 'Theme';
import { GameContext } from 'Context';
import AgeLimitIconButton from '../Object/AgeLimitIconButton';
import TransitionComponent from './TransitionComponent';
import TreeViewContext from './TreeViewContext';

const StyledTreeItem = ({
  disabled,
  isAbstract,
  ...props
}: TreeItemProps & { isAbstract?: boolean | null }) => {
  return (
    <Box
      component={TreeItem}
      {...props}
      disabled
      sx={{
        [`& .${treeItemClasses.label}`]: {
          [`& .MuiCheckbox-root`]: { opacity: isAbstract ? '0.3 !important' : '1 !important' },
        },
        pointerEvents: disabled ? 'none' : 'all',
        [`& .${treeItemClasses.disabled}`]: { opacity: '1 !important' },
        [`& .${treeItemClasses.iconContainer}`]: {
          opacity: 1,
          pointerEvents: 'all',
          ...(!props.icon ? { width: 0 } : {}),
        },
        [`& .${treeItemClasses.group}`]: {
          borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
          marginLeft: props.icon ? 2 : 0,
          paddingLeft: 2,
        },
      }}
    />
  );
};

export default function ObjectTreeItem({
  availableOnly,
  initialAge,
  object,
}: {
  availableOnly?: boolean;
  initialAge?: number | undefined;
  object: Object | FinancialObject;
}) {
  const {
    disableDetail,
    disabledFunction,
    hideDisabledCheckbox,
    isItemIndeterminent,
    isItemSelected,
    onChange,
    onDetailClick,
    onItemExpanded,
  } = useContext(TreeViewContext);
  const { timespacePerson } = useContext(GameContext) ?? {};
  const [expanded, setExpanded] = useState(false);

  const { data, loading } = useObjectsWithChildrenFlagQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ getObjects }) => onItemExpanded(getObjects!),
    skip: !expanded || !object.isAbstract,
    variables: {
      onlyAvailable: availableOnly,
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      ...(timespacePerson?.id && availableOnly
        ? { timespacePersonId: Number(timespacePerson.id) }
        : {}),
      where: {
        AND: [
          { code: { startsWith: object?.code } },
          { codeLength: { equals: 2 + (object?.codeLength ?? 0) } },
        ],
      },
    },
  });

  const objects = useMemo(
    () =>
      data?.getObjects?.filter(({ hasChildren, isAbstract }) => !isAbstract || hasChildren) ?? [],
    [data?.getObjects],
  );

  //only expand if icon was clicked
  const handleToggle = useCallback((event: SyntheticEvent) => {
    event.persist();
    const iconClicked = (event.target as HTMLElement).closest('.MuiTreeItem-iconContainer');
    if (iconClicked !== null) {
      setExpanded((prevState) => !prevState);
    }
  }, []);

  const disabled = (disabledFunction && disabledFunction(object)) ?? false;

  return (
    <StyledTreeItem
      TransitionComponent={TransitionComponent}
      disabled={disabled}
      icon={
        loading ? (
          <CircularProgress size="10px" />
        ) : object.isAbstract ? (
          expanded ? (
            <ExpandMore />
          ) : (
            <ChevronRight />
          )
        ) : undefined
      }
      isAbstract={object.isAbstract}
      label={
        <Box display="flex" justifyItems="center">
          <FormControlLabel
            control={
              disabled && hideDisabledCheckbox ? (
                <Box height={38} width={15} />
              ) : (
                <Checkbox
                  checked={isItemSelected(object, objects?.length)}
                  disabled={disabled}
                  indeterminate={isItemIndeterminent(object, objects?.length)}
                  size="small"
                />
              )
            }
            label={
              <Typography
                color={disabled && !object?.isAbstract ? theme.palette.grey.A400 : 'black'}>
                {object?.name}
              </Typography>
            }
            onChange={(_, checked) => onChange(object, checked)}
          />
          {initialAge && initialAge < (object?.ageMin ?? 0) ? (
            <AgeLimitIconButton ageMin={object?.ageMin ?? 0} />
          ) : (
            <></>
          )}
          {!disableDetail && !object.isAbstract && (
            <Tooltip placement="right" sx={{ pointerEvents: 'all' }} title="Detail">
              <IconButton onClick={() => onDetailClick(new TimespacePersonObject(object as any))}>
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
      nodeId={object.code!}
      onClick={handleToggle}>
      {expanded
        ? objects?.map((nestedObject) => {
            return (
              <ObjectTreeItem
                key={nestedObject.code}
                availableOnly={availableOnly}
                initialAge={initialAge ?? undefined}
                object={nestedObject}
              />
            );
          })
        : null}
    </StyledTreeItem>
  );
}
