import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { SelectFormik } from 'Components';
import { Constants } from 'Config';
import { GameContext } from 'Context';

interface Date {
  day: number;
  month: number;
  year: number;
}

export interface DatePickerFormikProps {
  allowPastDate?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  id: string;
  maxDate?: Date;
  minDate?: Date;
}

// just a tmp solution, we should find out something better
export function DatePickerFormik({
  allowPastDate,
  disabled,
  fullWidth,
  id,
  maxDate,
  minDate,
  readOnly,
}: DatePickerFormikProps & { readOnly?: boolean }): JSX.Element {
  if (!id) {
    throw new Error('Id must be defined');
  }

  const [{ value }, { error, touched }] = useField(id);
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);

  const yearOptions = Array.from(
    {
      length:
        (maxDate?.year ?? (timespacePerson.timespace?.round ?? 0) + Constants.START_YEAR) -
        (minDate?.year ?? Constants.START_YEAR) +
        1,
    },
    (_, i) => (minDate?.year ?? Constants.START_YEAR) + i,
  ).filter((item) => !maxDate?.year || item <= maxDate.year);

  const monthOptions = (
    minDate && minDate?.year === value?.year && !allowPastDate
      ? Array.from({ length: 12 - minDate.month + 1 }, (_, i) => i + minDate.month)
      : Array.from(Array(12), (_, i) => i + 1)
  ).filter((item) => !maxDate || value?.year !== maxDate.year || item <= maxDate.month);

  return readOnly ? (
    <Typography
      component={Box}
      display="flex"
      justifyContent="flex-end"
      mb={1}
      mt={2}
      textAlign="center">
      <Typography component={Box} pr={1}>
        {`${value.day}.${value.month}.${value.year}`}
      </Typography>
    </Typography>
  ) : (
    <Box display="flex" flexDirection="column" width={fullWidth ? '100%' : undefined}>
      <Box display="flex" width={fullWidth ? '100%' : undefined}>
        <SelectFormik<number>
          fullWidth
          disabled={disabled}
          id={`${id}.day`}
          options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
        />
        <SelectFormik<number>
          fullWidth
          disabled={disabled}
          id={`${id}.month`}
          options={monthOptions}
        />
        <SelectFormik<number>
          fullWidth
          disabled={disabled}
          id={`${id}.year`}
          options={yearOptions}
        />
      </Box>
      {error && touched ? (
        <FormHelperText error variant="outlined">
          <span className="text-red-500 text-xs px-1">{t(error)}</span>
        </FormHelperText>
      ) : null}
    </Box>
  );
}
