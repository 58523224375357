import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import {
  IsRoundRunningDocument,
  MyTimespacePersonDocument,
  RoundRunningStateEnum,
  TimespacePeopleDocument,
  TimespacePerson,
  TimespacePersonHoldingsDocument,
  TimespacePersonRandomEventsDocument,
  useIsTimespaceRoundRunningQuery,
  useUpdateTimespacePersonMutation,
} from 'Generated/graphql-hooks';
import { useApolloContext } from 'Lib/EnhancedApolloProvider';
import { useGameContextData } from 'Lib/Hooks';

export interface GameContextType {
  error?: boolean;
  hasAvailableCarshopObjects: boolean;
  hasAvailableEducationObjects: boolean;
  hasAvailableEshopObjects: boolean;
  hasAvailableLivingObjects: boolean;
  hasAvailableWorkObjects: boolean;
  hasEducationOrWork: boolean;
  isHouseholdSectionAvailable: boolean;
  isOwner?: boolean;
  isRoundRunning?: boolean;
  loading?: boolean;
  timespacePerson: TimespacePerson;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const GameContext = React.createContext<GameContextType>(null!);

interface Props {
  children: JSX.Element | JSX.Element[];
  setLastVisited?: boolean;
}

export function GameContextProvider({ children, setLastVisited = false }: Props) {
  const { timespaceId = '' } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const roundTransitionResult = useRef();
  const { setMutationsBlocked } = useApolloContext();
  const [updateTimespacePerson] = useUpdateTimespacePersonMutation();
  const client = useApolloClient();
  const {
    error,
    hasAvailableCarshopObjects,
    hasAvailableEducationObjects,
    hasAvailableEshopObjects,
    hasAvailableLivingObjects,
    hasAvailableWorkObjects,
    hasEducationOrWork,
    isHouseholdSectionAvailable,
    loading,
    myTimespacePerson,
  } = useGameContextData({
    timespaceId,
  });
  const {
    data: isRoundRunningData,
    loading: isRoundRunningLoading,
    previousData,
    subscribeToMore,
  } = useIsTimespaceRoundRunningQuery({
    skip: !timespaceId,
    variables: {
      where: {
        id: timespaceId,
      },
    },
  });

  useEffect(() => {
    subscribeToMore({
      document: IsRoundRunningDocument,
      onError: (subscriptionError) => {
        Bugsnag.notify(subscriptionError);
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        // @ts-expect-error wrong type
        roundTransitionResult.current = subscriptionData.data.isRoundRunning.state;

        return Object.assign({}, prev, {
          timespace: {
            ...(prev.timespace ?? {}),
            // @ts-expect-error wrong type
            isRoundRunning: subscriptionData.data.isRoundRunning.isRoundRunning,
          },
        });
      },
      variables: { timespaceId: parseInt(timespaceId) },
    });
  }, [timespaceId]);

  useEffect(() => {
    // set last visit
    if (setLastVisited && myTimespacePerson?.id) {
      try {
        updateTimespacePerson({
          ignoreResults: true,
          variables: {
            data: { lastVisitedDate: new Date() },
            where: { id: myTimespacePerson.id },
          },
        });
      } catch {
        // ignore error
      }
    }
  }, [myTimespacePerson?.id]);

  const isRoundRunning = !!isRoundRunningData?.timespace?.isRoundRunning;
  const isOwner = myTimespacePerson?.timespace?.owner?.id === myTimespacePerson?.user?.id;

  useEffect(() => {
    // refetch neccessary data
    const refetchData = async () => {
      await Promise.all([
        client.query({
          query: MyTimespacePersonDocument,
          variables: {
            timespaceId: Number(timespaceId),
          },
        }),
        client.query({
          query: TimespacePersonHoldingsDocument,
          variables: {
            timespacePersonId: Number(myTimespacePerson?.id),
          },
        }),
        // NOTE: working only with refetchQueries
        client.refetchQueries({
          include: [TimespacePersonRandomEventsDocument, TimespacePeopleDocument],
        }),
      ]);
    };

    // on round running finished - refresh data and show status message
    if (previousData?.timespace?.isRoundRunning && !isRoundRunning) {
      refetchData().finally(() => {
        setMutationsBlocked(isRoundRunning);
        if (isOwner && roundTransitionResult.current === RoundRunningStateEnum.Success) {
          enqueueSnackbar(t('game.management.next_round_success'), {
            persist: true,
            variant: 'success',
          });
        } else if (isOwner && roundTransitionResult.current === RoundRunningStateEnum.Error) {
          enqueueSnackbar(t('game.management.next_round_error'), {
            persist: true,
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t('game.management.next_round_finish'), {
            persist: true,
            variant: 'info',
          });
        }
        roundTransitionResult.current = undefined;
      });
    } else {
      setMutationsBlocked(isRoundRunning);
    }
  }, [isRoundRunning]);

  const memoValue = useMemo(
    () => ({
      error,
      hasAvailableCarshopObjects,
      hasAvailableEducationObjects,
      hasAvailableEshopObjects,
      hasAvailableLivingObjects,
      hasAvailableWorkObjects,
      hasEducationOrWork,
      isHouseholdSectionAvailable,
      isOwner,
      isRoundRunning,
      loading,
      timespacePerson: myTimespacePerson,
    }),
    [myTimespacePerson, isOwner, isRoundRunningLoading, loading, isRoundRunning, timespaceId],
  );

  return (
    // @ts-expect-error if we don't have a timespacePerson, we show an error and don't reach this code
    <GameContext.Provider value={memoValue}>
      {isRoundRunning ? (
        <Alert severity="warning" sx={{ position: 'fixed', right: 10, top: 70, zIndex: 2 }}>
          {t('timespace.roundRunning.warning')}
        </Alert>
      ) : null}
      {children}
    </GameContext.Provider>
  );
}
