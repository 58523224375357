/* eslint-disable sort-keys-fix/sort-keys-fix */
import { common } from '@mui/material/colors';

export default {
  background: {
    default: '#f1f2f2',
  },
  info: {
    contrastText: common.white,
    main: '#8DDEE4',
  },
  primary: {
    contrastText: common.white,
    main: '#5f8c85',
    dark: '#3F5C57',
  },
  secondary: {
    contrastText: '#0e2b44',
    main: '#E5B68A',
    light: '#fcc09c',
  },
  error: {
    contrastText: common.white,
    main: '#E58A9E',
  },
  success: {
    contrastText: common.white,
    main: '#8CE1AF',
  },
  warning: {
    contrastText: common.white,
    main: '#E5B68A',
  },
  green: '#34B76A',
  red: '#D94848',
};
