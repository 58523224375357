import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularLoader } from 'Components';
import { GameContext } from 'Context';
import { GameContextType } from 'Context/Game';
import { ScreenPaths } from 'Config';

interface Props {
  children: JSX.Element;
  hideScreen: (contextValues: GameContextType) => boolean | boolean;
}

export default function ConditionalGameScreenWrapper({ children, hideScreen }: Props) {
  const gameContext = useContext(GameContext);

  if (gameContext && !gameContext.loading && !gameContext.error && hideScreen(gameContext)) {
    return <Navigate replace to={ScreenPaths.NotFound} />;
  }

  if (gameContext.loading) {
    return <CircularLoader />;
  }

  return children;
}
