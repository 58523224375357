import React, { useCallback, useContext } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  BasicAccountSelector,
  CheckboxFormik,
  LabeledText,
  Section,
  SelectFormik,
  TermAccountTable,
} from 'Components';
import {
  FinancialObject,
  TimespacePersonFinancialObjectFragment,
  namedOperations,
  useCreateFixedTermDepositAccountMutation,
} from 'Generated/graphql-hooks';
import { Constants, TimespacePaths } from 'Config';
import { GameContext } from 'Context';
import { TermAccountCreateSchema } from 'Config/Validations';

interface FormValues {
  automaticRenewal: boolean;
  financialObject: FinancialObject | null;
  realizationDay: number;
  relatedFinancialObject: TimespacePersonFinancialObjectFragment | null;
}

export default function TermAccountCreate() {
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { timespacePerson } = useContext(GameContext);
  const [createTermAccount] = useCreateFixedTermDepositAccountMutation();

  const handleFormikSubmit = useCallback(
    async ({
      automaticRenewal,
      financialObject,
      realizationDay,
      relatedFinancialObject,
    }: FormValues) => {
      try {
        const { data, errors } = await createTermAccount({
          refetchQueries: [
            namedOperations.Query.BankCategories,
            namedOperations.Query.TimespacePersonFinancialObjects,
          ],
          variables: {
            automaticRenewal,
            financialObjectId: Number(financialObject!.id),
            realizationDay,
            relatedFinancialObjectId: Number(relatedFinancialObject!.id),
          },
        });

        if (data?.createFixedTermDepositAccount) {
          enqueueSnackbar(t('term.sign.success'), { variant: 'success' });
        } else if (errors?.some((x) => ~x.message.indexOf('NotEnoughMoneyError'))) {
          enqueueSnackbar(
            t('term.validation.notEnoughFunds', {
              replace: {
                accountName: relatedFinancialObject?.name,
              },
            }),
            { variant: 'error' },
          );
        } else {
          throw new Error();
        }
      } catch {
        enqueueSnackbar(t('term.sign.failed'), { variant: 'error' });
      }
    },
    [timespaceId, timespacePerson],
  );

  const handleClose = useCallback(() => {
    navigate(`${TimespacePaths.Bank}/${timespaceId}`);
  }, [navigate, timespaceId]);

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('term.account.create')}>
        <Formik<FormValues>
          validateOnMount
          initialValues={{
            automaticRenewal: false,
            financialObject: null,
            realizationDay: 1,
            relatedFinancialObject: null,
          }}
          onSubmit={handleFormikSubmit}
          validationSchema={TermAccountCreateSchema}>
          {({ handleSubmit, isSubmitting, isValid, setFieldValue, submitForm, values }) => {
            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <Box mb={4}>
                  <TermAccountTable
                    onRowSelected={(object) => setFieldValue('financialObject', object)}
                    value={values.financialObject}
                  />
                </Box>
                <LabeledText
                  label={t('object.attr.relatedFinancialObject')}
                  value={<BasicAccountSelector id="relatedFinancialObject" />}
                />
                <LabeledText
                  label={t('term.realization.day')}
                  value={
                    <SelectFormik
                      fullWidth
                      id="realizationDay"
                      options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
                    />
                  }
                />
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <FormControlLabel
                    control={<CheckboxFormik id="automaticRenewal" />}
                    label={t('object.attr.automaticRenewal') as string}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button onClick={handleClose} sx={{ marginRight: 2 }} variant="outlined">
                    {t('global.actions.cancel')}
                  </Button>
                  <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="contained">
                    <span>{t('term.actions.sign')}</span>
                  </LoadingButton>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Section>
    </Container>
  );
}
