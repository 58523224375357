import * as Yup from 'yup';
import { ExpirationTypeEnum } from 'Types/Global';

export default Yup.object({
  amount: Yup.number().min(0, 'validations.positiveNumber').required('validations.required'),
  beneficiaryAccount: Yup.object().required('validations.required').nullable(),
  constantSymbol: Yup.string().min(4, 'validations.exactly4').max(4, 'validations.exactly4'),
  expirationDate: Yup.object()
    .when('expirationType', {
      is: (expirationType: keyof typeof ExpirationTypeEnum) =>
        expirationType === ExpirationTypeEnum.DATE,
      then: Yup.object()
        .test(
          'expirationDate',
          'bank.standingOrder.expirationDate.error',
          function test(expirationDate = {}) {
            // @ts-expect-error not typed yet
            const { expirationType, initialDate } = this.options.parent ?? {};
            if (expirationType !== ExpirationTypeEnum.DATE || !expirationDate) {
              return true;
            }

            return (
              expirationDate.year > initialDate.year ||
              (expirationDate.year === initialDate.year &&
                expirationDate.month > initialDate.month) ||
              (expirationDate.year === initialDate.year &&
                expirationDate.month === initialDate.month &&
                expirationDate.day >= initialDate.day)
            );
          },
        )
        .nullable()
        .required('validations.required'),
    })
    .nullable(),
  expirationOnExecutionCount: Yup.number().when('expirationType', {
    is: (expirationType: keyof typeof ExpirationTypeEnum) =>
      expirationType === ExpirationTypeEnum.COUNT,
    then: Yup.number().required('validations.required'),
  }),
  expirationType: Yup.string().required('validations.required'),
  initialDate: Yup.object().required('validations.required'),
  name: Yup.string().max(30, 'validations.between1and30').required('validations.required'),
  payersAccount: Yup.object().required('validations.required').nullable(),
  period: Yup.number(),
  specificSymbol: Yup.string().min(10, 'validations.exactly10').max(10, 'validations.exactly10'),
  textMessage: Yup.string().max(50, 'validations.max50'),
  variableSymbol: Yup.string().min(10, 'validations.exactly10').max(10, 'validations.exactly10'),
});
