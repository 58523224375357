import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from 'Lib/Hooks/useAuth';
import { CircularLoader, RoutesConfig, SnackbarCloseButton } from 'Components';
import EnhancedApolloProvider from 'Lib/EnhancedApolloProvider';
import './Assets/Fonts/iuro-pt-sans/css/iuro-pt-sans.css';
import './Assets/Fonts/iuro-trebuchet/css/iuro-trebuchet.css';

function App() {
  return (
    <SnackbarProvider
      // preventDuplicate
      action={(id) => <SnackbarCloseButton id={id} />}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={3000}
      maxSnack={3}>
      <EnhancedApolloProvider>
        <BrowserRouter>
          <AuthProvider>
            <Suspense fallback={<CircularLoader />}>
              <RoutesConfig />
            </Suspense>
          </AuthProvider>
        </BrowserRouter>
      </EnhancedApolloProvider>
    </SnackbarProvider>
  );
}

export default App;
