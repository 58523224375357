import { useContext, useEffect } from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { DatePickerFormik, LabeledText, Section } from 'Components';
import { GameContext } from 'Context';
import PersonalBudgetSchema from 'Config/Validations/PersonalBudgetSchema';
import { PersonalBudgetTable } from 'Components/PersonalBudget';
import { useGetExportDataLazyQuery, usePersonalBudgetLazyQuery } from 'Generated/graphql-hooks';
import { Constants } from 'Config';
import { formatPrice } from 'Lib/Helpers/Number';
import { Date } from 'Types/Global';
import { getTimespaceYearFromRound } from 'Lib/Helpers/Date';
import { createCSV, downloadCSV } from 'Lib/Helpers/Csv';

interface FilterFormValues {
  finalDate: Date;
  initialDate: Date;
}

export default function PersonalBudget() {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadBudgetData, { data, loading }] = usePersonalBudgetLazyQuery();
  const [loadExportData, { loading: exportLoading }] = useGetExportDataLazyQuery();

  useEffect(() => {
    loadBudgetData({
      variables: {
        fromRound: Math.max((timespacePerson.timespace?.round ?? 0) - 12, 0),
        timespacePersonId: Number(timespacePerson.id),
        toRound: timespacePerson.timespace?.round ?? Constants.START_YEAR,
      },
    });
  }, []);

  const handleDownloadCSV = async (values: FilterFormValues) => {
    const { finalDate, initialDate } = values;

    await loadExportData({
      onCompleted: ({ getExportData }) => {
        downloadCSV(createCSV(getExportData));
        enqueueSnackbar(t('budget.export.success'), { variant: 'success' });
      },
      variables: {
        fromRound: (initialDate.year - Constants.START_YEAR) * 12 + initialDate.month,
        timespacePersonId: Number(timespacePerson.id),
        toRound: (finalDate.year - Constants.START_YEAR) * 12 + finalDate.month,
      },
    });
  };

  const handleFormikSubmit = ({ finalDate, initialDate }: FilterFormValues) => {
    loadBudgetData({
      variables: {
        fromRound: (initialDate.year - Constants.START_YEAR) * 12 + initialDate.month,
        timespacePersonId: Number(timespacePerson.id),
        toRound: (finalDate.year - Constants.START_YEAR) * 12 + finalDate.month,
      },
    });
  };

  const currentYearRound = (timespacePerson?.timespace?.round ?? 1) % 12 || 12;
  const currentYear = getTimespaceYearFromRound(timespacePerson?.timespace?.round);

  const { personalBudget } = data ?? {};

  const incomes =
    (personalBudget?.periodicalAffirmativeTransactions?.sum ?? 0) +
    (personalBudget?.nonPeriodicalAffirmativeTransactions?.sum ?? 0);

  const expenses = Math.abs(
    (personalBudget?.periodicalNegativeTransactions?.sum ?? 0) +
      (personalBudget?.nonPeriodicalNegativeTransactions?.sum ?? 0),
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Section title={t('game.menu.section.budget')}>
        <Formik<FilterFormValues>
          enableReinitialize
          initialValues={{
            finalDate: {
              day: 1,
              month: currentYearRound,
              year: currentYear,
            },
            initialDate: {
              day: 1,
              month: currentYear === Constants.START_YEAR ? 1 : currentYearRound,
              year: Math.max(Constants.START_YEAR, currentYear - 1),
            },
          }}
          onSubmit={handleFormikSubmit}
          validationSchema={PersonalBudgetSchema}>
          {({ handleSubmit, values }) => {
            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <Typography gutterBottom>{t('budget.overview')}</Typography>
                <Box alignItems="flex-start" display="flex" flexWrap="wrap">
                  <Box pr={2}>
                    <LabeledText
                      fixedLabel
                      label={t('global.from')}
                      value={
                        <Box maxWidth={214} minWidth={214} pl={1}>
                          <DatePickerFormik
                            allowPastDate
                            fullWidth
                            id="initialDate"
                            maxDate={{
                              day: 1,
                              month: currentYearRound,
                              year: currentYear,
                            }}
                            minDate={{
                              day: 1,
                              month: currentYear === Constants.START_YEAR ? 1 : currentYearRound,
                              year: Number(Constants.START_YEAR),
                            }}
                          />
                        </Box>
                      }
                    />
                  </Box>
                  <Box pr={2}>
                    <LabeledText
                      fixedLabel
                      label={t('global.to')}
                      value={
                        <Box maxWidth={214} minWidth={214} pl={1}>
                          <DatePickerFormik
                            allowPastDate
                            fullWidth
                            id="finalDate"
                            maxDate={{
                              day: 1,
                              month: currentYearRound,
                              year: currentYear,
                            }}
                            minDate={values.initialDate}
                          />
                        </Box>
                      }
                    />
                  </Box>
                  <Box display="flex" pt={1}>
                    <LoadingButton
                      loading={loading}
                      size="medium"
                      type="submit"
                      variant="contained">
                      <span>{t('budget.calculate')}</span>
                    </LoadingButton>
                  </Box>
                  <Box display="flex" pl={1} pt={1}>
                    <LoadingButton
                      loading={exportLoading}
                      onClick={() => handleDownloadCSV(values)}
                      size="medium"
                      variant="contained">
                      <span>{t('budget.export.download')}</span>
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            );
          }}
        </Formik>
        <Box my={3}>
          <Divider />
        </Box>

        <Box
          alignItems="center"
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          justifyContent="center"
          textAlign={{ lg: 'left', xs: 'center' }}>
          <Box display="flex" flexDirection="column">
            <Typography color="green" component="span" variant="h4">
              {formatPrice(incomes, false)}
            </Typography>
            <Typography component="span" variant="body2">
              {t('budget.total.income')}
            </Typography>
          </Box>
          <Box pb={2} px={3}>
            <Typography component="span" variant="h4">
              -
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography color="red" component="span" variant="h4">
              {formatPrice(expenses, false)}
            </Typography>
            <Typography component="span" variant="body2">
              {t('budget.total.expenses')}
            </Typography>
          </Box>
          <Box pb={2} px={3}>
            <Typography component="span" variant="h4">
              =
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography component="span" fontWeight="bold" variant="h4">
              {formatPrice(incomes - expenses, false)}
            </Typography>
            <Typography component="span" variant="body2">
              {t('budget.account.difference')}
            </Typography>
          </Box>
        </Box>

        <Box my={3}>
          <Divider />
        </Box>

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <PersonalBudgetTable
              data={personalBudget?.periodicalAffirmativeTransactions?.items ?? []}
              loading={loading}
              name={t('budget.periodic.income')}
              sum={personalBudget?.periodicalAffirmativeTransactions?.sum ?? 0}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PersonalBudgetTable
              data={personalBudget?.periodicalNegativeTransactions?.items ?? []}
              loading={loading}
              name={t('budget.periodic.expenses')}
              sum={personalBudget?.periodicalNegativeTransactions?.sum ?? 0}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PersonalBudgetTable
              data={personalBudget?.nonPeriodicalAffirmativeTransactions?.items ?? []}
              loading={loading}
              name={t('budget.non.periodic.income')}
              sum={personalBudget?.nonPeriodicalAffirmativeTransactions?.sum ?? 0}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PersonalBudgetTable
              data={personalBudget?.nonPeriodicalNegativeTransactions?.items ?? []}
              loading={loading}
              name={t('budget.non.periodic.expenses')}
              sum={personalBudget?.nonPeriodicalNegativeTransactions?.sum ?? 0}
            />
          </Grid>
        </Grid>
      </Section>
    </Container>
  );
}
