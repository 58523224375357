import { use } from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import locales from 'Locales';

export const defaultLanguage = 'sk';

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
// .use(LanguageDetector)
// pass the i18n instance to react-i18next.
// noinspection JSIgnoredPromiseFromCall
use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development',
    detection: {
      caches: ['localStorage'],
      order: ['localStorage', 'navigator'],
    },
    fallbackLng: defaultLanguage,
    keySeparator: '.',
    load: 'languageOnly',
    resources: {
      en: { translation: locales.en },
      sk: { translation: locales.sk },
    },
    supportedLngs: ['sk', 'en'],
  });
