import { useLayoutEffect, useState } from 'react';

export const useIsOverflow = (
  ref: React.MutableRefObject<HTMLElement | null>,
  callback?: (isOverflow: boolean) => void,
) => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = (current?.scrollHeight ?? 0) > (current?.clientHeight ?? 0);

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
