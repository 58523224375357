import React from 'react';
import { Box, Button } from '@mui/material';
import { useField } from 'formik';
import { t } from 'i18next';
import { RandomEventInput } from 'Models/RandomEventInput';
import TimespaceRandomEventModal from 'Components/Timespace/RandomEvent/TimespaceRandomEventModal';
import { useModal } from 'Lib/Hooks';

interface Props {
  id: string;
}

export default function TimespaceRandomEventAdd({ id }: Props) {
  const [{ value }, , { setValue }] = useField<RandomEventInput[]>(id);
  const { handleClose, handleOpen, isOpen } = useModal<RandomEventInput>();

  const handleOnSubmit = (newValue: RandomEventInput) => {
    setValue([...value, newValue]);
    handleClose();
  };
  return (
    <>
      <Box display="flex" justifyContent="end">
        <Button color="primary" onClick={() => handleOpen()} size="small" variant="outlined">
          {t('randomEvent.create')}
        </Button>
      </Box>

      <TimespaceRandomEventModal onClose={handleClose} onSubmit={handleOnSubmit} open={isOpen} />
    </>
  );
}
