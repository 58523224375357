import { CheckBox, CheckBoxOutlineBlank, Delete } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RandomEventInput } from 'Models/RandomEventInput';
import TimespaceRandomEventDetail from 'Components/Timespace/RandomEvent/TimespaceRandomEventDetail';
import TimespaceRandomEventAdd from 'Components/Timespace/RandomEvent/TimespaceRandomEventAdd';
import { EnhancedDataTable } from 'Components/Generic';

export default function TimespaceRandomEventTable({ id }: { id: string }) {
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField<RandomEventInput[]>(id);

  const onDelete = (index: number) => {
    const newValues = [...value];
    newValues.splice(index, 1);
    setValue(newValues);
  };
  const handleSubmit = (newObject: RandomEventInput, index: number) => {
    const newValues = [...value];
    newValues[index] = newObject;
    setValue(newValues);
  };
  const columns: MUIDataTableColumnDef[] = [
    { name: 'id', options: { display: 'excluded' } },
    {
      label: t('randomEvent.attr.name'),
      name: 'name',
    },
    {
      label: t('randomEvent.attr.executionRoundFrom'),
      name: 'executionRoundFrom',
    },
    {
      label: t('randomEvent.attr.executionRoundTo'),
      name: 'executionRoundTo',
    },
    {
      label: t('randomEvent.attr.executionMonthPeriodical'),
      name: 'executionMonthPeriodical',
    },
    {
      label: t('randomEvent.attr.executionYearPeriodical'),
      name: 'executionYearPeriodical',
    },
    {
      label: '%',
      name: 'executionProbability',
    },
    {
      label: t('randomEvent.attr.executionCount'),
      name: 'executionCount',
    },
    {
      label: 'Max',
      name: 'executionMaxCount',
    },
    {
      label: `${t('global.actions.yes')}/${t('global.actions.no')}`,
      name: 'executionUserAcceptance',
      options: {
        customBodyRender: (checkboxValue) =>
          checkboxValue ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank color="primary" />,
      },
    },
    {
      label: t('global.table.actions'),
      name: 'actions',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          return (
            <Box display="flex">
              <TimespaceRandomEventDetail
                data={value[rowIndex]}
                onSubmit={(item) => handleSubmit(item, rowIndex)}
              />
              <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                <IconButton aria-label="delete" onClick={() => onDelete(rowIndex)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  return (
    <EnhancedDataTable
      columns={columns}
      data={value || []}
      options={
        {
          customToolbar: () => <TimespaceRandomEventAdd id={id} />,
          pagination: false,
          setTableProps: () => ({ style: { minHeight: '165px' } }),
          sort: false,
        } as MUIDataTableOptions
      }
      title={t('timespace.randomEvents')}
    />
  );
}
