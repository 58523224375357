import { Maybe, RandomEventObjectAttribute } from 'Generated/graphql-hooks';

export class RandomEventObjectAttributeInput implements DeepNullable<RandomEventObjectAttribute> {
  __typename?: DeepNullable<'RandomEventObjectAttribute' | undefined> | null = null;
  absFrom?: DeepNullable<Maybe<number> | undefined> = null;
  absTo?: DeepNullable<Maybe<number> | undefined> = null;
  coeffFrom?: DeepNullable<Maybe<number> | undefined> = null;
  coeffTo?: DeepNullable<Maybe<number> | undefined> = null;
  id: string | null | undefined;
}
