import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { useModal, usePagination } from 'Lib/Hooks';
import {
  MutualFundInvestorStatusFragment,
  MutualFundTransaction,
  OrderDirection,
  useMutualFundTransactionsQuery,
} from 'Generated/graphql-hooks';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { EnhancedDataTable, Error, SkeletonLoader } from 'Components';
import MutualFundTransactionDeleteModal from './DeleteModal';

interface Props {
  mutualFundInvestorStatus?: MutualFundInvestorStatusFragment | null;
}

export default function MutualFundTransactionPendingTable({ mutualFundInvestorStatus }: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { skip, take, ...pagination } = usePagination();
  const { handleClose, handleOpen, isOpen, selected } = useModal<MutualFundTransaction>();
  const { data, error, loading } = useMutualFundTransactionsQuery({
    variables: {
      orderBy: [
        { round: OrderDirection.Desc },
        { day: OrderDirection.Desc },
        { id: OrderDirection.Desc },
      ],
      skip,
      take,
      where: {
        AND: [
          { person: { id: { equals: timespacePerson?.id } } },
          {
            repeatRounds: {
              gt: 0,
            },
          },
          {
            mutualFundStatus: {
              fund: {
                code: {
                  equals: mutualFundInvestorStatus?.mutualFundStatus?.fund?.code,
                },
              },
            },
          },
        ],
      },
    },
  });

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'round',
        options: { display: 'excluded', sort: false },
      },
      {
        label: t('bank.transaction.attr.day'),
        name: 'day',
        options: {
          customBodyRender: (day: string, meta: MUIDataTableMeta) => (
            <Typography variant="body2">{getHumanDateFromRound(meta.rowData[0], day)}</Typography>
          ),
          sort: false,
        },
      },
      {
        label: t('investments.buy/sell'),
        name: 'amountRequested',
        options: {
          customBodyRender: (value: number | null) => (
            <Typography variant="body2">
              {value
                ? t('investments.table.transaction.buy')
                : t('investments.table.transaction.sell')}
            </Typography>
          ),
          sort: false,
        },
      },
      {
        label: t('investments.requested.amount'),
        name: 'amountRequested',
        options: {
          customBodyRender: (value: string) => {
            return (
              value && (
                <Typography noWrap variant="body2">
                  {formatPrice(value, false)}
                </Typography>
              )
            );
          },
          setCellProps: () => ({ align: 'right' }),
          sort: false,
        },
      },
      {
        label: t('investments.invest.volume'),
        name: 'volume',
        options: {
          customBodyRender: (value: number) => Math.round(value * 100) / 100,
          setCellProps: () => ({ align: 'right' }),
          sort: false,
        },
      },
      {
        label: t('investments.repeat.rounds'),
        name: 'repeatRounds',
        options: {
          setCellProps: () => ({ align: 'right' }),
          sort: false,
        },
      },
      {
        label: t('bank.account.current'),
        name: 'relatedObjectName',
        options: {
          customBodyRender: (name: string) => name ?? '-',
          sort: false,
        },
      },
      {
        label: t('investments.actions'),
        name: 'actions',
        options: {
          customBodyRender: (_, { rowIndex }) => {
            const transaction = data?.mutualFundTransactions?.[rowIndex];

            return (
              <Box display="flex">
                <Tooltip
                  placement="right"
                  sx={{ pointerEvents: 'all' }}
                  title={t('global.actions.edit') as string}>
                  <IconButton component={Link} to={`${transaction?.id}`}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="right"
                  sx={{ pointerEvents: 'all' }}
                  title={t('global.actions.delete') as string}>
                  <IconButton
                    onClick={() => {
                      handleOpen(transaction);
                    }}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
          customHeadRender: () => null,
          sort: false,
        },
      },
    ],
    [data, handleOpen, t],
  );

  const prepareData = useCallback(
    () =>
      data?.mutualFundTransactions?.map((item) => ({
        ...item,
        relatedObjectName: item.relatedBankAccount?.displayName,
      })) ?? [],
    [data?.mutualFundTransactions],
  );

  if (error) {
    return <Error />;
  }

  return (
    <>
      <EnhancedDataTable
        columns={columns}
        data={prepareData()}
        options={
          {
            ...pagination,
            count: data?.count,
            setRowProps: (row, dataIndex) => {
              return {
                onMouseEnter: () => {
                  console.log(
                    data?.mutualFundTransactions && data.mutualFundTransactions[dataIndex],
                  );
                },
              };
            },
            textLabels: {
              body: {
                noMatch: loading ? <SkeletonLoader /> : t('bank.transaction.placeholder'),
              },
            },
          } as MUIDataTableOptions
        }
        title={<Typography variant="h6">{t('investments.instructionsTitle')}</Typography>}
      />

      {selected ? (
        <MutualFundTransactionDeleteModal
          isOpen={isOpen}
          onClose={handleClose}
          transaction={selected}
        />
      ) : null}
    </>
  );
}
