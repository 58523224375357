import { TypographyOptions } from '@mui/material/styles/createTypography';

export default {
  body3: {
    color: '#888',
    fontSize: '0.8rem',
  },
  fontFamily: ['iuro-trebuchet', 'Trebuchet MS', 'iuro-pt-sans', 'PT Sans', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'iuro-pt-sans, PT Sans',
  },
  h2: {
    fontFamily: 'iuro-pt-sans, PT Sans',
  },
  h3: {
    fontFamily: 'iuro-pt-sans, PT Sans',
  },
  h4: {
    fontFamily: 'iuro-pt-sans, PT Sans',
  },
  h5: {
    fontFamily: 'iuro-pt-sans, PT Sans',
  },
  h6: {
    fontFamily: 'iuro-pt-sans, PT Sans',
  },
} as TypographyOptions;
