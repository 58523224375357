import {
  Maybe,
  OrderDirection,
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
} from 'Generated/graphql-hooks';
import { TimespacePersonObject } from 'Models';

//TODO: review fallback values "1"
export function getUpdatedTimespacePersonObject(
  timespacePersonObject: TimespacePersonObject,
): TimespacePersonObject {
  const newAttributes = timespacePersonObject.timespacePersonObjectAttributes?.reduce(
    (acc, { objectAttribute }): any => {
      const newAcc = { ...acc };

      newAcc.lifeQualityImpactMaxRounds *= objectAttribute?.coeffLifeQualityImpactMaxRounds || 1;
      newAcc.assetsImpactOneTime *= objectAttribute?.coeffAssetsImpactOneTime || 1;
      newAcc.assetsImpactPeriodicalActive *=
        objectAttribute?.coeffAssetsImpactPeriodicalActive || 1;
      newAcc.assetsImpactPeriodicalPassive *=
        objectAttribute?.coeffAssetsImpactPeriodicalPassive || 1;
      newAcc.lifeQualityImpactMaxRounds *= objectAttribute?.coeffLifeQualityImpactMaxRounds || 1;
      newAcc.lifeQualityImpactOneTime *= objectAttribute?.coeffLifeQualityImpactOneTime || 1;
      newAcc.lifeQualityImpactPeriodical *= objectAttribute?.coeffLifeQualityImpactPeriodical || 1;
      return Object.fromEntries(Object.entries(newAcc).map(([key, value]) => [key, value]));
    },
    {
      assetsImpactMaxRounds: timespacePersonObject.object?.assetsImpactMaxRounds || 1,
      assetsImpactOneTime: timespacePersonObject.object?.assetsImpactOneTime || 0,
      assetsImpactPeriodicalActive: timespacePersonObject.object?.assetsImpactPeriodicalActive || 0,
      assetsImpactPeriodicalPassive:
        timespacePersonObject.object?.assetsImpactPeriodicalPassive || 0,
      lifeQualityImpactMaxRounds: timespacePersonObject.object?.lifeQualityImpactMaxRounds || 1,
      lifeQualityImpactOneTime: timespacePersonObject.object?.lifeQualityImpactOneTime || 0,
      lifeQualityImpactPeriodical: timespacePersonObject.object?.lifeQualityImpactPeriodical || 0,
    },
  );
  return {
    ...timespacePersonObject,
    actualValue:
      (newAttributes?.assetsImpactOneTime || timespacePersonObject.actualValue) &&
      Math.abs(newAttributes?.assetsImpactOneTime || timespacePersonObject.actualValue || 0),
    assetsImpactMaxRounds: Number(newAttributes?.assetsImpactMaxRounds),
    assetsImpactOneTime: newAttributes?.assetsImpactOneTime,
    assetsImpactPeriodicalActive: newAttributes?.assetsImpactPeriodicalActive,
    assetsImpactPeriodicalPassive: newAttributes?.assetsImpactPeriodicalPassive,
    initialValue:
      (newAttributes?.assetsImpactOneTime || timespacePersonObject.initialValue) &&
      Math.abs(newAttributes?.assetsImpactOneTime || timespacePersonObject.initialValue || 0),
    lifeQualityImpactMaxRounds: Number(newAttributes?.lifeQualityImpactMaxRounds),
    lifeQualityImpactOneTime: Number(newAttributes?.lifeQualityImpactOneTime),
    lifeQualityImpactPeriodical: Number(newAttributes?.lifeQualityImpactPeriodical),
  };
}

export const getDefaultObjectsQueryVariables = (id?: string) => ({
  orderBy: [{ id: OrderDirection.Desc }],
  where: {
    AND: [{ timespace: { id: { equals: id } } }, { timespacePerson: null }],
  },
});

export const getPersonFullName = (user: {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
}) => {
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export function isObjectDiscarded({
  objectStatus,
}: {
  objectStatus?: TimespacePersonFinancialObjectObjectStatusType | null | undefined;
}) {
  return objectStatus === TimespacePersonFinancialObjectObjectStatusType.Discarded;
}

export function willObjectBeDiscarded({
  discardmentRound,
  objectStatus,
}: {
  discardmentRound?: number | null;
  objectStatus?: TimespacePersonFinancialObjectObjectStatusType | null;
}) {
  return (
    objectStatus !== TimespacePersonFinancialObjectObjectStatusType.Discarded && discardmentRound
  );
}

export function getNextInterestPeriod(
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null,
) {
  return timespacePersonFinancialObject?.lastInterestPeriod
    ? timespacePersonFinancialObject?.lastInterestPeriod +
        (timespacePersonFinancialObject?.interestPeriod ?? 0)
    : (timespacePersonFinancialObject?.procurementRound ?? 0) +
        (timespacePersonFinancialObject?.interestPeriod ?? 0);
}
