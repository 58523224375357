import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function NotFound() {
  const { t } = useTranslation();

  const navigateToPreviousPage = () => {
    window.history.back();
  };

  return (
    <Box alignItems="center" display="flex" height={'91vh'} justifyContent="center">
      <Box component={Paper} p={4}>
        <Box alignItems="center" display="flex" gap={1}>
          <ErrorOutlineIcon />
          <Typography variant="h6">{t('errors.error.404')}</Typography>
        </Box>
        <Box display="flex" justifyContent="end" pt={4}>
          <LoadingButton onClick={navigateToPreviousPage} type="submit" variant="contained">
            <span>{t('global.back.to.previous')}</span>
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
