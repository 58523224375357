import * as React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from 'Theme';

export default function NoData() {
  const { t } = useTranslation();

  return (
    <Typography color={theme.palette.grey.A400} py={1} variant="body2">
      {t('errors.query.couldNotLoad')}
    </Typography>
  );
}
