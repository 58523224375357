import { t } from 'i18next';
import { ExportItem } from 'Generated/graphql-hooks';

export function createCSV(data: ExportItem[]): string {
  const csvRows = [];
  csvRows.push(
    `${t('budget.export.date')};${t('budget.export.category')};${t('budget.export.name')};${t(
      'budget.export.amount',
    )}`,
  );

  const rows = data.reduce((acc, item): any => {
    const value = `${item.date};${item.category};${item.name};${item.amount}\n`;
    return acc.concat(value);
  }, '');

  csvRows.push(rows);
  return csvRows.join('\n');
}

export function downloadCSV(csv: string): void {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', 'personal-budget.csv');

  a.click();
}
