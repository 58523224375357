import { Check, Close, Delete, DragIndicator } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import theme from 'Theme';
import {
  TimespacePersonObject,
  TimespacePersonObjectStatusType,
  namedOperations,
  useUpdateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { Constants } from 'Config';
import { useModal } from 'Lib/Hooks/useModal';
import { SafeDeleteModal } from 'Components';

const getItemStyle = (isDragging: boolean, isAvailable: boolean, draggableStyle: any) => ({
  background: isDragging
    ? theme.palette.common.white
    : !isAvailable
    ? '#EEEEEEFF'
    : theme.palette.common.white,
  userSelect: 'none',
  whiteSpace: 'nowrap',
  ...draggableStyle,
});

interface Props {
  consumedTime: number;
  data: TimespacePersonObject[];
  droppableProvided: any;
  onChange: (items: TimespacePersonObject[]) => void;
}

export default function TimeManagementTable({ consumedTime, data, droppableProvided }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, handleOpen, isOpen, selected } = useModal<TimespacePersonObject>();
  const [updateObject, { loading }] = useUpdateTimespacePersonObjectMutation({
    refetchQueries: [
      namedOperations.Query.MyTimespacePerson,
      namedOperations.Query.TimespacePersonObjects,
    ],
    variables: {
      data: { status: TimespacePersonObjectStatusType.Discarded },
      where: { id: selected?.id },
    },
  });

  const handleOnConfirm = useCallback(() => {
    updateObject()
      .then(() => {
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      });
  }, []);
  return (
    <>
      <TableContainer component={Paper} sx={{ overflowY: 'hidden' }}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('object.attr.status')}</TableCell>
              <TableCell>{t('object.attr.code')}</TableCell>
              <TableCell>{t('object.attr.name')}</TableCell>
              <TableCell>{t('object.attr.customName')}</TableCell>
              <TableCell>{t('object.attr.timeRequired')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody
            {...droppableProvided.droppableProps}
            style={{
              background: theme.palette.primary.light,
            }}>
            {data.map(({ timeRequired, ...item }, index: number) => {
              let status = false;
              if (timeRequired && consumedTime + timeRequired <= Constants.TIME_AVAILABLE) {
                consumedTime += timeRequired;
                status = true;
              }
              return (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <TableRow
                      key={index}
                      ref={draggableProvided.innerRef}
                      onMouseEnter={() => {
                        console.log({ ...item, timeRequired });
                      }}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={getItemStyle(
                        draggableSnapshot.isDragging,
                        status,
                        draggableProvided.draggableProps.style,
                      )}>
                      <TableCell width="5%">
                        <DragIndicator fontSize="small" />
                      </TableCell>
                      <TableCell width="5%">
                        {status ? (
                          <Tooltip title={t('time_management.active').toString()}>
                            <Check color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title={t('time_management.passive').toString()}>
                            <Close color="error" />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell width="15%">{item.object?.code}</TableCell>
                      <TableCell width="15%">{item.name}</TableCell>
                      <TableCell width="20%">
                        {item.customName || (
                          <Typography color={theme.palette.grey.A400} variant="caption">
                            {t('global.placeholder.undefined')}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell width="30%">{timeRequired}</TableCell>
                      <TableCell width="20%">
                        <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                          <IconButton
                            aria-label={t('global.actions.delete').toString()}
                            color="error"
                            onClick={() => handleOpen(item)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </TableBody>
        </Table>
      </TableContainer>
      <SafeDeleteModal
        loading={loading}
        onClose={handleClose}
        onConfirm={handleOnConfirm}
        open={isOpen}
        text={`${t('time_management.delete.confirm')} ${selected?.name} ?`}
      />
    </>
  );
}
