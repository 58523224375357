import { useParams } from 'react-router-dom';
import { RandomEventInput } from 'Models/RandomEventInput';
import {
  RandomEvent,
  useFinancialObjectQuery,
  useRandomEventsQuery,
  useTimespacePersonFinancialObjectsQuery,
  useTimespacePersonObjectsQuery,
  useTimespaceQuery,
} from 'Generated/graphql-hooks';
import { getDefaultObjectsQueryVariables } from 'Lib/Helpers/TimespacePersonObject';
import { Timespace, TimespacePersonFinancialObject } from 'Models';
import { FinancialObjectCodeEnum } from 'Types/Global';

export function useGenerateTimespaceInput() {
  const { id } = useParams<{ id?: string }>();
  //TODO: unify to one call
  const {
    data: timespaceData,
    error: error1,
    loading: loading1,
  } = useTimespaceQuery({ skip: !id, variables: { where: { id } } });

  const {
    data: objectsData,
    error: error2,
    loading: loading2,
  } = useTimespacePersonObjectsQuery({
    skip: !id,
    variables: getDefaultObjectsQueryVariables(id),
  });

  const {
    data: financialData,
    error: error3,
    loading: loading3,
  } = useTimespacePersonFinancialObjectsQuery({
    skip: !id,
    variables: getDefaultObjectsQueryVariables(id),
  });

  const {
    data: cashData,
    error: error4,
    loading: loading4,
  } = useFinancialObjectQuery({
    skip:
      loading3 ||
      (!!financialData?.timespacePersonFinancialObjects &&
        financialData.timespacePersonFinancialObjects.length !== 0),
    variables: {
      where: {
        code: FinancialObjectCodeEnum.Cash,
      },
    },
  });

  const {
    data: randomEventsData,
    error: error5,
    loading: loading5,
  } = useRandomEventsQuery({
    skip: !id,
    variables: { where: { timespace: { id: { equals: id } } } },
  });

  return {
    error: error1 || error2 || error3 || error4 || error5,
    input: {
      ...new Timespace(timespaceData?.timespace),
      defaultPaymentMethod:
        timespaceData?.timespace?.defaultPaymentMethod ||
        (cashData?.financialObject &&
          new TimespacePersonFinancialObject(cashData?.financialObject)),
      randomEvents:
        randomEventsData?.randomEvents?.map((item) => new RandomEventInput(item) as RandomEvent) ||
        [],
      timespacePersonFinancialObjects:
        financialData?.timespacePersonFinancialObjects ||
        (cashData?.financialObject
          ? [new TimespacePersonFinancialObject(cashData?.financialObject)]
          : []),
      timespacePersonObjects: objectsData?.timespacePersonObjects || [],
    },
    loading: loading1 || loading2 || loading3 || loading4 || loading5,
  };
}
