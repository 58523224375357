import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { Box, Container, FormHelperText, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { GameContext } from 'Context';
import {
  BasicAccountSelector,
  FormikTrigger,
  LabeledText,
  Section,
  SelectFormik,
  TextFieldFormik,
} from 'Components';
import { Constants } from 'Config';
import { ConsumerCreditCreateSchema } from 'Config/Validations';
import { useModal } from 'Lib/Hooks';
import {
  FinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
  namedOperations,
  useCreateTimespacePersonFinancialObjectMutation,
} from 'Generated/graphql-hooks';
import { TimespacePersonFinancialObject, TimespacePersonFinancialObjectCreateInput } from 'Models';
import { formatPrice, roundFloat } from 'Lib/Helpers/Number';
import useTimespacePersonFinancialInfo from 'Lib/Hooks/useTimespacePersonFinancialInfo';
import MaxCreditCalculationModal from './MaxCreditCalculationModal';
import {
  calculateConsumerCreditMonthlyPayment,
  calculateMaxLoan,
  calculateMaxMonthlyPayment,
} from './utils';
import { ConsumerCreditTable } from '.';

export interface FormValues {
  amountRequested: number | null;
  day: number;
  financialObject: FinancialObjectFragment | null;
  maturityPeriod: number | null;
  maxLoanAmount: number | null;
  maxMonthlyPayment: number | null;
  monthlyPayment: number | null;
  payersAccount: TimespacePersonFinancialObject | null;
}

export default function ConsumerCreditCreate() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [createTimespacePersonFinancialObject] = useCreateTimespacePersonFinancialObjectMutation();
  const {
    handleClose: handleMaxCreditCalculationClose,
    handleOpen: handleMaxCreditCalculationOpen,
    isOpen: isMaxCreditCalculationOpen,
  } = useModal();

  const {
    data: { livingWage, monthlyIncome, monthlyPaymentsForLoans, partnerIncome },
  } = useTimespacePersonFinancialInfo({
    timespaceId,
    timespacePerson,
  });

  const maxMonthlyPayment = calculateMaxMonthlyPayment({
    livingWage,
    monthlyIncome,
    monthlyPayments: monthlyPaymentsForLoans,
    partnerIncome,
  });

  const getInitialValues = (formValues: FormValues) => {
    if (!formValues?.financialObject) {
      return formValues;
    }

    const amountRequested = calculateMaxLoan({
      financialObject: formValues?.financialObject,
      maxMonthlyPayment,
    });

    const monthlyPayment = calculateConsumerCreditMonthlyPayment({
      financialObject: formValues.financialObject,
      maturityPeriod: formValues?.financialObject?.maturityPeriod ?? 0,
      requestedAmount: amountRequested,
      timespace: timespacePerson?.timespace,
    });

    return {
      ...formValues,
      amountRequested: roundFloat(amountRequested),
      maturityPeriod: (formValues.financialObject?.maturityPeriod ?? 0) / 12,
      maxLoanAmount: roundFloat(amountRequested),
      maxMonthlyPayment: roundFloat(maxMonthlyPayment),
      monthlyPayment: roundFloat(monthlyPayment),
    };
  };

  const handleSubmitFormik = async (
    {
      amountRequested,
      day,
      financialObject,
      maturityPeriod,
      monthlyPayment,
      payersAccount,
    }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    try {
      if (!financialObject) {
        throw new Error('Financial object not set');
      }

      const { data } = await createTimespacePersonFinancialObject({
        awaitRefetchQueries: true,
        refetchQueries: [
          namedOperations.Query.BankCategories,
          namedOperations.Query.TimespacePersonFinancialObjects,
        ],
        variables: {
          data: {
            ...new TimespacePersonFinancialObjectCreateInput(
              {
                ...new TimespacePersonFinancialObject(financialObject),
                maturityPeriod: (maturityPeriod ?? 0) * 12,
                monthlyPayment,
                objectStatus: TimespacePersonFinancialObjectObjectStatusType.Procured,
                realizationDay: day,
              },
              timespaceId,
              timespacePerson.id,
            ),
            initialValue: (amountRequested ?? 0) * -1,
            relatedFinancialObject: { connect: { id: payersAccount?.id } },
          },
        },
      });
      if (data?.createTimespacePersonFinancialObject) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('consumerCredit.create.title')}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={{
            amountRequested: null,
            day: 1,
            financialObject: null,
            maturityPeriod: null,
            maxLoanAmount: null,
            maxMonthlyPayment,
            monthlyPayment: null,
            payersAccount: null,
          }}
          onSubmit={handleSubmitFormik}
          validationSchema={ConsumerCreditCreateSchema}>
          {({
            errors,
            handleSubmit,
            isSubmitting,
            isValid,
            setTouched,
            setValues,
            submitForm,
            values,
          }) => {
            const isFinancialObjectSelected = !!values.financialObject;

            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <Box mb={4}>
                  <ConsumerCreditTable
                    onRowSelected={(financialObject) => {
                      const newValues = {
                        ...values,
                        financialObject,
                      };

                      setValues(getInitialValues(newValues));
                      setTouched(
                        {
                          amountRequested: true,
                          maturityPeriod: true,
                        },
                        false,
                      );
                    }}
                    value={values?.financialObject}
                  />
                </Box>
                <FormikTrigger<FormValues>
                  skipInitialRender
                  delay={300}
                  trigger={(object, formikHelpers) => {
                    if (object.financialObject) {
                      formikHelpers.setFieldValue(
                        'monthlyPayment',
                        calculateConsumerCreditMonthlyPayment({
                          financialObject: object.financialObject,
                          maturityPeriod: (object.maturityPeriod ?? 0) * 12,
                          requestedAmount: object.amountRequested ?? 0,
                          timespace: timespacePerson?.timespace,
                        }),
                      );
                    }
                  }}
                  watchProperties={['maturityPeriod', 'amountRequested']}
                />
                <LabeledText
                  label={t('consumerCredit.requested.amount')}
                  value={
                    <TextFieldFormik
                      disableTranslation
                      InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                      disabled={!isFinancialObjectSelected}
                      id="amountRequested"
                      type="number"
                    />
                  }
                />
                <LabeledText
                  label={t('mortgage.maturityPeriod')}
                  value={
                    <TextFieldFormik
                      InputProps={{
                        endAdornment: t('global.abbrevations.year'),
                      }}
                      disabled={!isFinancialObjectSelected}
                      id="maturityPeriod"
                      type="number"
                    />
                  }
                />
                <LabeledText
                  label={t('mortgage.monthly.payment.amount')}
                  sx={{ py: 1 }}
                  value={
                    <Box>
                      <Box alignItems="center" display="flex" gap={1} justifyContent="flex-end">
                        {formatPrice(values.monthlyPayment)}
                        {isFinancialObjectSelected ? (
                          <IconButton onClick={handleMaxCreditCalculationOpen}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                      {errors.monthlyPayment ? (
                        <FormHelperText error id="component-error-text">
                          {errors.monthlyPayment}
                        </FormHelperText>
                      ) : null}
                    </Box>
                  }
                />
                <LabeledText
                  label={t('consumerCredit.initial.day')}
                  value={
                    <SelectFormik
                      fullWidth
                      disabled={!isFinancialObjectSelected}
                      id="day"
                      options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
                    />
                  }
                />
                <LabeledText
                  label={t('object.attr.relatedFinancialObject')}
                  value={
                    <BasicAccountSelector
                      disabled={!isFinancialObjectSelected}
                      id="payersAccount"
                    />
                  }
                />
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="contained">
                    <span>{t('term.actions.sign')}</span>
                  </LoadingButton>
                </Box>
              </Box>
            );
          }}
        </Formik>
        <MaxCreditCalculationModal
          householdIncome={monthlyIncome + partnerIncome}
          livingWage={livingWage}
          monthlyPayments={monthlyPaymentsForLoans}
          onClose={handleMaxCreditCalculationClose}
          open={isMaxCreditCalculationOpen}
        />
      </Section>
    </Container>
  );
}
