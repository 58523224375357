import { Box, Tabs } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LinkTab } from 'Components/Generic/index';

interface Props {
  tabs: Array<{
    href: string;
    icon: React.ReactElement;
    label: string;
  }>;
}
export default function NavTabs({ tabs }: Props) {
  const { pathname } = useLocation();

  const getIndex = useCallback(() => {
    const index = tabs.findIndex((x) => x.href.length > 0 && pathname.endsWith(x.href));
    return ~index ? index : 0;
  }, [tabs, pathname]);

  const [value, setValue] = React.useState(getIndex);

  useEffect(() => {
    setValue(getIndex);
  }, [pathname]);

  return (
    <Box pb={2} sx={{ width: '100%' }}>
      <Tabs aria-label="nav tabs" scrollButtons="auto" value={value} variant="scrollable">
        {tabs.map((item, index) => (
          <LinkTab
            key={index}
            icon={item.icon}
            iconPosition="start"
            label={item.label}
            to={item.href}
          />
        ))}
      </Tabs>
    </Box>
  );
}
