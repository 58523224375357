import React, { useCallback, useContext } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent } from '@mui/material';
import { GameContext } from 'Context';
import {
  TimespacePersonFinancialObjectDocument,
  TimespacePersonFinancialObjectFragment,
  useTerminateBankAccountMutation,
} from 'Generated/graphql-hooks';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { ModalCloseButton } from 'Components/Generic';
import { getNextPossibleDiscardRound } from './utils';

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function TermAccountDiscardModal({
  onClose: handleClose,
  open,
  timespacePersonFinancialObject,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [discardAccount] = useTerminateBankAccountMutation();

  const possibleDiscardRound = getNextPossibleDiscardRound(
    timespacePersonFinancialObject,
    timespacePerson.timespace,
  );

  const handleDiscard = useCallback(async () => {
    try {
      const { data: responseData } = await discardAccount({
        refetchQueries: [
          {
            query: TimespacePersonFinancialObjectDocument,
            variables: { where: { id: Number(timespacePersonFinancialObject!.id) } },
          },
        ],
        variables: {
          discardmentRound: possibleDiscardRound ?? 0,
          fromTimespacePersonFinancialObjectId: Number(timespacePersonFinancialObject!.id),
          terminateImmediately: false,
          toTimespacePersonFinancialObjectId: Number(
            timespacePersonFinancialObject?.relatedFinancialObject!.id,
          ),
        },
      });
      if (responseData?.terminateBankAccount) {
        handleClose();
        enqueueSnackbar(t('bank.savings.cancel_success'), { variant: 'success' });
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [timespacePersonFinancialObject, discardAccount, enqueueSnackbar]);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle>
        {t('bank.term.discard.check')}
        <ModalCloseButton onClose={handleClose} />
      </DialogTitle>
      <Box>
        <DialogContent>
          {t('bank.term.discard.confirm', {
            replace: {
              account: timespacePersonFinancialObject?.relatedFinancialObject?.name,
              value: getHumanDateFromRound(
                possibleDiscardRound,
                timespacePersonFinancialObject?.realizationDay?.toString() || '1',
              ),
            },
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscard} variant="contained">
            {t('bank.term.cancel')}
          </Button>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.close')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
