import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { LabeledText } from 'Components';
import { calculateLevelThreshold } from 'Lib/Helpers/Level';

interface Props {
  experienceScore: number;
  level: number;
}

export default function TooltipInfo({ experienceScore, level }: Props) {
  const { t } = useTranslation();

  return (
    <Box>
      <LabeledText
        label={t('game.overall.level')}
        sx={{ p: 0.5, whiteSpace: 'noWrap' }}
        value={level + 1}
      />
      <LabeledText
        label={t('game.experience.score')}
        sx={{ p: 0.5, whiteSpace: 'nowrap' }}
        value={`${experienceScore} b`}
      />
      <LabeledText
        label={t('game.next.level.in')}
        sx={{ p: 0.5, whiteSpace: 'nowrap' }}
        value={`${calculateLevelThreshold(level) - experienceScore} b`}
      />
    </Box>
  );
}
