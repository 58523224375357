import {
  RandomEventObjectObjectStatusType,
  TimespaceFinancialTransactionStatusType,
  TimespaceFinancialTransactionTypeType,
  TimespacePersonObjectStatusType,
  UserGradeType,
  UserParticipantTypeType,
} from 'Generated/graphql-hooks';

export default {
  bank: {
    'account.consumer.credit': 'Spotrebiteľský úver',
    'account.current': 'Bežný účet',
    'account.detail': 'Detaily',
    'account.info': 'Informácie o účte',
    'account.mortgage': 'Hypotekárny úver',
    'account.name': 'Názov účtu',
    'account.savings': 'Sporiaci účet',
    'account.term': 'Termínovaný účet',
    'account.totalValue': 'Zostatok na účte: {{value}}',
    'account.transactions': 'Pohyby na účte',
    'account.willBeDiscarded': 'Sporiaci účet bude zrušený po najbližšom pripočítaní úrokov',
    bank_payment: 'Platba',
    'beneficiary.account': 'Účet príjemcu',
    'constant.symbol': 'Konštantný symbol',
    'create.standingOrder': 'Zriadiť trvalý príkaz',
    'edit.standingOrder': 'Upraviť trvalý príkaz',
    'expiration.count': 'Počet vykonaní',
    'expiration.date': 'Dátum zrušenia',
    my_products: 'Moje produkty',
    'no.basic.account': 'Nemáte žiaden bežný účet',
    'overview.title': 'Vyberte si zo zoznamu bankový produkt',
    'payers.account': 'Účet platiteľa',
    'payment.account': 'Na bankový účet',
    'payment.amount': 'Výška platby',
    'payment.deposit': 'Vklad hotovosti',
    'payment.deposit_amount': 'Výška vkladu hotovosti',
    'payment.realization.day': 'Deň realizácie',
    'payment.realization.month': 'Mesiac realizácie',
    'payment.realization.year': 'Rok realizácie',
    'payment.success': 'Operácia bola úspešne zrealizovaná',
    'payment.transfer_amount': 'Výška výberu',
    'payment.transfer_sign': 'Podpísať platbu',
    'payment.transfer_to': 'Na protiúčet',
    'payment.withdraw': 'Výber hotovosti',
    'payment.withdraw_amount': 'Výška výberu hotovosti',
    'placeholder.day': 'Zadajte deň',
    'placeholder.month': 'Zadajte mesiac',
    'savings.cancel': 'Zrušiť sporiaci účet',
    'savings.cancel_success': 'Sporiaci účet bol úspešne zrušený',
    'savings.discard.check': 'Naozaj chcete zrušiť sporiaci účet?',
    'savings.discard.choose_account':
      'Váš sporiaci účet bude zrušený {{value}}, prosím zadajte účet kam budú poslané zvyšné finančné prostriedky.',
    'savings.discard.immediately': 'Áno - zrušiť okamžite',
    'savings.discard.next_interest': 'Áno - zrušiť po najbližšom pripísaní úrokov',
    'savings.discarded': 'Účet je zrušený',
    'savings.interest.period': 'Frekvencia pripisovania úrokov',
    'savings.interest.positive': 'Úroková sadzba',
    'savings.maturityPeriod': 'Doba splatnosti',
    'savings.next.interest': 'Najbližší termín pripísania úrokov',
    'savings.principalMin': 'Minimálny vklad',
    'savings.realization.day': 'Začiatok sporenia',
    'specific.symbol': 'Špecifický symbol',
    'standing.orders': 'Trvalé príkazy',
    'standingOrder.count.expiration': 'Do počtu vykonaní',
    'standingOrder.create': 'Vytvoriť trvalý príkaz',
    'standingOrder.date.expiration': 'Do dátumu',
    'standingOrder.delete': 'Zrušiť trvalý príkaz',
    'standingOrder.deleteConfirm': 'Naozaj chcete zrušiť trvalý príkaz',
    'standingOrder.edit': 'Upraviť trvalý príkaz',
    'standingOrder.expiration': 'Platnosť',
    'standingOrder.expirationDate.error':
      'Dátum zrušenia musí byť vyšší alebo rovný dátumu inicializácie',
    'standingOrder.initial.day': 'Inicializačný dátum',
    'standingOrder.name': 'Pomenovanie trvalého príkazu',
    'standingOrder.period': 'Opakovať raz za (počet mesiacov)',
    'standingOrder.termination.expiration': 'Do zrušenia',
    'standingOrder.text.message': 'Textová správa',
    'term.cancel': 'Zrušiť termínovaný účet',
    'term.discard.check': 'Naozaj chcete zrušiť termínovaný účet?',
    'term.discard.confirm':
      'Váš termínovaný účet bude zrušený v najbližší možný termín - {{value}}. Zvyšné finančné prostriedky budú poslané na účet: {{account}}',
    'term.error.nextPeriod': 'Najbližšie je možné vložiť peniaze {{date}}',
    'term.willBeDiscarded': 'Termínovaný účet bude zrušený v najbližšom možnom termíne',
    'transaction.attr.amount': 'Suma',
    'transaction.attr.currentBalance': 'Priebežný zostatok',
    'transaction.attr.day': 'Dátum',
    'transaction.attr.relatedFinancialObject': 'Súvisiaci finančný objekt',
    'transaction.attr.relatedObject': 'Súvisiaci objekt',
    'transaction.attr.round': 'Kolo',
    'transaction.attr.status': 'Stav',
    'transaction.attr.type': 'Typ',
    'transaction.list': 'Zoznam transakcií',
    'transaction.placeholder': 'Na tomto účte zatiaľ neevidujeme žiadne pohyby',
    transfer: 'Prevod na účet',
    'variable.symbol': 'Variabilný symbol',
  },
  budget: {
    'account.difference': 'Rozdiel príjmov a výdavkov',
    calculate: 'Vypočítať',
    export: {
      amount: 'luro',
      category: 'Kategória',
      date: 'Dátum',
      download: 'Stiahnuť CSV',
      name: 'Položka',
      success: 'CSV bolo úspešne stiahnuté',
    },
    'finalDate.error': 'Konečný dátum musí byť vyšší alebo rovný než počiatočný dátum',
    item: 'Položka',
    'non.periodic.expenses': 'Nepravidelné výdavky',
    'non.periodic.income': 'Nepravidelné príjmy',
    overview: 'Prehľad osobného rozpočtu za obdobie',
    'periodic.expenses': 'Pravidelné výdavky',
    'periodic.income': 'Pravidelné príjmy',
    'total.expenses': 'Celkové výdavky',
    'total.income': 'Celkové príjmy',
    'total.label': 'Spolu',
  },
  carshop: {
    'overview.title': 'Vyberte si zo zoznamu značku',
  },
  consumerCredit: {
    'account.status': 'Stav účtu',
    'actual.value': 'Aktuálna výška úveru',
    'amountRequested.limitExceeded':
      'Prekročili ste limit pre získanie úveru. Najviac môžete žiadať o {{value}}.',
    'create.menu': 'Požiadať o: Spotrebiteľský úver',
    'create.title': 'Prehľad dostupných spotrebiteľských úverov',
    'initial.day': 'Deň čerpania/splácania',
    'installments.left': 'Počet zostávajúcich splátok',
    'last.installment.date': 'Dátum poslednej plánovanej splátky',
    'loan.repayed': 'Úver bol splatený',
    'loan.willBeDiscarded': 'Úver bude splatený pri najbližšom prechode kola',
    'maturityPeriod.limitExceeded': 'Prekročili ste limit pre dobu splácania úveru.',
    'maxCredit.amount': 'Maximálna výška úveru',
    'maxCredit.calculation': 'Výpočet maximálnej splátky spotrebiteľského úveru',
    'maximal.time': 'Dĺžka splácania najviac',
    'modal.actual.value': 'Výška spotrebiteľského úveru k dátumu splatenia',
    'modal.amount.left': 'Na splátky úveru ostáva',
    'modal.date': 'Dátum splatenia úveru',
    'modal.exit.fee': 'Výstupný poplatok',
    'modal.houshold.living.wage': 'Životné minimum domácnosti',
    'modal.income.total': 'Spoločný čistý príjem',
    'modal.income/wage.difference': 'Rozdiel medzi príjmom a minimom',
    'modal.maximal.repayment.amount': 'Maximálna výška splátky úveru',
    'modal.other.monthly.payments': 'Ostatné mesačné splátky',
    'modal.reserve': 'Rezerva (40% z rozdielu)',
    'modal.total': 'Spolu',
    'monthlyPayment.limitExceeded': 'Maximálny limit pre mesačnú splátku je {{value}}.',
    'periodical.fee': 'Poplatky za vedenie účtu',
    'realized.installments': 'Počet zrealizovaných splátok',
    'repay.loan': 'Splatiť úver',
    'requested.amount': 'Požadovaná výška úveru',
  },
  enums: {
    [RandomEventObjectObjectStatusType.Active]: 'aktívny/využívaný',
    [RandomEventObjectObjectStatusType.Passive]: 'pasívny/nevyužívaný',
    [RandomEventObjectObjectStatusType.FullyUtilised]: 'absolvovaný/opotrebený',
    [RandomEventObjectObjectStatusType.Discarded]: 'zrušený',
    [RandomEventObjectObjectStatusType.Procured]: 'obstarávaný',
    [RandomEventObjectObjectStatusType.ProcuredPassive]: 'obstarávaný pasívny',
    [TimespacePersonObjectStatusType.Active]: 'aktívny/využívaný',
    [TimespacePersonObjectStatusType.Passive]: 'pasívny/nevyužívaný',
    [TimespacePersonObjectStatusType.FullyUtilised]: 'absolvovaný/opotrebený',
    [TimespacePersonObjectStatusType.Discarded]: 'zrušený',
    [TimespacePersonObjectStatusType.Procured]: 'obstarávaný',
    [TimespacePersonObjectStatusType.ProcuredPassive]: 'obstarávaný pasívny',
    [UserParticipantTypeType.Student]: 'Žiak',
    [UserParticipantTypeType.Teacher]: 'Učiteľ',
    [TimespaceFinancialTransactionStatusType.Prepared]: 'pripravovaná',
    [TimespaceFinancialTransactionStatusType.Realized]: 'zrealizovaná',
    [TimespaceFinancialTransactionStatusType.Cancelled]: 'zrušená hráčom',
    [TimespaceFinancialTransactionStatusType.RejectedLackOfFunds]:
      'zamietnutá pre nedostatok prostriedkov',
    [TimespaceFinancialTransactionStatusType.InvalidCounterAccount]: 'neplatný protiúčet',
    [TimespaceFinancialTransactionTypeType.Purchase]: 'nákup',
    [TimespaceFinancialTransactionTypeType.Sale]: 'predaj',
    [TimespaceFinancialTransactionTypeType.OperatingCostPassive]: 'prevádzkové náklady pasívne',
    [TimespaceFinancialTransactionTypeType.OperatingCost]: 'prevádzkové náklady',
    [TimespaceFinancialTransactionTypeType.OperatingIncome]: 'pravidelné príjmy',
    [TimespaceFinancialTransactionTypeType.OperatingIncomePassive]: 'pravidelné príjmy pasívne',
    [TimespaceFinancialTransactionTypeType.Salary]: 'mzda',
    [TimespaceFinancialTransactionTypeType.SaleOfShares]: 'predaj podielov',
    [TimespaceFinancialTransactionTypeType.InterestReceived]: 'úroky prijaté',
    [TimespaceFinancialTransactionTypeType.PurchaseOfShares]: 'nákup podielov',
    [TimespaceFinancialTransactionTypeType.InterestPaid]: 'úroky zaplatené',
    [TimespaceFinancialTransactionTypeType.InterestPenalty]: 'úroky sankčné',
    [TimespaceFinancialTransactionTypeType.FeesOneTime]: 'poplatky jednorázové',
    [TimespaceFinancialTransactionTypeType.AccountManagementFee]: 'poplatok za vedenie účtu',
    [TimespaceFinancialTransactionTypeType.DrawingConsumerLoan]: 'čerpanie spotrebiteľského úveru',
    [TimespaceFinancialTransactionTypeType.FeesPeriodic]: 'poplatky periodické',
    [TimespaceFinancialTransactionTypeType.FeesOneTimePercentage]:
      'poplatky jednorázové percentuálne',
    [TimespaceFinancialTransactionTypeType.FeesPeriodicPercentage]:
      'poplatky periodické percentuálne',
    [TimespaceFinancialTransactionTypeType.Withdrawal]: 'výber',
    [TimespaceFinancialTransactionTypeType.Deposit]: 'vklad',
    [TimespaceFinancialTransactionTypeType.DrawingMortgageLoan]: 'čerpanie hypotekárneho úveru',
    [TimespaceFinancialTransactionTypeType.LoanPayment]: 'splátka úveru',
    [TimespaceFinancialTransactionTypeType.SavingsInterestReceived]: 'úroky prijaté',
    [TimespaceFinancialTransactionTypeType.SavingsInterestTax]: 'daň z úrokov',
    [UserGradeType.MiddleSchoolFirstYear]: 'SŠ, 1. ročník',
    [UserGradeType.MiddleSchoolFourthYear]: 'SŠ, 4. ročník',
    [UserGradeType.MiddleSchoolSecondYear]: 'SŠ, 2. ročník',
    [UserGradeType.MiddleSchoolThirdYear]: 'SŠ, 3. ročník',
    [UserGradeType.PrimarySchoolEighthYear]: 'ZŠ, 8. ročník',
    [UserGradeType.PrimarySchoolFifthYear]: 'ZŠ, 5. ročník',
    [UserGradeType.PrimarySchoolNinthYear]: 'ZŠ, 9. ročník',
    [UserGradeType.PrimarySchoolSeventhYear]: 'ZŠ, 7. ročník',
    [UserGradeType.PrimarySchoolSixthYear]: 'ZŠ, 6. ročník',
  },
  errors: {
    AccessDenied: 'Nemáte dostatočné práva',
    NotEnoughFunds: 'Nemáte dostatok financií',
    'error.404': 'Cieľová stránka nie je k dispozícii',
    'error.already_exists.timespace_person_object.custom_name': 'Dieťa s daným menom už existuje',
    'error.max-exceeded': 'Prekročený maximálny počet objektov',
    'error.max_age_exceeded': 'Prekročený maximálny vek.',
    'error.min_age_not_reached': 'Nebola splnená podmienka minimálneho veku {{value}} rokov',
    'error.missing': 'Chýbajú prerekvizity',

    'error.not-active-object': 'Účet nie je aktívny',
    'error.not-enough-money-on-account': 'Na platobnom účte nie je dostatok financií',
    'error.not-enough-volume':
      'Na podielovom fonde je {{value}} podielov. Viac nie je možné predať.',
    'error.not_enough_money': 'Nemáte dostatok financií',
    'error.out_of_range.timespace_person_financial_object.maturity_period':
      'Zadaná dĺžka splácania je mimo povolený rozsah.',
    'error.real_estate_encumbrance':
      'Nehnuteľnosť {{objectName}}, nie je možné predať. Najprv je potrebné splatiť hypotekárny úver {{financialObjectName}}.',
    'error.timespace_person_financial_object.not_active': 'Účet nie je aktívny',
    'error.too_many_kids': 'V jednom kole je možné pridať maximálne 9 detí',
    'error.too_young_child':
      'Najmladšie dieťa musí mať viac ako 10 mesiacov, alebo musí byť pridané v tom istom kole',
    generic: 'Vyskytla sa chyba',
    'in_use.email': 'Zadaný email sa už používa',
    'in_use.nickname': 'Zadaná prezývka sa už používa',
    login: 'Nepodarilo sa prihlásiť so zadanými údajmi',
    missing_data: 'Chýbajú dáta',
    'query.couldNotLoad': 'Nepodarilo sa načítať dáta.',
    recaptcha: 'Overenie reCAPTCHA nebolo úspešné. Obnovte stránku a skúste to znova.',
    title: 'Vyskytli sa nasledujúce problémy',
    'user.notVerified': 'Účet nebol doposiaľ potvrdený. Použite odkaz z e-mailu.',
  },
  eshop: {
    monthly: 'Mesačne',
    payment_method: 'Spôsob platby',
    'placeholder.empty': 'Ľutujeme v zadanej kategórii sa nenachádzajú žiadne položky',
    price: 'Cena',
    'product.detail': 'Detail produktu',
    'product.pruchased': 'Produkt bol úspešne zakúpený',
    recommended: 'Odporúčané',
    total_price: 'Výsledná cena',
  },
  forgotPassword: {
    button: 'Odoslať',
    description: 'Na vašu emailovú adresu bude odoslaný email s inštrukciami na obnovenie hesla',
    initiated: 'Požiadavka na obnovu hesla bola úspešne spracovaná',
    'reset.invalid': 'Tento odkaz už nie je platný',
    'reset.redeemed': 'Token pre obnovenie hesla bol už použitý',
    'reset.success': 'Heslo bol úspešne zmenené, prosím prihláste sa',
    'reset.title': 'Nastavenie nového hesla',
    title: 'Obnoviť heslo',
    'user.notFound':
      'Používateľ s prihlasovacím menom alebo mailovou adresou {{identifier}} neexistuje',
  },
  game: {
    'ask.for': 'Požiadať o:',
    'experience.score': 'Aktuálne skóre',
    interpersonalize: 'Náhľad osoby: {{ person }} (prihlásený: {{ user }})',
    'jobs.available': 'Zobraziť len dostupné',
    level: 'Úroveň: {{value}}',
    'management.next_round.button': 'Presun do ďalšieho kola',
    'management.next_round.prefix': 'Presun o',
    'management.next_round.suffix': 'kolá',
    'management.next_round_error':
      'Presun kola sa nepodarilo vykonať. O probléme sme boli notifikovaní a riešime ho.',
    'management.next_round_finish': 'Presun kola bol ukončený',
    'management.next_round_success': 'Presun bol úspešne vykonaný',
    'menu.bank': 'Banka',
    'menu.carshop': 'Autosalón',
    'menu.eshop': 'E-shop',
    'menu.holdings': 'Majetok',
    'menu.investments': 'Investície',
    'menu.living': 'Bývanie',
    'menu.management': 'Spravovať',
    'menu.section.age': 'Vek: {{years}} rok. {{months}} mes.',
    'menu.section.budget': 'Osobný rozpočet',
    'menu.section.household': 'Spoločná domácnosť',
    'menu.section.lifeQuality': 'Kvalita života',
    'menu.section.mystuff': 'Moje veci',
    'menu.section.round': 'Kolo',
    'menu.section.timeAvailable': 'Voľný čas',
    'menu.time_management': 'Časový fond',
    'menu.work_and_study': 'Štúdium a práca',
    'next.level.in': 'Ďalšia úroveň o',
    'overall.level': 'Celková úroveň',
    'study.not.available': 'V tomto časopriestore nie možné začať nové štúdium.',
    'study.placeholder': 'Nezačali ste ešte žiadne štúdium',
    'study.section': 'Vzdelanie',
    'study.start': 'Začať štúdium',
    'study.success': 'Štúdium bolo začaté',
    'work.not.available':
      'V tomto časopriestore nie možné zamestnať sa, zmeniť alebo si nájsť novú prácu.',
    'work.placeholder': 'Nezačali ste ešte žiadne zamestnanie',
    'work.section': 'Zamestnanie',
    'work.start': 'Začať zamestnanie',
    'work.success': 'Zamestnanie bolo začaté',
  },
  general: {
    table: {
      body: {
        actions: 'Akcie',
        columnHeaderTooltip: 'Zoradiť podľa ',
        noMatch: 'Žiadne výsledky',
        toolTip: 'Zoradiť',
      },
      filter: {
        all: 'Všetky',
        reset: 'RESET',
        title: 'FILTRE',
      },
      pagination: {
        displayRows: 'z',
        next: 'Ďalšie',
        previous: 'Predošlé',
        rowsPerPage: 'Počet riadkov:',
      },
      selectedRows: {
        delete: 'Vymazať',
        deleteAria: 'Vymazať vybrané riadky',
        text: 'vybraných riadkov',
      },
      toolbar: {
        downloadCsv: 'Stiahnuť CSV',
        filterTable: 'Filtrovať tabuľku',
        print: 'Tlač',
        search: 'Vyhľadávanie',
        viewColumns: 'Stĺpce',
      },
      viewColumns: {
        title: 'Zobraziť stĺpce',
        titleAria: 'Zobraziť/Skryť stĺpce tabuľky',
      },
    },
  },
  global: {
    abbrevations: {
      year: 'r.',
    },
    about_project: 'O projekte',
    'actions.add': 'Pridať',
    'actions.back': 'Naspäť',
    'actions.buy': 'Kúpiť',
    'actions.cancel': 'Zrušiť',
    'actions.close': 'Zavrieť',
    'actions.confirm': 'Potvrdiť',
    'actions.create': 'Vytvoriť',
    'actions.delete': 'Vymazať',
    'actions.detail': 'Detail',
    'actions.edit': 'Upraviť',
    'actions.interpersonalize': 'Interpersonalizovať',
    'actions.interpersonalizeCancel': 'Zrušiť interpersonalizáciu',

    'actions.no': 'Nie',
    'actions.ok': 'OK',
    'actions.save': 'Uložiť',
    'actions.sell': 'Predať',
    'actions.update': 'Aktualizovať zmeny',
    'actions.yes': 'Áno',
    app_version: 'Verzia aplikácie',
    'attribute.optional': 'Nepovinný atribút',
    'back.to.previous': 'Naspäť na predošlú stránku',
    'delete.confirm': 'Naozaj si prajete odstrániť objekt',
    from: 'od',
    immediately: 'okamžite',
    loading: 'Načítavam...',
    more_about_project: 'Viac o projekte',
    now: 'Ihneď',
    or: 'alebo',
    'placeholder.age': 'Zadaj vek',
    'placeholder.amount': 'Zadajte sumu',
    'placeholder.count': 'Zadajte počet',
    'placeholder.delete': 'Naozaj si prajete odstrániť túto položku ?',
    'placeholder.empty': 'Žiadne záznamy',
    'placeholder.name': 'Zadajte názov',
    'placeholder.noData': 'Nenašli sa žiadne dáta',
    'placeholder.search': 'Vyhľadávanie',
    'placeholder.undefined': 'Nevyplnené',
    project_version: 'Verzia projektu',
    server_version: 'Verzia backendu',
    sponsors_description:
      'Táto aktivita je financovaná z Grantovej schémy na podporu finančného vzdelávania, ktorá je súčasťou programu 5peňazí (www.5penazi.sk), administrovanej Inštitútom bankového vzdelávania NBS, n. o.',
    'table.actions': 'Akcie',
    to: 'do',
    'try.again': 'Skúsiť znovu',
    'units.ageInYearsAndMonths': '{{years}} rok. {{months}} mes.',
    'units.hourDuration': 'hodín',
    'units.month': 'mesiac',
    'units.round': 'kola',
    'units.rounds.plural': '{{period}} kolá',
    'units.rounds.plural2': '{{period}} kôl',
    'units.rounds.singular': '{{period}} kolo',
    'units.year': 'rok',
    'units.years.basic': 'rokov',
    'units.years.plural': '{{period}} roky',
    'units.years.plural2': '{{period}} rokov',
    'units.years.singular': '{{period}} rok',
    warning: 'Upozornenie',
  },
  holdings: {
    clear: 'Čistý majetok',
    debts: 'Záväzky',
    fees: 'Poplatky',
    financial: 'Finančný',
    goods: 'Hnuteľný',
    interest: 'Úroková sadzba',
    overview: 'Prehľad majetku',
    period: 'Doba splatenia',
    properties: 'Nehnuteľný',
    'tooltip.clear': 'čistý majetok = celkový majetok - záväzky',
    total: 'Celkový majetok',
  },
  household: {
    'actions.child.add': 'Pridať dieťa',
    'actions.employement.find': 'Nájsť zamestnanie',
    'actions.independency': 'Osamostatniť sa',
    'actions.partner.add': 'Pridať manžela/ku',
    'child.added': 'Dieťa bolo úspešne pridané',
    'child.placeholder.name': 'Zadajte meno dieťaťa',
    children: 'Deti',
    'independency.confirm': 'Naozaj si prajete osamostatniť dieťa',
    'partner.added': 'Partner bol úspešne pridaný',
    'partner.employment.added': 'Zamestnanie bolo úspešne pridané',
    'partner.label': 'Manžel/ka:',
    'partner.placeholder.name': 'Zadajte meno manžela/ky',
    'partnerEmployment.modal.title': 'Nové zamestnanie',
  },
  investments: {
    'actions.buy': 'Kúpiť podielové listy',
    'actions.sell': 'Predať podielové listy',
    'average.purchase.price': 'Priemerná obstarávacia hodnota podielu',
    averagePurchasePrice: 'Priemerná nákupná cena PL (Iuro/KS)',
    buy: 'kúpiť',
    'buy/sell': 'Nákup/Predaj',
    'choose.mutual.fund': 'Vybrať podielový fond',
    'current.fund.efficiency': 'Aktuálna výkonnosť fondu',
    'current.fund.value': 'Aktuálna hodnota investície',
    details: 'detaily',
    'entry.fee': 'Vstupné poplatky',
    'exit.fee': 'Výstupné poplatky',
    fee: 'Poplatok',
    'final.volume': 'Celkový počet podielových listov',
    'fund.efficiency': 'Výkonnosť fondu',
    'fund.volatility': 'Volatilita',
    instructions: 'pokyny',
    instructionsTitle: 'Zoznam pokynov',
    'invest.amount': 'Výška investície',
    'invest.day.buy': 'Deň nákupu',
    'invest.day.sell': 'Deň predaja',
    'invest.estimatedFundValue': 'Odhadovaná hodnota podielových listov',
    'invest.estimatedFundValueNetto': 'Čistá odhadovaná vyplatená čiastka',
    'invest.volume': 'Počet podielových listov',
    investAmount: 'Celková investovaná suma',
    'investVolume.count': 'Počet podielových listov (KS)',
    'main.information': 'Základné údaje',
    'management.fee': 'Poplatky za správu',
    'minimal.deposit': 'Minimálny vklad',
    'mutual.fund': 'Podielový fond',
    'mutualFund.transaction.buy.edit.title': 'Úprava pokynu pre nákup podielových listov',
    'mutualFund.transaction.buy.title': 'Nákup podielových listov',
    'mutualFund.transaction.sell.edit.title': 'Úprava pokynu pre predaj podielových listov',
    'mutualFund.transaction.sell.title': 'Predaj podielových listov',
    'mutualFund.validation.minimalDeposit': 'Minimálny vklad je {{value}} {{currency}}',
    'my.investment': 'Moja investícia',
    'my.mutual.funds': 'Moje podielové fondy',
    name: 'Názov podielového fondu',
    'net.worth': 'Čistá hodnota majetku',
    'overview.title': 'Vyberte si zo zoznamu investičných produktov',
    'price.change': 'Zmena ceny PL od minulého mesiaca',
    rentability: 'Výnosnosť',
    'repeat.count': 'Počet opakovaní investície',
    'repeat.investment': 'Opakovať investíciu',
    'repeat.rounds': 'Počet kôl opakovania',
    'requested.amount': 'Požadovaná suma',
    sell: 'predať',
    'share.price': 'Cena jedného podielového listu',
    sharePrice: 'Aktuálna cena PL',
    'table.transaction.buy': 'Nákup',
    'table.transaction.sell': 'Predaj',
    'total.profit': 'Celkový výnos',
    transactions: 'transakcie',
    volatility: 'Úroveň rizika',
  },
  lifeQuality: {
    'current.value': 'Aktuálna kvalita života',
    'relative.current.value': 'Aktuálna kvalita života (relatívne dáta)',
    'relative.lifeQualityImpact': 'Vplyv na kvalitu života (relatívne dáta)',
    title: 'Kvalita života: {{value}} b',
  },
  living: {
    'actions.manageMortgage': 'Vybaviť hypotéku',
  },
  login: {
    backToLogin: 'Naspäť na prihlásenie',
    forgotPassword: 'Zabudli ste heslo ?',
    recaptchaInfo:
      'Táto stránka je chránená systémom reCAPTCHA a platia pre ňu <a class="grecaptcha-terms-link" href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">Zásady ochrany osobných údajov</a> a <a href="https://policies.google.com/terms"  class="grecaptcha-terms-link" rel="noopener noreferrer" target="_blank">Zmluvné podmienky</a> spoločnosti Google.',
    register: 'Registrovať sa',
    signIn: 'Prihlásiť sa',
  },
  mortgage: {
    'actions.sign': 'Podpísať zmluvy',
    ageLimitExceeded: 'Hypotekárny úver je potrebné splatiť do veku {{ageMax}} rokov.',
    amount: 'Výška hypotéky',
    calculateMortgages: 'Vypočítať dostupné hypotekárne úvery',
    'funds.limitExceed': 'Na zvolenom účte nemáte dostatok prostriedkov',
    'initial.date': 'Začiatok splácania úveru',
    limitExceeded: 'Výška úveru je nad váš limit',
    maturityPeriod: 'Dĺžka splácania',
    maxMonthlyPayment: 'Maximálna možná výška splátky',
    'monthly.payment.amount': 'Výška mesačnej splátky',
    'overview.title': 'Prehľad dostupných hypoték',
    ownFunds: 'Výška vlastných prostriedkov',
    paymentSource: 'Zdroj platby',
    'property.price': 'Cena nehnuteľnosti',
    'property.title': 'Nehnuteľnosť',
    realizationDay: 'Deň realizácie platby',
    refinance: {
      'attr.refinance': 'Refinancovať zvyšok úveru',
      description: 'Suma zostatku hypotéky',
      limit: 'Výška vlastných prostriedkov presahuje výšku úveru',
      maxMonthlyPaymentLimit: 'Prekročili ste maximálnu výšku splátky',
      newMortgageAmount: 'Výška novej hypotéky',
      oldMortgageAmount: 'Výška hypotéky na refinancovanie',
      title: 'Koniec fixačného obdobia',
      titleDescription: 'Splatím úver alebo jeho časť vlastnými prostriedkami',
    },
    'sign.failed': 'Hypotekárny úver nebolo možné priradiť',
    'sign.success': 'Hypotekárny úver bol priradený',
    'table.attr.fixation': 'Obdobie fixácie',
    'table.attr.fixationEnd': 'Dátum konca fixácie',
    'table.attr.interest': 'Úrok',
    'table.attr.maturityPeriod': 'Dĺžka splácania',
    'table.attr.monthlyPayment': 'Výška splátky',
    'table.attr.mortgageAmount': 'Výška hypotéky',
    'table.attr.mortgageEnd': 'Dátum splatenia úveru',
    'table.attr.name': 'Názov úveru',
    'table.attr.startingFee': 'Vstupný poplatok',
    'user.profile.livingWage': 'Životné minimum',
    'user.profile.payments': 'Mesačné splátky',
    'user.profile.salary': 'Mesačný príjem',
    'user.profile.title': 'Profil žiadateľa',
    validations: {
      maturityPeriodLimits: 'Zadaná hodnota musí byť medzi 4-30',
    },
  },
  object: {
    add: 'Pridať objekt',
    'age.conflicting.objects': 'Následné objekty nie je možné vytvoriť z dôvodu vekového limitu:',
    assetsImpact: 'Vplyv na majetok',
    'attr.actualValue': 'Aktuálna hodnota',
    'attr.assetsImpactMaxRounds': 'Maximálný počet kôl použiteľnosti (od nadobudnutia)',
    'attr.assetsImpactOneTime': 'Obstarávacia cena',
    'attr.assetsImpactPeriodicalActive': 'Náklady na používanie (mesačné pri využívaní)',
    'attr.assetsImpactPeriodicalPassive': 'Udržiavacie náklady (mesačné pri nevyužívaní)',
    'attr.automaticRenewal': 'Automatické obnovenie',
    'attr.code': 'Kategória',
    'attr.customDescription': 'Vlastný popis',
    'attr.customName': 'Vlastné pomenovanie',
    'attr.description': 'Popis',
    'attr.discardmentRound': 'Kolo zrušenia',
    'attr.fullyUtilisedRound': 'Kolo max. využitia',
    'attr.initialValue': 'Počiatočná hodnota',
    'attr.interest.pa': 'Úrok p.a.',
    'attr.interestPeriod': 'Pripisovanie úrokov',
    'attr.interestPositive': 'Úrok',
    'attr.lifeQualityImpactAbsence': 'Pri absencii',
    'attr.lifeQualityImpactMaxCount': 'Maximálný počet objektov',
    'attr.lifeQualityImpactMaxRounds': 'Maximálný počet kôl',
    'attr.lifeQualityImpactOneTime': 'Jednorázový',
    'attr.lifeQualityImpactPeriodical': 'Periodický (mesačný)',
    'attr.lifespan': 'Životnosť do',
    'attr.maturityPeriod': 'Doba splatnosti',
    'attr.max.rounds': 'Celková využiteľnosť',
    'attr.name': 'Pomenovanie',
    'attr.objectAttributes': 'Voliteľné vlastnosti',
    'attr.parentCategory': 'Kategória',
    'attr.principalMin': 'Minimálny vklad',
    'attr.priority': 'Priorita',
    'attr.procurementRound': 'Kolo obstarania',
    'attr.realizationDay': 'Deň realizácie platby',
    'attr.relatedFinancialObject': 'Platobný účet',
    'attr.status': 'Stav objektu',
    'attr.timeRequired': 'Mesačná časová náročnosť',
    'attr.used': 'Využitý',
    'attr.utilisationMaxRounds': 'Maximálny počet kôl využívania',
    'attr.utilisationMinRounds': 'Počet kôl pre zabehnutie/čiastočné absolvovanie',
    'attr.utilisationRounds': 'Počet kôl využívania',
    attributes: 'Atribúty objektu',
    'back.to.edits': 'Naspäť k úpravám',
    'delete.conflicted.confirmation': 'Odstrániť objekty a pokračovať',
    detail: 'Detail objektu',
    employed: 'zamestnaný/á',
    'financial.add': 'Pridať finančný objekt',
    'financial.detail': 'Detail finančného objektu',
    independent: 'Osamostatnené dieťa',
    lifeQualityImpact: 'Vplyv na kvalitu života',
    'options.disabled': 'Dostupné len pre osoby staršie ako {{value}} rokov.',
    'placeholder.attributes': 'Objekt nemá definované žiadne atribúty',
    'placeholder.empty': 'Ľutujeme, zatiaľ nevlastníte žiadne objekty.',
    salary: 'mesačný príjem',
    single: 'Slobodný/á',
    unemployed: 'nezamestnaný/á',
  },
  player: {
    'attr.age': 'Vek',
    'attr.email': 'E-mail',
    'attr.holdings': 'Majetok',
    'attr.lastVisitedDate': 'Dátum posledného prístupu',
    'attr.lifeQuality': 'Kvalita života',
    'attr.name': 'Meno',
    'attr.nickName': 'Prezývka',
    'attr.overall': 'Celková úroveň',
    'attr.timeAvailable': 'Dostupný voľný čas',
    'delete.action': 'Odstrániť osobu z časopriestoru',
    'delete.description':
      'Naozaj chcete odstrániť osobu {{value}} z časopriestoru? Táto akcia je nezvratná.',
    'delete.success': 'Osoba bola odstránená',
    'delete.title': 'Odstrániť osobu',
    detail: 'Detail osoby',
  },
  profile: { logout: 'Odhlásiť sa', welcome: 'Vitaj' },
  randomEvent: {
    acquiredObject: {
      add: 'Pridať nadobudnutý objekt',
      table: 'Nadobudnutý objekt',
    },
    add: 'Pridať náhodnú udalosť',
    'attr.absFrom': 'od',
    'attr.absTo': 'do',
    'attr.coeffFrom': 'od (koef)',
    'attr.coeffTo': 'do (koef)',
    'attr.default': 'def',
    'attr.description': 'Popis',
    'attr.executionCount': 'Počet vykonaní',
    'attr.executionMaxCount': 'Max. počet vykonaní',
    'attr.executionMonthPeriodical': 'Mesačne',
    'attr.executionPeriodical': 'Vykonanie každý',
    'attr.executionProbability': 'Pravdepodobnosť vykonania',
    'attr.executionRoundFrom': 'Vykonanie udalosti od',
    'attr.executionRoundTo': 'Vykonanie udalosti do',
    'attr.executionUserAcceptance': 'Odmietnutie osobou',
    'attr.executionYearPeriodical': 'Ročne',
    'attr.expectedUserInput': 'vstup od užívateľa',
    'attr.name': 'Pomenovanie',
    create: 'Pridaj náhodnú udalosť',
    detail: 'Detail náhodnej udalosti',
    financialObject: {
      add: 'Pridaj finančný objekt ovplyvnený náhodnou udalosťou',
      table: 'Finančné objekty ovplyvnené náhodnou udalosťou',
    },
    'header.action.required': 'Náhodne udalosti vyžadujúce akciu',
    'header.history': 'História náhodných udalostí',
    history: {
      title: 'Správa o uskutočnení náhodnej udalosti',
    },
    'message.created': 'Náhodná udalosť bola úspešne vytvorená',
    'message.deleted': 'Náhodná udalosť bola úspešne vymazaná',
    'message.updated': 'Náhodná udalosť bola úspešne aktualizovaná',
    mortgageUserInput: {
      actions: {
        accept: 'Akceptujem ponuku',
        cancel: 'Rozhodnem sa neskôr',
        refinance: 'Chcem splatiť hypotéku alebo jej časť',
      },
      descriptionEnd:
        'Z dôvodu uplynutia doby fixácie môžete bezplatne celú výšku zostávajúcej hypotéky splatiť ku dňu {{date}}.',
      descriptionStart:
        'K dňu {{date}} sa končí fixácia na Vašom hypotekárnom úvere. Môžete v splátkach ďalej pokračovať za daných podmienok:',
      interest: 'nová uroková sadzba: {{interest}}',
      monthlyPayment: 'nová mesačná splátka: {{monthlyPayment}}',
      success: 'Ponuka bola akceptovaná',
      title: 'Oznam o konci obdobia fixácie',
    },
    object: {
      add: 'Pridaj objekt ovplyvnený náhodnou udalosťou',
      'attr.description': 'Popis',
      'attr.lifeQualityImpactOneTime': 'Vplyv na KŽ - jednorázový',
      'attr.lifeQualityImpactPeriodical': 'Vplyv na KŽ - periodický (mesačný)',
      'attr.objectStatus': 'Stav',
      'attr.objectsAffected': 'Ovplyvnené objekty',
      'attr.objectsAffectedType': 'Množstvo',
      'attr.timeRequired': 'Potrebný čas',
      table: 'Objekty ovplyvnené náhodnou udalosťou',
    },
    userInput: {
      helperText: 'Hodnota môže byť od {{from}}{{currency}} do {{to}}{{currency}}',
      title: 'Vyžaduje sa akcia náhodnej udalosti',
    },
  },
  'register.nickName.hint': 'Môže obsahovať iba veľké, malé písmena, čísla, pomlčku, podtržník',
  'register.success':
    'Registráciu dokončite potvrdením odkazu v mailovej správe, ktorá bola odoslaná na: {{email}}',
  savings: {
    'account.create': 'Zriadiť sporiaci účet',
    'account.title': 'Sporiaci účet',
    'actions.sign': 'Podpísať zmluvy',
    'discard.later': 'Zrušiť po najbližšom pripísaní úrokov',
    'discard.now': 'Zrušiť okamžite',
    'realization.day':
      'Deň zriadenia sporiaceho účtu (v tento deň v mesiaci sa budú pripočítavať úroky)',
    'sign.failed': 'Sporiaci účet nebolo možné vytvoriť',
    'sign.success': 'Sporiaci účet bol vytvorený',
  },
  sell: {
    'buyer.found': 'Našiel sa kupca',
    'buyer.notFound': 'Kupca sa nenašiel',
    'buyer.searching': 'Hľadá sa kupca...',
    message: '{{value}} ti ponúka za',
    'notFound.try.later':
      'Momentálne sa nepodarilo nájsť kupcu. Pokus o predaj môžete opakovať neskôr.',
  },
  template: {
    'delete.description': 'Naozaj chcete vymazať túto šablónu časopriestoru ?',
    'delete.success': 'Šablóna bola vymazaná',
    'delete.title': 'Vymazať šablónu',
  },
  term: {
    'account.create': 'Zriadiť termínovaný účet',
    'account.title': 'Termínovaný účet',
    'actions.sign': 'Podpísať zmluvy',
    'realization.day':
      'Deň zriadenia termínovaného účtu (v tento deň v mesiaci sa budú pripočítavať úroky)',
    'sign.failed': 'Termínovaný účet nebolo možné vytvoriť',
    'sign.success': 'Termínovaný účet bol vytvorený',
    'validation.notEnoughFunds':
      'Termínovaný účet nie je možné založiť, na platobnom účte {{accountName}} nie je dostatok prostriedkov',
  },
  time_management: {
    active: 'Táto činnosť sa vykoná v najbližšom kole',
    'delete.confirm': 'Naozaj si prajete odstrániť objekt',
    passive: 'Na túto činnosť nemáte dostatok času',
    time: {
      available: 'Dostupný voľný čas: {{value}} hodín ({{unit}}%)',
      total: 'Celkový fond voľného času: {{value}} hodín',
    },
  },
  timespace: {
    'attr.code': 'Kód',
    'attr.customName': 'Vlastný názov',
    'attr.name': 'Názov',
    'attr.owner': 'Vlastník',
    'attr.procurement.date': 'Dátum obstarania',
    'attr.round': 'Kolo',
    'attr.timespacePersonLastVisitedDate': 'Posledný prístup',
    'attr.type.label': 'Typ',
    'attr.type.private': 'Súkromná',
    'attr.type.public': 'Verejná',
    code: 'Kód časopriestoru',
    'create.button': 'Vytvoriť ',
    'create.from_template': 'Vytvoriť nový časopriestor zo šablóny',
    'create.prototype': 'Vyrobiť šablónu',
    'create.template': 'Vytvoriť novú šablónu',
    defaultPaymentMethod: 'Primárny platobný prostriedok',
    'delete.button': 'Vymazať časopriestor',
    'delete.description': 'Naozaj chcete vymazať tento časopriestor ?',
    'delete.success': 'Časopriestor bol vymazaný',
    'delete.warning': 'Táto akcia je nenávratná!',
    description: 'Popis priestoru',
    detail: 'Detail šablóny',
    goalsDescription: 'Popis cieľov',
    interpersonalizeBlock: 'Počas interpersonalizácie nie je možné robiť žiadne akcie.',
    'join.action': 'Vstúpiť',
    'join.button': 'Pridaj ma do časopriestoru',
    'join.error': 'Nepodarilo sa pripojiť do časopriestoru',
    'join.success': 'Vstúpili ste do časopriestoru',
    'join.title': 'Zadajte kod časopriestoru',
    label: 'Časopriestor',
    'leave.action': 'Opustiť',
    'leave.button': 'Opustiť časopriestor',
    'leave.description': 'POZOR ! Ak opustíte časopriestor, už nie je možné sa doň vrátiť.',
    'leave.success': 'Opustili ste časopriestor',
    'leave.title': 'Naozaj chcete opustiť časopriestor ?',
    management: 'Správa časopriestoru',
    'message.created': 'Časopriestor úspešne vytvorený',
    'message.updated': 'Zmeny boli uložené',
    name: 'Názov',
    'other.settings': 'Ostatné nastavenia',
    overview: 'Časopriestory',
    'personInitialAge.prefix': 'Vek postavy',
    'personInitialAge.suffix': 'rokov',
    players: 'Osoby',
    randomEvents: 'Náhodné udalosti',
    round: 'Kolo',
    'roundRunning.warning': 'Prebieha presun do ďalšieho kola. Počkajte prosím na dokončenie.',
    roundTransitionBlock: 'Počas vykonávania prechodu kola nie je možné robiť žiadne akcie.',
    'roundsPerStep.prefix': 'Posun o',
    'roundsPerStep.suffix': 'v jednom kroku',
    setDefaultPaymentMethod: 'Nastaviť primárny platobný prostriedok',
    'template.created': 'Šablóna bola úspešne vytvorená',
    'template.updated': 'Šablóna bola úspešne aktualizovaná',
    templates: 'Šablóny',
    timespaceAvailableObjects: 'Objekty dostupné v časopriestore',
    timespacePersonFinancialObjects: 'Zoznam finančných objektov postavy',
    timespacePersonObjects: 'Zoznam objektov postavy',
  },
  user: {
    attr: {
      fullName: 'Meno, Priezvisko',
      lastVisitedDate: 'Posledný prístup',
      lifeQuality: 'Kvalita života',
      nickName: 'Prezývka',
      overall: 'Celková úroveň',
    },
    confirmPassword: 'Zopakujte heslo',
    email: 'Emailová adresa',
    firstName: 'Meno',
    grade: 'Ročník',
    interpersonalize: {
      cancel: 'Interpersonalizácia úspešne ukončená',
      success: 'Interpersonalizácia úspešná',
    },
    lastName: 'Priezvisko',
    nickName: 'Prihlasovacie meno',
    nickNameOrEmail: 'Prihlasovacie meno alebo e-mail',
    password: 'Heslo',
    school: 'Škola',
    teacherFirstName: 'Meno učiteľa',
    teacherLastName: 'Priezvisko učiteľa',
    'verification.success': 'Váš účet bol úspešne verifikovaný',
  },
  validations: {
    'age.adult': 'Zadaná hodnota musí byť medzi 18-99',
    between1and12: 'Zadaná hodnota musí byť medzi 1-12',
    between1and30: 'Zadaná hodnota musí byť medzi 1-30',
    customName: 'Minimálna dĺžka sú 3 znaky',
    exactly10: 'Číslo musí byť desaťmiestne',
    exactly4: 'Číslo musí byť štvormiestne',
    'invalid.email': 'Email nemá správny formát',
    max50: 'Zadaný text môže obsahovať maximálne 50 znakov',
    moreThen0: 'Číslo musí byť väčšie ako 0',
    moreThen1: 'Číslo musí byť väčšie ako 1',
    password: 'Minimálna dĺžka hesla je 8 znakov',
    passwordMatch: 'Heslá sa musia zhodovať',
    positiveNumber: 'Číslo musí byť väčšie alebo rovné 0',
    realizationMonth: 'Zadaný mesiac nie je validný',
    required: 'Tento atribút je povinný',
    signMismatch: 'Všetky hodnoty musia byť kladné alebo záporné.',
  },
};
