import { isNil } from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  Box,
  LinearProgress,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Skeleton,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip';
import { DisplaySettings, ExpandLess, ExpandMore, InfoOutlined, Logout } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { UserFragment, useMeQuery, useMyTimespacePersonQuery } from 'Generated/graphql-hooks';
import { ScreenPaths } from 'Config';
import { ABOUT_PROJECT_URL } from 'Config/Constants';
import { useModal } from 'Lib/Hooks';
import { calculateLevelFromExperienceScore, calculateLevelThreshold } from 'Lib/Helpers/Level';
import { isInterpersonalizedView } from 'Lib/Helpers/Session';
import { ProjectVersionModal } from './Generic';
import { PlayerTooltipInfo } from './Player';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 150,
  },
});

const formatName = (user?: UserFragment | null) =>
  `${user?.firstName ?? ''} ${(user?.lastName ?? '')?.slice(0, 1)}`;

export default function ProfileMenuItem(): JSX.Element {
  const { timespaceId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: personData, loading } = useMyTimespacePersonQuery({
    variables: {
      timespaceId: Number(timespaceId),
    },
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleNavigate = useCallback(() => navigate(ScreenPaths.Logout), [navigate]);
  const round = personData?.myTimespacePerson?.timespace?.round;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const {
    handleClose: handleVersionModalClose,
    handleOpen: handleVersionModalOpen,
    isOpen: isVersionModalOpen,
  } = useModal();

  const { data: meData, loading: meLoading } = useMeQuery();
  const level = calculateLevelFromExperienceScore(meData?.me?.experienceScore ?? 0);
  const currentLevelProgress =
    ((meData?.me?.experienceScore ?? 0) - calculateLevelThreshold(level - 1)) /
    (calculateLevelThreshold(level) - calculateLevelThreshold(level - 1));

  return (
    <Box display="flex" position="relative">
      {loading || meLoading ? (
        <Skeleton height={60} width={180} />
      ) : (
        <div>
          <Box alignItems="center" display="flex" justifyContent="center">
            <CustomWidthTooltip
              title={
                <PlayerTooltipInfo
                  experienceScore={meData?.me?.experienceScore ?? 0}
                  level={level}
                />
              }>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mr={4}
                px={1}>
                <Typography gutterBottom variant="body2">
                  {t('game.level', { replace: { value: level + 1 } })}
                </Typography>
                <LinearProgress
                  color="inherit"
                  sx={{ borderRadius: 25, height: 10, paddingX: 7 }}
                  value={currentLevelProgress * 100}
                  variant="determinate"
                />
              </Box>
            </CustomWidthTooltip>

            <Avatar alt="User" src="https://picsum.photos/200" />

            <Box
              alignSelf="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              px={1}>
              <Typography gutterBottom={false} lineHeight={1.4} variant="body1">{`${formatName(
                personData?.myTimespacePerson?.user || personData?.me,
              )}`}</Typography>
              {!isNil(round) && !Number.isNaN(round) ? (
                <Typography fontWeight="bold" gutterBottom={false} variant="caption">
                  {getHumanDateFromRound(round || 0)}
                </Typography>
              ) : null}
            </Box>
            <IconButton color="info" onClick={handleClick}>
              {open ? (
                <ExpandLess style={{ color: 'white' }} />
              ) : (
                <ExpandMore style={{ color: 'white' }} />
              )}
            </IconButton>
          </Box>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            id={id}
            onClose={handleClose}
            open={open}>
            <Box component={List} p={0}>
              <Link
                color="inherit"
                href={ABOUT_PROJECT_URL}
                rel="noopener noreferrer"
                target="_blank"
                underline="none">
                <ListItemButton>
                  <ListItemIcon>
                    <InfoOutlined />
                  </ListItemIcon>
                  <ListItemText>{t('global.about_project')}</ListItemText>
                </ListItemButton>
              </Link>

              <ListItemButton onClick={handleVersionModalOpen}>
                <ListItemIcon>
                  <DisplaySettings />
                </ListItemIcon>
                <ListItemText>{t('global.project_version')}</ListItemText>
              </ListItemButton>

              {!isInterpersonalizedView() ? (
                <ListItemButton onClick={handleNavigate}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>{t('profile.logout')}</ListItemText>
                </ListItemButton>
              ) : (
                <></>
              )}
            </Box>
          </Popover>
        </div>
      )}
      <ProjectVersionModal onClose={handleVersionModalClose} open={isVersionModalOpen} />
    </Box>
  );
}
