import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  onClose: () => void;
}

export default function ModalCloseButton({ onClose }: Props) {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        color: (theme) => theme.palette.grey[500],
        position: 'absolute',
        right: 8,
        top: 8,
      }}>
      <CloseIcon />
    </IconButton>
  );
}
