import formatDate from 'date-fns/format';
import { t } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Edit, Input } from '@mui/icons-material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import RemoveTemplateModalButton from 'Components/Timespace/RemoveTemplateModal';
import { User, UserFragment } from 'Generated/graphql-hooks';
import { ScreenPaths, TimespacePaths } from 'Config';

export const overviewColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('timespace.attr.code'),
    name: 'code',
    options: {
      sortThirdClickReset: true,
    },
  },
  {
    label: t('timespace.attr.name'),
    name: 'name',
    options: {
      sortThirdClickReset: true,
    },
  },
  {
    label: t('timespace.attr.round'),
    name: 'round',
    options: {
      sortThirdClickReset: true,
    },
  },
  {
    label: t('timespace.attr.timespacePersonLastVisitedDate'),
    name: 'timespacePersonLastVisitedDate',
    options: {
      customBodyRender: (value: string) => formatDate(new Date(value), 'dd.MM.yyyy HH:mm:ss'),
    },
  },
  {
    label: t('timespace.attr.owner'),
    name: 'owner',
    options: {
      customBodyRender: (value: UserFragment) =>
        `${value?.firstName || ''} ${value?.lastName || ''}`,

      sort: false,
    },
  },
  {
    label: t('global.table.actions'),
    name: 'actions',
    options: {
      customBodyRender: (_, { rowData }) => {
        return (
          <>
            <Tooltip placement="top" title={t('timespace.join.action').toString()}>
              <IconButton
                color="primary"
                component={RouterLink}
                to={`${TimespacePaths.Root}/${rowData[0]}`}>
                <Input />
              </IconButton>
            </Tooltip>
          </>
        );
      },

      sort: false,
    },
  },
];

export const templateModalColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('timespace.attr.owner'),
    name: 'owner',
    options: {
      customBodyRender: (value: UserFragment) => `${value.firstName || ''} ${value.lastName || ''}`,
    },
  },
  {
    label: t('timespace.attr.name'),
    name: 'name',
  },
  {
    label: t('timespace.attr.code'),
    name: 'code',
  },
  {
    label: t('global.table.actions'),
    name: 'actions',
    options: {
      customBodyRender: (_, { rowData }) => {
        return (
          <>
            <Tooltip placement="top" title={t('global.actions.edit').toString()}>
              <IconButton
                color="warning"
                component={RouterLink}
                to={`${ScreenPaths.Create}/${rowData[0]}`}>
                <Edit />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  },
];

export const templateColumns = (userId?: string): MUIDataTableColumnDef[] => [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('timespace.name'),
    name: 'name',
  },
  {
    label: t('timespace.description'),
    name: 'description',
  },
  {
    label: t('timespace.attr.owner'),
    name: 'owner',
    options: {
      customBodyRender: (value: UserFragment) => `${value.firstName || ''} ${value.lastName || ''}`,
    },
  },
  {
    label: t('timespace.attr.type.label'),
    name: 'isPublic',
    options: {
      customBodyRender: (value: boolean) =>
        t(value ? 'timespace.attr.type.public' : 'timespace.attr.type.private'),
    },
  },
  {
    label: t('global.table.actions'),
    name: 'actions',
    options: {
      customBodyRender: (_, { rowData }) => {
        const owner = rowData[3] as User;
        const isPublic = rowData[4];
        return isPublic && owner.id !== userId ? null : (
          <Box display="flex">
            <Tooltip placement="top" title={t('global.actions.edit').toString()}>
              <IconButton
                color="warning"
                component={RouterLink}
                to={`${ScreenPaths.Edit}/${rowData[0]}`}>
                <Edit />
              </IconButton>
            </Tooltip>
            <RemoveTemplateModalButton timespaceId={rowData[0]} />
          </Box>
        );
      },
    },
  },
];
