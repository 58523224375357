import {
  Maybe,
  RandomEventFinancialObject,
  RandomEventObjectAttribute,
} from 'Generated/graphql-hooks';
import { RandomEventObjectAttributeInput } from 'Models/RandomEventObjectAttributeInput';

export class RandomEventFinancialObjectInput implements DeepNullable<RandomEventFinancialObject> {
  __typename?: DeepNullable<'RandomEventFinancialObject' | undefined> | null;
  actualValue?: DeepNullable<Maybe<RandomEventObjectAttribute> | undefined> =
    new RandomEventObjectAttributeInput();
  description?: DeepNullable<Maybe<string> | undefined>;
  id: string | null | undefined;
  objectsAffected?: DeepNullable<Maybe<string> | undefined>;
  objectsAffectedType?: DeepNullable<Maybe<number> | undefined> = -1;
}
