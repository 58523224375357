import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FinancialObject,
  Object,
  TimespacePerson,
  TimespacePersonObjectAttribute,
  namedOperations,
  useCreateTimespacePersonFinancialObjectMutation,
  useCreateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import useTimespacePersonFinancialInfo from 'Lib/Hooks/useTimespacePersonFinancialInfo';
import { MortgageRequestSchema } from 'Config/Validations';
import {
  TimespacePersonFinancialObject,
  TimespacePersonFinancialObjectCreateInput,
  TimespacePersonObject,
  TimespacePersonObjectCreateInput,
} from 'Models';
import { GameContext } from 'Context';
import { useErrorHandler } from 'Lib/Hooks';
import { ModalCloseButton } from 'Components/Generic';
import { Constants } from 'Config';
import {
  calculateAnnualIncome,
  calculateMaxMortgage,
  calculateMonthlyPayment,
  calculatePrincipalMaximum,
} from './utils';
import MortgageOptionsTable from './OptionsTable';
import MortgageRequestSection from './RequestSection';
import MortgagePersonSection from './PersonSection';

interface Props {
  finalPrice?: number | null;
  onClose: () => void;
  open: boolean;
  property: Object;
  propertyAttributes?: TimespacePersonObjectAttribute[];
}

export interface FormData {
  amount: number;
  livingWage: number;
  maturityPeriod: number;
  maxMonthlyPayment: number;
  maxMortgageAmount: number;
  monthlyIncome: number;
  monthlyPayments: number;
  mortgageFinalAmount: number;
  mortgageFinancialObject: FinancialObject | null;
  ownFunds: number;
  paymentSource: FinancialObject | null;
  propertyPrice: number;
  realizationDay: number;
  timespacePerson?: TimespacePerson | null;
}

export default function MortgageOverviewModal({
  finalPrice,
  onClose,
  open,
  property,
  propertyAttributes,
}: Props) {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [mortgageRequestParams, setMortgageRequestParams] = useState<{
    amount: number;
    maturityPeriod: number;
  }>();
  const { timespacePerson } = useContext(GameContext);
  const [createTimespacePersonFinancialObject] = useCreateTimespacePersonFinancialObjectMutation();
  const [createTimespacePersonObject] = useCreateTimespacePersonObjectMutation();
  const {
    data: { livingWage, maxMonthlyPayment, monthlyIncome, monthlyPayments, partnerIncome },
    loading: userFinancialDataLoading,
    objects: { financialObjects },
  } = useTimespacePersonFinancialInfo({
    timespaceId,
    timespacePerson,
  });

  const familyIncome = monthlyIncome + partnerIncome;
  const objectPrice = Math.abs(finalPrice ?? 0);
  const attributes = propertyAttributes;
  const maxMortgageAmount = calculateMaxMortgage({
    annualIncome: calculateAnnualIncome(familyIncome),
    principalMaximum: calculatePrincipalMaximum(financialObjects ?? []),
    propertyPrice: objectPrice,
  });

  const handleFormikSubmit = async ({
    maturityPeriod,
    mortgageFinalAmount,
    mortgageFinancialObject,
    paymentSource,
    propertyPrice,
    realizationDay,
  }: FormData) => {
    try {
      if (!mortgageFinancialObject) {
        throw new Error('Financial object not set');
      }

      const ownFundsFinal = propertyPrice - mortgageFinalAmount;
      const paymentPersonSource = paymentSource as TimespacePersonFinancialObject;

      if (ownFundsFinal > Number(paymentPersonSource?.actualValue ?? 0)) {
        enqueueSnackbar(t('mortgage.funds.limitExceed'), { variant: 'error' });
        return;
      }

      const tpo = await createTimespacePersonObject({
        refetchQueries: [namedOperations.Query.TimespacePersonObjects],
        variables: {
          data: new TimespacePersonObjectCreateInput(
            {
              ...new TimespacePersonObject(property),
              actualValue: objectPrice,
              assetsImpactOneTime: objectPrice * -1,
              financialObjectOneTime: paymentSource
                ? (paymentSource as TimespacePersonFinancialObject)
                : undefined,
              initialValue: objectPrice,
              realizationDay,
              timespacePersonObjectAttributes: attributes,
            },
            timespaceId,
            timespacePerson.id,
          ),
        },
      });

      const tpfo = await createTimespacePersonFinancialObject({
        variables: {
          data: {
            ...new TimespacePersonFinancialObjectCreateInput(
              {
                ...new TimespacePersonFinancialObject(mortgageFinancialObject),
                maturityPeriod: maturityPeriod * 12,
                monthlyPayment: calculateMonthlyPayment({
                  financialObject: mortgageFinancialObject,
                  maturityPeriod: maturityPeriod * 12,
                  mortgageAmount: mortgageFinalAmount,
                  timespace: timespacePerson.timespace,
                }),
                nextFixation:
                  (mortgageFinancialObject.fixationPeriod ?? 0) +
                  (timespacePerson.timespace?.round ?? 0),
              },
              timespaceId,
              timespacePerson.id,
            ),
            initialValue: -mortgageFinalAmount,
            priority: tpo.data?.createTimespacePersonObject?.priority,
            realizationDay,
          },
        },
      });

      if (
        tpo.data?.createTimespacePersonObject &&
        tpfo.data?.createTimespacePersonFinancialObject
      ) {
        enqueueSnackbar(t('mortgage.sign.success'), { variant: 'success' });
        onClose();
      } else {
        handleError(tpo?.errors || tpfo?.errors, { ageMin: property?.ageMin });
      }
    } catch {
      enqueueSnackbar(t('mortgage.sign.failed'), { variant: 'error' });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>
        {t('mortgage.overview.title')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      {userFinancialDataLoading ? (
        <Box display="flex" justifyContent="center" py={5}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Formik<FormData>
          validateOnMount
          initialTouched={{ amount: true, maturityPeriod: true, ownFunds: true }}
          initialValues={{
            amount: maxMortgageAmount,
            livingWage,
            maturityPeriod: 30,
            maxMonthlyPayment,
            maxMortgageAmount,
            monthlyIncome: familyIncome,
            monthlyPayments,
            mortgageFinalAmount: maxMortgageAmount,
            mortgageFinancialObject: null,
            ownFunds: objectPrice - maxMortgageAmount,
            paymentSource: null,
            propertyPrice: objectPrice,
            realizationDay: 1,
            timespacePerson,
          }}
          onSubmit={handleFormikSubmit}
          validationSchema={MortgageRequestSchema}>
          {({ handleSubmit, isSubmitting, isValid, setFieldValue, submitForm, values }) => {
            const isAmountConditionFulfilled = values.amount <= maxMortgageAmount;
            const isAgeConditionFulfilled =
              timespacePerson.age &&
              timespacePerson.age / 12 + values.maturityPeriod < Constants.MORTGAGE_MAX_AGE;
            const isMortgageAvailable = isAmountConditionFulfilled && isAgeConditionFulfilled;

            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <MortgagePersonSection />
                      <MortgageRequestSection />
                      <Button
                        disabled={!isMortgageAvailable}
                        onClick={() => {
                          setFieldValue('mortgageFinancialObject', null);
                          setMortgageRequestParams({
                            amount: values.amount,
                            maturityPeriod: values.maturityPeriod,
                          });
                        }}
                        sx={{ marginY: 4 }}
                        variant="outlined">
                        {t('mortgage.calculateMortgages')}
                      </Button>
                      {mortgageRequestParams !== undefined ? (
                        <MortgageOptionsTable
                          maturityPeriod={mortgageRequestParams.maturityPeriod}
                          maxMonthlyPayment={values.maxMonthlyPayment}
                          mortgageAmount={mortgageRequestParams.amount}
                          onRowSelected={(item) => {
                            if (item) {
                              setFieldValue('mortgageFinalAmount', item.mortgageAmount);
                            }
                            setFieldValue('mortgageFinancialObject', item?.financialObject ?? null);
                          }}
                          refinance={false}
                          value={values.mortgageFinancialObject}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} variant="outlined">
                    {t('global.actions.cancel')}
                  </Button>
                  <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="contained">
                    <span>{t('mortgage.actions.sign')}</span>
                  </LoadingButton>
                </DialogActions>
              </Box>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
}
