import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { usePagination } from 'Lib/Hooks';
import {
  MutualFundInvestorStatusFragment,
  OrderDirection,
  useMutualFundTransactionsQuery,
} from 'Generated/graphql-hooks';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { EnhancedDataTable, Error, SkeletonLoader } from 'Components';

interface Props {
  mutualFundInvestorStatus?: MutualFundInvestorStatusFragment | null;
}

export default function MutualFundTransactionTable({ mutualFundInvestorStatus }: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { skip, take, ...pagination } = usePagination();

  const { data, error, loading } = useMutualFundTransactionsQuery({
    variables: {
      orderBy: [
        { round: OrderDirection.Desc },
        { day: OrderDirection.Desc },
        { id: OrderDirection.Desc },
      ],
      skip,
      take,
      where: {
        AND: [
          { person: { id: { equals: timespacePerson?.id } } },
          { repeatRounds: { equals: 0 } },
          {
            mutualFundStatus: {
              fund: {
                code: {
                  equals: mutualFundInvestorStatus?.mutualFundStatus?.fund?.code,
                },
              },
            },
          },
        ],
      },
    },
  });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'round',
      options: { display: 'excluded', sort: false },
    },
    {
      label: t('bank.transaction.attr.day'),
      name: 'day',
      options: {
        customBodyRender: (day: string, meta: MUIDataTableMeta) => (
          <Typography variant="body2">{getHumanDateFromRound(meta.rowData[0], day)}</Typography>
        ),

        sort: false,
      },
    },
    {
      label: t('investments.buy/sell'),
      name: 'amountRequested',
      options: {
        customBodyRender: (value: number) => {
          return value ? (
            <Typography variant="body2">{t('investments.table.transaction.buy')}</Typography>
          ) : (
            <Typography variant="body2">{t('investments.table.transaction.sell')}</Typography>
          );
        },
      },
    },
    {
      label: t('bank.transaction.attr.amount'),
      name: 'amount',
      options: {
        customBodyRender: (value: string) => {
          return (
            value && (
              <Typography noWrap color={Number(value) > 0 ? 'green' : 'error'} variant="body2">
                {formatPrice(value, false)}
              </Typography>
            )
          );
        },

        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
    {
      label: t('investments.requested.amount'),
      name: 'amountRequested',
      options: {
        customBodyRender: (value: string) => {
          return value ? (
            <Typography noWrap variant="body2">
              {formatPrice(value, false)}
            </Typography>
          ) : (
            '-'
          );
        },

        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
    {
      label: t('investments.invest.volume'),
      name: 'volume',
      options: {
        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
    {
      label: t('investments.repeat.rounds'),
      name: 'repeatRounds',
      options: {
        customBodyRender: (value: string) => {
          return value ? value : '-';
        },

        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
    {
      label: t('investments.fee'),
      name: 'fee',
      options: {
        customBodyRender: (value: string) => {
          return value ? (
            <Typography noWrap variant="body2">
              {formatPrice(value, false)}
            </Typography>
          ) : (
            '-'
          );
        },

        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
    {
      label: t('investments.final.volume'),
      name: 'fund.totalVolume',
      options: {
        customBodyRender: (value: string) => {
          return value ? value : '-';
        },

        setCellProps: () => ({ align: 'right' }),
        sort: false,
      },
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <EnhancedDataTable
      columns={columns}
      data={data?.mutualFundTransactions || []}
      options={
        {
          ...pagination,
          count: data?.count,
          setRowProps: (row, dataIndex) => {
            return {
              onMouseEnter: () => {
                console.log(data?.mutualFundTransactions && data.mutualFundTransactions[dataIndex]);
              },
            };
          },
          textLabels: {
            body: {
              noMatch: loading ? <SkeletonLoader /> : t('bank.transaction.placeholder'),
            },
          },
        } as MUIDataTableOptions
      }
      title={<Typography variant="h6">{t('bank.transaction.list')}</Typography>}
    />
  );
}
