import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { TimespacePersonObject, TimespacePersonObjectCreateInput } from 'Models';
import {
  ErrorFallbackPlaceholder,
  ModalCloseButton,
  ObjectModal,
  RadioGroupFormik,
} from 'Components';
import {
  OrderDirection,
  namedOperations,
  useCreateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { useErrorHandler, useGetTimespaceAvailableObjects, useModal } from 'Lib/Hooks';
import { HouseholdPartnerEmploymentSchema } from 'Config/Validations';
import { HouseholdObjectCodes } from 'Types/Global';

interface Props {
  onClose: () => void;
  open: boolean;
}

interface FormValues {
  selectedEmploymentObjectCode: string | null;
}

export default function HouseholdPartnerEmploymentAddModal({ onClose, open }: Props) {
  const { handleError } = useErrorHandler();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    data: employmentObjectsData,
    error,
    loading,
  } = useGetTimespaceAvailableObjects({
    skip: !open,
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      where: {
        AND: [
          { code: { startsWith: HouseholdObjectCodes.EMPLOYMENT } },
          { isAbstract: { equals: false } },
        ],
      },
    },
  });

  const {
    handleClose: handleDetailModalClose,
    handleOpen: handleDetailModalOpen,
    isOpen: isDetailModalOpen,
    selected: selectedEmploymentObject,
  } = useModal<TimespacePersonObject>();

  const [createTimespacePersonObject] = useCreateTimespacePersonObjectMutation();

  const handleFormikSubmit = useCallback(
    async ({ selectedEmploymentObjectCode }: FormValues) => {
      const selectedObject = employmentObjectsData?.objects?.find(
        ({ code }) => code === selectedEmploymentObjectCode,
      );

      if (!selectedObject) {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
        return;
      }

      const { data, errors } = await createTimespacePersonObject({
        refetchQueries: [namedOperations.Query.TimespacePersonHouseholdObjects],
        variables: {
          data: new TimespacePersonObjectCreateInput(
            new TimespacePersonObject(selectedObject),
            timespaceId,
            timespacePerson.id,
          ),
        },
      });

      if (data?.createTimespacePersonObject) {
        enqueueSnackbar(t('household.partner.employment.added'), { variant: 'success' });
        onClose();
      } else {
        handleError(errors);
      }
    },
    [onClose, employmentObjectsData, enqueueSnackbar, t],
  );

  const renderRadioOptionAdornment = useCallback(
    ({ value }) => (
      <Tooltip placement="top" title={t('global.actions.detail').toString()}>
        <IconButton
          onClick={() => {
            const selectedObject = employmentObjectsData?.objects?.find(
              ({ code }) => code === value,
            );

            if (selectedObject) {
              handleDetailModalOpen(new TimespacePersonObject(selectedObject));
            }
          }}>
          <Visibility />
        </IconButton>
      </Tooltip>
    ),
    [employmentObjectsData],
  );

  const options =
    employmentObjectsData?.objects?.map(({ code, name }, index) => ({
      label: name ?? `option_${index}`,
      value: code ?? `${index}`,
    })) ?? [];

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('household.partnerEmployment.modal.title')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{ selectedEmploymentObjectCode: null }}
        onSubmit={handleFormikSubmit}
        validationSchema={HouseholdPartnerEmploymentSchema}>
        {({ handleSubmit, isSubmitting, submitForm }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <ErrorFallbackPlaceholder
                  data={employmentObjectsData?.objects}
                  error={error}
                  loading={loading}>
                  {loading ? (
                    <Box display="flex" justifyContent="center" py={5}>
                      <CircularProgress size={30} />
                    </Box>
                  ) : (
                    <Grid item display="flex" justifyContent="center" xs={12}>
                      <RadioGroupFormik
                        row
                        id="selectedEmploymentObjectCode"
                        options={options}
                        renderOptionAdornment={renderRadioOptionAdornment}
                      />
                    </Grid>
                  )}
                </ErrorFallbackPlaceholder>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                {!error ? (
                  <LoadingButton loading={isSubmitting} onClick={submitForm} variant="contained">
                    <span>{t('global.actions.save')}</span>
                  </LoadingButton>
                ) : null}
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
      {selectedEmploymentObject ? (
        <ObjectModal
          readonly
          onClose={handleDetailModalClose}
          open={isDetailModalOpen}
          timespaceObject={selectedEmploymentObject}
        />
      ) : null}
    </Dialog>
  );
}
