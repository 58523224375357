import React, { useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { TransferWithinAStation } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import {
  clearInterpersonalizedPerson,
  clearSessionToken,
  clearUser,
  getOwnerToken,
  isInterpersonalizedView,
  setInterpersonalizedPerson,
  setSessionToken,
  setUser,
} from 'Lib/Helpers/Session';
import { TimespacePaths } from 'Config';
import { useLogoutInterpersonalizedUserMutation } from 'Generated/graphql-hooks';
import { useErrorHandler } from 'Lib/Hooks';
import theme from 'Theme';

export default function InterpersonalizeMenuItem(): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const [logoutInterpersonalizedUserMutation] = useLogoutInterpersonalizedUserMutation();
  const { timespaceId } = useParams();

  const handleCancelInterpersonalize = useCallback(async () => {
    try {
      const logoutUser = await logoutInterpersonalizedUserMutation();
      if (logoutUser.data?.logoutInterpersonalizedUser) {
        await client.resetStore();

        clearInterpersonalizedPerson();
        clearUser();
        clearSessionToken();
        setSessionToken(getOwnerToken() ?? '');
        enqueueSnackbar(t('user.interpersonalize.cancel'), { variant: 'success' });
        await client.refetchQueries({
          include: 'active',
        });
      } else {
        handleError(logoutUser.errors);
      }
    } catch (e: any) {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      navigate(`${TimespacePaths.Root}/${timespaceId}/${TimespacePaths.Management}/players`);
    }
  }, [timespaceId]);

  return (
    <>
      {isInterpersonalizedView() ? (
        <Tooltip placement="top" title={t('global.actions.interpersonalizeCancel').toString()}>
          <IconButton
            color="inherit"
            onClick={handleCancelInterpersonalize}
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}>
            <TransferWithinAStation />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
}
