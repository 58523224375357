import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// eslint-disable-next-line import/named
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import { Box, Link, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Warning } from '@mui/icons-material';
import { ProfileMenuItem } from 'Components';
import { InvestlandLogoHorizontal, InvestlandLogoIcon } from 'Assets';
import { ScreenPaths } from 'Config';
import { getInterpersonalizedPerson, getUser, isInterpersonalizedView } from 'Lib/Helpers/Session';
import NotificationsMenuItem from './NotificationsMenuItem';
import InterpersonalizeMenuItem from './InterpersonalizeMenuItem';

export default function AppBar({
  left,
  right = (
    <Box alignItems="center" display="flex">
      <ProfileMenuItem />
      <NotificationsMenuItem />
      <InterpersonalizeMenuItem />
    </Box>
  ),
  ...restProps
}: AppBarProps & {
  left?: React.ReactNode;
  right?: React.ReactNode;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <MuiAppBar {...restProps} position="fixed">
      <Toolbar>
        <Box alignItems="center" display="flex" flex="1" justifyContent="space-between">
          {left || (
            <Link
              alignItems="center"
              color="inherit"
              component={RouterLink}
              display="inline-flex"
              to={ScreenPaths.Root}>
              {isMobile ? (
                <InvestlandLogoIcon height={39} width={39} />
              ) : (
                <InvestlandLogoHorizontal height={39} width={168} />
              )}
            </Link>
          )}
          {isInterpersonalizedView() ? (
            !isMobile ? (
              <Box
                alignItems="center"
                border={4}
                borderColor={theme.palette.secondary.main}
                borderRadius={2}
                color={theme.palette.secondary.main}
                display="flex"
                px={4}
                py={1}>
                <Warning />
                <Typography
                  sx={{
                    paddingLeft: 4,
                  }}
                  variant="body1">
                  {t('game.interpersonalize', {
                    replace: {
                      person: getInterpersonalizedPerson(),
                      user: getUser(),
                    },
                  })}
                </Typography>
              </Box>
            ) : null
          ) : null}
          <Box pl={4}>{right}</Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}
