import * as Apollo from '@apollo/client';
import { useContext } from 'react';
import { getCodeFilterRecursive } from 'Lib/Helpers/Code';
import { GameContext } from 'Context';
import {
  ObjectsQuery,
  ObjectsQueryVariables,
  OrderDirection,
  useObjectsQuery,
} from 'Generated/graphql-hooks';

export function useGetTimespaceAvailableObjects(
  baseOptions: Apollo.QueryHookOptions<ObjectsQuery, ObjectsQueryVariables>,
) {
  const context = useContext(GameContext);
  return useObjectsQuery({
    ...baseOptions,
    variables: {
      // TODO: update type, so that we don't have to send orderBy everywhere and this will be the default
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      ...baseOptions.variables,
      where: {
        AND: [
          baseOptions?.variables?.where || {},
          {
            OR: [
              ...(context?.timespacePerson?.timespace?.timespaceAvailableObjects?.map(
                ({ objectCode }) => getCodeFilterRecursive(objectCode),
              ) || []),
              ...(context?.timespacePerson?.timespace?.timespaceAvailableObjects?.map(
                ({ objectCode }) => ({
                  code: { startsWith: objectCode },
                }),
              ) || []),
            ],
          },
        ],
      },
    },
  });
}
