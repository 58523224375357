import omit from 'lodash/omit';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import {
  RandomEventCreateInput as IRandomEventCreateInput,
  InputMaybe,
  RandomEvent,
  RandomEventAcquiredObjectRelateToOneForCreateInput,
  RandomEventFinancialObjectRelateToManyForCreateInput,
  RandomEventObjectRelateToManyForCreateInput,
  Scalars,
  TimespaceRelateToOneForCreateInput,
} from 'Generated/graphql-hooks';
import { RandomEventObjectCreateInput } from 'Models/RandomEventObjectCreateInput';
import { RandomEventFinancialObjectCreateInput } from './RandomEventFinancialObjectCreateInput';

export default class RandomEventCreateInput implements IRandomEventCreateInput {
  constructor(randomEvent: RandomEvent, timespaceId?: string) {
    randomEvent = mapValuesDeep(randomEvent, (v) => (v === '' ? null : v)) as RandomEvent;
    this.description = randomEvent.description;
    this.executionCount = randomEvent.executionCount;
    this.executionLastRound = randomEvent.executionLastRound;
    this.executionMaxCount = randomEvent.executionMaxCount;
    this.executionMonthPeriodical = randomEvent.executionMonthPeriodical;
    this.executionProbability =
      randomEvent.executionProbability && !Number.isNaN(randomEvent.executionProbability)
        ? Number(randomEvent.executionProbability) / 100 //TODO: remove this and divide in view
        : 1;
    this.executionRoundFrom = randomEvent.executionRoundFrom;
    this.executionRoundTo = randomEvent.executionRoundTo;
    this.executionUserAcceptance = randomEvent.executionUserAcceptance;
    this.executionYearPeriodical = randomEvent.executionYearPeriodical;
    this.name = randomEvent.name;
    this.randomEventAcquiredObject = randomEvent.randomEventAcquiredObject
      ? {
          create: {
            assetsImpactOneTime: {
              create: omit(randomEvent.randomEventAcquiredObject.assetsImpactOneTime, 'id'),
            },
            isAssetsImpactPositive: randomEvent.randomEventAcquiredObject.isAssetsImpactPositive,
            object: {
              connect: { id: randomEvent.randomEventAcquiredObject.object?.id },
            },
          },
        }
      : null;
    this.randomEventFinancialObjects =
      randomEvent.randomEventFinancialObjects && randomEvent.randomEventFinancialObjects.length > 0
        ? {
            create: randomEvent.randomEventFinancialObjects.map(
              (item) => new RandomEventFinancialObjectCreateInput(item),
            ),
          }
        : undefined;
    this.randomEventObjects =
      randomEvent.randomEventObjects && randomEvent.randomEventObjects.length > 0
        ? {
            create: randomEvent.randomEventObjects.map(
              (item) => new RandomEventObjectCreateInput(item),
            ),
          }
        : undefined;
    this.timespace = { connect: { id: timespaceId } };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  description?: InputMaybe<Scalars['String']['input']>;
  executionCount?: InputMaybe<Scalars['Int']['input']>;
  executionLastRound?: InputMaybe<Scalars['Int']['input']>;
  executionMaxCount?: InputMaybe<Scalars['Int']['input']>;
  executionMonthPeriodical?: InputMaybe<Scalars['Int']['input']>;
  executionProbability?: InputMaybe<Scalars['Int']['input']>;
  executionRoundFrom?: InputMaybe<Scalars['Int']['input']>;
  executionRoundTo?: InputMaybe<Scalars['Int']['input']>;
  executionUserAcceptance?: InputMaybe<Scalars['Boolean']['input']>;
  executionYearPeriodical?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  randomEventAcquiredObject?: InputMaybe<RandomEventAcquiredObjectRelateToOneForCreateInput>;
  randomEventFinancialObjects?: InputMaybe<RandomEventFinancialObjectRelateToManyForCreateInput>;
  randomEventObjects?: InputMaybe<RandomEventObjectRelateToManyForCreateInput>;
  timespace?: InputMaybe<TimespaceRelateToOneForCreateInput>;
}
