import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';
// eslint-disable-next-line import/named
import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { roundFloat } from 'Lib/Helpers/Number';

// const allowedNumberInputKeyCodes = [8, 9, 13, 27, 44, 45, 46];

export default React.memo(function TextFieldFormik({
  disableTranslation,
  helperText,
  id,
  readOnly,
  type,
  ...restProps
}: TextFieldProps & { disableTranslation?: boolean; readOnly?: boolean }): JSX.Element {
  if (!id) {
    throw new Error('Id must be defined');
  }
  const { t } = useTranslation();
  const [{ onBlur, onChange, value }, { error, touched }] = useField(id);

  const textFieldRef = useRef<any>(null);
  useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault();
    textFieldRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      textFieldRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  // const handleKeyPress = (event: any) => {
  //   if (type !== 'number') {
  //     return;
  //   }

  //   // prevent if not number/dot
  //   if (
  //     (event.which < 48 && !allowedNumberInputKeyCodes.includes(event.which)) ||
  //     event.which > 57
  //   ) {
  //     event.preventDefault();
  //   }

  //   // prevent if already dot or comma
  //   if (
  //     allowedNumberInputKeyCodes.includes(event.which) &&
  //     (event.target.value.indexOf('.') != -1 || event.target.value.indexOf(',') != -1)
  //   ) {
  //     event.preventDefault();
  //   }
  // };

  // TODO: just a tmp solution, we should remove the translation from formik components and move it to validations, with an event listener changing the validation strings on language change
  // https://dev.to/codedivoire/how-to-internationalize-a-yup-validation-schema-in-a-react-formik-and-react-i18next-app-cj7
  const errorMessage = disableTranslation && error ? error : error ? t(error) : undefined;

  const newValue = typeof value === 'number' ? roundFloat(Number(value)) : value;

  return readOnly ? (
    <Typography
      component={Box}
      display="flex"
      justifyContent="space-between"
      mb={1}
      mt={2}
      textAlign="center">
      <Typography component={Box} pr={1}>
        {newValue ?? ''}
      </Typography>
    </Typography>
  ) : (
    <TextField
      ref={textFieldRef}
      fullWidth
      error={touched && errorMessage !== undefined}
      helperText={errorMessage && touched ? errorMessage : helperText}
      margin="dense"
      size="small"
      variant="outlined"
      {...restProps}
      InputProps={{
        ...restProps?.InputProps,
        ...(type === 'number'
          ? {
              inputProps: {
                ...(restProps?.InputProps?.inputProps ?? {}),
                lang: 'sk',
              },
            }
          : {}),
      }}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      // onKeyPress={handleKeyPress}
      type={type}
      value={newValue ?? ''}
    />
  );
});
