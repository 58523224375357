import { MUIDataTableColumnDef } from 'mui-datatables';
import { t } from 'i18next';
// @ts-expect-error does not have type definitions
import pmt from 'formula-pmt';
import {
  FinancialObjectFragment,
  Timespace,
  TimespacePersonFinancialObject,
} from 'Generated/graphql-hooks';
import { formatDuration, formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { FinancialObjectCodeEnum } from 'Types/Global';

export function calculateMaxMortgage({
  annualIncome,
  principalMaximum,
  propertyPrice,
}: {
  annualIncome: number;
  principalMaximum: number;
  propertyPrice: number;
}) {
  return Math.min(0.8 * propertyPrice, 8 * annualIncome, principalMaximum);
}

export function calculateAnnualIncome(monthlyIncome: number) {
  return 12 * monthlyIncome;
}

export function calculatePrincipalMaximum(financialObjects: TimespacePersonFinancialObject[]) {
  const principalMinimums = financialObjects
    ?.filter(({ financialObject }) =>
      financialObject?.code?.startsWith(FinancialObjectCodeEnum.MortgageLoan),
    )
    .map(({ principalMin }) => (principalMin ?? 0) * -1) ?? [Infinity];

  return Math.max(...(principalMinimums.length ? principalMinimums : [Infinity]), 0);
}

export function calculateInterest(
  financialObject: FinancialObjectFragment,
  timespace?: Timespace | null,
) {
  const { interestNegative, interestSurplus } = financialObject;

  return (
    (((interestSurplus ?? 0) > 0
      ? (interestSurplus ?? 0) + (timespace?.centralInterestRate ?? 0)
      : interestNegative) ?? 0) * 100
  );
}

export function calculatePV(rate: number, periods: number, payment: number) {
  if (rate === 0) {
    return -payment * periods;
  } else {
    return (
      (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate)) /
      Math.pow(1 + rate, periods)
    );
  }
}

export const calculateMonthlyPayment = ({
  financialObject,
  maturityPeriod,
  mortgageAmount,
  timespace,
}: {
  financialObject: FinancialObjectFragment;
  // in months
  maturityPeriod: number;
  mortgageAmount: number;
  timespace?: Timespace | null;
}) => {
  const { feesPeriodical, feesPeriodicalPercent } = financialObject;
  const interest = calculateInterest(financialObject, timespace);

  const periodicalFee =
    (feesPeriodical ?? 0) + (((feesPeriodicalPercent ?? 0) * 100) / 12) * mortgageAmount;

  return pmt((interest ?? 0) / 100 / 12, maturityPeriod ?? 0, -mortgageAmount) + periodicalFee;
};

export const mortgageOptionsTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('timespace.attr.name'),
    name: 'financialObject.name',
  },
  {
    label: t('mortgage.table.attr.interest'),
    name: 'interest',
    options: {
      customBodyRender: (value: number) => formatPercent(value, { multiply: false }),
      setCellProps: () => ({
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
  },
  {
    label: t('mortgage.table.attr.fixation'),
    name: 'fixationPeriod',
    options: {
      customBodyRender: (value) => formatDuration(value),
    },
  },
  {
    label: t('mortgage.table.attr.maturityPeriod'),
    name: 'maturityPeriod',
    options: {
      customBodyRender: (value) => formatDuration(value),
    },
  },
  {
    label: t('mortgage.table.attr.mortgageAmount'),
    name: 'mortgageAmount',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
    },
  },
  {
    label: t('mortgage.table.attr.monthlyPayment'),
    name: 'monthlyPayment',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
    },
  },
  {
    label: t('mortgage.table.attr.startingFee'),
    name: 'startingFee',
    options: {
      customBodyRender: (value: number) => formatPrice(value),
    },
  },
];
