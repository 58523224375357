import { Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getObjectCodeFilterRecursive } from 'Lib/Helpers/Code';
import { OrderDirection, useObjectAttributesQuery } from 'Generated/graphql-hooks';
import { TimespaceObjectAttribute } from 'Models';
import { ErrorFallbackPlaceholder, LabeledText, Select } from 'Components';

interface Props {
  code?: string | null;
  id: string;
  readOnly?: boolean;
}

export default function ObjectAttributesTable({ code, id, readOnly }: Props) {
  const { t } = useTranslation();
  const [, { value }, { setValue }] = useField<TimespaceObjectAttribute[]>(id);
  const { data, error, loading } = useObjectAttributesQuery({
    onCompleted: (result) => {
      if (!value || value.length === 0) {
        setValue(
          result.objectAttributes
            ?.filter((x) => x.isDefaultAttributeValue)
            .map((x) => new TimespaceObjectAttribute(x)) ?? [],
        );
      }
    },
    skip: !code,
    variables: {
      orderBy: [{ attributeValue: OrderDirection.Desc }],
      where: getObjectCodeFilterRecursive(code),
    },
  });

  const groupedObjectAttributes =
    data?.objectAttributes?.reduce((result, item) => {
      if (item?.attribute?.id) {
        const existingItem = result[item.attribute.id];
        return {
          ...result,
          [item.attribute.id]: {
            name: item.attribute.name,
            options: existingItem
              ? [...existingItem.options, new TimespaceObjectAttribute(item)]
              : [new TimespaceObjectAttribute(item)],
          },
        };
      }
      return result;
    }, {} as { [key: string]: { name?: string | null; options: TimespaceObjectAttribute[] } }) ??
    {};

  return (
    <ErrorFallbackPlaceholder data={data?.objectAttributes} error={error} loading={loading}>
      {loading ? (
        <Grid item xs={12}>
          <Skeleton variant="text" width="35%" />
        </Grid>
      ) : (
        <Grid container>
          {data?.objectAttributes?.length ? (
            <Box component={Typography} pb={1} pt={2} variant="h6">
              {t('object.attr.objectAttributes')}
            </Box>
          ) : null}

          {Object.entries(groupedObjectAttributes).map(([key, attribute]) => {
            const inputValue = value?.find((x) => x?.objectAttribute.attribute?.id == key);

            return readOnly ? (
              <Box key={key} my={1} width="100%">
                <LabeledText
                  label={attribute.name ?? ''}
                  value={inputValue?.objectAttribute?.attributeValue}
                />
              </Box>
            ) : (
              <Grid
                key={key}
                container
                item
                alignContent="center"
                alignItems="center"
                justifyItems="center"
                pb={1}
                xs={12}>
                <Grid item xs={4}>
                  {attribute.name}
                </Grid>
                <Grid item xs={8}>
                  <Select<TimespaceObjectAttribute>
                    fullWidth
                    onChange={({ target }) => {
                      const index = value.findIndex(
                        (x) => x?.objectAttribute.attribute?.id === key,
                      );
                      if (~index) {
                        const newItems = [...value];
                        newItems[index] = target.value as TimespaceObjectAttribute;
                        setValue(newItems);
                      }
                    }}
                    options={attribute.options}
                    renderValue={(item) => item?.objectAttribute?.attributeValue}
                    value={inputValue}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </ErrorFallbackPlaceholder>
  );
}
