import React, { memo, useCallback, useContext, useMemo } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { IconButton, Tooltip } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  MutualFundStatus,
  OrderDirection,
  useMutualFundStatusesQuery,
} from 'Generated/graphql-hooks';
import { formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { EnhancedDataTable, SkeletonLoader } from 'Components/Generic';
import { useModal } from 'Lib/Hooks';
import { formatVolatility } from '../utils';
import MutualFundStatusDetailModal from './DetailModal';

interface Props {
  onRowSelected: (mutualFundStatus: MutualFundStatus) => void;
  value: MutualFundStatus | null;
}

export default memo(function MutualFundStatusTable({ onRowSelected, value: selectedValue }: Props) {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const {
    handleClose: handleMutualFundStatusDetailClose,
    handleOpen: handleMutualFundStatusDetailOpen,
    isOpen: isMutualFundStatusDetailOpen,
    selected: selectedMutualFundStatus,
  } = useModal<MutualFundStatus>();

  const { data, loading } = useMutualFundStatusesQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        round: { equals: Math.max(0, (timespacePerson.timespace?.round ?? 0) - 1) },
        timespace: { id: { equals: timespaceId } },
      },
    },
  });

  const handleRowSelectionChange = useCallback(
    (_, __, selectedRows: any[]) => {
      if (data?.mutualFundStatuses) {
        onRowSelected(data?.mutualFundStatuses?.[selectedRows[0]]);
      }
    },
    [data?.mutualFundStatuses],
  );

  const mutualFundsTableColumns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: 'excluded',
        },
      },
      {
        label: t('timespace.attr.name'),
        name: 'fund.name',
      },
      {
        label: t('investments.rentability'),
        name: 'fund.rentability',
        options: {
          customBodyRender: (value: number) => formatPercent(value),
          setCellProps: () => ({
            style: {
              whiteSpace: 'noWrap',
            },
          }),
        },
      },
      {
        label: t('investments.volatility'),
        name: 'fund.volatility',
        options: {
          customBodyRender: formatVolatility,
          setCellProps: () => ({
            style: {
              whiteSpace: 'noWrap',
            },
          }),
        },
      },
      {
        label: t('investments.minimal.deposit'),
        name: 'fund.minimalDeposit',
        options: {
          customBodyRender: (value) => formatPrice(value),
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('investments.entry.fee'),
        name: 'fund.entryFee',
        options: {
          customBodyRender: (value) => formatPercent(value),
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('investments.exit.fee'),
        name: 'fund.exitFee',
        options: {
          customBodyRender: (value) => formatPercent(value),
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('investments.management.fee'),
        name: 'fund.managementFee',
        options: {
          customBodyRender: (value) => formatPercent(value),
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('investments.details'),
        name: 'detail',
        options: {
          customBodyRender: (_, { rowData }) => {
            return (
              <Tooltip placement="right" sx={{ pointerEvents: 'all' }} title="Detail">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    const selectedObject = data?.mutualFundStatuses?.find(
                      ({ fund }) => fund?.name === rowData[1],
                    );
                    if (selectedObject) {
                      handleMutualFundStatusDetailOpen(selectedObject);
                    }
                  }}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            );
          },
          customHeadRender: () => null,
          sort: false,
        },
      },
    ],
    [data, t],
  );

  const selectedRowIndex =
    data?.mutualFundStatuses?.findIndex(({ id }) => id === selectedValue?.id) ?? -1;

  return (
    <>
      <EnhancedDataTable
        hideToolbar
        columns={mutualFundsTableColumns}
        data={data?.mutualFundStatuses ?? []}
        options={
          {
            onRowSelectionChange: handleRowSelectionChange,
            rowsSelected: selectedRowIndex > -1 ? [selectedRowIndex] : undefined,
            selectableRows: 'single',
            selectableRowsOnClick: true,
            textLabels: {
              body: {
                noMatch: loading ? (
                  <SkeletonLoader height={63} rows={7} />
                ) : (
                  t('global.placeholder.empty')
                ),
              },
            },
          } as MUIDataTableOptions
        }
        title={''}
      />
      {selectedMutualFundStatus ? (
        <MutualFundStatusDetailModal
          mutualFundStatus={selectedMutualFundStatus}
          onClose={handleMutualFundStatusDetailClose}
          open={isMutualFundStatusDetailOpen}
        />
      ) : null}
    </>
  );
});
