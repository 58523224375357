import React, { useContext } from 'react';
import { Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { Box, Container, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import {
  BasicAccountSelector,
  CheckboxFormik,
  LabeledText,
  Section,
  SelectFormik,
  TextFieldFormik,
} from 'Components';
import {
  MutualFundStatus,
  TimespacePersonFinancialObject,
  namedOperations,
  useCreateMutualFundInvestorTransactionMutation,
} from 'Generated/graphql-hooks';
import { Constants } from 'Config';
import { MutualFundInstructionCreateSchema } from 'Config/Validations';
import { MutualFundInstructionCreateInput } from 'Models';
import { GameContext } from 'Context';
import { useErrorHandler } from 'Lib/Hooks';
import MutualFundStatusesTable from '../MutualFundStatus/Table';

export type FormValues = {
  amountRequested?: number;
  day: number;
  investmentRepeat: boolean;
  mutualFundStatus: MutualFundStatus | null;
  payersAccount: TimespacePersonFinancialObject | null;
  repeatRounds?: number;
};

export default function MutualFundInvestorStatusCreate() {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [createMutualFundInvestorTransactionMutation] =
    useCreateMutualFundInvestorTransactionMutation();

  const handleFormikSubmit = async (
    {
      amountRequested,
      day,
      investmentRepeat,
      mutualFundStatus,
      payersAccount,
      repeatRounds,
    }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    try {
      const { data, errors } = await createMutualFundInvestorTransactionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [namedOperations.Query.MutualFundInvestorStatuses],
        variables: {
          amountRequested,
          day,
          fee: (amountRequested ?? 0) * (mutualFundStatus?.fund?.entryFee ?? 0),
          mutualFundStatusId: Number(mutualFundStatus?.id),
          relatedBankAccountId: Number(payersAccount?.id),
          repeatRounds: investmentRepeat ? (repeatRounds ?? 0) + 1 : 1,
          timespacePersonId: Number(timespacePerson?.id),
        },
      });
      if (data?.createMutualFundInvestorTransaction) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
      } else if (errors?.some((x) => ~x.message.indexOf('MinimalDepositError'))) {
        const error = errors?.find((x) => ~x.message.indexOf('MinimalDepositError'));
        // @ts-expect-error not typed
        const value = error?.extensions?.exception?.thrownValue?.cause?.minimalDeposit ?? 0;
        enqueueSnackbar(
          t('investments.mutualFund.validation.minimalDeposit', {
            replace: {
              currency: Constants.CURRENCY,
              value,
            },
          }),
          { variant: 'error' },
        );
      } else {
        handleError(errors);
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 2 }}>
      <Section title={t('investments.choose.mutual.fund')}>
        <Formik<FormValues>
          validateOnMount
          initialValues={{
            ...new MutualFundInstructionCreateInput(),
            mutualFundStatus: null,
          }}
          onSubmit={handleFormikSubmit}
          validationSchema={MutualFundInstructionCreateSchema}>
          {({ handleSubmit, isSubmitting, isValid, setFieldValue, submitForm, values }) => {
            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <Box mb={4}>
                  <MutualFundStatusesTable
                    onRowSelected={(object) => setFieldValue('mutualFundStatus', object)}
                    value={values?.mutualFundStatus}
                  />
                </Box>
                <LabeledText
                  label={t('investments.invest.amount')}
                  value={
                    <TextFieldFormik
                      InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                      id="amountRequested"
                      placeholder={t('global.placeholder.amount')}
                      type="number"
                    />
                  }
                />
                <LabeledText
                  label={t('investments.invest.day.buy')}
                  value={
                    <SelectFormik
                      fullWidth
                      id="day"
                      options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
                    />
                  }
                />
                <LabeledText
                  label={t('object.attr.relatedFinancialObject')}
                  value={<BasicAccountSelector id="payersAccount" redText={true} />}
                />
                <Box display="flex" justifyContent="flex-end">
                  <FormControlLabel
                    control={<CheckboxFormik id="investmentRepeat" />}
                    label={t('investments.repeat.investment') as string}
                  />
                </Box>
                {values.investmentRepeat ? (
                  <LabeledText
                    label={t('investments.repeat.count')}
                    value={
                      <TextFieldFormik
                        InputProps={{
                          inputProps: { min: 1 },
                        }}
                        id="repeatRounds"
                        type="number"
                      />
                    }
                  />
                ) : null}

                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="contained">
                    <span>{t('investments.actions.buy')}</span>
                  </LoadingButton>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Section>
    </Container>
  );
}
