import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import Select, { ExtendedSelectProps } from 'Components/Input/Select';

export interface SelectFormikProps<T> extends ExtendedSelectProps<T> {
  id: string;
  readOnly?: boolean;
}

export function SelectFormik<T>({
  fullWidth,
  id,
  readOnly,
  renderValue,
  ...restProps
}: SelectFormikProps<T>): JSX.Element {
  const [formikFunctions, { error, touched }] = useField(id);
  const { t } = useTranslation();

  return readOnly ? (
    <Typography
      component={Box}
      display="flex"
      justifyContent="space-between"
      mb={1}
      mt={2}
      textAlign="center">
      <Typography component={Box} pr={1}>
        {renderValue?.(formikFunctions.value) ?? formikFunctions.value ?? ''}
      </Typography>
    </Typography>
  ) : (
    <Box width={fullWidth ? '100%' : undefined}>
      <Select
        {...restProps}
        error={!!error && touched}
        fullWidth={fullWidth}
        renderValue={renderValue}
        {...formikFunctions}
      />
      {error && touched ? (
        <FormHelperText error variant="outlined">
          <span className="text-red-500 text-xs px-1">{t(error)}</span>
        </FormHelperText>
      ) : null}
    </Box>
  );
}
