import React from 'react';
import { useField } from 'formik';
// eslint-disable-next-line import/named
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default React.memo(function AutocompleteFormik({
  id,
  label,
  ...restProps
}: Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & { label?: string }): JSX.Element {
  if (!id) {
    throw new Error('Id must be defined');
  }
  const { t } = useTranslation();
  const [{ onBlur, value }, { error, touched }, { setValue }] = useField(id);

  return (
    <Autocomplete
      {...restProps}
      noOptionsText={t('global.placeholder.empty')}
      onBlur={onBlur}
      onChange={(e, newValue) => setValue(newValue)}
      renderInput={(params) => (
        <TextField
          {...params} //
          error={touched && error !== undefined && error !== ''}
          helperText={error && touched ? t(error) : null}
          label={label}
          margin="dense"
          size="small"
        />
      )}
      value={value ?? ''}
    />
  );
});
