import { AccessTime, ListAlt } from '@mui/icons-material';
import * as React from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { AppBar, NavTabs, ProfileMenuItem } from 'Components';
import TimespaceOverview from './TimespaceOverview';
import TemplateOverview from './TemplateOverview';

export default function LobbyRoot() {
  const { t } = useTranslation();

  return (
    <>
      <AppBar right={<ProfileMenuItem />} />

      <Container maxWidth="md" sx={{ pt: 2 }}>
        <NavTabs
          tabs={[
            { href: ``, icon: <AccessTime />, label: t('timespace.overview') },
            {
              href: `templates`,
              icon: <ListAlt />,
              label: t('timespace.templates'),
            },
          ]}
        />
        <Routes>
          <Route element={<TimespaceOverview />} index={true} />
          <Route element={<TemplateOverview />} path="templates" />
        </Routes>
      </Container>
    </>
  );
}
