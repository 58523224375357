import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, DialogContent } from '@mui/material';
import { TimespacePersonFinancialObjectFragment } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { RadioGroup } from 'Components/Input';
import { ModalCloseButton } from 'Components/Generic';
import PostponedDiscardModal from './PostponedDiscardModal';

// eslint-disable-next-line no-shadow
export enum DiscardTypeEnum {
  LATER,
  NOW,
}

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function SavingsAccountDiscardModal({
  onClose: handleClose,
  open,
  timespacePersonFinancialObject,
}: Props) {
  const { t } = useTranslation();
  const {
    handleClose: handlePostponedDiscardClose,
    handleOpen: handlePostponedDiscardOpen,
    isOpen: isPostponedDiscardOpen,
  } = useModal();
  const [discardType, setDiscardType] = useState(DiscardTypeEnum.NOW);

  const discardOptions = useMemo(
    () => [
      { label: t('savings.discard.now'), value: DiscardTypeEnum.NOW },
      {
        label: t('savings.discard.later'),
        value: DiscardTypeEnum.LATER,
      },
    ],
    [t],
  );

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle>
        {t('bank.savings.discard.check')}
        <ModalCloseButton onClose={handleClose} />
      </DialogTitle>
      <Box>
        <DialogContent>
          <RadioGroup
            id="selectDiscardType"
            onChange={(e) => {
              setDiscardType(e.target.value as unknown as DiscardTypeEnum);
            }}
            options={discardOptions}
            value={discardType}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePostponedDiscardOpen} variant="contained">
            {t('bank.savings.cancel')}
          </Button>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.close')}
          </Button>
        </DialogActions>
      </Box>
      <PostponedDiscardModal
        discardType={discardType}
        onClose={() => {
          handlePostponedDiscardClose();
          handleClose();
        }}
        open={isPostponedDiscardOpen}
        timespacePersonFinancialObject={timespacePersonFinancialObject}
      />
    </Dialog>
  );
}
