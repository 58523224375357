import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  OrderDirection,
  TimespacePersonFinancialObjectObjectStatusType,
  useMeQuery,
  useTimespacePersonFinancialObjectsListQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { FinancialObjectCodeEnum } from 'Types/Global';
import Section from 'Components/Section';
import { EnhancedDataTable } from 'Components/Generic';
import { debtsColumns } from './utils';

export default function DebtsTable() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { data: userData } = useMeQuery();

  const { data, loading } = useTimespacePersonFinancialObjectsListQuery({
    skip: !userData?.me?.id,
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        AND: [
          {
            OR: [
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.CreditCard },
                },
              },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.MortgageLoan },
                },
              },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.ConsumerLoan },
                },
              },
              {
                actualValue: { lt: 0 },
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount },
                },
              },
            ],
          },
          { objectStatus: { equals: TimespacePersonFinancialObjectObjectStatusType.Active } },
        ],
        timespacePerson: {
          id: { equals: timespacePerson.id },
          timespace: { id: { equals: timespaceId } },
        },
      },
    },
  });

  const tableData = data?.timespacePersonFinancialObjects ?? [];

  return (
    <Section title={t('holdings.debts')}>
      <EnhancedDataTable
        hideToolbar
        columns={debtsColumns(tableData)}
        data={tableData}
        loading={loading}
        options={
          {
            setRowProps: (row, dataIndex) => {
              return {
                onMouseEnter: () => {
                  console.log(
                    data?.timespacePersonFinancialObjects &&
                      data.timespacePersonFinancialObjects[dataIndex],
                  );
                },
              };
            },
            sort: false,
          } as MUIDataTableOptions
        }
        title={''}
      />
    </Section>
  );
}
