import {
  TimespacePersonObjectAttribute as ITimespaceObjectAttribute,
  ObjectAttribute,
} from 'Generated/graphql-hooks';

export default class TimespacePersonObjectAttribute implements ITimespaceObjectAttribute {
  constructor(object: ObjectAttribute) {
    this.objectAttribute = object;
  }
  id = '';
  objectAttribute;
}
