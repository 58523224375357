import React, { lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import {
  PlayersTable,
  RandomEventTable,
  SavingsAccountCreate,
  StandingOrderCreate,
  StandingOrderEdit,
  TermAccountCreate,
} from 'Components';
import useAuth from 'Lib/Hooks/useAuth';
import { ScreenPaths, TimespacePaths } from 'Config';
import * as Screens from 'Screens';
import {
  AuthLayout,
  BankLayout,
  CarshopLayout,
  EshopLayout,
  GameLayout,
  InvestmentsLayout,
  LivingLayout,
  TimespaceLayout,
} from 'Layouts';
import TemplateEdit from 'Screens/Lobby/TemplateEdit';
import OtherSettings from 'Screens/Game/OtherSettings';
import {
  MutualFundInvestorStatusCreate,
  MutualFundInvestorStatusTable,
  MutualFundTransactionEdit,
} from 'Components/Investments';
import { ConsumerCreditCreate } from 'Components/ConsumerCredit';
import {
  HoldingsDebtsTable,
  HoldingsFinancialTable,
  HoldingsGoodsTable,
  HoldingsOverview,
  HoldingsPropertiesTable,
} from 'Components/Holdings';

const Login = lazy(() => import('../Screens/Public/Login'));
const ForgotPassword = lazy(() => import('../Screens/Public/ForgotPassword'));
const ResetPassword = lazy(() => import('../Screens/Public/ResetPassword'));
const Register = lazy(() => import('../Screens/Public/Register'));
const Verification = lazy(() => import('../Screens/Public/Verification'));

export default function Routes() {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return useRoutes([
    { element: <Screens.NotFound />, path: ScreenPaths.NotFound },
    ...(!isLoggedIn
      ? [
          {
            children: [
              {
                element: <Login />,
                index: true,
              },
            ],
            element: <AuthLayout />,
            path: ScreenPaths.Login,
          },
          {
            children: [
              {
                element: <ForgotPassword />,
                path: ScreenPaths.ForgotPassword,
              },
            ],
            element: <AuthLayout />,
            path: ScreenPaths.ForgotPassword,
          },
          {
            children: [
              {
                element: <ResetPassword />,
                path: ScreenPaths.ResetPassword,
              },
            ],
            element: <AuthLayout />,
            path: ScreenPaths.ResetPassword,
          },
          {
            children: [
              {
                element: <Register />,
                path: ScreenPaths.Register,
              },
            ],
            element: <AuthLayout />,
            path: ScreenPaths.Register,
          },
          {
            children: [
              {
                element: <Verification />,
                path: ScreenPaths.Verification,
              },
            ],
            element: <AuthLayout />,
            path: ScreenPaths.Verification,
          },
          {
            element: <Navigate state={{ fromUrl: location.pathname }} to={ScreenPaths.Login} />,
            index: true,
            path: '/*',
          },
        ]
      : [
          {
            children: [
              { element: <Screens.GameOverview />, index: true },
              {
                children: [
                  { element: <Screens.RandomEventDetail create />, path: `create` },
                  { element: <Screens.RandomEventDetail />, path: `:eventId` },
                  {
                    children: [
                      { element: <RandomEventTable />, path: `` },
                      { element: <PlayersTable />, path: `players` },
                      { element: <OtherSettings />, path: `other` },
                    ],
                    element: <Screens.GameManagement />,
                  },
                ],
                path: `${TimespacePaths.Management}`,
              },
              {
                element: <Screens.MortgageRefinance />,
                path: `${TimespacePaths.RandomEvent}/:eventId/mortgage-refinance/:timespacePersonFinancialObjectId`,
              },
              { element: <Screens.EducationAndWork />, path: TimespacePaths.EducationAndWork },
              { element: <Screens.Household />, path: TimespacePaths.Household },
              {
                children: [
                  { element: <HoldingsOverview />, path: `` },
                  { element: <HoldingsGoodsTable />, path: `goods` },
                  { element: <HoldingsPropertiesTable />, path: `properties` },
                  { element: <HoldingsFinancialTable />, path: `financial` },
                  { element: <HoldingsDebtsTable />, path: `debts` },
                ],
                element: <Screens.HoldingsOverview />,
                path: `${TimespacePaths.Holdings}`,
              },
              { element: <Screens.TimeManagement />, path: TimespacePaths.TimeManagement },
              { element: <Screens.PersonalBudget />, path: TimespacePaths.PersonalBudget },
              { element: <Screens.LifeQuality />, path: TimespacePaths.LifeQuality },
              { element: <Screens.MyStuff />, path: TimespacePaths.MyStuff },
              { element: <Navigate to="" />, path: '*' },
            ],
            element: <GameLayout />,
            path: `${TimespacePaths.Root}/:timespaceId`,
          },
          {
            children: [
              { element: <Screens.EshopOverview />, index: true },
              { element: <Screens.EshopDetail />, path: ':code' },
            ],
            element: <EshopLayout />,
            path: `${TimespacePaths.Eshop}/:timespaceId`,
          },
          {
            children: [{ element: <Screens.Living />, index: true }],
            element: <LivingLayout />,
            path: `${TimespacePaths.Living}/:timespaceId`,
          },
          {
            children: [
              { element: <Screens.CarshopOverview />, index: true },
              { element: <Screens.CarshopDetail />, path: ':code' },
            ],
            element: <CarshopLayout />,
            path: `${TimespacePaths.Carshop}/:timespaceId`,
          },
          {
            children: [
              { element: <Screens.InvestmentsOverview />, index: true },
              {
                element: <MutualFundTransactionEdit />,
                path: ':objectId/instructions/:transactionId',
              },
              { element: <Screens.InvestmentsDetail />, path: ':objectId/*' },
              { element: <MutualFundInvestorStatusCreate />, path: 'create' },
              { element: <MutualFundInvestorStatusTable />, path: 'my-investments' },
            ],
            element: <InvestmentsLayout />,
            path: `${TimespacePaths.Investments}/:timespaceId`,
          },
          {
            children: [
              { element: <StandingOrderEdit />, path: 'standing-order/:id' },
              { element: <Screens.BankOverview />, index: true },
              { element: <Screens.BankDetail />, path: ':objectId/*' },
              { element: <SavingsAccountCreate />, path: 'create-savings-account' },
              { element: <TermAccountCreate />, path: 'create-term-account' },
              { element: <StandingOrderCreate />, path: 'create-standing-order' },
              { element: <ConsumerCreditCreate />, path: 'create-consumer-credit' },
            ],
            element: <BankLayout />,
            path: `${TimespacePaths.Bank}/:timespaceId`,
          },
          {
            children: [
              { element: <Screens.TimespaceDetail />, path: `${ScreenPaths.Create}/:id` },
              { element: <Screens.TimespaceDetail />, path: ScreenPaths.Create },
              { element: <TemplateEdit />, path: `${ScreenPaths.Edit}/:id` },
              { element: <Screens.Logout />, path: ScreenPaths.Logout },
              {
                element: <Screens.LobbyRoot />,
                path: `${ScreenPaths.Timespaces}/*`,
              },
              {
                element: <Navigate to={ScreenPaths.Timespaces} />,
                index: true,
              },
            ],
            element: <TimespaceLayout />,
            path: '/',
          },
          {
            element: <Navigate to={ScreenPaths.Timespaces} />,
            index: true,
            path: '/*',
          },
        ]),
  ]);
}
