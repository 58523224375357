/* eslint-disable @typescript-eslint/member-ordering */
import {
  TimespaceCreateInput as ITimespaceCreateInput,
  InputMaybe,
  Timespace,
  TimespacePersonFinancialObjectRelateToOneForCreateInput,
  UserFragment,
} from 'Generated/graphql-hooks';
import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';

export default class TimespaceCreateInput implements ITimespaceCreateInput {
  constructor(timespace: Timespace, owner: UserFragment | null | undefined) {
    timespace = mapValuesDeep(timespace, (v) => (v === '' ? null : v));
    this.name = timespace.name;
    this.description = timespace.description;
    this.goalsDescription = timespace.goalsDescription;
    this.owner = { connect: { id: owner?.id } };
    this.personInitialAge = timespace?.personInitialAge || 0;
    this.round = timespace.round;
    this.roundsPerStep = timespace.roundsPerStep;
    this.timespaceAvailableObjects = {
      create: timespace.timespaceAvailableObjects?.map(({ objectCode }) => ({
        objectCode,
      })),
    };
    this.timespacePersons = null;
    this.isPrototype = timespace.isPrototype;
    this.defaultPaymentMethod = timespace.defaultPaymentMethod?.id
      ? { connect: { id: timespace.defaultPaymentMethod.id } }
      : undefined;
  }

  defaultPaymentMethod?:
    | InputMaybe<TimespacePersonFinancialObjectRelateToOneForCreateInput>
    | undefined;
  description;
  goalsDescription;
  name;
  owner;
  personInitialAge;
  round;
  roundsPerStep;
  timespaceAvailableObjects;
  timespacePersons = null;
  isPrototype;
}
