import React, { useCallback } from 'react';
import { Container, Grid } from '@mui/material';
import { ConditionalGameScreenWrapper, EducationSection, WorkSection } from 'Components';

export default function EducationAndWork() {
  const conditionCallback = useCallback(
    ({ hasAvailableEducationObjects, hasAvailableWorkObjects, hasEducationOrWork }) =>
      !hasEducationOrWork && !hasAvailableEducationObjects && !hasAvailableWorkObjects,
    [],
  );

  return (
    <ConditionalGameScreenWrapper hideScreen={conditionCallback}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EducationSection />
          </Grid>

          <Grid item xs={12}>
            <WorkSection />
          </Grid>
        </Grid>
      </Container>
    </ConditionalGameScreenWrapper>
  );
}
