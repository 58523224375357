import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, styled } from '@mui/material';
import MUIDataTable, { Display, MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { CircularLoader, Section, SkeletonLoader } from 'Components';
import { OrderDirection, useTimespacePersonLifeQualitiesQuery } from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { usePagination } from 'Lib/Hooks';

const StyledTable = styled(MUIDataTable)(() => ({
  '&.MuiPaper-root > .MuiToolbar-root': {
    display: 'none',
  },
}));

export default function LifeQuality() {
  const { t } = useTranslation();
  const { loading: timespacePersonLoading, timespacePerson } = useContext(GameContext);
  const { skip, take, ...pagination } = usePagination();

  const { data, loading } = useTimespacePersonLifeQualitiesQuery({
    skip: !timespacePerson?.id,
    variables: {
      orderBy: [{ round: OrderDirection.Desc }, { createdAt: OrderDirection.Desc }],
      skip,
      take,
      where: {
        round: { gte: Math.max((timespacePerson?.timespace?.round ?? 0) - 36, 0) },
        timespacePerson: { id: { equals: timespacePerson?.id } },
      },
    },
  });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      options: { display: 'excluded' },
    },
    {
      label: t('timespace.attr.round'),
      name: 'round',
    },
    {
      label: t('object.attr.description'),
      name: 'description',
    },
    {
      label: t('object.lifeQualityImpact'),
      name: 'lifeQualityDelta',
      options: {
        customBodyRender: (value) =>
          `${value.toLocaleString('sk', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })} b`,
        setCellHeaderProps: () => ({ align: 'right' }),
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      label: t('lifeQuality.current.value'),
      name: 'lifeQuality',
      options: {
        customBodyRender: (value) =>
          `${value.toLocaleString('sk', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })} b`,
        setCellHeaderProps: () => ({ align: 'right' }),
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      label: t('lifeQuality.relative.lifeQualityImpact'),
      name: 'relativeLifeQualityDelta',
      options: {
        customBodyRender: (value) =>
          value === null || value === 0
            ? '-'
            : `${value.toLocaleString('sk', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })} b`,
        setCellHeaderProps: () => ({ align: 'right' }),
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      label: t('lifeQuality.relative.current.value'),
      name: 'relativeLifeQuality',
      options: {
        customBodyRender: (value) =>
          `${value.toLocaleString('sk', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })} b`,
        setCellHeaderProps: () => ({ align: 'right' }),
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      label: 'categoryChanges',
      name: 'categoryChanges',
      options: { display: 'excluded' as Display },
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {timespacePersonLoading ? (
        <CircularLoader />
      ) : (
        <Section
          title={t('lifeQuality.title', {
            replace: {
              value: Math.round(Number(timespacePerson?.lifeQuality)),
            },
          })}>
          <StyledTable
            columns={columns}
            data={data?.timespacePersonLifeQualities ?? []}
            options={
              {
                ...pagination,
                count: data?.count,
                download: 'false',
                enableNestedDataAccess: '.',
                filter: 'false',
                print: 'false',
                rowHover: false,
                search: 'false',
                selectToolbarPlacement: 'none',
                selectableRows: 'none',
                setRowProps: (cells: boolean[]) => {
                  const [categoryChange] = cells.slice(-1);
                  return {
                    className: !categoryChange ? 'text-red' : '',
                  };
                },
                sort: false,
                tableBodyHeight: 'auto',
                textLabels: {
                  body: {
                    noMatch: loading ? <SkeletonLoader /> : t('eshop.placeholder.empty'),
                  },
                },
                viewColumns: 'false',
              } as MUIDataTableOptions
            }
            title={''}
          />
        </Section>
      )}
    </Container>
  );
}
