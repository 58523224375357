import * as Yup from 'yup';

export default Yup.object({
  finalDate: Yup.object()
    .test('finalDate', 'budget.finalDate.error', function test(finalDate = {}) {
      // @ts-expect-error not typed yet
      const { initialDate } = this.options.parent ?? {};

      return (
        !finalDate ||
        finalDate.year > initialDate.year ||
        (finalDate.year === initialDate.year && finalDate.month > initialDate.month) ||
        (finalDate.year === initialDate.year &&
          finalDate.month === initialDate.month &&
          finalDate.day >= initialDate.day)
      );
    })
    .nullable()
    .required('validations.required'),
  initialDate: Yup.object(),
});
