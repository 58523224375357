import { ListItem, Skeleton } from '@mui/material';
import React from 'react';

type Props = {
  height?: number;
  rows?: number;
};

export default function SkeletonLoader({ height = 50, rows = 3 }: Props) {
  return (
    <>
      {new Array(rows).fill(null).map((_, index) => (
        <ListItem key={index} dense>
          <Skeleton height={height} variant="text" width="100%" />
        </ListItem>
      ))}
    </>
  );
}
