import {
  OrderDirection,
  TimespacePerson,
  TimespacePersonFinancialObjectObjectStatusType,
  TimespacePersonObjectStatusType,
  useTimespacePersonFinancialObjectsQuery,
  useTimespacePersonHouseholdObjectsQuery,
  useTimespacePersonObjectsQuery,
} from 'Generated/graphql-hooks';
import { livingWageSums } from 'Config';
import { getObjectYearFromRound } from 'Lib/Helpers/Date';
import {
  FinancialObjectCodeEnum,
  HouseholdObjectCodes,
  OcupationObjectCodeEnum,
} from 'Types/Global';

interface Props {
  timespaceId?: string;
  timespacePerson?: TimespacePerson | null;
}

export default function useTimespacePersonFinancialInfo({ timespaceId, timespacePerson }: Props) {
  const { age, id: timespacePersonId } = timespacePerson ?? {};

  const { data: workData, loading: workDataLoading } = useTimespacePersonObjectsQuery({
    skip: !timespacePersonId,
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      skip: 0,
      take: 100,
      where: {
        AND: [
          { object: { code: { startsWith: OcupationObjectCodeEnum.Ocupation } } },
          { object: { code: { not: { startsWith: HouseholdObjectCodes.EMPLOYMENT } } } },
          {
            OR: [
              { status: { equals: TimespacePersonObjectStatusType.Active } },
              { status: { equals: TimespacePersonObjectStatusType.Passive } },
            ],
          },
          { timespacePerson: { id: { equals: timespacePersonId } } },
        ],
      },
    },
  });

  const { data: financialObjectsData, loading: financialDataLoading } =
    useTimespacePersonFinancialObjectsQuery({
      skip: !timespacePersonId || !timespaceId,
      variables: {
        orderBy: [{ name: OrderDirection.Desc }],
        where: {
          AND: [
            { timespacePerson: { id: { equals: timespacePersonId } } },
            { timespace: { id: { equals: timespaceId } } },
            {
              OR: [
                { objectStatus: { equals: TimespacePersonFinancialObjectObjectStatusType.Active } },
                {
                  AND: [
                    {
                      objectStatus: {
                        equals: TimespacePersonFinancialObjectObjectStatusType.Procured,
                      },
                    },
                    {
                      financialObject: {
                        code: {
                          startsWith: FinancialObjectCodeEnum.ConsumerLoan,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    });

  const { data: householdObjectsData, loading: householdDataLoading } =
    useTimespacePersonHouseholdObjectsQuery({
      skip: !timespacePersonId,
      // @ts-expect-error skip query if id is null
      variables: { timespacePersonId },
    });

  const paymentObjects = workData?.timespacePersonObjects?.filter(
    (x) => x.status !== TimespacePersonObjectStatusType.Passive,
  );

  const monthlyIncome =
    paymentObjects?.reduce(
      (sum, { assetsImpactPeriodicalActive }) =>
        (assetsImpactPeriodicalActive ?? 0) > 0 ? sum + (assetsImpactPeriodicalActive ?? 0) : sum,
      0,
    ) ?? 0;

  const monthlyPayments =
    financialObjectsData?.timespacePersonFinancialObjects?.reduce(
      (sum, { actualValue, monthlyPayment, objectStatus }) =>
        (actualValue ?? 0) < 0 ||
        // NOTE: procured objects have 0 actualValue as they are not active yet
        objectStatus === TimespacePersonFinancialObjectObjectStatusType.Procured
          ? sum + (monthlyPayment ?? 0)
          : sum,
      0,
    ) ?? 0;

  const monthlyPaymentsForLoans =
    financialObjectsData?.timespacePersonFinancialObjects
      ?.filter(
        ({ financialObject }) =>
          financialObject?.code?.startsWith(FinancialObjectCodeEnum.MortgageLoan) ||
          financialObject?.code?.startsWith(FinancialObjectCodeEnum.ConsumerLoan),
      )
      .reduce(
        (sum, { actualValue, monthlyPayment, objectStatus }) =>
          (actualValue ?? 0) < 0 ||
          // NOTE: procured objects have 0 actualValue as they are not active yet
          objectStatus === TimespacePersonFinancialObjectObjectStatusType.Procured
            ? sum + (monthlyPayment ?? 0)
            : sum,
        0,
      ) ?? 0;

  const partnerIncome =
    householdObjectsData?.timespacePersonObjects?.reduce(
      (sum, { assetsImpactPeriodicalActive }) =>
        (assetsImpactPeriodicalActive ?? 0) > 0 ? sum + (assetsImpactPeriodicalActive ?? 0) : sum,
      0,
    ) ?? 0;

  const householdLivingWage =
    householdObjectsData?.timespacePersonObjects
      ?.filter(
        ({ object, status }) =>
          object?.code &&
          [HouseholdObjectCodes.PARTNER, HouseholdObjectCodes.CHILD].includes(
            object.code as (typeof HouseholdObjectCodes)[keyof typeof HouseholdObjectCodes],
          ) &&
          status !== TimespacePersonObjectStatusType.Discarded,
      )
      .reduce((sum, { object, procurementRound }) => {
        if (
          object?.code === HouseholdObjectCodes.PARTNER ||
          (object?.code === HouseholdObjectCodes.CHILD &&
            getObjectYearFromRound(procurementRound, timespacePerson?.timespace?.round) >= 18)
        ) {
          return sum + livingWageSums.PARTNER_OR_ADULT_CHILD;
        } else if (
          object?.code === HouseholdObjectCodes.CHILD &&
          (procurementRound ?? 0) < 18 * 12
        ) {
          return sum + livingWageSums.CHILD;
        } else {
          return sum;
        }
      }, 0) ?? 0;

  const livingWage =
    (age ?? 0) / 12 >= 18 ? householdLivingWage + livingWageSums.ADULT : householdLivingWage;

  const maxMonthlyPayment = (monthlyIncome + partnerIncome - livingWage) * 0.6 - monthlyPayments;

  return {
    data: {
      livingWage,
      maxMonthlyPayment,
      monthlyIncome,
      monthlyPayments,
      monthlyPaymentsForLoans,
      partnerIncome,
    },
    loading: workDataLoading || financialDataLoading || householdDataLoading,
    objects: {
      financialObjects: financialObjectsData?.timespacePersonFinancialObjects,
      householdObjects: householdObjectsData?.timespacePersonObjects,
      workObjects: workData?.timespacePersonObjects,
    },
  };
}
