import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useCallback } from 'react';
import { FieldArray, Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { inRange } from 'lodash';
import { RandomEventInput } from 'Models/RandomEventInput';
import TimespaceRandomEventTable from 'Components/Timespace/RandomEvent/TimespaceRandomEventTable';
import { useGenerateTimespaceInput } from 'Lib/Hooks/useGenerateTimespaceInput';
import {
  Object,
  OrderDirection,
  RandomEvent,
  namedOperations,
  useCreateMutualFundStatusesMutation,
  useCreateRandomEventsMutation,
  useCreateTimespaceMutation,
  useCreateTimespacePersonFinancialObjectsMutation,
  useCreateTimespacePersonObjectsMutation,
  useFinancialObjectsLazyQuery,
  useMeQuery,
  useTimespaceJoinMutation,
  useUpdateTimespaceMutation,
} from 'Generated/graphql-hooks';
import {
  AppBar,
  LabeledText,
  Section,
  TextFieldFormik,
  TimespacePersonFinancialObjectTable,
  TimespacePersonObjectTable,
} from 'Components';
import {
  RandomEventCreateInput,
  Timespace,
  TimespaceCreateInput,
  TimespacePersonFinancialObject,
  TimespacePersonFinancialObjectCreateInput,
  TimespacePersonObject,
  TimespacePersonObjectCreateInput,
} from 'Models';
import { ScreenPaths } from 'Config';
import { TimespaceSchema } from 'Config/Validations';
import ObjectTreeView from 'Components/TreeView/ObjectTreeView';
import { SelectFormik } from 'Components/Input/SelectFormik';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { useModal } from 'Lib/Hooks';
import AgeConflictModal from './AgeConflictModal';
import { getAgeConflictingObjects } from './utils';

interface FormikData extends Timespace {
  randomEvents: RandomEventInput[];
  timespacePersonFinancialObjects: TimespacePersonFinancialObject[];
  timespacePersonObjects: TimespacePersonObject[];
}

export default function TimespaceDetail() {
  const params = useParams<{ id?: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userQuery = useMeQuery();
  const { error, input, loading } = useGenerateTimespaceInput();
  const {
    handleClose: handleAgeConflictModalClose,
    handleOpen: handleAgeConflictModalOpen,
    isOpen: isAgeConflictModalOpen,
    selected: selectedAgeConflictingObjects,
  } = useModal<TimespacePersonObject[] | TimespacePersonFinancialObject[]>();

  const [joinTimespace] = useTimespaceJoinMutation();
  const [updateTimespace] = useUpdateTimespaceMutation();
  const [createTimespace] = useCreateTimespaceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [namedOperations.Query.Timespace, namedOperations.Query.Timespaces],
  });
  const [createTimespacePersonObjects] = useCreateTimespacePersonObjectsMutation();
  const [createTimespacePersonFinancialObjects] =
    useCreateTimespacePersonFinancialObjectsMutation();
  const [createRandomEvents] = useCreateRandomEventsMutation();
  const [loadFinancialObjects] = useFinancialObjectsLazyQuery();
  const [createMutualFundStatuses] = useCreateMutualFundStatusesMutation();

  const handleRemoveConflictingAndSubmit = (
    { timespacePersonFinancialObjects, timespacePersonObjects, ...values }: FormikData,
    formikHelpers: Pick<FormikHelpers<FormikData>, 'setSubmitting'>,
  ) => {
    formikHelpers.setSubmitting(true);
    handleSubmitFormik(
      {
        ...values,
        timespacePersonFinancialObjects: timespacePersonFinancialObjects?.filter((item) =>
          inRange(values?.personInitialAge, item?.ageMin ?? 0, item?.ageMax ?? Infinity),
        ),
        timespacePersonObjects: timespacePersonObjects?.filter((item) =>
          inRange(values?.personInitialAge, item?.ageMin ?? 0, item?.ageMax ?? Infinity),
        ),
      },
      formikHelpers,
    );
  };

  const handleSubmitFormik = useCallback(
    async (
      {
        defaultPaymentMethod,
        randomEvents,
        timespacePersonFinancialObjects,
        timespacePersonObjects,
        ...values
      }: FormikData,
      { setSubmitting }: Pick<FormikHelpers<FormikData>, 'setSubmitting'>,
    ) => {
      try {
        const ageConflictingObjects = getAgeConflictingObjects(
          values?.personInitialAge,
          timespacePersonObjects,
          timespacePersonFinancialObjects,
        );

        if (ageConflictingObjects?.length) {
          handleAgeConflictModalOpen(ageConflictingObjects);
          return;
        }

        const { data } = await createTimespace({
          variables: {
            data: new TimespaceCreateInput(
              { ...values, isPrototype: !params.id },
              userQuery?.data?.me,
            ),
          },
        });

        const { code, id: newId, isPrototype } = data?.createTimespace ?? {};
        if (newId) {
          await createTimespacePersonObjects({
            variables: {
              data: (() => {
                const objectPriorities: number[] = [];
                return timespacePersonObjects
                  .sort(
                    (a, b) =>
                      (a?.priority && a.priority.toString().length !== 0 ? a.priority : Infinity) -
                      (b?.priority && b.priority.toString().length !== 0 ? b.priority : Infinity),
                  )
                  .map(({ priority, ...item }) => {
                    //TODO: refactor cond
                    priority =
                      (priority?.toString() === '' ? undefined : priority) ??
                      objectPriorities.length + 1;
                    while (objectPriorities.indexOf(priority) !== -1) {
                      priority++;
                    }
                    objectPriorities.push(priority);
                    return new TimespacePersonObjectCreateInput(
                      // NOTE: disable max age for template objects
                      { ...item, ageMax: isPrototype ? null : item.ageMax, priority },
                      newId,
                    );
                  });
              })(),
            },
          });
          const newFinancialObjects = await createTimespacePersonFinancialObjects({
            variables: {
              data: timespacePersonFinancialObjects.map(
                (item) => new TimespacePersonFinancialObjectCreateInput(item, newId),
              ),
            },
          });
          if (newFinancialObjects.data?.createTimespacePersonFinancialObjects) {
            await updateTimespace({
              variables: {
                data: {
                  defaultPaymentMethod: {
                    connect: {
                      id: newFinancialObjects.data?.createTimespacePersonFinancialObjects?.find(
                        (x) =>
                          x?.financialObject?.code === defaultPaymentMethod?.financialObject?.code,
                      )?.id,
                    },
                  },
                },
                where: { id: newId },
              },
            });
          }

          await createRandomEvents({
            variables: {
              data: randomEvents.map(
                (item) => new RandomEventCreateInput(item as RandomEvent, newId),
              ),
            },
          });

          if (code && !isPrototype) {
            const fundFinancialObjects =
              (
                await loadFinancialObjects({
                  variables: {
                    orderBy: [{ name: OrderDirection.Asc }],
                    where: {
                      AND: [
                        { code: { startsWith: FinancialObjectCodeEnum.MutualFund } },
                        { code: { not: { equals: FinancialObjectCodeEnum.MutualFund } } },
                      ],
                    },
                  },
                })
              ).data?.financialObjects ?? [];

            if (fundFinancialObjects.length) {
              await createMutualFundStatuses({
                variables: {
                  data: fundFinancialObjects.map(({ id, initialSharePrice, initialVolume }) => ({
                    fund: {
                      connect: {
                        id,
                      },
                    },
                    round: 0,
                    sharePrice: initialSharePrice,
                    timespace: {
                      connect: {
                        id: newId,
                      },
                    },
                    totalVolume: initialVolume,
                  })),
                },
              });
            }

            await joinTimespace({
              refetchQueries: [namedOperations.Query.Timespaces],
              variables: { code: code },
            });
          }
        }

        if (data?.createTimespace?.id) {
          enqueueSnackbar(
            t(!params.id ? 'timespace.template.created' : 'timespace.message.created'),
            { variant: 'success' },
          );
          navigate(data.createTimespace.isPrototype ? ScreenPaths.Templates : ScreenPaths.Root);
        } else {
          throw new Error();
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
    [
      t,
      createTimespace,
      createTimespacePersonObjects,
      createTimespacePersonFinancialObjects,
      enqueueSnackbar,
      userQuery,
    ],
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" pt={4}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box display="flex" justifyContent="center" pt={4}>
        <Typography color="error">{error ?? t('errors.generic')}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Formik<FormikData>
        enableReinitialize
        initialValues={input}
        onSubmit={handleSubmitFormik}
        validateOnBlur={false}
        validationSchema={TimespaceSchema}>
        {({ handleSubmit, isSubmitting, setFieldValue, setSubmitting, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <AppBar
                right={
                  <Grid container display="flex" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        color="secondary"
                        component={RouterLink}
                        to={ScreenPaths.Root}
                        variant="outlined">
                        {t('global.actions.cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <LoadingButton
                        color="secondary"
                        loading={isSubmitting}
                        type="submit"
                        variant="contained">
                        <span>{t('global.actions.create')}</span>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                }
              />
              <Section
                title={t(
                  params.id ? 'timespace.create.from_template' : 'timespace.create.template',
                )}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextFieldFormik id="name" label={t('timespace.name')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      multiline
                      id="description"
                      label={t('timespace.description')}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      multiline
                      id="goalsDescription"
                      label={t('timespace.goalsDescription')}
                      rows={4}
                    />
                  </Grid>

                  <Grid item alignItems="center" display="flex" xs={6}>
                    <span>{t('timespace.roundsPerStep.prefix')}</span>
                    <Box
                      component={TextFieldFormik}
                      fullWidth={false}
                      id="roundsPerStep"
                      px={2}
                      type="number"
                      variant="standard"
                      width="20%"
                    />
                    <span>{t('timespace.roundsPerStep.suffix')}</span>
                  </Grid>

                  <Grid item alignItems="center" display="flex" xs={6}>
                    <span>{t('timespace.personInitialAge.prefix')}</span>
                    <Box
                      component={TextFieldFormik}
                      fullWidth={false}
                      id="personInitialAge"
                      px={2}
                      type="number"
                      variant="standard"
                      width="20%"
                    />
                    <span>{t('timespace.personInitialAge.suffix')}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <TimespaceRandomEventTable id="randomEvents" />
                  </Grid>
                  <Grid item xs={12}>
                    <TimespacePersonObjectTable
                      id="timespacePersonObjects"
                      initialAge={values?.personInitialAge}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TimespacePersonFinancialObjectTable
                      id="timespacePersonFinancialObjects"
                      initialAge={values?.personInitialAge}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabeledText
                      label={t('timespace.defaultPaymentMethod')}
                      value={
                        <SelectFormik<TimespacePersonFinancialObject>
                          fullWidth
                          id="defaultPaymentMethod"
                          options={values.timespacePersonFinancialObjects}
                          renderValue={(item) => item.name}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t('timespace.timespaceAvailableObjects')}
                    </Typography>
                    <FieldArray name="timespaceAvailableObjects">
                      {({ push, remove }) => (
                        <ObjectTreeView
                          hideDisabledCheckbox
                          disabledFunction={({ code }) => {
                            return code
                              ? values.timespaceAvailableObjects?.some(({ objectCode }) =>
                                  objectCode && objectCode.length !== code.length
                                    ? code.startsWith(objectCode)
                                    : false,
                                )
                              : false;
                          }}
                          onChange={(object, checked) => {
                            if (checked) {
                              push({ objectCode: object.code });
                            } else if (values.timespaceAvailableObjects) {
                              remove(
                                values.timespaceAvailableObjects.findIndex(
                                  (obj) => obj.objectCode === object.code,
                                ),
                              );
                            }
                          }}
                          onDataLoaded={
                            !params.id
                              ? (data) =>
                                  setFieldValue(
                                    'timespaceAvailableObjects',
                                    data.map((object) => ({ objectCode: object.code })),
                                  )
                              : undefined
                          }
                          onToggleAll={(data) =>
                            setFieldValue(
                              'timespaceAvailableObjects',
                              data.map((object) => ({ objectCode: object.code })),
                            )
                          }
                          values={(values.timespaceAvailableObjects ?? []).map(
                            (x) => ({ code: x.objectCode } as Object),
                          )}
                        />
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Section>
              {selectedAgeConflictingObjects ? (
                <AgeConflictModal
                  ageConflictingObjects={selectedAgeConflictingObjects}
                  isSubmitting={isSubmitting}
                  onClose={handleAgeConflictModalClose}
                  onConfirm={() => {
                    handleRemoveConflictingAndSubmit(values, { setSubmitting });
                  }}
                  open={isAgeConflictModalOpen}
                />
              ) : null}
            </Box>
          );
        }}
      </Formik>
    </Container>
  );
}
