import React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import { RadioGroupOption } from 'Interfaces/Global';

export interface RadioGroupProps<T> extends MuiRadioGroupProps {
  error?: string;
  id: string;
  label?: string;
  nullable?: boolean;
  options: RadioGroupOption<T>[];
  renderOptionAdornment?: (item: RadioGroupOption<T>) => JSX.Element;
}

export default function RadioGroup<T>({
  error,
  id,
  nullable = false,
  options,
  renderOptionAdornment,
  ...restProps
}: RadioGroupProps<T>): JSX.Element {
  const { t } = useTranslation();

  return (
    <MuiRadioGroup {...restProps} name={id}>
      {nullable && (
        <FormControlLabel
          control={<Radio style={error ? { color: '#f56565' } : {}} />}
          label={t('general.any_of_these').toString()}
          value={''}
        />
      )}
      {options.map((item, index) => (
        <Box key={index}>
          <FormControlLabel
            key={index}
            control={<Radio style={error ? { color: '#f56565' } : {}} />}
            label={item.label}
            value={item.value || ''}
          />
          {renderOptionAdornment?.(item) ?? null}
        </Box>
      ))}
      {error ? (
        <Box color="red" component={Typography} mx={1} variant="caption">
          {t(error)}
        </Box>
      ) : null}
    </MuiRadioGroup>
  );
}
