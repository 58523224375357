import {
  FinancialObject,
  TimespacePerson,
  TimespacePersonFinancialObject,
} from 'Generated/graphql-hooks';

export default class MortgageRefinanceInput {
  constructor(
    timespacePerson: TimespacePerson,
    livingWage: number,
    propertyPrice: number,
    familyIncome: number,
    maxMonthlyPayment: number,
    newMonthlyPayment: number,
    monthlyPaymentsWithoutCurrentMortgage: number,
    actualValue: number,
    realizationDay: number,
  ) {
    this.timespacePerson = timespacePerson;
    this.livingWage = livingWage;
    this.propertyPrice = propertyPrice;
    this.monthlyIncome = familyIncome;
    this.realizationDay = realizationDay;
    this.maxMonthlyPayment = maxMonthlyPayment;
    this.monthlyPayment = newMonthlyPayment;
    this.monthlyPayments = monthlyPaymentsWithoutCurrentMortgage;
    this.mortgageBalance = actualValue;
  }

  accountFinancialObject: TimespacePersonFinancialObject | null = null;
  livingWage = 0;
  maturityPeriod = 30;
  maxMonthlyPayment: number;
  monthlyIncome: number;
  monthlyPayment: number;
  monthlyPayments: number;
  mortgageBalance: number;
  mortgageFinancialObject: FinancialObject | null = null;
  payment = 0;
  propertyPrice: number;
  realizationDay: number;
  refinance = false;
  timespacePerson?: TimespacePerson | null;
}
