import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import theme from 'Theme';

interface Props {
  action?: JSX.Element | Array<JSX.Element | null>;
  children?: React.ReactNode;
  subTitle?: string | JSX.Element;
  title?: string | JSX.Element;
}

export default function Section({ action, children, subTitle, title }: Props): JSX.Element {
  return (
    <Box component={Paper} height="100%" overflow="hidden" pb={2} variant="outlined">
      {title || subTitle ? (
        <Box px={4} py={3}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <div>
              {title && (
                <Typography
                  color={theme.palette.primary.dark}
                  fontWeight="bold"
                  gutterBottom={false}
                  pr={3}
                  variant="h6">
                  {title}
                </Typography>
              )}
              {subTitle && <Typography color={theme.palette.grey['600']}>{subTitle}</Typography>}
            </div>
            {action}
          </Box>
        </Box>
      ) : null}
      <Box position="relative" px={4}>
        {children}
      </Box>
    </Box>
  );
}
