import { Constants } from 'Config';
import {
  StandingOrder as IStandingOrder,
  InputMaybe,
  Scalars,
  StandingOrderStatusType,
  TimespacePersonFinancialObject,
} from 'Generated/graphql-hooks';

export default class StandingOrder implements Nullable<IStandingOrder> {
  constructor(object?: IStandingOrder | null) {
    this.id = object?.id ?? null;
    this.amount = object?.amount;
    this.beneficiaryAccount = object?.beneficiaryAccount ? object.beneficiaryAccount : undefined;
    this.constantSymbol = object?.constantSymbol;
    this.expirationDate = {
      day: object?.expirationDay ?? 1,
      month: object?.expirationRound ? object.expirationRound % 12 : 1,
      year: object?.expirationRound
        ? Math.floor(object.expirationRound / 12) + Constants.START_YEAR
        : Constants.START_YEAR,
    };
    this.expirationOnExecutionCount = object?.expirationOnExecutionCount ?? 0;
    this.initialDate = {
      day: object?.initialDay ?? 1,
      month: object?.initialRound ? object.initialRound % 12 : 1,
      year: object?.initialRound
        ? Math.floor(object.initialRound / 12) + Constants.START_YEAR
        : Constants.START_YEAR,
    };
    this.name = object?.name ?? '';
    this.payersAccount = object?.payersAccount ? object.payersAccount : undefined;
    this.period = object?.period ?? 1;
    this.specificSymbol = object?.specificSymbol ?? '';
    this.status = object?.status ?? StandingOrderStatusType.Active;
    this.textMessage = object?.textMessage ?? '';
    this.variableSymbol = object?.variableSymbol ?? '';
  }

  amount?: InputMaybe<Scalars['Float']['input']>;
  beneficiaryAccount?: InputMaybe<TimespacePersonFinancialObject>;
  constantSymbol?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<{ day: number; month: number; year: number }>;
  expirationOnExecutionCount?: InputMaybe<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  initialDate?: InputMaybe<{ day: number; month: number; year: number }>;
  name?: InputMaybe<Scalars['String']['input']>;
  payersAccount?: InputMaybe<TimespacePersonFinancialObject>;
  period?: InputMaybe<Scalars['Int']['input']>;
  specificSymbol?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StandingOrderStatusType>;
  textMessage?: InputMaybe<Scalars['String']['input']>;
  variableSymbol?: InputMaybe<Scalars['String']['input']>;
}
