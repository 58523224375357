import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import {
  FinancialObject,
  OrderDirection,
  useFinancialObjectsLazyQuery,
} from 'Generated/graphql-hooks';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { GameContext } from 'Context';
import { EnhancedDataTable, SkeletonLoader } from 'Components/Generic';
import {
  calculateInterest,
  calculateMonthlyPayment,
  calculatePV,
  mortgageOptionsTableColumns,
} from './utils';

interface Props {
  maturityPeriod: number;
  maxMonthlyPayment: number;
  mortgageAmount: number;
  onRowSelected: (item: { financialObject: FinancialObject; mortgageAmount: number }) => void;
  refinance: boolean;
  value: FinancialObject | null;
}

export default memo(function MortgageOptionsTable({
  maturityPeriod,
  maxMonthlyPayment,
  mortgageAmount,
  onRowSelected,
  refinance,
  value,
}: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const [loadFinancialObjects, { data, loading }] = useFinancialObjectsLazyQuery({
    variables: {
      orderBy: [{ name: OrderDirection.Asc }],
      where: {
        AND: [
          { code: { startsWith: FinancialObjectCodeEnum.MortgageLoan } },
          { isAbstract: { equals: false } },
          { maturityPeriod: { gte: maturityPeriod * 12 } },
          // NOTE: principalMin has may have negative values
          {
            OR: [
              { principalMin: { lt: -Math.abs(mortgageAmount) } },
              { principalMin: { gt: Math.abs(mortgageAmount) } },
            ],
          },
        ],
      },
    },
  });

  useEffect(() => {
    loadFinancialObjects();
  }, [maturityPeriod, mortgageAmount]);

  const preparedData = useMemo(
    () =>
      data?.financialObjects?.map((item) => {
        const interest = calculateInterest(item, timespacePerson?.timespace);
        let monthlyPayment = calculateMonthlyPayment({
          financialObject: item,
          maturityPeriod: maturityPeriod * 12,
          mortgageAmount,
          timespace: timespacePerson?.timespace,
        });

        let finalMortgageAmount = mortgageAmount;
        if (!refinance && monthlyPayment > maxMonthlyPayment) {
          finalMortgageAmount = Math.abs(
            calculatePV(interest / 100 / 12, maturityPeriod * 12, maxMonthlyPayment),
          );

          monthlyPayment = maxMonthlyPayment;
        }

        return {
          financialObject: item,
          fixationPeriod: ((item.fixationPeriod ?? 0) / 12).toFixed(),
          interest: interest,
          maturityPeriod,
          monthlyPayment: monthlyPayment,
          mortgageAmount: finalMortgageAmount,
          startingFee:
            (item.feesOneTime ?? 0) +
            ((item.feesOneTimePercent ?? 0) / 100 / 12) * (finalMortgageAmount ?? 0),
        };
      }) ?? [],
    [data?.financialObjects, mortgageAmount, timespacePerson?.timespace],
  );

  const handleRowSelectionChange = useCallback(
    (_, __, selectedRows: any[]) => {
      if (preparedData) {
        onRowSelected(preparedData?.[selectedRows[0]]);
      }
    },
    [preparedData],
  );

  const selectedRowIndex = data?.financialObjects?.findIndex(({ id }) => id === value?.id) ?? -1;

  return (
    <EnhancedDataTable
      hideToolbar
      columns={mortgageOptionsTableColumns}
      data={preparedData}
      options={
        {
          onRowSelectionChange: handleRowSelectionChange,
          rowsSelected: selectedRowIndex > -1 ? [selectedRowIndex] : null,
          selectableRows: 'single',
          selectableRowsOnClick: true,
          textLabels: {
            body: {
              noMatch: loading ? (
                <SkeletonLoader height={60} rows={4} />
              ) : (
                t('global.placeholder.empty')
              ),
            },
          },
        } as MUIDataTableOptions
      }
      title={''}
    />
  );
});
