import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import RadioGroup, { RadioGroupProps } from './RadioGroup';

export default function RadioGroupFormik<T>({ id, ...restProps }: RadioGroupProps<T>): JSX.Element {
  const { t } = useTranslation();
  const [{ onChange, value }, { error, touched }] = useField(id);

  return (
    <RadioGroup
      {...restProps}
      error={error && touched ? error : undefined}
      id={id}
      name={id}
      onChange={onChange}
      value={value || ''}
    />
  );
}
