import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  OrderDirection,
  useMyTimespacePersonQuery,
  useTimespacePersonObjectsCodeListQuery,
} from 'Generated/graphql-hooks';
import {
  GlobalObjectCodeEnum,
  GoodsObjectCodeEnum,
  HouseholdObjectCodes,
  LivingObjectCodeEnum,
  OcupationObjectCodeEnum,
} from 'Types/Global';
import { ScreenPaths } from 'Config';

interface Props {
  timespaceId: string;
}

export function useGameContextData({ timespaceId }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { data, error, loading } = useMyTimespacePersonQuery({
    fetchPolicy: 'cache-and-network',
    skip: !parseInt(timespaceId) || !timespaceId,
    variables: { timespaceId: parseInt(timespaceId) },
  });

  const {
    called,
    data: timespacePersonObjectsData,
    loading: timespacePersonObjectsLoading,
  } = useTimespacePersonObjectsCodeListQuery({
    fetchPolicy: 'cache-and-network',
    skip: !data?.myTimespacePerson?.id,
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        AND: [
          {
            OR: [
              { object: { code: { startsWith: GlobalObjectCodeEnum.Education } } },
              { object: { code: { startsWith: OcupationObjectCodeEnum.Ocupation } } },
              { object: { code: { startsWith: HouseholdObjectCodes.FAMILYMEMBERS } } },
            ],
          },
          { timespacePerson: { id: { equals: data?.myTimespacePerson?.id } } },
        ],
      },
    },
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      navigate(ScreenPaths.Timespaces);
    }
  }, [enqueueSnackbar, navigate, error]);

  return {
    error: error || !data?.myTimespacePerson,
    hasAvailableCarshopObjects: data?.myTimespacePerson?.timespace?.timespaceAvailableObjects?.some(
      (object) => object?.objectCode?.startsWith(GoodsObjectCodeEnum.Car),
    ),
    hasAvailableEducationObjects:
      data?.myTimespacePerson?.timespace?.timespaceAvailableObjects?.some((object) =>
        object?.objectCode?.startsWith(GlobalObjectCodeEnum.Education),
      ),
    hasAvailableEshopObjects: data?.myTimespacePerson?.timespace?.timespaceAvailableObjects?.some(
      (object) =>
        Object?.values(GoodsObjectCodeEnum).some((code) => object?.objectCode?.startsWith(code)) &&
        !object?.objectCode?.startsWith(GoodsObjectCodeEnum.Car),
    ),
    hasAvailableLivingObjects: data?.myTimespacePerson?.timespace?.timespaceAvailableObjects?.some(
      (object) => object?.objectCode?.startsWith(LivingObjectCodeEnum.Living),
    ),
    hasAvailableWorkObjects: data?.myTimespacePerson?.timespace?.timespaceAvailableObjects?.some(
      (object) => object?.objectCode?.startsWith(OcupationObjectCodeEnum.Ocupation),
    ),
    hasEducationOrWork: timespacePersonObjectsData?.timespacePersonObjects?.some(
      (object) =>
        object?.object?.code?.startsWith(OcupationObjectCodeEnum.Ocupation) ||
        object?.object?.code?.startsWith(GlobalObjectCodeEnum.Education),
    ),
    isHouseholdSectionAvailable:
      timespacePersonObjectsData?.timespacePersonObjects?.some((object) =>
        object?.object?.code?.startsWith(HouseholdObjectCodes.FAMILYMEMBERS),
      ) ||
      data?.myTimespacePerson?.timespace?.timespaceAvailableObjects?.some((object) =>
        object?.objectCode?.startsWith(HouseholdObjectCodes.FAMILYMEMBERS),
      ),
    loading: loading || timespacePersonObjectsLoading || !called,
    myTimespacePerson: data?.myTimespacePerson,
  };
}
