import React from 'react';
import { useTranslation } from 'react-i18next';
import FinancialObjectSelector from 'Components/Eshop/FinancialObjectSelector';
import type { Props as FinancialObjectSelectorProps } from 'Components/Eshop/FinancialObjectSelector';
import { FinancialObjectCodeEnum } from 'Types/Global';

export default function BasicAccountSelector(props: Omit<FinancialObjectSelectorProps, 'where'>) {
  const { t } = useTranslation();
  return (
    <FinancialObjectSelector
      {...props}
      noDataPlaceholder={t('bank.no.basic.account')}
      where={{
        financialObject: {
          code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount },
        },
      }}
    />
  );
}
