import React from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import RadioGroup, { RadioGroupProps } from './RadioGroup';

export interface RadioGroupHFProps<T>
  extends Omit<RadioGroupProps<T>, 'name'>,
    UseControllerProps<any, string> {}

export default function RadioGroupHF<T>({
  control,
  name,
  ...props
}: RadioGroupHFProps<T>): JSX.Element {
  if (!control || !name) {
    throw new Error('Control and name must be defined');
  }
  const {
    field,
    fieldState: { error, isTouched },
  } = useController<FieldValues>({ control, name });

  return (
    <RadioGroup {...props} error={isTouched && error ? error.message : undefined} {...field} />
  );
}
