import React, { useContext, useState } from 'react';
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';
import { NotificationsActive, NotificationsOutlined, PriorityHigh } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import RandomEventHistoryModal from 'Components/RandomEvent/RandomEventHistoryModal';
import { useModal } from 'Lib/Hooks';
import RandomEventUserInputModal from 'Components/RandomEvent/RandomEventUserInputModal';
import {
  RandomEventHistory,
  TimespacePersonRandomEvent,
  useTimespacePersonRandomEventsQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { FinancialObjectCodeEnum } from '../Types/Global';
import MortgageRefinanceUserInputModal from './RandomEvent/MortgageRefinanceUserInputModal';

const RingAnimate = styled('div')({
  '@keyframes ring': {
    '0%': { transform: 'translate3d(0em, 0, 0)' },
    '10%': { transform: 'translate3d(0.06em, 0, 0)' },
    '12%': { transform: 'translate3d(-0.06em, 0, 0)' },
    '14%': { transform: 'translate3d(0.06em, 0, 0)' },
    '16%': { transform: 'translate3d(-0.06em, 0, 0)' },
    '18%': { transform: 'translate3d(0.06em, 0, 0)' },
    '2%': { transform: 'translate3d(0.06em, 0, 0)' },
    '20%': { transform: 'translate3d(-0.06em, 0, 0)' },
    '22%': { transform: 'translate3d(0.06em, 0, 0)' },
    '24%': { transform: 'translate3d(-0.06em, 0, 0)' },
    '26%': { transform: 'translate3d(0.06em, 0, 0)' },
    '28%': { transform: 'translate3d(0, 0, 0)' },
    '30%': { transform: 'rotate(-25deg)' },
    '35%': { transform: 'rotate(25deg)' },
    '4%': { transform: 'translate3d(-0.06em, 0, 0)' },
    '40%': { transform: 'rotate(-25deg)' },
    '45%': { transform: 'rotate(25deg)' },
    '50%': { transform: 'rotate(-25deg)' },
    '55%': { transform: 'rotate(25deg)' },
    '6%': { transform: 'translate3d(0.06em, 0, 0)' },
    '60%': { transform: 'rotate(0)' },
    '8%': { transform: 'translate3d(-0.06em, 0, 0)' },
  },
  animation: 'ring 3s infinite ease',
});

export default function NotificationsMenuItem(): JSX.Element {
  const { t } = useTranslation();
  const userInputModal = useModal<TimespacePersonRandomEvent>();
  const randomEventHistoryModal = useModal<RandomEventHistory>();
  const { timespacePerson } = useContext(GameContext);
  const { data, loading } = useTimespacePersonRandomEventsQuery({
    skip: !timespacePerson?.id,
    variables: {
      timespacePersonId: timespacePerson?.id,
    },
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const randomEvents = data?.timespacePersonRandomEvents?.filter(
    (x) =>
      !(
        x.randomEvent?.description?.startsWith(FinancialObjectCodeEnum.MortgageLoan) &&
        (x.randomEvent?.randomEventFinancialObjects?.length || 0) === 0
      ),
  );

  const isMortgageEvent =
    userInputModal.selected?.randomEvent?.description &&
    userInputModal.selected?.randomEvent?.description.startsWith(
      FinancialObjectCodeEnum.MortgageLoan,
    );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const randomEventCount =
    (randomEvents?.length || 0) +
    (data?.randomEventHistories?.filter((x) => x.visited === false)?.length || 0);

  return (
    <Box display="flex" position="relative">
      {loading ? (
        <Skeleton height={60} width={60} />
      ) : (
        <div>
          <Box component={IconButton} onClick={handleClick}>
            {randomEventCount > 0 ? (
              <Badge
                badgeContent={randomEventCount}
                className="MuiBadge-pulseAnimate"
                color="error">
                <RingAnimate>
                  <NotificationsActive style={{ color: 'white' }} />
                </RingAnimate>
              </Badge>
            ) : (
              <NotificationsOutlined style={{ color: 'white' }} />
            )}
          </Box>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            id="notifications-popover"
            onClose={handleClose}
            open={Boolean(anchorEl)}>
            <Box
              component={List}
              maxHeight={300}
              minWidth={200}
              overflow="auto"
              p={0}
              position="relative"
              subheader={<li />}
              sx={{
                '& ul': { padding: 0 },
                bgcolor: 'background.paper',
              }}
              width="100%">
              {randomEvents && userInputModal?.selected ? (
                isMortgageEvent ? (
                  <MortgageRefinanceUserInputModal
                    onClose={userInputModal.handleClose}
                    open={userInputModal.isOpen}
                    timespacePersonRandomEvent={userInputModal?.selected}
                  />
                ) : (
                  <RandomEventUserInputModal
                    onClose={userInputModal.handleClose}
                    open={userInputModal.isOpen}
                    timespacePersonRandomEvent={userInputModal.selected}
                  />
                )
              ) : (
                <></>
              )}
              {randomEvents && randomEvents.length > 0 && (
                <li>
                  <ul>
                    <ListSubheader
                      sx={{
                        overflowWrap: 'never',
                      }}>
                      {t('randomEvent.header.action.required')}
                    </ListSubheader>
                    {randomEvents?.map((item, index) => (
                      <ListItemButton
                        key={`item-${index}-${item?.randomEvent?.name}`}
                        onClick={() => userInputModal.handleOpen(item)}>
                        <ListItemText primary={`${item?.randomEvent?.name}`} />
                        <PriorityHigh color="error" />
                      </ListItemButton>
                    ))}
                  </ul>
                </li>
              )}
              <RandomEventHistoryModal
                onClose={randomEventHistoryModal.handleClose}
                open={randomEventHistoryModal.isOpen}
                randomEventHistory={randomEventHistoryModal.selected}
              />
              <li>
                <ul>
                  <ListSubheader>{t('randomEvent.header.history')}</ListSubheader>
                  {data?.randomEventHistories?.length ? (
                    data?.randomEventHistories.map((item, index) => (
                      <Box
                        key={`item-${index}-${item?.name}`}
                        component={ListItemButton}
                        onClick={() =>
                          randomEventHistoryModal.handleOpen(item as RandomEventHistory)
                        }
                        py={1}>
                        <ListItemText
                          primary={`${item?.name}`}
                          secondary={
                            <Typography gutterBottom={false} variant="caption">
                              {getHumanDateFromRound(item.executionRound!)}
                            </Typography>
                          }
                          sx={{ margin: 0 }}
                        />
                        {!item.visited && <PriorityHigh color="error" />}
                      </Box>
                    ))
                  ) : (
                    <ListItem>
                      <Box
                        component={ListItemText}
                        display="flex"
                        justifyContent="center"
                        primary={
                          <Typography textAlign="center" variant="caption">
                            {t('global.placeholder.empty')}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </ul>
              </li>
            </Box>
          </Popover>
        </div>
      )}
    </Box>
  );
}
