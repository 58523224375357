import React, { useContext } from 'react';
import { Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BasicAccountSelector, LabeledText, SelectFormik, TextFieldFormik } from 'Components';
import {
  FinancialObject,
  OrderDirection,
  TimespacePersonFinancialObject,
  useMutualFundStatusesQuery,
} from 'Generated/graphql-hooks';
import { MutualFundTransactionSellSchema } from 'Config/Validations';
import { formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { Constants } from 'Config';

export type FormValues = {
  day?: number | null;
  relatedBankAccount?: TimespacePersonFinancialObject | null;
  volume?: number | null;
};
interface Props {
  fundFinancialObject?: FinancialObject | null;
  initialValues: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<any>;
}

export default function MutualFundTransactionSellForm({
  fundFinancialObject,
  initialValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { data, loading } = useMutualFundStatusesQuery({
    variables: {
      orderBy: { id: OrderDirection.Desc },
      where: {
        fund: {
          code: {
            equals: fundFinancialObject?.code,
          },
        },
        round: {
          equals: Math.max((timespacePerson.timespace?.round ?? 0) - 1, 0),
        },
        timespace: { id: { equals: timespacePerson.timespace?.id } },
      },
    },
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" py={5}>
        <CircularProgress size={30} />
      </Box>
    );
  }

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={MutualFundTransactionSellSchema}>
      {({ handleSubmit, isSubmitting, isValid, submitForm, values }) => {
        const estimatedFundValue =
          (data?.mutualFundStatuses?.[0]?.sharePrice ?? 0) * (values.volume ?? 0);
        const estimatedFundValueNetto =
          estimatedFundValue - estimatedFundValue * (fundFinancialObject?.entryFee ?? 0);

        return (
          <Box noValidate component="form" onSubmit={handleSubmit}>
            <LabeledText
              label={t('investments.invest.volume')}
              value={
                <TextFieldFormik
                  InputProps={{ inputProps: { min: 0 } }}
                  id="volume"
                  placeholder={t('global.placeholder.count')}
                  type="number"
                />
              }
            />
            <LabeledText
              label={t('investments.invest.estimatedFundValue')}
              sx={{ py: 2 }}
              value={formatPrice(estimatedFundValue)}
            />
            <LabeledText
              label={t('investments.invest.estimatedFundValueNetto')}
              sx={{ py: 2 }}
              value={formatPrice(estimatedFundValueNetto)}
            />
            <LabeledText
              label={t('investments.invest.day.sell')}
              value={
                <SelectFormik
                  fullWidth
                  id="day"
                  options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
                />
              }
            />
            <LabeledText
              label={t('object.attr.relatedFinancialObject')}
              value={<BasicAccountSelector id="relatedBankAccount" />}
            />

            <Box display="flex" justifyContent="flex-end" mt={3}>
              <LoadingButton
                disabled={!isValid}
                loading={isSubmitting}
                onClick={submitForm}
                variant="contained">
                <span>{t('investments.actions.sell')}</span>
              </LoadingButton>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
