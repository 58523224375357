import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useQuery from 'Lib/Hooks/useQuery';
import { useVerifyUserMutation } from 'Generated/graphql-hooks';
import { ScreenPaths } from 'Config';

export default function Verification() {
  const navigate = useNavigate();
  const query = useQuery(),
    token = query.get('token') || '';
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [verifyUser] = useVerifyUserMutation();

  const handleVerify = async () => {
    try {
      if (!token) {
        throw new Error('Token not found in url');
      }
      const { data } = await verifyUser({ variables: { token } });

      if (!data?.verifyUser) {
        throw new Error('Verification failed');
      }

      enqueueSnackbar(t('user.verification.success'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      navigate(ScreenPaths.Login, { replace: true });
    }
  };

  useEffect(() => {
    handleVerify();
  }, []);

  return (
    <Box display="flex" justifyContent="center" pt={4}>
      <CircularProgress />
    </Box>
  );
}
