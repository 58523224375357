import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Divider, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { InvestlandLogoVertical, logo5Penazi, logoIBVNBS, logoIndicia } from 'Assets';
import { ABOUT_PROJECT_URL } from 'Config/Constants';
import './auth.css';

export default function AuthLayout() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box
          alignItems="center"
          display={{ md: 'none', xs: 'flex' }}
          justifyContent="center"
          py={2}>
          <InvestlandLogoVertical color={theme.palette.primary.main} height={128} width={128} />
        </Box>
        <Box
          alignItems="center"
          component="main"
          display="flex"
          flex="1"
          justifyContent={{ md: 'flex-start', xs: 'center' }}
          minHeight={{ md: '100vh' }}
          pb={8}
          pl={{ lg: '40%', md: '45%', xs: 0 }}
          pt={{ md: 8, xs: 4 }}>
          <Box maxWidth={768} px={{ lg: 16, md: 8, xs: 4 }}>
            <Outlet />
          </Box>
        </Box>
        <Box
          alignItems="center"
          bgcolor="primary.main"
          className="sponsor-section"
          display="flex"
          flexDirection="column"
          height={{ md: '100%', xs: 'auto' }}
          justifyContent="center"
          left="0"
          minHeight={216}
          position={{ md: 'fixed' }}
          right={{ lg: '60%', md: '55%', xs: 0 }}
          top="0">
          <Box display={{ md: 'block', xs: 'none' }} flex={1} height="50%" py={6} width="50%">
            <InvestlandLogoVertical color="white" height="100%" width="100%" />
          </Box>
          <Box pb={2} px={{ sm: 6, xs: 4 }}>
            <Box alignItems="center" display="flex" justifyContent="center" pb={2} pt={4}>
              <Box>
                <a href="https://www.indicia.sk/" rel="noopener noreferrer" target="_blank">
                  <img alt="indicia.sk" className="sponsor-logo" height="56" src={logoIndicia} />
                </a>
              </Box>
            </Box>
            <Box display={{ md: 'block', xs: 'none' }}>
              <Divider color="white" sx={{ opacity: 0.2 }} />
            </Box>
            <Box alignItems="center" display="flex" justifyContent="center" pb={2} pt={4}>
              <Box pr={4}>
                <a href="https://5penazi.sk/" rel="noopener noreferrer" target="_blank">
                  <img alt="5peňazí" className="sponsor-logo" height="56" src={logo5Penazi} />
                </a>
              </Box>
              <Box>
                <a href="https://www.ibv-nbs.sk/" rel="noopener noreferrer" target="_blank">
                  <img
                    alt="Inštitút bankového vzdelávania NBS, n. o."
                    className="sponsor-logo"
                    height="56"
                    src={logoIBVNBS}
                  />
                </a>
              </Box>
            </Box>
            <Typography
              className="sponsor-text"
              color="white"
              fontSize={13}
              lineHeight={1.25}
              maxWidth={440}
              mx={{ sm: 0, xs: -0.5 }}
              sx={{ opacity: 0.8 }}
              textAlign="center"
              variant="body1">
              {t('global.sponsors_description')}{' '}
              <Link
                color="inherit"
                href={ABOUT_PROJECT_URL}
                rel="noopener noreferrer"
                target="_blank"
                whiteSpace="nowrap">
                {t('global.more_about_project')}.
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </GoogleReCaptchaProvider>
  );
}
