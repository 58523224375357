import * as Yup from 'yup';
import { t } from 'i18next';
import { mortgageMaturityPeriodLimits, realizationDayLimits } from 'Config/FinancialConstants';
import { Constants } from 'Config';

export default Yup.object({
  amount: Yup.number()
    .test('amount', 'mortgage.limitExceeded', function test(amount = 0) {
      // @ts-expect-error not typed yet
      return amount <= this.options.parent.maxMortgageAmount;
    })
    .required('validations.required'),
  maturityPeriod: Yup.number()
    .test(
      'maturityPeriod',
      'mortgage.ageLimitExceeded',
      function test(maturityPeriod = 0, { createError }) {
        // @ts-expect-error not typed yet
        const { timespacePerson } = this.options.parent ?? {};
        const resultAge = timespacePerson.age / 12 + maturityPeriod;

        return resultAge < Constants.MORTGAGE_MAX_AGE
          ? true
          : createError({
              message: t('mortgage.ageLimitExceeded', {
                replace: {
                  ageMax: Constants.MORTGAGE_MAX_AGE,
                },
              }),
            });
      },
    )
    .required('validations.required')
    .min(mortgageMaturityPeriodLimits.min, 'mortgage.validations.maturityPeriodLimits')
    .max(mortgageMaturityPeriodLimits.max, 'mortgage.validations.maturityPeriodLimits'),
  mortgageFinancialObject: Yup.mixed().required(),
  ownFunds: Yup.number()
    .test('ownFunds', 'mortgage.funds.limitExceed', function test(ownFunds = 0) {
      return (
        // @ts-expect-error not typed yet
        ownFunds < (this.options.parent?.paymentSource?.actualValue ?? 0)
      );
    })
    .required('validations.required')
    .nullable(),
  paymentSource: Yup.object().required('validations.required'),
  realizationDay: Yup.number()
    .min(realizationDayLimits.min, 'validations.between1and30')
    .max(realizationDayLimits.max, 'validations.between1and30')
    .required('validations.required'),
});
