// eslint-disable-next-line no-shadow
export enum TimespacePaths {
  Root = '/timespace',
  Bank = '/bank',
  Eshop = '/eshop',
  Living = '/living',
  Carshop = '/carshop',
  Holdings = 'holdings',
  Investments = '/investments',
  MutualFunds = 'mutual-funds',
  TimeManagement = 'free-time',
  Management = 'management',
  EducationAndWork = 'education-and-work',
  Household = 'household',
  PersonalBudget = 'personal-budget',
  LifeQuality = 'life-quality',
  MyStuff = 'my-stuff',
  RandomEvent = 'random-event',
}
