import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  OrderDirection,
  useMeQuery,
  useMutualFundInvestorStatusesQuery,
  useTimespacePersonFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { EnhancedDataTable, Error, Section } from 'Components';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { financialColumns } from './utils';

export default function FinancialTable() {
  const { t } = useTranslation();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { data: userData } = useMeQuery();

  const { data, error, loading } = useTimespacePersonFinancialObjectsQuery({
    skip: !userData?.me?.id,
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        OR: [
          {
            OR: [
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.Cash },
                },
              },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.FixedTermDepositAccount },
                },
              },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.SavingAccount },
                },
              },
            ],
          },
          {
            actualValue: { gt: 0 },
            financialObject: {
              code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount },
            },
          },
        ],
        timespacePerson: {
          id: { equals: timespacePerson.id },
          timespace: { id: { equals: timespaceId } },
        },
      },
    },
  });

  const {
    data: mutualFundData,
    error: mutualFundError,
    loading: mutualFundLoading,
  } = useMutualFundInvestorStatusesQuery({
    variables: {
      orderBy: [{ id: OrderDirection.Desc }],
      where: {
        person: { id: { equals: timespacePerson?.id } },
      },
    },
  });

  const preparedData = useMemo(
    () =>
      data?.timespacePersonFinancialObjects
        ?.map(({ actualValue, customName, financialObject, id, interestPositive, name }) => {
          return {
            actualValue,
            customName,
            id,
            interest:
              financialObject?.code?.startsWith(FinancialObjectCodeEnum.BasicBankAccount) ||
              financialObject?.code?.startsWith(FinancialObjectCodeEnum.Cash)
                ? undefined
                : interestPositive,
            name,
          };
        })
        .concat(
          mutualFundData?.mutualFundInvestorStatuses?.map(
            ({ finalVolume, id, mutualFundStatus }) => {
              return {
                actualValue: (finalVolume ?? 0) * (mutualFundStatus?.sharePrice ?? 0),
                customName: '',
                fees: mutualFundStatus?.fund?.managementFee ?? null,
                id,
                interest: undefined,
                name: mutualFundStatus?.fund?.name,
              };
            },
          ) ?? [],
        ) ?? [],
    [data?.timespacePersonFinancialObjects, mutualFundData?.mutualFundInvestorStatuses],
  );

  if (error || mutualFundError) {
    return <Error />;
  }

  return (
    <Section title={t('holdings.financial')}>
      <EnhancedDataTable
        hideToolbar
        columns={financialColumns()}
        data={preparedData}
        loading={loading || mutualFundLoading}
        options={
          {
            setRowProps: (row, dataIndex) => {
              return {
                onMouseEnter: () => {
                  console.log(
                    data?.timespacePersonFinancialObjects &&
                      data.timespacePersonFinancialObjects[dataIndex],
                  );
                },
              };
            },
            sort: false,
          } as MUIDataTableOptions
        }
        title={''}
      />
    </Section>
  );
}
