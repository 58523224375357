import { Menu, MenuOpen } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useCallback } from 'react';

const MenuButton = ({
  drawerOpen,
  onDrawerOpen,
}: {
  drawerOpen: boolean;
  onDrawerOpen: (open: boolean) => void;
}) => {
  const handleDrawerOpen = useCallback(() => onDrawerOpen(!drawerOpen), [onDrawerOpen, drawerOpen]);

  return (
    <IconButton
      aria-label={`${drawerOpen ? 'Close' : 'Open'} Menu`}
      onClick={handleDrawerOpen}
      size="large">
      {drawerOpen ? <MenuOpen sx={{ color: 'white' }} /> : <Menu sx={{ color: 'white' }} />}
    </IconButton>
  );
};

export default MenuButton;
