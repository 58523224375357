import React, { useRef } from 'react';
import type { FormikTouched } from 'formik';
import { useFormikContext } from 'formik';
import { isEqual, pick } from 'lodash';
import { useDebouncedEffect } from 'Lib/Hooks';

export default function FormikTrigger<T = any>({
  delay = 500,
  skipInitialRender,
  trigger,
  watchProperties,
}: {
  delay?: number;
  skipInitialRender?: boolean;
  trigger: (
    oldValues: T,
    formikHelpers: {
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
      setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean | undefined) => void;
      setValues: (newValues: React.SetStateAction<T>, shouldValidate?: boolean) => void;
    },
  ) => void;
  watchProperties: string[];
}) {
  const didMountRef = useRef(!skipInitialRender);
  const { setFieldValue, setTouched, setValues, values } = useFormikContext<T>();
  const observedValues = pick(values, watchProperties);
  const observedValuesRef = useRef(observedValues);
  if (!isEqual(observedValuesRef.current, observedValues)) {
    observedValuesRef.current = pick(values, watchProperties);
  }
  useDebouncedEffect(
    () => {
      if (didMountRef.current) {
        trigger(values, { setFieldValue, setTouched, setValues });
      } else {
        didMountRef.current = true;
      }
    },
    delay,
    [observedValuesRef.current],
  );

  return null;
}
