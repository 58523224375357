import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, DialogContent } from '@mui/material';
import {
  MutualFundStatusFragment,
  OrderDirection,
  useMutualFundStatusesQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { ModalCloseButton } from 'Components/Generic';
import MutualFundStatusInfoSection from './InfoSection';

interface Props {
  mutualFundStatus?: MutualFundStatusFragment | null;
  onClose: () => void;
  open: boolean;
}

export default function MutualFundStatusDetialModal({
  mutualFundStatus,
  onClose: handleClose,
  open,
}: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);

  const { data: statusesData, loading } = useMutualFundStatusesQuery({
    skip: !mutualFundStatus?.fund?.code,
    variables: {
      orderBy: { id: OrderDirection.Desc },
      where: {
        fund: {
          code: {
            equals: mutualFundStatus?.fund?.code,
          },
        },
        round: {
          equals: Math.max((timespacePerson.timespace?.round ?? 0) - 12, 0),
        },
        timespace: { id: { equals: timespacePerson.timespace?.id } },
      },
    },
  });

  const currentSharePrice = mutualFundStatus?.sharePrice ?? 0;
  const startOfYearSharePrice = statusesData?.mutualFundStatuses?.[0]?.sharePrice ?? 1;
  const currentEfficiency = currentSharePrice / startOfYearSharePrice - 1;

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <ModalCloseButton onClose={handleClose} />
      <Box>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <MutualFundStatusInfoSection
              currentEfficiency={currentEfficiency}
              mutualFundStatus={mutualFundStatus}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Box p={2}>
            <Button onClick={handleClose} variant="outlined">
              {t('global.actions.close')}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
