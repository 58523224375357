import React, { useContext } from 'react';
import { Hail, Logout } from '@mui/icons-material';
import { Box, Icon, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TimespacePersonObject, TimespacePersonObjectStatusType } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { formatAge } from 'Lib/Helpers/Number';
import { getObjectYearFromRound } from 'Lib/Helpers/Date';
import { GameContext } from 'Context';
import HouseholdObjectDiscardModal from './ObjectDeleteModal';

interface Props {
  object: TimespacePersonObject;
}

export default function HouseholdChildItem({ object }: Props) {
  const { t } = useTranslation();
  const {
    timespacePerson: { timespace },
  } = useContext(GameContext);
  const {
    handleClose: handleIndependencyModalClose,
    handleOpen: handleIndependencyModalOpen,
    isOpen: isIndependencyModalOpen,
  } = useModal();

  const ageInRounds = (timespace?.round ?? 0) - (object?.procurementRound ?? 0);

  const ageInYears = getObjectYearFromRound(object?.procurementRound, timespace?.round);

  return (
    <>
      <Box alignItems="center" display="flex" minHeight={40}>
        <Box
          mr={1}
          onMouseEnter={() => {
            console.log(object);
          }}>{`${object.customName ? object.customName : object.name}, ${formatAge(
          ageInRounds,
        )}`}</Box>
        {object.status === TimespacePersonObjectStatusType.Discarded ? (
          <Tooltip placement="top" title={t('object.independent').toString()}>
            <Icon fontSize="small">
              <Hail />
            </Icon>
          </Tooltip>
        ) : ageInYears >= 18 ? (
          <Tooltip placement="top" title={t('household.actions.independency').toString()}>
            <IconButton onClick={handleIndependencyModalOpen} size="small">
              <Logout />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
      <HouseholdObjectDiscardModal
        isOpen={isIndependencyModalOpen}
        object={object}
        onClose={handleIndependencyModalClose}
        text={`${t('household.independency.confirm')} ${object?.customName} ?`}
      />
    </>
  );
}
