import React from 'react';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LabeledText } from 'Components/Generic';
import { BankDetailSchema } from 'Config/Validations';
import { useTimespacePersonFinancialObjectUpdate } from 'Lib/Hooks';
import { TimespacePersonFinancialObjectFragment } from 'Generated/graphql-hooks';
import TextFieldHF from 'Components/Input/TextFieldHF';

export interface FormValues {
  customName: string;
}

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function CurrentAccountDetail({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: { customName: timespacePersonFinancialObject?.displayName ?? '' },
    mode: 'onChange',
    resolver: yupResolver(BankDetailSchema),
  });
  const [updateTimespacePersonFinancialObject, { loading }] =
    useTimespacePersonFinancialObjectUpdate(timespacePersonFinancialObject?.id);
  const onSubmit = (data: FormValues) => updateTimespacePersonFinancialObject(data);

  return timespacePersonFinancialObject ? (
    <Paper sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <LabeledText
            label={t('bank.account.current')}
            value={<TextFieldHF control={control} name="customName" />}
          />
          <Box display="flex" justifyContent="end" pt={2}>
            <LoadingButton loading={loading} type="submit" variant="contained">
              <span>{t('global.actions.update')}</span>
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Paper>
  ) : null;
}
