import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import useAuth from 'Lib/Hooks/useAuth';

export default function Logout(): JSX.Element {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
}
