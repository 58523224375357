import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid } from '@mui/material';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { RandomEventObjectInput } from 'Models/RandomEventObjectInput';
import RandomEventAttributeRow from 'Components/RandomEvent/RandomEventAttributeRow';
import { RandomEventObjectObjectStatusType } from 'Generated/graphql-hooks';
import { SelectFormik } from 'Components/Input/SelectFormik';
import { ModalCloseButton, TextFieldFormik } from 'Components/index';

interface Props {
  onClose: () => void;
  onSubmit?: (values: RandomEventObjectInput) => Promise<any> | void;
  open: boolean;
  value?: RandomEventObjectInput;
}

export default function RandomEventObjectModal({
  onClose,
  onSubmit = onClose,
  open,
  value,
}: Props) {
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (values) => {
      onSubmit(values);
      onClose();
    },
    [onSubmit, onClose],
  );

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open={open}>
      <DialogTitle>
        {t('object.detail')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik initialValues={value ?? new RandomEventObjectInput()} onSubmit={handleOnSubmit}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextFieldFormik multiline id="description" label="popis" rows={4} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik id="objectsAffected" label="Ovplyvnené objekty" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik
                      id="objectsAffectedType"
                      label="Množstvo objektov"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik
                      id="objectsDescriptionModification"
                      label="Rozširujúci popis objektu"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectFormik
                      fullWidth
                      id="objectStatus"
                      label="Stav objektu"
                      options={Object.values(RandomEventObjectObjectStatusType)}
                      renderValue={(item) => t(`enums.${item}`)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik
                      InputProps={{ endAdornment: 'hod.' }}
                      id="timeRequired"
                      label="Potrebný čas"
                      type="number"
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    textAlign="center"
                    wrap="nowrap">
                    <Grid item flex={1} />
                    <Grid item xs={1.2}>
                      <Typography variant="caption">{t('randomEvent.attr.coeffFrom')}</Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography variant="caption">{t('randomEvent.attr.coeffTo')}</Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography variant="caption">{t('randomEvent.attr.absFrom')}</Typography>
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography variant="caption">{t('randomEvent.attr.absTo')}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow
                      attributePrefix="lifeQualityImpactPeriodical"
                      label={t('randomEvent.object.attr.lifeQualityImpactPeriodical')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="lifeQualityImpactMaxCount" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="assetsImpactPeriodicalPassive" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="assetsImpactPeriodicalActive" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="assetsImpactMaxRounds" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="utilisationMinRounds" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="utilisationMaxRounds" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAttributeRow attributePrefix="actualValue" />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                  <span>{t('global.actions.save')}</span>
                </LoadingButton>
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
