import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
// eslint-disable-next-line import/named
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import TreeViewContext from './TreeViewContext';

export type SimpleObject = { code: string; codeLength: number; isAbstract: boolean; name: string };

interface Props<T = SimpleObject> {
  data: any[];
  disableDetail?: boolean;
  disabledFunction?: (item: any) => boolean | undefined | null;
  hideDisabledCheckbox?: boolean;
  onChange: (event: any, checked: boolean) => void;
  onDetailClick: (item: any) => void;
  renderItem: (item: T) => React.ReactNode;
  values: any[];
}

export default function TreeViewCustomized<T = SimpleObject>({
  data,
  disableDetail,
  disabledFunction,
  hideDisabledCheckbox,
  onChange,
  onDetailClick,
  renderItem,
  values,
}: Props<T>) {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    data && setExpandedItems(data.map((x) => x.code));
  }, [data]);

  // auto expand on click
  const onItemExpanded = useCallback(
    (newItems: any[]) =>
      setExpandedItems((oldItems) =>
        oldItems
          .concat(newItems.map((item) => item.code))
          .filter((value, index, self) => self.indexOf(value) === index),
      ),
    [],
  );

  const isItemSelected = useCallback(
    (item: any) => values.some((x) => item.code === x?.code),
    [values],
  );

  const isItemIndeterminent = useCallback(
    (item: any): boolean => {
      const selected = isItemSelected(item);
      if (selected) {
        return false;
      }
      const selectedChildrenLength = values.filter(
        (x) => x?.code && item?.code && x?.code.startsWith(item?.code),
      ).length;
      return selectedChildrenLength > 0;
    },
    [isItemSelected, values],
  );

  const memoValue = useMemo(
    () => ({
      disableDetail,
      disabledFunction,
      hideDisabledCheckbox,
      isItemIndeterminent,
      isItemSelected,
      onChange,
      onDetailClick,
      onItemExpanded,
    }),
    [
      disableDetail,
      disabledFunction,
      hideDisabledCheckbox,
      isItemIndeterminent,
      isItemSelected,
      onChange,
      onDetailClick,
      onItemExpanded,
    ],
  );

  return (
    <TreeViewContext.Provider value={memoValue}>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        disableSelection={true}
        expanded={expandedItems}
        onNodeFocus={(e) => e.preventDefault()}
        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}>
        {data.map(renderItem)}
      </TreeView>
    </TreeViewContext.Provider>
  );
}
