import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { FormikTrigger, LabeledText, SelectFormik, TextFieldFormik } from 'Components';
import FinancialObjectSelector from 'Components/Eshop/FinancialObjectSelector';
import {
  Constants,
  mortgageMaturityPeriodLimits,
  realizationDayLimits as realizationDayLimits,
} from 'Config';
import { formatPrice } from 'Lib/Helpers/Number';
import { FormData } from './OverviewModal';

export default function MortgageRequestSection() {
  const { t } = useTranslation();
  const {
    values: { maxMortgageAmount, propertyPrice },
  } = useFormikContext<FormData>();

  return (
    <>
      <Typography fontWeight="bold" sx={{ marginTop: 4 }} variant="subtitle1">
        {t('mortgage.property.title')}
      </Typography>
      <Box pl={4}>
        <Box marginBottom={1}>
          <LabeledText label={t('mortgage.property.price')} value={formatPrice(propertyPrice)} />
        </Box>
        <LabeledText
          label={t('mortgage.amount')}
          value={
            <TextFieldFormik
              InputProps={{
                endAdornment: Constants.CURRENCY,
                inputProps: { max: maxMortgageAmount, min: 0 },
              }}
              id="amount"
              type="number"
            />
          }
        />
        <FormikTrigger
          skipInitialRender
          delay={200}
          // eslint-disable-next-line no-shadow
          trigger={({ amount }, { setFieldValue }) => {
            setFieldValue('ownFunds', Math.max(propertyPrice - amount, 0));
          }}
          watchProperties={['amount']}
        />
        <LabeledText
          label={t('mortgage.ownFunds')}
          value={
            <TextFieldFormik
              InputProps={{
                endAdornment: Constants.CURRENCY,
                inputProps: { max: propertyPrice, min: 0 },
              }}
              id="ownFunds"
              type="number"
            />
          }
        />
        <FormikTrigger
          skipInitialRender
          delay={200}
          // eslint-disable-next-line no-shadow
          trigger={({ ownFunds }, { setFieldValue }) => {
            setFieldValue('amount', Math.max(propertyPrice - ownFunds, 0));
          }}
          watchProperties={['ownFunds']}
        />
        <LabeledText
          label={t('mortgage.maturityPeriod')}
          value={
            <TextFieldFormik
              InputProps={{
                endAdornment: t('global.units.years.basic'),
                inputProps: mortgageMaturityPeriodLimits,
              }}
              id="maturityPeriod"
              type="number"
            />
          }
        />
        <LabeledText
          label={t('mortgage.realizationDay')}
          value={
            <SelectFormik
              fullWidth
              id="realizationDay"
              options={Array.from({ length: realizationDayLimits.max }, (_, i) => i + 1)}
            />
          }
        />
        <LabeledText
          label={t('mortgage.paymentSource')}
          value={<FinancialObjectSelector id="paymentSource" />}
        />
      </Box>
    </>
  );
}
