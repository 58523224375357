import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Delete, ExpandMore, ShoppingCart, Visibility } from '@mui/icons-material';
import { xor } from 'lodash';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import {
  Object,
  TimespacePersonObject,
  TimespacePersonObjectStatusType,
  namedOperations,
  useObjectCategoriesQuery,
  useUpdateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import {
  CircularLoader,
  EnhancedDataTable,
  Error,
  ObjectModal,
  SafeDeleteModal,
  Section,
  SellModal,
  SkeletonLoader,
} from 'Components';
import { GameContext } from 'Context';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { useModal } from 'Lib/Hooks';
import { formatDuration, formatPrice } from 'Lib/Helpers/Number';
import { GlobalObjectCodeEnum } from 'Types/Global';
import { sellableObjectCodes } from 'Config/Constants';

function createObjectsTree(
  objects?: Object[] | null,
  timespacePersonObjects?: TimespacePersonObject[] | null,
) {
  return objects
    ?.map((abstractObject) => {
      const items = timespacePersonObjects?.filter(
        ({ object, status }) =>
          object?.code?.startsWith(abstractObject.code ?? '-') &&
          status !== TimespacePersonObjectStatusType.Discarded,
      );

      return {
        ...abstractObject,
        items,
      };
    })
    .filter(({ code, items }) => code?.length === 2 && !!items?.length);
}

export default function MyStuff() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const [expanded, setExpanded] = useState<string[]>([]);

  const {
    handleClose: handleDetailModalClose,
    handleOpen: handleDetailModalOpen,
    isOpen: isDetailModalOpen,
    selected: selectedTimespacePersonObject,
  } = useModal<TimespacePersonObject>();
  const {
    handleClose: handleDeleteModalClose,
    handleOpen: handleDeleteModalOpen,
    isOpen: isDeleteModalOpen,
    selected: selectedForDelete,
  } = useModal<TimespacePersonObject>();
  const {
    handleClose: handleSellModalClose,
    handleOpen: handleSellModalOpen,
    isOpen: isSellModalOpen,
    selected: selectedForSell,
  } = useModal<TimespacePersonObject>();

  const { data, error, loading, previousData } = useObjectCategoriesQuery({
    skip: !timespacePerson?.id,
    variables: {
      timespacePersonId: timespacePerson?.id,
      where: {
        code: { not: { startsWith: GlobalObjectCodeEnum.RandomEvent } },
        isAbstract: { equals: true },
      },
    },
  });

  const [updateObject, { loading: updateObjectLoading }] = useUpdateTimespacePersonObjectMutation({
    awaitRefetchQueries: true,
    refetchQueries: [namedOperations.Query.ObjectCategories],
    variables: {
      data: { status: TimespacePersonObjectStatusType.Discarded },
      where: { id: selectedForDelete?.id },
    },
  });

  const handleAccordionToggle = (id: string) => {
    setExpanded((prev) => xor(prev, [id]));
  };

  const handleOnDeleteConfirm = useCallback(async () => {
    try {
      const { data: responseData } = await updateObject();
      if (responseData?.updateTimespacePersonObject) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        handleDeleteModalClose();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [updateObject]);

  const objectsTree = createObjectsTree(data?.objects, data?.timespacePersonObjects);

  const preparedDataTree = useMemo(
    () =>
      objectsTree?.map(({ code, items, name }) => {
        return {
          categoryName: name,
          code,
          items:
            items?.map(
              ({
                actualValue,
                assetsImpactMaxRounds,
                initialValue,
                procurementRound,
                realizationDay,
                ...item
              }) => {
                return {
                  ...item,
                  actualValue: actualValue === 0 ? undefined : actualValue,
                  initialValue: initialValue === 0 ? undefined : initialValue,
                  lifespan: getHumanDateFromRound(
                    (procurementRound ?? 0) + (assetsImpactMaxRounds ?? 0),
                    (realizationDay ? realizationDay : 1).toString(),
                  ),
                  procurementDate: getHumanDateFromRound(
                    procurementRound ?? 0,
                    (realizationDay ? realizationDay : 1).toString(),
                  ),
                };
              },
            ) ?? [],
        };
      }) ?? [],
    [objectsTree],
  );

  useEffect(() => {
    if (!loading && previousData) {
      setExpanded((prev) => prev.filter((id) => preparedDataTree.some(({ code }) => code === id)));
    }
  }, [preparedDataTree?.length]);

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        options: { display: 'excluded' },
      },
      {
        label: t('timespace.attr.name'),
        name: 'displayName',
        options: {
          setCellProps: () => ({
            style: {
              maxWidth: '130px',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
            },
          }),
        },
      },
      {
        label: t('timespace.attr.procurement.date'),
        name: 'procurementDate',
        options: {
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('object.attr.assetsImpactOneTime'),
        name: 'initialValue',
        options: {
          customBodyRender: (value) => formatPrice(value),
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('bank.transaction.attr.status'),
        name: 'status',
        options: {
          customBodyRender: (value: string) => t(`enums.${value}`),
        },
      },
      {
        label: t('object.attr.actualValue'),
        name: 'actualValue',
        options: {
          customBodyRender: (value) => formatPrice(value ?? 0),
          setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
        },
      },
      {
        label: t('object.attr.lifespan'),
        name: 'lifespan',
        options: {
          setCellProps: () => ({ align: 'right' }),
        },
      },
      {
        label: t('object.attr.used'),
        name: 'utilisationRounds',
        options: {
          customBodyRender: (value) => formatDuration(value, 'rounds'),
          setCellProps: () => ({ align: 'right' }),
        },
      },
      {
        label: t('object.attr.max.rounds'),
        name: 'utilisationMaxRounds',
        options: {
          customBodyRender: (value) => formatDuration(value, 'rounds'),
          setCellProps: () => ({ align: 'right' }),
        },
      },
      {
        label: ' ',
        name: 'detail',
        options: {
          customBodyRender: (_, { rowData }) => {
            const selectedObject = data?.timespacePersonObjects?.find(
              ({ id }) => id === rowData[0],
            );
            return (
              <Box display="flex">
                <Tooltip placement="top" sx={{ pointerEvents: 'all' }} title="Detail">
                  <IconButton
                    onClick={() => {
                      if (selectedObject) {
                        handleDetailModalOpen(selectedObject);
                      }
                    }}>
                    <Visibility />
                  </IconButton>
                </Tooltip>
                {sellableObjectCodes.some(
                  (code) =>
                    selectedObject?.object?.code?.startsWith(code) &&
                    (selectedObject?.utilisationMaxRounds ?? 0) -
                      (selectedObject?.utilisationRounds ?? 0) >
                      12 &&
                    (selectedObject?.status === TimespacePersonObjectStatusType.Active ||
                      selectedObject?.status === TimespacePersonObjectStatusType.Passive),
                ) ? (
                  <Tooltip placement="top" title={t('global.actions.sell').toString()}>
                    <IconButton onClick={() => handleSellModalOpen(selectedObject)}>
                      <ShoppingCart />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                  <IconButton
                    aria-label={t('global.actions.delete').toString()}
                    color="error"
                    onClick={() => handleDeleteModalOpen(selectedObject)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        },
      },
    ],
    [data, t, handleDetailModalOpen],
  );

  if (error) {
    return <Error />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Section title={t('game.menu.section.mystuff')}>
        {loading ? (
          <CircularLoader height="40vh" />
        ) : (
          <>
            {preparedDataTree.length ? (
              preparedDataTree?.map(({ categoryName, code, items }, index) => {
                const id = code ?? `accordion_${index}`;

                return (
                  <Accordion
                    key={index}
                    expanded={expanded.includes(id)}
                    onChange={() => {
                      handleAccordionToggle(id);
                    }}
                    sx={{ marginBottom: 1, py: 0.5 }}>
                    <AccordionSummary
                      aria-controls="categoryContent"
                      expandIcon={<ExpandMore />}
                      id="categoryHeader">
                      <Typography>{categoryName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <EnhancedDataTable
                        hideToolbar
                        columns={columns}
                        data={items}
                        options={
                          {
                            setRowProps: (row, dataIndex) => {
                              return {
                                onMouseEnter: () => {
                                  console.log(items && items[dataIndex]);
                                },
                              };
                            },
                            sort: false,
                            textLabels: {
                              body: {
                                noMatch: loading ? (
                                  <SkeletonLoader />
                                ) : (
                                  t('eshop.placeholder.empty')
                                ),
                              },
                            },
                          } as MUIDataTableOptions
                        }
                        title={''}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <Typography>{t('object.placeholder.empty')}</Typography>
            )}
          </>
        )}
      </Section>
      {selectedTimespacePersonObject ? (
        <ObjectModal
          readonly
          onClose={handleDetailModalClose}
          open={isDetailModalOpen}
          timespaceObject={selectedTimespacePersonObject}
        />
      ) : null}
      {selectedForDelete ? (
        <SafeDeleteModal
          loading={updateObjectLoading}
          onClose={handleDeleteModalClose}
          onConfirm={handleOnDeleteConfirm}
          open={isDeleteModalOpen}
          text={`${t('time_management.delete.confirm')} ${selectedForDelete?.name} ?`}
        />
      ) : null}
      {selectedForSell ? (
        <SellModal
          onClose={handleSellModalClose}
          open={isSellModalOpen}
          timespacePersonObject={selectedForSell}
        />
      ) : null}
    </Container>
  );
}
