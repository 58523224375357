import { Delete } from '@mui/icons-material';
import { useField } from 'formik';
import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'Lib/Helpers/Number';
import { TimespacePersonFinancialObject } from 'Generated/graphql-hooks';
import TimespacePersonFinancialObjectDetailModal from 'Components/Timespace/Object/FinancialObjectDetail';
import TimespacePersonFinancialObjectAddModal from 'Components/Timespace/Object/FinancialObjectAdd';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { EnhancedDataTable } from 'Components/Generic';

interface Props {
  id: string;
  initialAge?: number | null;
}

export default function TimespacePersonFinancialObjectTable({ id, initialAge }: Props) {
  const { t } = useTranslation();
  const [, { value }, { setValue }] = useField<TimespacePersonFinancialObject[]>(id);

  const onDelete = (index: number) => {
    const newValues = [...value];
    newValues.splice(index, 1);
    setValue(newValues);
  };
  const handleSubmit = (newObject: TimespacePersonFinancialObject, index: number) => {
    const newValues = [...value];
    newValues[index] = newObject;
    setValue(newValues);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'financialObject',
      options: { display: 'excluded' },
    },
    {
      label: t('timespace.attr.code'),
      name: 'financialObject.code',
    },
    {
      label: t('timespace.attr.name'),
      name: 'name',
    },
    {
      label: t('timespace.attr.customName'),
      name: 'customName',
    },
    {
      label: t('object.attr.initialValue'),
      name: 'actualValue',
      options: {
        customBodyRender: (price) => formatPrice(price),
      },
    },
    {
      label: t('global.table.actions'),
      name: 'actions',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { financialObject } = value[rowIndex];
          return (
            <>
              <TimespacePersonFinancialObjectDetailModal
                data={value[rowIndex]}
                onSubmit={(object) => handleSubmit(object, rowIndex)}
              />
              {financialObject?.code !== FinancialObjectCodeEnum.Cash && (
                <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                  <IconButton aria-label="delete" onClick={() => onDelete(rowIndex)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  return (
    <EnhancedDataTable
      columns={columns}
      data={value}
      options={
        {
          customToolbar: () => (
            <TimespacePersonFinancialObjectAddModal id={id} initialAge={initialAge} />
          ),
          pagination: false,
          setTableProps: () => ({ style: { minHeight: '165px' } }),
        } as MUIDataTableOptions
      }
      title={<Typography variant="h6">{t('timespace.timespacePersonFinancialObjects')}</Typography>}
    />
  );
}
