/* eslint-disable @typescript-eslint/member-ordering */
import {
  TimespacePersonObject as ITimespacePersonObject,
  ImpactType,
  Maybe,
  Object,
  Scalars,
  Timespace,
  TimespacePerson,
  TimespacePersonFinancialObject,
  TimespacePersonObjectAttribute,
  TimespacePersonObjectStatusType,
} from 'Generated/graphql-hooks';

export default class TimespacePersonObject implements ITimespacePersonObject {
  constructor(object?: Object, status?: TimespacePersonObjectStatusType) {
    this.id = '';
    this.assetsImpactOneTime = object?.assetsImpactOneTime;
    this.initialValue = Math.abs(object?.assetsImpactOneTime || 0);
    this.actualValue = this.initialValue;
    this.ageMax = object?.ageMax;
    this.ageMin = object?.ageMin;
    this.assetsImpactMaxRounds = object?.assetsImpactMaxRounds;
    this.assetsImpactType = object?.assetsImpactType;
    this.lifeQualityImpactPeriodical = object?.lifeQualityImpactPeriodical;
    this.assetsImpactPeriodicalActive = object?.assetsImpactPeriodicalActive;
    this.assetsImpactPeriodicalPassive = object?.assetsImpactPeriodicalPassive;
    this.name = object?.name;
    this.description = object?.description;
    this.lifeQuality = object?.lifeQualityImpactOneTime;
    this.lifeQualityImpactMaxCount = object?.lifeQualityImpactMaxCount;
    this.lifeQualityImpactMaxRounds = object?.lifeQualityImpactMaxRounds;
    this.lifeQualityImpactOneTime = object?.lifeQualityImpactOneTime;
    this.lifeQualityImpactTypeRounds = object?.lifeQualityImpactTypeRounds;
    this.lifeQualityImpactType = object?.lifeQualityImpactType;
    this.object = object;
    this.timeRequired = object?.timeRequired;
    this.timespacePersonObjectAttributes = [];
    this.utilisationMaxRounds = object?.utilisationMaxRounds;
    this.utilisationMinRounds = object?.utilisationMinRounds;
    this.realizationDay = 0;
    this.status = status; // Must stay undefined to correctly apply status on BE, but in some cases, we need to force the status
    this.customName = undefined;
    this.procurementRound = undefined;
    this.priority = undefined;
    this.discardmentRound = undefined;
    this.fullyUtilisedRound = undefined;
    this.utilisationRounds = undefined;
  }

  __typename?: 'TimespacePersonObject' | undefined;
  actualValue?: Maybe<Scalars['Float']['input']>;
  ageMax?: Maybe<Scalars['Int']['input']>;
  ageMin?: Maybe<Scalars['Int']['input']>;
  assetsImpactMaxRounds?: Maybe<Scalars['Int']['input']>;
  assetsImpactOneTime?: Maybe<Scalars['Float']['input']>;
  assetsImpactPeriodicalActive?: Maybe<Scalars['Float']['input']>;
  assetsImpactPeriodicalPassive?: Maybe<Scalars['Float']['input']>;
  assetsImpactType?: Maybe<ImpactType>;
  customDescription?: Maybe<Scalars['String']['input']>;
  customName?: Maybe<Scalars['String']['input']>;
  description?: Maybe<Scalars['String']['input']>;
  discardmentRound?: Maybe<Scalars['Int']['input']>;
  financialObjectOneTime?: Maybe<TimespacePersonFinancialObject>;
  financialObjectPeriodical?: Maybe<TimespacePersonFinancialObject>;
  fullyUtilisedRound?: Maybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  initialValue?: Maybe<Scalars['Float']['input']>;
  lifeQuality?: Maybe<Scalars['Float']['input']>;
  lifeQualityImpactMaxCount?: Maybe<Scalars['Int']['input']>;
  lifeQualityImpactMaxRounds?: Maybe<Scalars['Int']['input']>;
  lifeQualityImpactOneTime?: Maybe<Scalars['Float']['input']>;
  lifeQualityImpactPeriodical?: Maybe<Scalars['Float']['input']>;
  lifeQualityImpactType?: Maybe<ImpactType>;
  lifeQualityImpactTypeRounds?: Maybe<ImpactType>;
  name?: Maybe<Scalars['String']['input']>;
  object?: Maybe<Object>;
  priority?: Maybe<Scalars['Int']['input']>;
  procurementRound?: Maybe<Scalars['Int']['input']>;
  realizationDay?: Maybe<Scalars['Int']['input']>;
  status?: Maybe<TimespacePersonObjectStatusType>;
  timeRequired?: Maybe<Scalars['Int']['input']>;
  timespace?: Maybe<Timespace>;
  timespacePerson?: Maybe<TimespacePerson>;
  timespacePersonObjectAttributes?: Maybe<Array<TimespacePersonObjectAttribute>>;
  timespacePersonObjectAttributesCount?: Maybe<Scalars['Int']['input']>;
  utilisationMaxRounds?: Maybe<Scalars['Int']['input']>;
  utilisationMinRounds?: Maybe<Scalars['Int']['input']>;
  utilisationRounds?: Maybe<Scalars['Int']['input']>;
}
