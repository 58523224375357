import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

export default function TimespaceLayout() {
  return (
    <Box
      component="main"
      sx={{
        pb: 4,
        pt: 10,
      }}>
      <Outlet />
    </Box>
  );
}
