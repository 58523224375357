import * as Yup from 'yup';
import { t } from 'i18next';
import { Constants } from 'Config';
import { mortgageMaturityPeriodLimits } from 'Config/FinancialConstants';
import { roundFloat } from 'Lib/Helpers/Number';

export default Yup.object({
  accountFinancialObject: Yup.object().required('validations.required').nullable(),
  maturityPeriod: Yup.number()
    .test(
      'maturityPeriod',
      'mortgage.ageLimitExceeded',
      function test(maturityPeriod = 0, { createError }) {
        // @ts-expect-error not typed yet
        const { refinance, timespacePerson } = this.options.parent ?? {};
        const resultAge = timespacePerson.age / 12 + maturityPeriod;

        return !refinance || resultAge < Constants.MORTGAGE_MAX_AGE
          ? true
          : createError({
              message: t('mortgage.ageLimitExceeded', {
                replace: {
                  ageMax: Constants.MORTGAGE_MAX_AGE,
                },
              }),
            });
      },
    )
    .min(mortgageMaturityPeriodLimits.min, 'mortgage.validations.maturityPeriodLimits')
    .max(mortgageMaturityPeriodLimits.max, 'mortgage.validations.maturityPeriodLimits')
    .required('validations.required'),
  payment: Yup.number()
    .test('payment', 'mortgage.refinance.limit', function test(payment = 0) {
      // @ts-expect-error not typed yet
      return payment <= roundFloat(this.options.parent.mortgageBalance);
    })
    .min(0, 'validations.positiveNumber')
    .required('validations.required'),
});
