import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  GetUserNicknameDocument,
  useForgotPasswordMutation,
  useVerifyRecaptchaTokenMutation,
} from 'Generated/graphql-hooks';
import { ScreenPaths } from 'Config';

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [forgotPassword] = useForgotPasswordMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [verifyRecaptchaTokenMutation] = useVerifyRecaptchaTokenMutation();
  const client = useApolloClient();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const formData = new FormData(event.currentTarget);
    event.preventDefault();

    setLoading(true);
    const token = await executeRecaptcha('register');

    const identifier = formData.get('nickName')?.toString().toLowerCase() ?? '';

    const recaptchaResult = await verifyRecaptchaTokenMutation({
      variables: { recaptchaToken: token },
    });

    if (recaptchaResult.data?.verifyRecaptchaToken) {
      try {
        const { data: userNicknameData } = await client.query({
          query: GetUserNicknameDocument,
          variables: { identifier },
        });

        if (!userNicknameData.getUserNickname) {
          throw new Error();
        }

        const { data } = await forgotPassword({
          variables: { nickname: userNicknameData.getUserNickname },
        });

        if (data?.sendUserPasswordResetLink) {
          enqueueSnackbar(t('forgotPassword.initiated'), { variant: 'success' });
        } else {
          throw new Error();
        }
      } catch (e: any) {
        const userNotFound = e?.graphQLErrors?.some((x: any) => {
          return x.extensions?.exception?.thrownValue?.message === 'error.not_found.user';
        });

        enqueueSnackbar(
          t(userNotFound ? 'forgotPassword.user.notFound' : 'errors.generic', {
            replace: { identifier },
          }),
          {
            variant: 'warning',
          },
        );
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);

      enqueueSnackbar(t('errors.recaptcha'), { variant: 'error' });

      return;
    }
  };

  return (
    <>
      <Box alignItems="center" display="flex" mb={4}>
        <Avatar sx={{ bgcolor: grey[200], mr: 2 }}>
          <LockOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('forgotPassword.title')}
        </Typography>
      </Box>

      <Box component={Typography} pb={2} variant="body2">
        {t('forgotPassword.description')}
      </Box>
      <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          autoFocus
          fullWidth
          required
          id="nickName"
          label={t('user.nickNameOrEmail')}
          margin="dense"
          name="nickName"
          size="medium"
        />
        <Grid container mt={2}>
          <Typography component="p" textAlign={'center'} variant="body3">
            <span dangerouslySetInnerHTML={{ __html: t('login.recaptchaInfo') }}></span>
          </Typography>
        </Grid>

        <LoadingButton
          fullWidth
          loading={loading}
          size="large"
          sx={{ mb: 2, mt: 3 }}
          type="submit"
          variant="contained">
          <span>{t('forgotPassword.button')}</span>
        </LoadingButton>

        <Grid container justifyContent="center">
          <Grid item>
            <Link component={RouterLink} to={ScreenPaths.Login} underline="hover">
              {t('login.backToLogin')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
