import React, { useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { LabeledText, SavingsAccountTable, Section, SelectFormik } from 'Components';
import {
  FinancialObject,
  TimespacePersonFinancialObjectObjectStatusType,
  namedOperations,
  useCreateTimespacePersonFinancialObjectMutation,
} from 'Generated/graphql-hooks';
import { Constants, TimespacePaths } from 'Config';
import { TimespacePersonFinancialObject, TimespacePersonFinancialObjectCreateInput } from 'Models';
import { GameContext } from 'Context';
import { SavingsAccountCreateSchema } from 'Config/Validations';
import { useErrorHandler } from 'Lib/Hooks';

interface FormValues {
  financialObject: FinancialObject | null;
  realizationDay: number;
}

export default function SavingsAccountCreate() {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { timespaceId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { timespacePerson } = useContext(GameContext);
  const [createTimespacePersonFinancialObject] = useCreateTimespacePersonFinancialObjectMutation();

  const handleFormikSubmit = useCallback(
    async (
      { financialObject, realizationDay }: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>,
    ) => {
      try {
        if (!financialObject) {
          throw new Error('Financial object not set');
        }

        const tpfo = await createTimespacePersonFinancialObject({
          refetchQueries: [
            namedOperations.Query.BankCategories,
            namedOperations.Query.TimespacePersonFinancialObjects,
          ],
          variables: {
            data: {
              ...new TimespacePersonFinancialObjectCreateInput(
                new TimespacePersonFinancialObject(financialObject),
                timespaceId,
                timespacePerson.id,
              ),
              actualValue: 0,
              initialValue: 0,
              objectStatus: TimespacePersonFinancialObjectObjectStatusType.Active,
              procurementRound: timespacePerson.timespace?.round,
              realizationDay,
            },
          },
        });

        if (tpfo.data?.createTimespacePersonFinancialObject) {
          enqueueSnackbar(t('savings.sign.success'), { variant: 'success' });
        } else {
          handleError(tpfo?.errors, { ageMin: financialObject?.ageMin });
        }
      } catch {
        enqueueSnackbar(t('savings.sign.failed'), { variant: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
    [timespaceId, timespacePerson],
  );

  const handleClose = useCallback(() => {
    navigate(`${TimespacePaths.Bank}/${timespaceId}`);
  }, [navigate, timespaceId]);

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('savings.account.create')}>
        <Formik<FormValues>
          validateOnMount
          initialValues={{ financialObject: null, realizationDay: 1 }}
          onSubmit={handleFormikSubmit}
          validationSchema={SavingsAccountCreateSchema}>
          {({ handleSubmit, isSubmitting, isValid, setFieldValue, submitForm, values }) => {
            return (
              <Box noValidate component="form" onSubmit={handleSubmit}>
                <Box mb={4}>
                  <SavingsAccountTable
                    onRowSelected={(object) => setFieldValue('financialObject', object)}
                    value={values.financialObject}
                  />
                </Box>
                <LabeledText
                  label={t('savings.realization.day')}
                  value={
                    <SelectFormik
                      fullWidth
                      id="realizationDay"
                      options={Array.from(Array(Constants.DAYS_IN_MONTH), (_, i) => i + 1)}
                    />
                  }
                />
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button onClick={handleClose} sx={{ marginRight: 2 }} variant="outlined">
                    {t('global.actions.cancel')}
                  </Button>
                  <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="contained">
                    <span>{t('savings.actions.sign')}</span>
                  </LoadingButton>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Section>
    </Container>
  );
}
