import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LabeledText } from 'Components/Generic';
import { formatPercent, formatPrice } from 'Lib/Helpers/Number';
import { MutualFundStatusFragment } from 'Generated/graphql-hooks';
import { formatVolatility } from '../utils';

interface Props {
  currentEfficiency: number;
  mutualFundStatus?: MutualFundStatusFragment | null;
}

export default function MutualFundStatusInfoSection({
  currentEfficiency,
  mutualFundStatus,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="space-between" pt={2}>
        <Typography fontWeight="bold">{t('investments.main.information')}</Typography>
      </Box>

      <Box ml={4} pt={2}>
        <Typography py={1}>{mutualFundStatus?.fund?.name}</Typography>
        {mutualFundStatus?.fund?.description ? (
          <Typography pl={4} py={1}>
            {mutualFundStatus?.fund?.description}
          </Typography>
        ) : null}
        <LabeledText
          label={t('investments.minimal.deposit')}
          sx={{ py: 1 }}
          value={formatPrice(mutualFundStatus?.fund?.minimalDeposit)}
        />
        <LabeledText
          label={t('investments.entry.fee')}
          sx={{ py: 1 }}
          value={formatPercent(mutualFundStatus?.fund?.entryFee)}
        />
        <LabeledText
          label={t('investments.exit.fee')}
          sx={{ py: 1 }}
          value={formatPercent(mutualFundStatus?.fund?.exitFee)}
        />
        <LabeledText
          label={t('investments.management.fee')}
          sx={{ py: 1 }}
          value={formatPercent(mutualFundStatus?.fund?.managementFee)}
        />
      </Box>
      <Box alignItems="center" display="flex" justifyContent="space-between" pt={2}>
        <Typography fontWeight="bold">{t('investments.fund.efficiency')}</Typography>
      </Box>

      <Box ml={4} pt={2}>
        <LabeledText
          label={t('investments.current.fund.efficiency')}
          sx={{ py: 1 }}
          value={formatPercent(currentEfficiency, { multiply: false })}
        />
        <LabeledText
          label={t('investments.rentability')}
          sx={{ py: 1 }}
          value={formatPercent(mutualFundStatus?.fund?.rentability)}
        />
        <LabeledText
          label={t('investments.fund.volatility')}
          sx={{ py: 1 }}
          value={formatPercent(mutualFundStatus?.fund?.volatility)}
        />
        <LabeledText
          label={t('investments.volatility')}
          sx={{ py: 1 }}
          value={
            mutualFundStatus?.fund?.volatility
              ? formatVolatility(mutualFundStatus?.fund?.volatility)
              : '-'
          }
        />
        <LabeledText
          label={t('investments.share.price')}
          sx={{ py: 1 }}
          value={formatPrice(mutualFundStatus?.sharePrice)}
        />
        <LabeledText
          label={t('investments.final.volume')}
          sx={{ py: 1 }}
          value={mutualFundStatus?.totalVolume?.toLocaleString('sk')}
        />
        <LabeledText
          label={t('investments.net.worth')}
          sx={{ py: 1 }}
          value={formatPrice(
            (mutualFundStatus?.sharePrice ?? 0) * (mutualFundStatus?.totalVolume ?? 0),
          )}
        />
      </Box>
    </>
  );
}
