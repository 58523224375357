import { useCallback, useState } from 'react';

export default function useSessionStorageItem(
  sessionItemKey: string,
): [value: string | null | undefined, setValue: (value: string | null) => void] {
  const [value, setValue] = useState(sessionStorage.getItem(sessionItemKey));

  const handleValueChange = useCallback(
    (newValue: string | null) => {
      setValue(newValue);
      if (newValue) {
        sessionStorage.setItem(sessionItemKey, newValue);
      } else {
        sessionStorage.removeItem(sessionItemKey);
      }
    },
    [sessionItemKey],
  );

  return [value, handleValueChange];
}
