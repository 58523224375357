import { t } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Typography } from '@mui/material';
import React from 'react';
import { Constants } from 'Config';
import { TimespacePersonFinancialObject } from 'Generated/graphql-hooks';
import { formatPercent, formatPrice } from 'Lib/Helpers/Number';
import theme from 'Theme';

export const debtsColumns = (
  tableData: TimespacePersonFinancialObject[],
): MUIDataTableColumnDef[] => [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('object.attr.name'),
    name: 'name',
  },
  {
    label: t('object.attr.customName'),
    name: 'customName',
    options: {
      customBodyRender: (value: string) =>
        value || (
          <Typography color={theme.palette.grey.A400} variant="caption">
            {t('global.placeholder.undefined')}
          </Typography>
        ),
    },
  },
  {
    label: t('object.attr.actualValue'),
    name: 'actualValue',
    options: {
      customBodyRender: (value) => formatPrice(value, false),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('holdings.interest'),
    name: '.',
    options: {
      customBodyRender: (value, { rowIndex }) => {
        const tpFinancialObject = tableData[rowIndex];
        if (tpFinancialObject?.interestSurplus && tpFinancialObject.interestSurplus > 0) {
          return tpFinancialObject.interestSurplus + Constants.CENTRAL_INTEREST_PERCENT;
        } else {
          return formatPercent(tpFinancialObject.interestNegative);
        }
      },
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('holdings.fees'),
    name: '.',
    options: {
      customBodyRender: (value, { rowIndex }) => {
        const { actualValue, financialObject } = tableData[rowIndex];
        if (
          actualValue &&
          financialObject?.feesPeriodical &&
          financialObject?.feesPeriodicalPercent
        ) {
          return (
            financialObject.feesPeriodical +
            (financialObject.feesPeriodicalPercent / 12) * actualValue
          );
        } else {
          return '-';
        }
      },
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('holdings.period'),
    name: '.',
    options: {
      customBodyRender: (value, { rowIndex }) => {
        //TODO: TPFO doesnt have procurement round
        const { maturityPeriod } = tableData[rowIndex];
        // if (
        //   actualValue &&
        //   financialObject?.feesPeriodical &&
        //   financialObject?.feesPeriodicalPercent
        // ) {
        //   return (
        //     financialObject.feesPeriodical +
        //     (financialObject.feesPeriodicalPercent / 12) * actualValue
        //   );
        // } else {
        return '-';
        // }
      },
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
];

export const financialColumns = (): MUIDataTableColumnDef[] => [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('object.attr.name'),
    name: 'name',
  },
  {
    label: t('object.attr.customName'),
    name: 'customName',
    options: {
      customBodyRender: (value: string) =>
        value || (
          <Typography color={theme.palette.grey.A400} variant="caption">
            {t('global.placeholder.undefined')}
          </Typography>
        ),
    },
  },
  {
    label: t('object.attr.actualValue'),
    name: 'actualValue',
    options: {
      customBodyRender: (value) => formatPrice(value, false),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('holdings.interest'),
    name: 'interest',
    options: {
      customBodyRender: (value) => formatPercent(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('holdings.fees'),
    name: 'fees',
    options: {
      customBodyRender: (value) => formatPercent(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
];

export const propertyAndGoodsColumns: MUIDataTableColumnDef[] = [
  {
    name: 'id',
    options: {
      display: 'excluded',
    },
  },
  {
    label: t('object.attr.parentCategory'),
    name: 'parentCategoryName',
  },
  {
    label: t('object.attr.name'),
    name: 'name',
  },
  {
    label: t('object.attr.procurementRound'),
    name: 'procurementRound',
    options: {
      setCellProps: () => ({ style: { textAlign: 'right' } }),
    },
  },
  {
    label: t('object.attr.actualValue'),
    name: 'actualValue',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('object.attr.initialValue'),
    name: 'initialValue',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('object.attr.assetsImpactPeriodicalActive'),
    name: 'assetsImpactPeriodicalActive',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('object.attr.assetsImpactPeriodicalPassive'),
    name: 'assetsImpactPeriodicalPassive',
    options: {
      customBodyRender: (value) => formatPrice(value),
      setCellProps: () => ({ style: { textAlign: 'right', whiteSpace: 'nowrap' } }),
    },
  },
  {
    label: t('object.attr.utilisationRounds'),
    name: 'utilisationRounds',
    options: {
      setCellProps: () => ({ style: { textAlign: 'right' } }),
    },
  },
];
