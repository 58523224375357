import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  OrderDirection,
  TimespacePersonFinancialObject,
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
  useBankAccountTransactionMutation,
  useTimespacePersonFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { LabeledText, TextFieldHF } from 'Components';
import { Constants, realizationDayLimits } from 'Config';
import BankTransferSchema from 'Config/Validations/BankTransferSchema';
import { getHumanDateFromRound, getTimespaceYearFromRound } from 'Lib/Helpers/Date';
import { FinancialObjectCodeEnum } from 'Types/Global';
import { useErrorHandler } from 'Lib/Hooks';
import SelectHF from 'Components/Input/SelectHF';

interface Props {
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export interface FormValues {
  amount?: number;
  currentYear: number;
  currentYearRound: number;
  realizationDay: number;
  realizationMonth?: number;
  realizationYear?: number;
  toFinancialObject?: TimespacePersonFinancialObjectFragment;
}

export default function BankTransfer({ timespacePersonFinancialObject }: Props) {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const [bankAccountTransactionMutation] = useBankAccountTransactionMutation();

  const { data } = useTimespacePersonFinancialObjectsQuery({
    skip: !timespacePerson?.id,
    variables: {
      orderBy: { name: OrderDirection.Desc },
      where: {
        AND: [
          { timespacePerson: { id: { equals: timespacePerson?.id } } },
          { timespace: { id: { equals: timespaceId } } },
          { objectStatus: { equals: TimespacePersonFinancialObjectObjectStatusType.Active } },
          {
            OR: [
              {
                financialObject: { code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount } },
              },
              { financialObject: { code: { startsWith: FinancialObjectCodeEnum.CreditCard } } },
              { financialObject: { code: { startsWith: FinancialObjectCodeEnum.SavingAccount } } },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.FixedTermDepositAccount },
                },
              },
            ],
          },
          {
            financialObject: {
              code: { not: { equals: timespacePersonFinancialObject?.financialObject?.code } },
            },
          },
        ],
      },
    },
  });

  const currentYearRound = (timespacePerson?.timespace?.round ?? 1) % 12 || 12;
  const currentYear = getTimespaceYearFromRound(timespacePerson?.timespace?.round);
  const defaultValues = {
    amount: undefined,
    currentYear,
    currentYearRound,
    realizationDay: -1,
    realizationMonth: currentYearRound,
    realizationYear: currentYear,
    toFinancialObject: undefined,
  };

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    trigger,
    watch,
  } = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(BankTransferSchema),
  });

  const [observedRealizationDay, observedYear] = watch(['realizationDay', 'realizationYear']);

  useEffect(() => {
    trigger('realizationMonth');
  }, [observedYear]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const { amount, realizationDay, realizationMonth, realizationYear, toFinancialObject } =
        values;
      try {
        const { data: responseData, errors } = await bankAccountTransactionMutation({
          variables: {
            amount: amount ?? 0,
            fromTimespacePersonFinancialObjectId: Number(timespacePersonFinancialObject!.id),
            realizationDay: realizationDay === -1 ? 0 : realizationDay,
            // NOTE: we send the input value to the backend and it will calculate the round
            round:
              realizationDay === -1
                ? undefined
                : ((realizationYear ?? Constants.START_YEAR) - Constants.START_YEAR) * 12 +
                  (realizationMonth ?? 0),
            toTimespacePersonFinancialObjectId: Number(toFinancialObject!.id),
          },
        });

        if (responseData?.bankAccountTransaction) {
          enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        } else if (errors?.some((x) => ~x.message.indexOf('WrongPeriodDateError'))) {
          // @ts-expect-error ignore type
          const nextPeriodDateObject = errors?.find(
            (x) => ~x.message.indexOf('WrongPeriodDateError'),
          )?.extensions?.exception?.thrownValue?.cause;

          enqueueSnackbar(
            t('bank.term.error.nextPeriod', {
              replace: {
                date: getHumanDateFromRound(nextPeriodDateObject.round, nextPeriodDateObject.day),
              },
            }),
            { variant: 'error' },
          );
        } else {
          handleError(errors);
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      }
    },
    [timespacePersonFinancialObject],
  );

  return (
    <Paper sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <LabeledText
            label={t('bank.payment.amount')}
            value={
              <TextFieldHF
                InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                control={control}
                id="amount"
                name="amount"
                placeholder={t('global.placeholder.amount')}
                type="number"
              />
            }
          />
          <LabeledText
            label={t('bank.payment.transfer_to')}
            value={
              <SelectHF<TimespacePersonFinancialObject>
                fullWidth
                control={control}
                id="toFinancialObject"
                name="toFinancialObject"
                options={data?.timespacePersonFinancialObjects ?? []}
                renderValue={(item) => item.displayName}
              />
            }
          />
          <LabeledText
            label={t('bank.payment.realization.day')}
            value={
              <SelectHF<number>
                fullWidth
                control={control}
                id="realizationDay"
                name="realizationDay"
                options={[-1, ...Array.from({ length: realizationDayLimits.max }, (_, i) => i + 1)]}
                renderValue={(item) => (item === -1 ? t('global.now') : item)}
              />
            }
          />

          {observedRealizationDay === -1 ? null : (
            <>
              <LabeledText
                label={t('bank.payment.realization.month')}
                value={
                  <SelectHF
                    fullWidth
                    control={control}
                    name="realizationMonth"
                    options={
                      observedYear === currentYear
                        ? Array.from(
                            { length: 12 - currentYearRound + 1 },
                            (_, i) => i + currentYearRound,
                          )
                        : Array.from({ length: 12 }, (_, i) => i + 1)
                    }
                  />
                }
              />
              <LabeledText
                label={t('bank.payment.realization.year')}
                value={
                  <SelectHF
                    fullWidth
                    control={control}
                    name="realizationYear"
                    options={Array.from({ length: 2 }, (_, i) => i + currentYear)}
                  />
                }
              />
            </>
          )}

          <Box display="flex" justifyContent="end" pt={2}>
            <LoadingButton loading={isSubmitting} type="submit" variant="contained">
              <span>{t('bank.payment.transfer_sign')}</span>
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}
