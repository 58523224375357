import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatPrice } from 'Lib/Helpers/Number';
import { getUpdatedTimespacePersonObject } from 'Lib/Helpers/TimespacePersonObject';
import { TimespacePersonObject, TimespacePersonObjectCreateInput } from 'Models';
import {
  AutocompleteFormik,
  FormikTrigger,
  LabeledText,
  ModalCloseButton,
  ObjectAttributesTable,
} from 'Components';
import {
  Object as IObject,
  TimespacePersonObjectAttribute,
  namedOperations,
  useCreateTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { useErrorHandler } from 'Lib/Hooks';
import { pickSortedObjectProperties } from './utils';
import FinancialObjectSelector from './FinancialObjectSelector';

interface Props {
  customActions?: (props: {
    finalPrice: number;
    propertyAttributes: TimespacePersonObjectAttribute[];
  }) => JSX.Element;
  item?: IObject;
  onClose: () => void;
  open: boolean;
}

export default function BuyModal({ customActions, item, onClose, open }: Props) {
  const { handleError } = useErrorHandler();
  const { timespaceId } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [createTimespacePersonObject] = useCreateTimespacePersonObjectMutation();

  const handleFormikSubmit = useCallback(
    async (
      values: TimespacePersonObject,
      { setSubmitting }: FormikHelpers<TimespacePersonObject>,
    ) => {
      try {
        const { data, errors } = await createTimespacePersonObject({
          refetchQueries: [namedOperations.Query.TimespacePersonObjects],
          variables: {
            data: new TimespacePersonObjectCreateInput(values, timespaceId, timespacePerson.id),
          },
        });
        if (data?.createTimespacePersonObject) {
          enqueueSnackbar(t('eshop.product.pruchased'), { variant: 'success' });
          onClose();
        } else {
          handleError(errors, { ageMin: values?.object?.ageMin });
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
    [onClose, enqueueSnackbar, t],
  );

  return !item ? null : (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('eshop.product.detail')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik<TimespacePersonObject>
        initialValues={new TimespacePersonObject(item)}
        onSubmit={handleFormikSubmit}>
        {({ handleSubmit, isSubmitting, submitForm, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <FormikTrigger<TimespacePersonObject>
                skipInitialRender
                delay={500}
                trigger={(object, formikHelpers) => {
                  formikHelpers.setValues(getUpdatedTimespacePersonObject(object));
                }}
                watchProperties={['timespacePersonObjectAttributes']}
              />
              <DialogContent>
                <div>{values?.name}</div>
                {values?.description != null ? <Box py={1}>{values?.description}</Box> : null}
                <Box component={Typography} py={2} variant="h6">
                  {t('object.attributes')}
                </Box>
                {Object.entries(pickSortedObjectProperties(item)).map(([key, value]) => (
                  <LabeledText key={key} label={t(`object.attr.${key}`)} value={value} />
                ))}

                <ObjectAttributesTable
                  code={values.object?.code}
                  id="timespacePersonObjectAttributes"
                />

                <Grid
                  container
                  item
                  alignContent="center"
                  alignItems="center"
                  justifyItems="center"
                  xs={12}>
                  <Grid item xs={4}>
                    {t('eshop.payment_method')}
                  </Grid>
                  <Grid item xs={8}>
                    <FinancialObjectSelector id="financialObjectOneTime" />
                  </Grid>

                  <Grid item xs={4}>
                    {t('object.attr.realizationDay')}
                  </Grid>
                  <Grid item xs={8}>
                    <AutocompleteFormik
                      blurOnSelect
                      disableClearable
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      getOptionLabel={(x) => (x === 0 ? t('global.now') : x.toString())}
                      id="realizationDay"
                      options={Array.from(Array(31).keys())}
                    />
                  </Grid>
                </Grid>

                {item.assetsImpactOneTime && Number(item.assetsImpactOneTime) !== 0 ? (
                  <Typography
                    component={Box}
                    display="flex"
                    justifyContent="space-between"
                    pt={2}
                    textAlign="center">
                    {t('eshop.total_price')}:
                    <Typography
                      bgcolor="success.main"
                      border={1}
                      borderColor="primary.main"
                      borderRadius={0.75}
                      component={Box}
                      px={1}>
                      {formatPrice(values.assetsImpactOneTime)}
                    </Typography>
                  </Typography>
                ) : null}

                {item.assetsImpactPeriodicalActive &&
                Number(item.assetsImpactPeriodicalActive) !== 0 ? (
                  <Typography
                    component={Box}
                    display="flex"
                    justifyContent="space-between"
                    pt={1}
                    textAlign="center">
                    {t('eshop.monthly')}:
                    <Typography component={Box} pr={1}>
                      {formatPrice(values.assetsImpactPeriodicalActive)}
                    </Typography>
                  </Typography>
                ) : null}
              </DialogContent>
              <DialogActions>
                {customActions?.({
                  finalPrice: values?.assetsImpactOneTime ?? 0,
                  propertyAttributes: values?.timespacePersonObjectAttributes ?? [],
                }) ?? null}
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                <LoadingButton loading={isSubmitting} onClick={submitForm} variant="contained">
                  <span>{t('global.actions.buy')}</span>
                </LoadingButton>
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
