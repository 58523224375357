import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, Typography } from '@mui/material';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import Select, { ExtendedSelectProps } from 'Components/Input/Select';

export interface SelectHFProps<T>
  extends Omit<ExtendedSelectProps<T>, 'defaultValue' | 'name' | 'onChange' | 'value'>,
    UseControllerProps<any, string> {
  readOnly?: boolean;
}

export default function SelectHF<T>({
  control,
  fullWidth,
  name,
  readOnly,
  renderValue,
  ...restProps
}: SelectHFProps<T>): JSX.Element {
  if (!control || !name) {
    throw new Error('Control and name must be defined');
  }
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error, isTouched },
  } = useController<FieldValues>({ control, name });

  const errorMessage = error?.message ? t(error.message) : undefined;

  return readOnly ? (
    <Typography
      component={Box}
      display="flex"
      justifyContent="space-between"
      mb={1}
      mt={2}
      textAlign="center">
      <Typography component={Box} pr={1}>
        {renderValue?.(field.value) ?? field.value ?? ''}
      </Typography>
    </Typography>
  ) : (
    <Box width={fullWidth ? '100%' : undefined}>
      <Select
        {...restProps}
        error={isTouched && !!errorMessage}
        fullWidth={fullWidth}
        renderValue={renderValue}
        {...field}
      />
      {error && isTouched ? (
        <FormHelperText error variant="outlined">
          <span className="text-red-500 text-xs px-1">{t(errorMessage ?? '')}</span>
        </FormHelperText>
      ) : null}
    </Box>
  );
}
