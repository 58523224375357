import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItem, ListItemButton, ListItemText, TextField } from '@mui/material';
import List from '@mui/material/List';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetTimespaceAvailableObjects } from 'Lib/Hooks/useGetTimespaceAvailableObjects';
import { defaultEshopCategoriesFilter } from 'Config/EshopConstants';
import { TimespacePaths } from 'Config';
import { Object, OrderDirection, QueryMode } from 'Generated/graphql-hooks';
import { GameContext } from 'Context';
import { EnhancedListItemButton, SkeletonLoader } from '../Generic';

interface Props {
  expanded?: boolean;
  object: Object;
}

function EshopCategoryListItem({ expanded = false, object }: Props) {
  const { timespaceId } = useParams();
  const [open, setOpen] = React.useState(expanded);
  const handleClick = () => setOpen(!open);

  const { data } = useGetTimespaceAvailableObjects({
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      where: {
        ...defaultEshopCategoriesFilter,
        AND: [
          { codeLength: { equals: 2 + (object?.codeLength ?? 0) } },
          { code: { startsWith: object?.code } },
          { isAbstract: { equals: true } },
        ],
      },
    },
  });
  const hasItems = data?.objects && data.objects.length > 0;
  return (
    <>
      <ListItemButton
        component={hasItems ? ListItemButton : EnhancedListItemButton}
        onClick={handleClick}
        path={`${TimespacePaths.Eshop}/${timespaceId}/${object.code}`}
        sx={{
          paddingLeft: object.codeLength ?? 0,
          whiteSpace: 'normal',
        }}>
        {/* TODO: icons ? */}
        <ListItemText primary={object.name} />
        {hasItems ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse unmountOnExit in={open} timeout="auto">
        <List disablePadding component="div">
          {data?.objects?.map((item, index) => {
            return <EshopCategoryListItem key={index} object={item} />;
          })}
        </List>
      </Collapse>
    </>
  );
}

export default function EshopCategories() {
  const { t } = useTranslation();
  const { loading: gameContextLoading } = useContext(GameContext);
  const [search, setSearch] = useState('');
  const { data, loading } = useGetTimespaceAvailableObjects({
    skip: gameContextLoading,
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      skip: 0,
      take: 1000,
      where: {
        ...defaultEshopCategoriesFilter,
        AND: [
          ...(search ? [] : [{ codeLength: { equals: 2 } }]),
          { name: { contains: search, mode: QueryMode.Insensitive } },
          { isAbstract: { equals: true } },
        ],
      },
    },
  });
  return (
    <>
      <ListItem>
        <TextField
          margin="dense"
          //TODO: debounce
          onChange={({ target: { value } }) => setSearch(value)}
          placeholder={t('global.placeholder.search')}
          size="small"
        />
      </ListItem>
      <List>
        {loading || gameContextLoading ? (
          <SkeletonLoader height={40} />
        ) : (
          <>
            {data?.objects?.map((item, index) => (
              <EshopCategoryListItem key={index} expanded object={item} />
            ))}
          </>
        )}
      </List>
    </>
  );
}
