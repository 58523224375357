export default {
  resultCaching: false,
  typePolicies: {
    TimespacePersonFinancialObject: {
      fields: {
        displayName: {
          read(_: string | undefined, { readField }: { readField: (fieldName: string) => string }) {
            return readField('customName') || readField('name');
          },
        },
      },
    },
    TimespacePersonObject: {
      fields: {
        displayName: {
          read(_: string | undefined, { readField }: { readField: (fieldName: string) => string }) {
            return readField('customName') || readField('name');
          },
        },
      },
    },
  },
};
