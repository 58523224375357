import { Box, Typography } from '@mui/material';
import { useField } from 'formik';
import { MUIDataTableOptions } from 'mui-datatables';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedDataTable } from 'Components/Generic';
import RandomEventFinancialObjectAdd from 'Components/RandomEvent/FinancialObject/RandomEventFinancialObjectAdd';
import { financialObjectColumns } from './utils';

interface Props {
  id: string;
}

export default function RandomEventFinancialObjectTable({ id }: Props) {
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField(id);

  return (
    <EnhancedDataTable
      columns={financialObjectColumns(id)}
      data={value}
      options={
        {
          customToolbar: () => (
            <RandomEventFinancialObjectAdd
              onConfirm={(newValue) => setValue([...value, newValue])}
            />
          ),
          pagination: false,
          setTableProps: () => ({ style: { minHeight: '165px' } }),
          sort: false,
        } as MUIDataTableOptions
      }
      title={
        <Box display="flex" justifyContent="space-between">
          <Typography>{t('randomEvent.financialObject.table')}</Typography>
        </Box>
      }
    />
  );
}
