import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import { Formik } from 'formik';
import type { FormikHelpers } from 'formik';
import { DatePickerFormik, LabeledText, SelectFormik, TextFieldFormik } from 'Components';
import {
  OrderDirection,
  TimespacePersonFinancialObjectFragment,
  TimespacePersonFinancialObjectObjectStatusType,
  useTimespacePersonFinancialObjectsQuery,
} from 'Generated/graphql-hooks';
import { Constants } from 'Config';
import { GameContext } from 'Context';
import StandingOrderSchema from 'Config/Validations/StandingOrderSchema';
import { ExpirationTypeEnum, FinancialObjectCodeEnum } from 'Types/Global';
import StandingOrder from 'Models/StandingOrder';
import { getTimespaceYearFromRound } from 'Lib/Helpers/Date';

interface Props {
  formType: 'CREATE' | 'EDIT';
  initialValues: FormValues;
  onDelete?: () => void;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void | Promise<any>;
}

export type FormValues = StandingOrder & {
  expirationType?: keyof typeof ExpirationTypeEnum;
  initialDate?: {
    day: number;
    month: number;
    year: number;
  } | null;
};

const periodOptions = [1, 2, 3, 4, 6, 12];

const expirationTypeLabelKeyMap = new Map([
  [ExpirationTypeEnum.COUNT, 'bank.standingOrder.count.expiration'],
  [ExpirationTypeEnum.DATE, 'bank.standingOrder.date.expiration'],
  [ExpirationTypeEnum.TERMINATION, 'bank.standingOrder.termination.expiration'],
]);

export default function StandingOrderForm({ formType, initialValues, onDelete, onSubmit }: Props) {
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();
  const { timespacePerson } = useContext(GameContext);
  const readOnlyAttr = formType === 'EDIT';

  const { data } = useTimespacePersonFinancialObjectsQuery({
    skip: !timespacePerson?.id,
    variables: {
      orderBy: { name: OrderDirection.Desc },
      where: {
        AND: [
          { timespacePerson: { id: { equals: timespacePerson?.id } } },
          { timespace: { id: { equals: timespaceId } } },
          {
            OR: [
              {
                financialObject: { code: { startsWith: FinancialObjectCodeEnum.BasicBankAccount } },
              },
              { financialObject: { code: { startsWith: FinancialObjectCodeEnum.CreditCard } } },
              { financialObject: { code: { startsWith: FinancialObjectCodeEnum.SavingAccount } } },
              {
                financialObject: {
                  code: { startsWith: FinancialObjectCodeEnum.FixedTermDepositAccount },
                },
              },
            ],
          },
          {
            objectStatus: {
              not: { equals: TimespacePersonFinancialObjectObjectStatusType.Discarded },
            },
          },
        ],
      },
    },
  });

  const currentAccounts = useMemo(
    () =>
      data?.timespacePersonFinancialObjects?.filter(({ financialObject }) =>
        financialObject?.code?.startsWith(FinancialObjectCodeEnum.BasicBankAccount),
      ) ?? [],
    [data],
  );

  const notCurrentAccounts = useMemo(
    () =>
      data?.timespacePersonFinancialObjects?.filter(
        ({ financialObject }) =>
          !financialObject?.code?.startsWith(FinancialObjectCodeEnum.BasicBankAccount),
      ) ?? [],
    [data],
  );

  const currentYearRound = (timespacePerson?.timespace?.round ?? 1) % 12 || 12;
  const currentYear = getTimespaceYearFromRound(timespacePerson?.timespace?.round);

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={StandingOrderSchema}>
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <LabeledText
                label={t('bank.standingOrder.name')}
                value={
                  <TextFieldFormik
                    id="name"
                    placeholder={t('global.placeholder.name')}
                    type="string"
                  />
                }
              />
              <LabeledText
                label={t('bank.standingOrder.initial.day')}
                value={
                  <DatePickerFormik
                    fullWidth
                    id="initialDate"
                    maxDate={{ day: 1, month: currentYearRound, year: currentYear + 20 }}
                    minDate={{ day: 1, month: currentYearRound, year: currentYear }}
                    readOnly={readOnlyAttr}
                  />
                }
              />
              <LabeledText
                label={t('bank.standingOrder.period')}
                value={
                  <SelectFormik<TimespacePersonFinancialObjectFragment>
                    fullWidth
                    id="period"
                    options={periodOptions}
                    readOnly={readOnlyAttr}
                    renderValue={(item) => item}
                  />
                }
              />
              <LabeledText
                label={t('bank.payment.amount')}
                value={
                  <TextFieldFormik
                    InputProps={{ endAdornment: Constants.CURRENCY, inputProps: { min: 0 } }}
                    id="amount"
                    placeholder={t('global.placeholder.amount')}
                    type="number"
                  />
                }
              />
              <LabeledText
                label={t('bank.payers.account')}
                value={
                  <SelectFormik<TimespacePersonFinancialObjectFragment>
                    fullWidth
                    id="payersAccount"
                    options={currentAccounts}
                    readOnly={readOnlyAttr}
                    renderValue={(item) => item.displayName}
                  />
                }
              />
              <LabeledText
                label={t('bank.beneficiary.account')}
                value={
                  <SelectFormik<TimespacePersonFinancialObjectFragment>
                    fullWidth
                    id="beneficiaryAccount"
                    options={notCurrentAccounts}
                    readOnly={readOnlyAttr}
                    renderValue={(item) => item.displayName}
                  />
                }
              />
              <LabeledText
                label={t('bank.variable.symbol')}
                value={<TextFieldFormik id="variableSymbol" />}
              />
              <LabeledText
                label={t('bank.constant.symbol')}
                value={<TextFieldFormik id="constantSymbol" />}
              />
              <LabeledText
                label={t('bank.specific.symbol')}
                value={<TextFieldFormik id="specificSymbol" />}
              />
              <LabeledText
                label={t('bank.standingOrder.text.message')}
                value={<TextFieldFormik id="textMessage" />}
              />
              <LabeledText
                label={t('bank.standingOrder.expiration')}
                value={
                  <SelectFormik<keyof typeof ExpirationTypeEnum>
                    fullWidth
                    id="expirationType"
                    options={Object.values(ExpirationTypeEnum)}
                    renderValue={(item) => t(expirationTypeLabelKeyMap.get(item) ?? '')}
                  />
                }
              />
              {values.expirationType === ExpirationTypeEnum.DATE ? (
                <LabeledText
                  label={t('bank.expiration.date')}
                  value={
                    <DatePickerFormik
                      fullWidth
                      id="expirationDate"
                      maxDate={
                        values.initialDate
                          ? { ...values.initialDate, year: values.initialDate.year + 20 }
                          : undefined
                      }
                      minDate={
                        currentYearRound > (values?.initialDate?.month ?? 0) &&
                        currentYear > (values?.initialDate?.year ?? 0)
                          ? { day: 1, month: currentYearRound, year: currentYear }
                          : values.initialDate ?? undefined
                      }
                    />
                  }
                />
              ) : values.expirationType === ExpirationTypeEnum.COUNT ? (
                <LabeledText
                  label={t('bank.expiration.count')}
                  value={<TextFieldFormik id="expirationOnExecutionCount" type="number" />}
                />
              ) : null}
              <Box display="flex" justifyContent="end" pt={2}>
                {formType === 'EDIT' ? (
                  <Button
                    aria-label="delete"
                    color="error"
                    onClick={onDelete}
                    startIcon={<Delete />}
                    sx={{ mr: 2 }}
                    variant="contained">
                    {t('bank.standingOrder.delete')}
                  </Button>
                ) : null}
                <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                  <span>
                    {formType === 'EDIT'
                      ? t('bank.standingOrder.edit')
                      : t('bank.standingOrder.create')}
                  </span>
                </LoadingButton>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
}
