import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';
// eslint-disable-next-line import/named
import { Formik, FormikHelpers } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { TimespacePersonFinancialObject } from 'Models';
import { ModalCloseButton } from 'Components/Generic';
import { Constants } from 'Config';
import { TextFieldFormik } from '../Input';

interface Props {
  id?: string;
  onClose: () => void;
  onSubmit?: (
    values: TimespacePersonFinancialObject,
    formikHelpers: FormikHelpers<TimespacePersonFinancialObject>,
  ) => Promise<any> | void;
  open: boolean;
  readonly?: boolean;
  timespaceFinancialObject?: TimespacePersonFinancialObject | null;
}

const LabeledText = ({ label, value }: { label: string; value?: React.ReactNode }) => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" display="flex" maxWidth="70%">
      <Box flex={1} pr={2}>
        {label}
      </Box>
      {value ?? t('global.placeholder.undefined')}
    </Box>
  );
};

export default function FinancialObjectModal({
  onClose,
  onSubmit = onClose,
  open,
  readonly,
  timespaceFinancialObject,
}: Props) {
  const { t } = useTranslation();

  return !timespaceFinancialObject ? null : (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('object.financial.detail')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik<TimespacePersonFinancialObject>
        initialValues={{
          ...timespaceFinancialObject,
          actualValue: Number(timespaceFinancialObject.actualValue),
        }}
        onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <Typography>{values?.name}</Typography>
                <Box py={2}>{values?.description}</Box>

                <LabeledText
                  label={t('object.attr.initialValue')}
                  value={
                    <TextFieldFormik
                      InputProps={{ endAdornment: Constants.CURRENCY }}
                      fullWidth={false}
                      id="actualValue"
                      type="number"
                    />
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                {!readonly && (
                  <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                    <span>{t('global.actions.save')}</span>
                  </LoadingButton>
                )}
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
