import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';
// eslint-disable-next-line import/named
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { UseControllerProps } from 'react-hook-form';
import { useToggle } from 'Lib/Hooks';
import TextFieldHF from './TextFieldHF';

type PasswordHFProps = TextFieldProps & UseControllerProps<any, string>;

export default React.memo(function PasswordHF({ ...props }: PasswordHFProps): JSX.Element {
  const [showPassword, handleToggle] = useToggle();

  return (
    <TextFieldHF
      {...props}
      InputProps={{
        ...(props.InputProps ?? {}),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleToggle}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  );
});
