import React, { useCallback } from 'react';
import { Box, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RandomEventInput } from 'Models/RandomEventInput';
import { RandomEventSchema } from 'Config/Validations';
import RandomEventObjectTable from 'Components/RandomEvent/Object/RandomEventObjectTable';
import {
  RandomEventAcquiredObjectTable,
  RandomEventFinancialObjectTable,
} from 'Components/RandomEvent';
import { CheckboxFormik, ModalCloseButton, TextFieldFormik } from 'Components/index';

interface Props {
  onClose: () => void;
  onSubmit?: (values: RandomEventInput) => Promise<any> | void;
  open: boolean;
  value?: RandomEventInput;
}

export default function TimespaceRandomEventModal({
  onClose,
  onSubmit = onClose,
  open,
  value,
}: Props) {
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (values) => {
      onSubmit(values);
      onClose();
    },
    [onSubmit, onClose],
  );

  return (
    <Dialog fullWidth maxWidth="xl" onClose={onClose} open={open}>
      <DialogTitle>
        {t(value ? 'randomEvent.detail' : 'randomEvent.create')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik<RandomEventInput>
        initialValues={value || new RandomEventInput()}
        onSubmit={handleOnSubmit}
        validationSchema={RandomEventSchema}>
        {({ handleSubmit, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldFormik id="name" label={t('randomEvent.attr.name')} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      multiline
                      id="description"
                      label={t('randomEvent.attr.description')}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      InputProps={{ endAdornment: t('global.units.round') }}
                      helperText={t('global.attribute.optional')}
                      id="executionRoundFrom"
                      label={t('randomEvent.attr.executionRoundFrom')}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      InputProps={{ endAdornment: t('global.units.round') }}
                      helperText={t('global.attribute.optional')}
                      id="executionRoundTo"
                      label={t('randomEvent.attr.executionRoundTo')}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      InputProps={{ endAdornment: t('global.units.month') }}
                      helperText={t('global.attribute.optional')}
                      id="executionMonthPeriodical"
                      label={t('randomEvent.attr.executionPeriodical')}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      InputProps={{ endAdornment: t('global.units.year') }}
                      id="executionYearPeriodical"
                      label={t('randomEvent.attr.executionPeriodical')}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      InputProps={{ endAdornment: '%' }}
                      id="executionProbability"
                      inputProps={{ step: 1 }}
                      label={t('randomEvent.attr.executionProbability')}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="executionMaxCount"
                      label={t('randomEvent.attr.executionMaxCount')}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxFormik
                      id="executionUserAcceptance"
                      label={t('randomEvent.attr.executionUserAcceptance').toString()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventObjectTable id="randomEventObjects" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventFinancialObjectTable id="randomEventFinancialObjects" />
                  </Grid>
                  <Grid item xs={12}>
                    <RandomEventAcquiredObjectTable id="randomEventAcquiredObject" />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" sx={{ marginRight: 1 }} type="submit" variant="contained">
                  {t(values.id ? 'global.actions.save' : 'global.actions.create')}
                </Button>
                <Button onClick={onClose}> {t('global.actions.cancel')}</Button>
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
