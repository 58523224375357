import React from 'react';
import { useSnackbar } from 'notistack';
import type { SnackbarKey } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

interface Props {
  id: SnackbarKey;
}

export default function SnackbarCloseButton({ id }: Props) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <Close sx={{ color: 'white' }} />
    </IconButton>
  );
}
