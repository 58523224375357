/* eslint-disable @typescript-eslint/member-ordering */

import mapValuesDeep from 'Lib/Helpers/mapValuesDeep';
import {
  FinancialObjectRelateToOneForCreateInput,
  TimespacePersonFinancialObjectCreateInput as ITimespacePersonFinancialObjectCreateInput,
  InputMaybe,
  Scalars,
  TimespacePersonFinancialObject,
  TimespacePersonRelateToOneForCreateInput,
  TimespaceRelateToOneForCreateInput,
} from '../Generated/graphql-hooks';

export default class TimespacePersonFinancialObjectCreateInput
  implements ITimespacePersonFinancialObjectCreateInput
{
  constructor(
    timespaceObject: TimespacePersonFinancialObject,
    timespaceId?: string,
    timespacePersonId?: string,
  ) {
    timespaceObject = mapValuesDeep(timespaceObject, (v) => (v === '' ? null : v));
    this.name = timespaceObject.name;
    this.automaticRenewal = timespaceObject.automaticRenewal;
    this.customName = timespaceObject.customName ?? '';
    this.description = timespaceObject.description ?? ''; // TODO: tmp fix for BE
    this.customDescription = timespaceObject.customDescription ?? '';
    this.financialObject = { connect: { id: timespaceObject.financialObject?.id } };

    this.actualValue = timespaceObject.actualValue;
    this.ageMax = timespaceObject.ageMax;
    this.ageMin = timespaceObject.ageMin;
    this.feesOneTime = timespaceObject?.feesOneTime;
    this.feesOneTimePercent = timespaceObject?.feesOneTimePercent;
    this.feesPeriodical = timespaceObject?.feesPeriodical;
    this.feesPeriodicalPercent = timespaceObject?.feesPeriodicalPercent;
    this.fixationPeriod = timespaceObject?.fixationPeriod;
    this.interestNegative = timespaceObject?.interestNegative;
    this.interestPositive = timespaceObject?.interestPositive;
    this.interestSanction = timespaceObject?.interestSanction;
    this.interestSurplus = timespaceObject?.interestSurplus;
    this.interestPeriod = timespaceObject?.interestPeriod;
    this.maturityPeriod = timespaceObject?.maturityPeriod;
    this.monthlyPayment = timespaceObject?.monthlyPayment;
    this.nextFixation = timespaceObject.nextFixation;
    this.principalMin = timespaceObject?.principalMin;
    this.realizationDay = timespaceObject.realizationDay;
    this.timespace = timespaceId ? { connect: { id: timespaceId } } : undefined;
    this.timespacePerson = timespacePersonId
      ? {
          connect: { id: timespacePersonId },
        }
      : undefined;
  }

  actualValue?: InputMaybe<Scalars['Float']['input']>;
  ageMax?: InputMaybe<Scalars['Int']['input']>;
  ageMin?: InputMaybe<Scalars['Int']['input']>;
  automaticRenewal?: InputMaybe<Scalars['Boolean']['input']>;
  customDescription?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feesOneTime?: InputMaybe<Scalars['Float']['input']>;
  feesOneTimePercent?: InputMaybe<Scalars['Float']['input']>;
  feesPeriodical?: InputMaybe<Scalars['Float']['input']>;
  feesPeriodicalPercent?: InputMaybe<Scalars['Float']['input']>;
  financialObject?: InputMaybe<FinancialObjectRelateToOneForCreateInput>;
  fixationPeriod?: InputMaybe<Scalars['Int']['input']>;
  interestNegative?: InputMaybe<Scalars['Float']['input']>;
  interestPositive?: InputMaybe<Scalars['Float']['input']>;
  interestSanction?: InputMaybe<Scalars['Float']['input']>;
  interestSurplus?: InputMaybe<Scalars['Float']['input']>;
  interestPeriod?: InputMaybe<Scalars['Int']['input']>;
  maturityPeriod?: InputMaybe<Scalars['Int']['input']>;
  monthlyPayment?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextFixation?: InputMaybe<Scalars['Int']['input']>;
  principalMin?: InputMaybe<Scalars['Float']['input']>;
  realizationDay?: InputMaybe<Scalars['Int']['input']>;
  timespace?: InputMaybe<TimespaceRelateToOneForCreateInput>;
  timespacePerson?: InputMaybe<TimespacePersonRelateToOneForCreateInput>;
}
