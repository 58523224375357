import React, { useMemo } from 'react';
import MUIDataTable, { MUIDataTableProps, SelectableRows } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { SkeletonLoader } from 'Components';

type Props = MUIDataTableProps & {
  hideToolbar?: boolean;
  loading?: boolean;
};

const StyledTable = styled(MUIDataTable, {
  shouldForwardProp: (prop) => prop !== 'hideToolbar',
})<{ hideToolbar?: boolean }>(({ hideToolbar }) =>
  hideToolbar
    ? {
        '& .MuiToolbar-root': {
          display: 'none',
        },
      }
    : {},
);

export default function EnhancedDataTable({ hideToolbar, loading, options, ...props }: Props) {
  const { t } = useTranslation();

  const tableOptions = useMemo(
    () => ({
      download: false,
      enableNestedDataAccess: '.',
      filter: false,
      print: false,
      rowHover: false,
      search: false,
      selectToolbarPlacement: 'none',
      selectableRows: 'none' as SelectableRows,
      tableBodyHeight: 'auto',
      viewColumns: false,
      ...options,
      textLabels: {
        body: {
          noMatch: loading ? <SkeletonLoader /> : t('global.placeholder.empty'),
        },
        filter: {
          all: t('general.table.filter.all'),
          reset: t('general.table.filter.reset'),
          title: t('general.table.filter.title'),
        },
        pagination: {
          displayRows: t('general.table.pagination.displayRows'),
          next: t('general.table.pagination.next'),
          previous: t('general.table.pagination.previous'),
          rowsPerPage: t('general.table.pagination.rowsPerPage'),
        },
        selectedRows: {
          delete: t('general.table.selectedRows.delete'),
          deleteAria: t('general.table.selectedRows.deleteAria'),
          text: t('general.table.selectedRows.text'),
        },
        toolbar: {
          downloadCsv: t('general.table.toolbar.downloadCsv'),
          filterTable: t('general.table.toolbar.filterTable'),
          print: t('general.table.toolbar.print'),
          search: t('general.table.toolbar.search'),
          viewColumns: t('general.table.toolbar.viewColumns'),
        },
        viewColumns: {
          title: t('general.table.viewColumns.title'),
          titleAria: t('general.table.viewColumns.titleAria'),
        },
        ...(options?.textLabels ?? {}),
      },
    }),
    [loading, options, t],
  );

  // @ts-expect-error typesafe
  return <StyledTable {...props} hideToolbar={hideToolbar} options={tableOptions} />;
}
