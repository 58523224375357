import React, { useCallback, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { ModalCloseButton, TemplateTableModal } from 'Components';
import {
  TimespaceFragment,
  namedOperations,
  useCreateTimespaceFromPrototypeMutation,
} from 'Generated/graphql-hooks';

export default function SelectTemplateModalButton() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState<TimespaceFragment>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = useCallback(() => {
    setSelected(undefined);
    setIsOpen(false);
  }, []);

  const [createTimespaceFromPrototype] = useCreateTimespaceFromPrototypeMutation();

  const handleSubmit = async () => {
    if (!selected) {
      throw new Error();
    }

    try {
      const { data } = await createTimespaceFromPrototype({
        refetchQueries: [namedOperations.Query.Timespaces],
        variables: {
          timespacePrototypeId: Number(selected?.id),
        },
      });

      if (data?.createTimespaceFromPrototype) {
        enqueueSnackbar(t('timespace.message.created'), { variant: 'success' });
        handleClose();
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button onClick={handleOpen} startIcon={<Add />} variant="outlined">
        {t('timespace.create.from_template')}
      </Button>
      <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={isOpen}>
        <DialogTitle>
          {t('timespace.templates')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <TemplateTableModal onRowSelected={setSelected} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.cancel')}
          </Button>
          <LoadingButton
            disabled={!selected}
            loading={submitting}
            onClick={handleSubmit}
            variant="contained">
            <span>{t('global.actions.create')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
