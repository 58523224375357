const SESSION_TOKEN_KEY = 'token';
const OWNER_TOKEN_KEY = 'ownerToken';
const FROM_URL_KEY = 'fromUrl';
const INTERPERSONALIZED_PERSON = 'person';
const USER = 'user';

export function getSessionToken(): string | null {
  return sessionStorage.getItem(SESSION_TOKEN_KEY);
}

export function setSessionToken(token: string): void {
  return sessionStorage.setItem(SESSION_TOKEN_KEY, token);
}

export function clearSessionToken(): void {
  return sessionStorage.removeItem(SESSION_TOKEN_KEY);
}

export function getOwnerToken(): string | null {
  return sessionStorage.getItem(OWNER_TOKEN_KEY);
}

export function setOwnerToken(token: string): void {
  return sessionStorage.setItem(OWNER_TOKEN_KEY, token);
}

export function clearOwnerToken(): void {
  return sessionStorage.removeItem(OWNER_TOKEN_KEY);
}

export function getFromUrl(): string | null {
  return sessionStorage.getItem(FROM_URL_KEY);
}

export function setFromUrl(fromUrl: string): void {
  return sessionStorage.setItem(FROM_URL_KEY, fromUrl);
}

export function clearFromUrl(): void {
  return sessionStorage.removeItem(FROM_URL_KEY);
}

export function isInterpersonalizedView(): boolean {
  return getOwnerToken() !== getSessionToken();
}

export function getInterpersonalizedPerson(): string | null {
  return sessionStorage.getItem(INTERPERSONALIZED_PERSON);
}

export function setInterpersonalizedPerson(person: string): void {
  return sessionStorage.setItem(INTERPERSONALIZED_PERSON, person);
}

export function clearInterpersonalizedPerson(): void {
  return sessionStorage.removeItem(USER);
}

export function getUser(): string | null {
  return sessionStorage.getItem(USER);
}

export function setUser(user: string): void {
  return sessionStorage.setItem(USER, user);
}

export function clearUser(): void {
  return sessionStorage.removeItem(USER);
}

//
// export function clearStorageAndRedirectToLogin(): void {
//   clearSessionToken();
//
//   // document.location.href = ScreenPaths.Login;
// }
