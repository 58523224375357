import React, { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import { useApolloClient } from '@apollo/client';
import { TimespacePersonObject } from 'Models';
import { formatPrice } from 'Lib/Helpers/Number';
import {
  FindBuyerDocument,
  FindBuyerQuery,
  TimespacePersonHoldingsDocument,
  namedOperations,
  useSellTimespacePersonObjectMutation,
} from 'Generated/graphql-hooks';
import { GameContext } from 'Context';

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePersonObject?: TimespacePersonObject;
}

interface State {
  buyer: FindBuyerQuery | null;
  isSearching: boolean;
  queryError: string | null;
}

type Action =
  | { payload: boolean; type: 'SET_IS_SEARCHING' }
  | { payload: FindBuyerQuery | null; type: 'SET_BUYER' }
  | { payload: string | null; type: 'SET_QUERY_ERROR' };

const initialState: State = {
  buyer: null,
  isSearching: true,
  queryError: null,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_IS_SEARCHING':
      return {
        ...state,
        buyer: null,
        isSearching: action.payload,
        queryError: null,
      };
    case 'SET_BUYER':
      return {
        ...state,
        buyer: action.payload,
        isSearching: false,
        queryError: null,
      };
    case 'SET_QUERY_ERROR':
      return {
        ...state,
        buyer: null,
        isSearching: false,
        queryError: action.payload,
      };
    default:
      return state;
  }
}

export default function SellModal({ onClose, open, timespacePersonObject }: Props) {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { timespacePerson } = useContext(GameContext);
  const playerRef = useRef<Player | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [sellObject, { loading }] = useSellTimespacePersonObjectMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      namedOperations.Query.ObjectCategories,
      {
        query: TimespacePersonHoldingsDocument,
        variables: { timespacePersonId: Number(timespacePerson.id) },
      },
    ],
    variables: {
      price: state.buyer?.findBuyer.price ?? 0,
      timespacePersonId: Number(timespacePerson?.id),
      timespacePersonObjectId: Number(timespacePersonObject?.id),
    },
  });

  const handleSell = useCallback(async () => {
    try {
      const { data: responseData } = await sellObject();
      if (responseData?.sellTimespacePersonObject) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        onClose();
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  }, [sellObject]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (open) {
      dispatch({ payload: true, type: 'SET_IS_SEARCHING' });
      const probability = 0.75;
      const buyerFound = Math.random() < probability;

      (async () => {
        try {
          const [promiseResult] = await Promise.all([
            buyerFound
              ? client.query<FindBuyerQuery>({
                  query: FindBuyerDocument,
                  variables: {
                    timespacePersonId: Number(timespacePerson.id),
                    timespacePersonObjectId: Number(timespacePersonObject?.id),
                  },
                })
              : null,
            new Promise<void>((resolve) => {
              timeoutId = setTimeout(resolve, 3000);
            }),
          ]);
          dispatch({ payload: promiseResult?.data ?? null, type: 'SET_BUYER' });
          playerRef.current?.pause();
        } catch (error: any) {
          let errorMessage = t('errors.query.couldNotLoad');

          dispatch({ payload: errorMessage, type: 'SET_QUERY_ERROR' });

          const realEstateError = error?.graphQLErrors?.find((x: any) => {
            return (
              x.extensions?.exception?.thrownValue?.message ===
              'error.timespace_person_object.real_estate_encumbrance'
            );
          });

          if (realEstateError) {
            errorMessage = t('errors.error.real_estate_encumbrance', {
              replace: {
                financialObjectName:
                  realEstateError.extensions?.exception?.thrownValue?.cause?.financialObjectName,
                objectName: realEstateError.extensions?.exception?.thrownValue?.cause?.objectName,
              },
            });
            dispatch({
              payload: errorMessage,
              type: 'SET_QUERY_ERROR',
            });
          }
        }
      })();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open} sx={{ overflow: 'hidden' }}>
      {!state.queryError ? (
        <>
          <Box alignItems="center" display="flex" flexDirection="column">
            <DialogTitle>
              {state.isSearching
                ? t('sell.buyer.searching')
                : state.buyer
                ? t('sell.buyer.found')
                : t('sell.buyer.notFound')}
            </DialogTitle>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height={350}
            sx={{ overflow: 'hidden' }}>
            <Player
              ref={playerRef}
              autoplay
              loop
              src="https://assets5.lottiefiles.com/packages/lf20_rlmdrwm8.json"
              style={{
                height: state.isSearching ? '300px' : '200px',
                transition: 'height 0.7s, width 0.7s',
                width: state.isSearching ? '300px' : '200px',
              }}></Player>
            {!state.isSearching ? (
              state.buyer ? (
                <>
                  <Typography p={2} textAlign="center">
                    {`${t('sell.message', { replace: { value: state.buyer.findBuyer.name } })} ${
                      timespacePersonObject?.name
                    } `}
                    <span style={{ fontWeight: 'bold' }}>
                      {formatPrice(state.buyer.findBuyer.price)}
                    </span>
                  </Typography>
                  <DialogActions>
                    <Button color="secondary" onClick={onClose} size="large" variant="text">
                      {t('global.actions.cancel')}
                    </Button>
                    <LoadingButton
                      loading={loading}
                      onClick={handleSell}
                      size="large"
                      variant="text">
                      <span>{t('global.actions.sell')}</span>
                    </LoadingButton>
                  </DialogActions>
                </>
              ) : (
                <>
                  <Typography p={2} textAlign="center">
                    {t('sell.notFound.try.later')}
                  </Typography>
                  <DialogActions>
                    <Button onClick={onClose} size="large" variant="text">
                      {t('global.actions.close')}
                    </Button>
                  </DialogActions>
                </>
              )
            ) : null}
          </Box>
        </>
      ) : (
        <>
          <Box alignItems="center" display="flex" flexDirection="column" pb={4}>
            <Box alignItems="center" display="flex" gap={1} height={300} p={4}>
              <ErrorOutlineIcon />
              <Typography variant="h6">{state.queryError}</Typography>
            </Box>
            <Button onClick={onClose} size="large" variant="text">
              {t('global.actions.close')}
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
}
