import React, { useCallback } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Object, OrderDirection } from 'Generated/graphql-hooks';
import {
  BuyModal,
  CircularLoader,
  ConditionalGameScreenWrapper,
  ErrorFallbackPlaceholder,
  EshopItemCard,
  MortgageOverviewModal,
} from 'Components';
import { useGetTimespaceAvailableObjects, useModal } from 'Lib/Hooks';
import { useLivingContext } from 'Context/Living';

export default function Living() {
  const { t } = useTranslation();
  const { handleClose, handleOpen, isOpen, selected } = useModal<Object>();
  const {
    handleClose: handleMortgageModalClose,
    handleOpen: handleMortgageModalOpen,
    isOpen: isMortgageModalOpen,
  } = useModal();
  const { loading: categoriesLoading, selectedCategoryCode } = useLivingContext();
  const { data, error, loading } = useGetTimespaceAvailableObjects({
    variables: {
      orderBy: [{ displayPriority: OrderDirection.Asc }, { name: OrderDirection.Asc }],
      where: {
        AND: [{ code: { startsWith: selectedCategoryCode } }, { isAbstract: { equals: false } }],
      },
    },
  });
  const conditionCallback = useCallback(
    ({ hasAvailableLivingObjects }) => !hasAvailableLivingObjects,
    [],
  );

  return (
    <ConditionalGameScreenWrapper hideScreen={conditionCallback}>
      {loading || categoriesLoading ? (
        <CircularLoader />
      ) : (
        <ErrorFallbackPlaceholder
          data={data?.objects}
          error={error}
          // NOTE: show loader until the query returns first results
          loading={loading || !!categoriesLoading}
          placeholder={t('eshop.placeholder.empty')}>
          <Container maxWidth="md" sx={{ pb: 3 }}>
            <Grid
              container
              spacing={4}
              sx={(theme) => ({
                paddingTop: theme.spacing(4),
              })}>
              {(loading ? new Array(8).fill(null) : data?.objects)?.map((item, index) => (
                <Grid key={index} item lg={4} sm={6} xs={12}>
                  <EshopItemCard item={item} loading={loading} onBuyClick={handleOpen} />
                </Grid>
              ))}
            </Grid>
          </Container>
          <BuyModal
            customActions={
              selected?.code?.startsWith('BYVL')
                ? ({ finalPrice, propertyAttributes }) => (
                    <>
                      <Button
                        color="warning"
                        onClick={handleMortgageModalOpen}
                        sx={{ marginRight: 4 }}
                        variant="contained">
                        {t('living.actions.manageMortgage')}
                      </Button>
                      <MortgageOverviewModal
                        finalPrice={finalPrice}
                        onClose={() => {
                          handleMortgageModalClose();
                          handleClose();
                        }}
                        open={isMortgageModalOpen}
                        property={selected}
                        propertyAttributes={propertyAttributes}
                      />
                    </>
                  )
                : undefined
            }
            item={selected}
            onClose={handleClose}
            open={isOpen}
          />
        </ErrorFallbackPlaceholder>
      )}
    </ConditionalGameScreenWrapper>
  );
}
