import React from 'react';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { formatPrice } from 'Lib/Helpers/Number';

export function getPersonalBudgetTableColumns() {
  return [
    {
      label: t('budget.item'),
      name: 'name',
      options: {
        customBodyRender: (name: string) => name || '-',
        customHeadRender: () => null,
        sort: false,
      },
    },
    {
      label: t('bank.transaction.attr.amount'),
      name: 'sum',
      options: {
        customBodyRender: (value: string) => {
          return (
            value && (
              <Typography
                noWrap
                color={Number(value) > 0 ? 'green' : 'red'}
                textAlign="right"
                variant="body2">
                {formatPrice(value, false)}
              </Typography>
            )
          );
        },
        customHeadRender: () => null,
        sort: false,
      },
    },
  ];
}
