import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { formatPrice } from 'Lib/Helpers/Number';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { RandomEventHistory, useUpdateRandomEventHistoryMutation } from 'Generated/graphql-hooks';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  onClose: () => void;
  open: boolean;
  randomEventHistory: RandomEventHistory | undefined;
}

export default function RandomEventHistoryModal({ onClose, open, randomEventHistory }: Props) {
  const { t } = useTranslation();
  const [updateRandomEventHistory] = useUpdateRandomEventHistoryMutation();

  useEffect(() => {
    if (randomEventHistory && !randomEventHistory.visited) {
      updateRandomEventHistory({
        variables: {
          data: {
            visited: true,
          },
          where: {
            id: randomEventHistory?.id,
          },
        },
      }).catch(() => {
        console.log('error updating visited flag on: ' + randomEventHistory?.id);
      });
    }
  }, [randomEventHistory]);

  if (!randomEventHistory) {
    return null;
  }
  const {
    description,
    executionRound,
    executionUserAcceptance,
    executionUserAcceptanceProcessed,
    financialObjects,
    name,
    objects,
  } = randomEventHistory;

  /*
    TODO:
      - zobrazit odmietnutu udalost
      - zobrazit acquired object assetImpactOneTime + user input value
   */

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {t('randomEvent.history.title')}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Dátum uskutočnenia: {getHumanDateFromRound(executionRound || 0)}
            </Typography>
          </Grid>
          {executionUserAcceptanceProcessed && !executionUserAcceptance && (
            <Grid item xs={12}>
              <Typography fontWeight="bold" variant="body1">
                Táto udalosť bola odmietnutá
              </Typography>
            </Grid>
          )}
          {objects && objects.length > 0 && (
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Objekty zmenené udalosťou
              </Typography>
              {objects?.map(({ modifiedObject, previousObject }, index) => {
                return modifiedObject && previousObject ? (
                  <Box key={index} border={1} borderColor="gray" borderRadius={1} my={2} p={2}>
                    {modifiedObject.name}
                    <Grid container pl={4} spacing={1}>
                      <Grid
                        item>{`LQ_impact_periodical = ${modifiedObject.lifeQualityImpactPeriodical} (pred udalosťou ${previousObject.lifeQualityImpactPeriodical})`}</Grid>
                      <Grid
                        item>{`lifeQualityImpactMaxCount = ${modifiedObject.lifeQualityImpactMaxCount} (pred udalosťou ${previousObject.lifeQualityImpactMaxCount})`}</Grid>
                      <Grid
                        item>{`assetsImpactPeriodicalPassive = ${modifiedObject.assetsImpactPeriodicalPassive} (pred udalosťou ${previousObject.assetsImpactPeriodicalPassive})`}</Grid>
                      <Grid
                        item>{`assetsImpactPeriodicalActive = ${modifiedObject.assetsImpactPeriodicalActive} (pred udalosťou ${previousObject.assetsImpactPeriodicalActive})`}</Grid>
                      <Grid
                        item>{`assetsImpactMaxRounds = ${modifiedObject.assetsImpactMaxRounds} (pred udalosťou ${previousObject.assetsImpactMaxRounds})`}</Grid>
                      <Grid
                        item>{`utilisationMinRounds = ${modifiedObject.utilisationMinRounds} (pred udalosťou ${previousObject.utilisationMinRounds})`}</Grid>
                      <Grid
                        item>{`utilisationMaxRounds = ${modifiedObject.utilisationMaxRounds} (pred udalosťou ${previousObject.utilisationMaxRounds})`}</Grid>
                      <Grid
                        item>{`actualValue = ${modifiedObject.actualValue} (pred udalosťou ${previousObject.actualValue})`}</Grid>
                    </Grid>
                  </Box>
                ) : (
                  'chybný záznam'
                );
              })}
            </Grid>
          )}
          {financialObjects && financialObjects.length > 0 && (
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Finančné objekty zmenené udalosťou
              </Typography>
              {financialObjects?.map(({ modifiedObject, previousObject }, index) => {
                return modifiedObject && previousObject ? (
                  <Box key={index} border={1} borderColor="gray" borderRadius={1} my={2} p={2}>
                    {modifiedObject.name}
                    <Box pl={2}>
                      {`Actual_value = ${formatPrice(
                        modifiedObject.actualValue,
                      )} (pred udalosťou ${formatPrice(previousObject.actualValue)})`}
                    </Box>
                  </Box>
                ) : (
                  'chybný záznam'
                );
              })}
            </Grid>
          )}
          {randomEventHistory?.acquiredObject && (
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Získaný objekt
              </Typography>
              <Box border={1} borderColor="gray" borderRadius={1} my={2} p={2}>
                {randomEventHistory?.acquiredObject?.object?.name}
                <Box pl={4}>
                  {`assetImpactOneTime = ${randomEventHistory?.acquiredObject?.assetsImpactOneTime}`}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
