import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { namedOperations, useDeleteTimespaceMutation } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { ScreenPaths } from 'Config';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  timespaceId: string | undefined;
}

export default function DeleteTimespaceModalButton({ timespaceId }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { handleClose, handleOpen, isOpen } = useModal();
  const [timespaceDelete, { loading }] = useDeleteTimespaceMutation({
    refetchQueries: [namedOperations.Query.Timespaces],
    variables: { where: { id: timespaceId } },
  });

  const handleOnClick = useCallback(
    () =>
      timespaceDelete()
        .then(({ data }) => {
          if (data?.deleteTimespace) {
            enqueueSnackbar(t('timespace.delete.success'), { variant: 'success' });
            handleClose();
            navigate(ScreenPaths.Timespaces);
          }
        })
        .catch(() => {
          enqueueSnackbar(t('errors.generic'), { variant: 'error' });
        }),
    [handleClose, navigate, timespaceDelete],
  );
  return (
    <>
      <Dialog fullWidth onClose={handleClose} open={isOpen}>
        <DialogTitle>
          {t('timespace.delete.button')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Typography>{t('timespace.delete.description')}</Typography>
          <Box bgcolor="red" display="inline-block" mt={2} px={1}>
            <Typography color="white">{t('timespace.delete.warning')}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.no')}
          </Button>
          <LoadingButton loading={loading} onClick={handleOnClick} variant="contained">
            <span>{t('global.actions.yes')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        aria-label="delete"
        color="error"
        onClick={handleOpen}
        startIcon={<Delete />}
        variant="contained">
        {t('timespace.delete.button')}
      </Button>
    </>
  );
}
