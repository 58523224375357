// eslint-disable-next-line import/named
import { FormikHelpers } from 'formik';
import * as React from 'react';
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { ObjectModal } from 'Components';
import { TimespacePersonObject } from 'Models';

interface Props {
  data?: TimespacePersonObject;
  onSubmit?: (
    values: TimespacePersonObject,
    formikHelpers: FormikHelpers<TimespacePersonObject>,
  ) => Promise<any> | void;
}

export default function TimespacePersonObjectDetailModal({ data, onSubmit }: Props) {
  //TODO: useModal hook
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip placement="top" title={t('global.actions.edit').toString()}>
        <IconButton aria-label="edit" color="warning" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      </Tooltip>
      <ObjectModal
        editableAttributes
        onClose={handleClose}
        onSubmit={(values, helpers) => {
          handleClose();
          return onSubmit && onSubmit(values, helpers);
        }}
        open={open}
        timespaceObject={data}
      />
    </>
  );
}
