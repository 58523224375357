// eslint-disable-next-line no-shadow
export enum ScreenPaths {
  Root = '/',
  Create = '/create',
  Edit = '/edit',
  ForgotPassword = '/forgot-password',
  NotFound = '/not-found',
  ResetPassword = '/reset-password',
  Register = '/register',
  Login = '/login',
  Logout = '/logout',
  Timespaces = '/timespaces',
  Templates = '/timespaces/templates',
  Verification = '/user-verification',
}
