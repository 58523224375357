import React, { useContext } from 'react';
import { Add, Delete, Visibility } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ObjectModal } from 'Components';
import { TimespacePersonObject, TimespacePersonObjectStatusType } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { formatAge, formatPrice } from 'Lib/Helpers/Number';
import { GameContext } from 'Context';
import { HouseholdObjectCodes, OcupationObjectCodeEnum } from 'Types/Global';
import HouseholdObjectDiscardModal from './ObjectDeleteModal';
import HouseholdPartnerEmploymentAddModal from './PartnerEmploymentAddModal';

interface Props {
  employmentObjects?: TimespacePersonObject[];
  personObject: TimespacePersonObject;
}

export default function HouseholdPartnerItem({ employmentObjects, personObject }: Props) {
  const { t } = useTranslation();
  const {
    timespacePerson: { timespace },
  } = useContext(GameContext);
  const {
    handleClose: handleDeleteModalClose,
    handleOpen: handleDeleteModalOpen,
    isOpen: isDeleteModalOpen,
    selected: deleteModalObject,
  } = useModal<TimespacePersonObject>();
  const {
    handleClose: handleDetailModalClose,
    handleOpen: handleDetailModalOpen,
    isOpen: isDetailModalOpen,
    selected: detailModalObject,
  } = useModal<TimespacePersonObject>();
  const {
    handleClose: handleEmploymentModalClose,
    handleOpen: handleEmploymentModalOpen,
    isOpen: isEmploymentModalOpen,
  } = useModal();

  const isPartnerEmploymentAvailable = timespace?.timespaceAvailableObjects?.some(
    (object) =>
      object?.objectCode === OcupationObjectCodeEnum.Ocupation ||
      object?.objectCode?.startsWith(HouseholdObjectCodes.EMPLOYMENT),
  );

  const isEmployed = Array.isArray(employmentObjects) && employmentObjects.length;
  const ageInRounds = Math.abs((timespace?.round ?? 0) - (personObject.procurementRound ?? 0));

  return (
    <>
      <Box>{t('household.partner.label')}</Box>
      <Box display="flex" pl={4}>
        <Box display="flex" flexDirection="column" mr={2}>
          <Box
            onMouseEnter={() => {
              console.log(personObject);
            }}>{`${
            personObject.customName ? personObject.customName : personObject.name
          }, ${formatAge(ageInRounds)}`}</Box>
          {isEmployed ? (
            employmentObjects.map((employmentObject, index) => (
              <Box key={`partner_${index}`} alignItems="center" display="flex">
                <Box
                  onMouseEnter={() => {
                    console.log(employmentObject);
                  }}>
                  {`${t('object.employed')}, ${t('object.salary')} ${formatPrice(
                    employmentObject.assetsImpactPeriodicalActive,
                  )}`}
                </Box>
                <Tooltip placement="top" title={t('global.actions.detail').toString()}>
                  <IconButton onClick={() => handleDetailModalOpen(employmentObject)}>
                    <Visibility />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                  <IconButton onClick={() => handleDeleteModalOpen(employmentObject)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ))
          ) : (
            <Box>{t('object.unemployed')}</Box>
          )}
        </Box>
      </Box>
      {personObject.status === TimespacePersonObjectStatusType.Active &&
      isPartnerEmploymentAvailable ? (
        <Button
          onClick={handleEmploymentModalOpen}
          startIcon={<Add />}
          sx={{ marginTop: 2 }}
          variant="outlined">
          {t('household.actions.employement.find')}
        </Button>
      ) : null}
      {detailModalObject ? (
        <ObjectModal
          readonly
          onClose={handleDetailModalClose}
          open={isDetailModalOpen}
          timespaceObject={detailModalObject}
        />
      ) : null}
      {deleteModalObject ? (
        <HouseholdObjectDiscardModal
          isOpen={isDeleteModalOpen}
          object={deleteModalObject}
          onClose={handleDeleteModalClose}
        />
      ) : null}
      <HouseholdPartnerEmploymentAddModal
        onClose={handleEmploymentModalClose}
        open={isEmploymentModalOpen}
      />
    </>
  );
}
