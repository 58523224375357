// eslint-disable-next-line import/named
import { Typography } from '@mui/material';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useErrorHandler() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleErrorByMessage = useCallback((errorMessage, params) => {
    switch (errorMessage) {
      case 'error.min_age_not_reached':
        enqueueSnackbar(
          t(`errors.${errorMessage}`, t('errors.generic'), {
            replace: { value: params.ageMin },
          }),
          { variant: 'error' },
        );
        break;
    }
  }, []);

  const handleError = useCallback(
    (errors?: readonly GraphQLError[], params?: Record<string, unknown>) => {
      errors?.forEach((error) => {
        const errorType = error.path?.find(() => true);
        const parsedErrors = errors?.flatMap((x) =>
          x.message.split('\n').filter((str) => ~str.indexOf('- ')),
        );
        const errorMessage = t(
          // @ts-expect-error ignore type
          errors?.[0]?.extensions?.exception?.thrownValue?.message ?? '',
          undefined,
        );

        const errorCode = t(
          // @ts-expect-error ignore type
          errors?.[0]?.extensions?.code ?? '',
          undefined,
        );

        switch (errorType) {
          case 'createTimespacePersonObject':
            {
              errorCode === 'KS_ACCESS_DENIED'
                ? enqueueSnackbar(t('errors.AccessDenied'), { variant: 'error' })
                : enqueueSnackbar(
                    <>
                      <Typography variant="body2">
                        {t('errors.title')}:
                        {parsedErrors.map((x, index) => {
                          const [, type, data] = x.split(':');
                          return (
                            <Typography key={index} variant="body2">
                              {`${++index}: ${t(`errors.${type.trim()}`, type, {
                                replace: { value: params?.ageMin },
                              })}`}
                              {data?.replace(',', ` ${t('global.or')} `)}
                            </Typography>
                          );
                        })}
                      </Typography>
                    </>,
                    {
                      key: errorType + error.message,
                      variant: 'warning',
                    },
                  );
            }
            break;
          default:
            handleErrorByMessage(errorMessage.trim(), params);
        }
      });
    },
    [],
  );

  return {
    handleError,
  };
}
