import React, { useCallback, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { ConsumerCreditEarlyRepaymentSchema } from 'Config/Validations';
import { LabeledText, ModalCloseButton } from 'Components/Generic';
import { BasicAccountSelector } from 'Components/FinancialObject';
import {
  TimespacePersonFinancialObjectDocument,
  TimespacePersonFinancialObjectFragment,
  useTerminateBankAccountMutation,
} from 'Generated/graphql-hooks';
import { formatPrice } from 'Lib/Helpers/Number';
import { getHumanDateFromRound } from 'Lib/Helpers/Date';
import { GameContext } from 'Context';
import { calculateInterest } from 'Components/Mortgage/utils';
import { useErrorHandler } from 'Lib/Hooks';

interface FormValues {
  payersAccount?: TimespacePersonFinancialObjectFragment | null;
}

interface Props {
  onClose: () => void;
  open: boolean;
  timespacePersonFinancialObject?: TimespacePersonFinancialObjectFragment | null;
}

export default function ConsumerCreditEarlyRepaymentModal({
  onClose: handleClose,
  open,
  timespacePersonFinancialObject,
}: Props) {
  const { t } = useTranslation();
  const { timespacePerson } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const [repayLoanEarly, { loading }] = useTerminateBankAccountMutation();

  const handleFormikSubmit = useCallback(
    async ({ payersAccount }) => {
      try {
        const { data, errors } = await repayLoanEarly({
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: TimespacePersonFinancialObjectDocument,
              variables: { where: { id: Number(timespacePersonFinancialObject!.id) } },
            },
          ],
          variables: {
            discardmentRound: timespacePerson?.timespace?.round ?? 0,
            fromTimespacePersonFinancialObjectId: Number(timespacePersonFinancialObject!.id),
            terminateImmediately: false,
            toTimespacePersonFinancialObjectId: Number(payersAccount!.id),
          },
        });
        if (data?.terminateBankAccount) {
          handleClose();
          enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
        } else {
          handleError(errors);
        }
      } catch {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      }
    },
    [timespacePersonFinancialObject, repayLoanEarly, enqueueSnackbar],
  );

  const interest = timespacePersonFinancialObject?.financialObject
    ? calculateInterest(timespacePersonFinancialObject?.financialObject, timespacePerson?.timespace)
    : 0;
  const amountToRepay = Math.abs(
    ((timespacePersonFinancialObject?.actualValue ?? 0) -
      interest -
      (timespacePersonFinancialObject?.feesPeriodical ?? 0) +
      (timespacePersonFinancialObject?.monthlyPayment ?? 0)) *
      -1,
  );
  const exitFee = Math.abs(
    amountToRepay * -1 * (timespacePersonFinancialObject?.financialObject?.exitFee ?? 0),
  );

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <ModalCloseButton onClose={handleClose} />
      <Formik<FormValues>
        initialValues={{
          payersAccount: timespacePersonFinancialObject?.relatedFinancialObject,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={ConsumerCreditEarlyRepaymentSchema}>
        {({ handleSubmit }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <DialogContent>
                <LabeledText
                  label={t('consumerCredit.modal.date')}
                  sx={{ mt: 3, py: 1 }}
                  value={getHumanDateFromRound(
                    (timespacePerson?.timespace?.round ?? 1) + 1,
                    (timespacePersonFinancialObject?.realizationDay ?? 1).toString(),
                  )}
                />
                <LabeledText
                  label={t('consumerCredit.modal.actual.value')}
                  sx={{ py: 1 }}
                  value={formatPrice(amountToRepay)}
                />
                <LabeledText
                  label={t('consumerCredit.modal.exit.fee')}
                  sx={{ py: 1 }}
                  value={formatPrice(exitFee)}
                />
                <LabeledText
                  label={t('consumerCredit.modal.total')}
                  sx={{ fontWeight: 'bold', py: 1 }}
                  value={formatPrice(amountToRepay + exitFee)}
                />
                <LabeledText
                  label={t('object.attr.relatedFinancialObject')}
                  value={<BasicAccountSelector id="payersAccount" />}
                />
              </DialogContent>
              <Box p={2}>
                <DialogActions>
                  <Button onClick={handleClose} variant="outlined">
                    {t('global.actions.cancel')}
                  </Button>
                  <LoadingButton loading={loading} type="submit" variant="contained">
                    <span>{t('global.actions.ok')}</span>
                  </LoadingButton>
                </DialogActions>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
