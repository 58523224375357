import { Delete } from '@mui/icons-material';
import { useField } from 'formik';
import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { TimespacePersonObject } from 'Models';
import { EnhancedDataTable } from 'Components/Generic';
import TimespacePersonObjectDetailModal from './ObjectDetailModal';
import TimespacePersonObjectAddModal from './ObjectAddModal';

interface Props {
  id: string;
  initialAge?: number | null;
}

export default function TimespacePersonObjectTable({ id, initialAge }: Props) {
  const { t } = useTranslation();
  const [, { value }, { setValue }] = useField(id);

  const onDelete = (index: number) => {
    const newValues = [...value];
    newValues.splice(index, 1);
    setValue(newValues);
  };
  const handleSubmit = (newObject: TimespacePersonObject, index: number) => {
    const newValues = [...value];
    newValues[index] = newObject;
    setValue(newValues);
  };
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'object',
      options: { display: 'excluded' },
    },
    {
      label: t('timespace.attr.code'),
      name: 'object.code',
    },
    {
      label: t('timespace.attr.name'),
      name: 'object.name',
    },
    {
      label: t('timespace.attr.customName'),
      name: 'object.customName',
    },
    {
      label: t('global.table.actions'),
      name: 'actions',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          return (
            <>
              <TimespacePersonObjectDetailModal
                data={value[rowIndex]}
                onSubmit={(object) => handleSubmit(object, rowIndex)}
              />
              <Tooltip placement="top" title={t('global.actions.delete').toString()}>
                <IconButton aria-label="delete" onClick={() => onDelete(rowIndex)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <EnhancedDataTable
      columns={columns}
      data={value}
      options={
        {
          customToolbar: () => <TimespacePersonObjectAddModal id={id} initialAge={initialAge} />,
          pagination: false,
          setTableProps: () => ({ style: { minHeight: '165px' } }),
          sort: false,
        } as MUIDataTableOptions
      }
      title={<Typography variant="h6">{t('timespace.timespacePersonObjects')}</Typography>}
    />
  );
}
