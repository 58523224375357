import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ErrorFallbackPlaceholder, SafeDeleteModal, Section } from 'Components';
import {
  StandingOrderStatusType,
  namedOperations,
  useStandingOrderQuery,
  useUpdateStandingOrderMutation,
} from 'Generated/graphql-hooks';
import { Constants, TimespacePaths } from 'Config';
import { ExpirationTypeEnum } from 'Types/Global';
import { StandingOrder } from 'Models';
import { useModal } from 'Lib/Hooks';
import StandingOrderForm, { FormValues } from './Form';

export default function StandingOrderEdit() {
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();
  const { timespaceId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    handleClose: handleDeleteModalClose,
    handleOpen: handleDeleteModalOpen,
    isOpen: isDeleteModalOpen,
  } = useModal();
  const [updateStandingOrder, { loading: updateLoading }] = useUpdateStandingOrderMutation();

  const { data, error, loading } = useStandingOrderQuery({
    variables: {
      where: {
        id,
      },
    },
  });

  const handleFormikSubmit = async ({
    expirationDate,
    expirationOnExecutionCount,
    expirationType,
    // eslint-disable-next-line no-shadow
    id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initialDate,
    ...values
  }: FormValues) => {
    try {
      const { data: responseData } = await updateStandingOrder({
        refetchQueries: [
          namedOperations.Query.BankCategories,
          namedOperations.Query.TimespacePersonFinancialObjects,
        ],
        variables: {
          data: {
            ...values,
            beneficiaryAccount: undefined,
            expirationDay:
              expirationType === ExpirationTypeEnum.DATE ? expirationDate?.day ?? 1 : null,
            expirationOnExecutionCount:
              expirationType === ExpirationTypeEnum.COUNT ? expirationOnExecutionCount : null,
            expirationRound:
              expirationType === ExpirationTypeEnum.DATE
                ? ((expirationDate?.year ?? Constants.START_YEAR) - Constants.START_YEAR) * 12 +
                  (expirationDate?.month ?? 0)
                : null,
            payersAccount: undefined,
          },
          where: {
            id,
          },
        },
      });

      if (responseData?.updateStandingOrder) {
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  };

  const handleDelete = async () => {
    try {
      const { data: responseData } = await updateStandingOrder({
        refetchQueries: [
          namedOperations.Query.BankCategories,
          namedOperations.Query.StandingOrders,
        ],
        variables: {
          data: {
            status: StandingOrderStatusType.Cancelled,
          },
          where: {
            id,
          },
        },
      });

      if (responseData?.updateStandingOrder) {
        navigate(`${TimespacePaths.Bank}/${timespaceId}`);
        enqueueSnackbar(t('bank.payment.success'), { variant: 'success' });
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Section title={t('bank.edit.standingOrder')}>
        <ErrorFallbackPlaceholder
          data={data?.standingOrder}
          error={error}
          loading={loading}
          placeholder={t('global.placeholder.noData')}>
          {loading ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <>
              <StandingOrderForm
                formType="EDIT"
                initialValues={{
                  ...new StandingOrder(data?.standingOrder),
                  expirationType: (data?.standingOrder?.expirationRound
                    ? ExpirationTypeEnum.DATE
                    : data?.standingOrder?.expirationOnExecutionCount
                    ? ExpirationTypeEnum.COUNT
                    : ExpirationTypeEnum.TERMINATION) as keyof typeof ExpirationTypeEnum,
                }}
                onDelete={handleDeleteModalOpen}
                onSubmit={handleFormikSubmit}
              />
              <SafeDeleteModal
                loading={updateLoading}
                onClose={handleDeleteModalClose}
                onConfirm={handleDelete}
                open={isDeleteModalOpen}
                text={`${t('bank.standingOrder.deleteConfirm')} ${data?.standingOrder?.name} ?`}
              />
            </>
          )}
        </ErrorFallbackPlaceholder>
      </Section>
    </Container>
  );
}
