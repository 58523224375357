import { Link } from '@mui/material';
import * as React from 'react';
import { useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { grey } from '@mui/material/colors';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ScreenPaths } from 'Config';
import { PasswordHF, SchoolAutocompleteHF, SelectHF, TextFieldHF } from 'Components';
import {
  UserParticipantTypeType,
  useCreateUserMutation,
  useVerifyRecaptchaTokenMutation,
} from 'Generated/graphql-hooks';
import { RegisterInput } from 'Models';
import { RegisterSchema } from 'Config/Validations';
import parseGraphqlErrors from 'Lib/Helpers/parseGraphqlErrors';
import RadioGroupHF from 'Components/Input/RadioGroupHF';
import { orderedUserGradeTypes } from 'Config/UserConstants';
import { participantTypeOptions } from './utils';

export default function Register() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [registerMutation] = useCreateUserMutation();
  const [verifyRecaptchaTokenMutation] = useVerifyRecaptchaTokenMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: new RegisterInput(),
    mode: 'all',
    resolver: yupResolver(RegisterSchema),
  });

  const observedParticipantType = watch('participantType');
  const notTeacher = observedParticipantType !== UserParticipantTypeType.Teacher;

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ confirmPassword, ...values }) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('register');
      const recaptchaResult = await verifyRecaptchaTokenMutation({
        variables: { recaptchaToken: token },
      });

      if (recaptchaResult.data?.verifyRecaptchaToken) {
        const result = await registerMutation({ variables: { data: values } });
        if (result.data?.createUser) {
          enqueueSnackbar(
            t('register.success', {
              email: values.email,
            }),
            { autoHideDuration: 10000, variant: 'success' },
          );
          navigate(ScreenPaths.Login);
        } else {
          const error = parseGraphqlErrors(result?.errors);
          enqueueSnackbar(t(error[0], t('errors.generic')), { variant: 'error' });
        }
      } else {
        enqueueSnackbar(t('errors.recaptcha'), { variant: 'error' });

        return;
      }
    },
    [t, registerMutation, executeRecaptcha, verifyRecaptchaTokenMutation],
  );

  return (
    <>
      <Box alignItems="center" display="flex" mb={4}>
        <Avatar sx={{ bgcolor: grey[200], mr: 2 }}>
          <LockOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('login.register')}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={1}>
            {/*
                  ● Škola (automatická ponuka aktualizovaná filtrovaním pri písaní - doťahuje dáta z ministerskej databázy - EduId školy)
                  dateOfBirth: DateTime
                */}
            <Grid item sm={6} xs={12}>
              <TextFieldHF
                required
                autoComplete="given-name"
                control={control}
                label={t('user.firstName')}
                name="firstName"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextFieldHF
                required
                autoComplete="family-name"
                control={control}
                label={t('user.lastName')}
                name="lastName"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldHF
                required
                autoComplete="nickname"
                control={control}
                helperText={t('register.nickName.hint')}
                label={t('user.nickName')}
                name="nickName"
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldHF
                required
                autoComplete="email"
                control={control}
                label={t('user.email')}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordHF required control={control} label={t('user.password')} name="password" />
            </Grid>
            <Grid item xs={12}>
              <PasswordHF
                required
                control={control}
                label={t('user.confirmPassword')}
                name="confirmPassword"
              />
            </Grid>
            <Grid item xs={12}>
              <SchoolAutocompleteHF control={control} name="schoolName" />
            </Grid>
            <Grid item display="flex" justifyContent="center" xs={12}>
              <RadioGroupHF
                row
                control={control}
                id="participantType"
                name="participantType"
                options={participantTypeOptions}
              />
            </Grid>
            {notTeacher && (
              <>
                <Grid item xs={12}>
                  <TextFieldHF
                    control={control}
                    label={t('user.teacherFirstName')}
                    name="teacherFirstName"
                    required={notTeacher}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldHF
                    control={control}
                    label={t('user.teacherLastName')}
                    name="teacherLastName"
                    required={notTeacher}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectHF
                    fullWidth
                    control={control}
                    label={t('user.grade')}
                    name="grade"
                    options={orderedUserGradeTypes}
                    renderValue={(item) => t(`enums.${item}`)}
                    required={notTeacher}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container mt={2}>
            <Typography component="p" textAlign={'center'} variant="body3">
              <span dangerouslySetInnerHTML={{ __html: t('login.recaptchaInfo') }}></span>
            </Typography>
          </Grid>
          <LoadingButton
            fullWidth
            loading={isSubmitting}
            sx={{ mb: 2, mt: 3 }}
            type="submit"
            variant="contained">
            {t('login.register')}
          </LoadingButton>
          <Grid container justifyContent="center">
            <Grid item>
              <Link component={RouterLink} to={ScreenPaths.Login} underline="hover">
                {t('login.backToLogin')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
}
