import React, { memo } from 'react';
import { useField } from 'formik';
import {
  Box,
  // eslint-disable-next-line import/named
  CheckboxProps,
  FormControlLabel,
  // eslint-disable-next-line import/named
  FormControlLabelProps,
  Checkbox as MUICheckbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CheckboxFormikProps
  extends Omit<FormControlLabelProps, 'control' | 'onChange' | 'label'>,
    Pick<CheckboxProps, 'onChange'> {
  error?: boolean;
  helperText?: string | JSX.Element;
  hint?: string;
  id?: string;
  label?: string;
  value?: boolean;
}

export function Checkbox({
  disabled,
  helperText,
  hint,
  id,
  label = '',
  onChange,
  value,
  ...restProps
}: CheckboxFormikProps): JSX.Element {
  const CheckboxElement = (
    <MUICheckbox
      checked={value}
      color="primary"
      name={id}
      onChange={!disabled ? onChange : undefined}
    />
  );
  return (
    <>
      {label?.length > 0 ? (
        <Box display="flex">
          <FormControlLabel label={label} {...restProps} control={CheckboxElement} />
        </Box>
      ) : (
        CheckboxElement
      )}
      {/*{hint ? (*/}
      {/*  <Tooltip arrow placement="top" title={hint}>*/}
      {/*    <InfoOutlined color="inherit" fontSize="small" />*/}
      {/*  </Tooltip>*/}
      {/*) : null}*/}
      {helperText}
    </>
  );
}

export const CheckboxFormik = memo(function CheckboxFormik({
  id,
  ...restProps
}: CheckboxFormikProps) {
  const { t } = useTranslation();
  if (!id) {
    throw new Error('Id must be defined');
  }
  const [{ value }, { error, touched }, { setValue }] = useField(id);
  return (
    <Checkbox
      helperText={error && touched ? t(error) : undefined}
      id={id}
      onChange={(_, bool) => setValue(bool)}
      value={value || false}
      {...restProps}
    />
  );
});
