import {
  Maybe,
  RandomEvent,
  RandomEventAcquiredObject,
  RandomEventFinancialObject,
  RandomEventObject,
  Timespace,
} from 'Generated/graphql-hooks';

export class RandomEventInput implements DeepNullable<RandomEvent> {
  constructor(randomEvent?: RandomEvent | null) {
    this.description = randomEvent?.description;
    this.executionMaxCount = randomEvent?.executionMaxCount ?? 1;
    this.executionMonthPeriodical = randomEvent?.executionMonthPeriodical;
    this.executionProbability = randomEvent?.executionProbability
      ? Math.round(randomEvent?.executionProbability * 100)
      : 100;
    this.executionRoundFrom = randomEvent?.executionRoundFrom;
    this.executionRoundTo = randomEvent?.executionRoundTo;
    this.executionUserAcceptance = randomEvent?.executionUserAcceptance;
    this.executionYearPeriodical = randomEvent?.executionYearPeriodical ?? 1;
    this.id = randomEvent?.id;
    this.name = randomEvent?.name;
    this.randomEventAcquiredObject = randomEvent?.randomEventAcquiredObject;
    this.randomEventFinancialObjects = randomEvent?.randomEventFinancialObjects ?? [];
    this.randomEventObjects = randomEvent?.randomEventObjects ?? [];
    this.timespace = randomEvent?.timespace;
    this.__typename = randomEvent?.__typename;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  __typename?: DeepNullable<'RandomEvent' | undefined> | null;
  description?: DeepNullable<Maybe<string> | undefined>;
  executionCount?: DeepNullable<Maybe<number> | undefined>;
  executionLastRound?: DeepNullable<Maybe<number> | undefined>;
  executionMaxCount?: DeepNullable<Maybe<number> | undefined>;
  executionMonthPeriodical?: DeepNullable<Maybe<number> | undefined>;
  executionProbability?: DeepNullable<Maybe<number> | undefined>;
  executionRoundFrom?: DeepNullable<Maybe<number> | undefined>;
  executionRoundTo?: DeepNullable<Maybe<number> | undefined>;
  executionUserAcceptance?: DeepNullable<Maybe<boolean> | undefined>;
  executionYearPeriodical?: DeepNullable<Maybe<number> | undefined>;
  id: string | null | undefined;
  name?: DeepNullable<Maybe<string> | undefined>;
  randomEventAcquiredObject?: Maybe<RandomEventAcquiredObject>;
  randomEventFinancialObjects?: DeepNullable<Maybe<RandomEventFinancialObject[]> | undefined>;
  randomEventObjects?: DeepNullable<Maybe<RandomEventObject[]> | undefined>;
  timespace?: DeepNullable<Maybe<Timespace> | undefined>;
}
