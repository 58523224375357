import React, { useCallback, useContext, useMemo, useRef } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import omitDeep from 'omit-deep-lodash';
import { OperationDefinitionNode } from 'graphql';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { InMemoryCacheConfig, ScreenPaths } from 'Config';
import { getOwnerToken, getSessionToken, isInterpersonalizedView } from 'Lib/Helpers/Session';
import { backgroundMutations, logoutMutations } from 'Config/Constants';

interface Props {
  children: JSX.Element;
}

function buildHeaders(headers: any, token: string | null, ownerToken: string | null) {
  if (token == null || token === '' || ownerToken == null || ownerToken === '') {
    return headers;
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      OwnerAuthorization: `Bearer ${ownerToken}`,
    },
  };
}

interface ApolloContextType {
  setIsInterpersonalized: (value: boolean) => void;
  setMutationsBlocked: (value: boolean) => void;
}

export const ApolloContext = React.createContext<ApolloContextType>({
  setIsInterpersonalized: () => null,
  setMutationsBlocked: () => null,
});

export default function EnhancedApolloProvider({ children }: Props) {
  const mutationsBlocked = useRef(false);
  const isInterpersonalized = useRef(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (~err.message.indexOf('Access control')) {
          window.location.href = ScreenPaths.Logout;
        } else if (~err.message.indexOf('UnauthorizedError')) {
          window.location.href = ScreenPaths.Logout;
        }
      }
    }

    if (networkError && !graphQLErrors) {
      console.error(`[Network error]: ${networkError}, response:`, response);

      Bugsnag.notify(new Error(`[Network error]: ${networkError}`));
    }
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const def = getMainDefinition(operation.query) as OperationDefinitionNode;
    if (def && def.operation === 'mutation') {
      operation.variables = omitDeep(operation.variables, '__typename');
    }
    return forward ? forward(operation) : null;
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }: { headers: any }) => {
      return buildHeaders(headers, getSessionToken(), getOwnerToken());
    });
    return forward(operation);
    //   .map((result) => {
    //   if (operation.operationName === 'me' && result?.data?.authenticatedItem === null) {
    //     window.location.href = ScreenPaths.Logout;
    //   }
    //   return result;
    // });
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      connectionParams: () => ({ authentication: getSessionToken() }),
      on: {
        closed: () => {
          setMutationsBlocked(false);
        },
        error: (subscriptionError) => {
          Bugsnag.notify(new Error(JSON.stringify(subscriptionError)));
        },
      },
      url: process.env.REACT_APP_WS_URL ?? '',
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const setMutationsBlocked = useCallback((value: boolean) => {
    mutationsBlocked.current = value;
  }, []);

  const setIsInterpersonalized = useCallback((value: boolean) => {
    isInterpersonalized.current = value;
  }, []);

  const stopLink = new ApolloLink((operation, forward) => {
    const def = getMainDefinition(operation.query) as OperationDefinitionNode;

    if (isInterpersonalizedView()) {
      setIsInterpersonalized(true);
    } else {
      setIsInterpersonalized(false);
    }

    if (
      isInterpersonalized.current &&
      def &&
      def.operation === 'mutation' &&
      !logoutMutations.includes(def?.name?.value ?? '')
    ) {
      return new Observable((observer) => {
        // ignore error for background mutations
        if (!backgroundMutations.includes(def?.name?.value ?? '')) {
          enqueueSnackbar(t('timespace.interpersonalizeBlock'), { variant: 'error' });
        }
        // Set loading flag to false
        observer.next({});
        observer.complete(); // Stop the execution chain
      });
    }

    if (
      mutationsBlocked.current &&
      def &&
      def.operation === 'mutation' &&
      !logoutMutations.includes(def?.name?.value ?? '')
    ) {
      return new Observable((observer) => {
        // ignore error for background mutations
        if (!backgroundMutations.includes(def?.name?.value ?? '')) {
          enqueueSnackbar(t('timespace.roundTransitionBlock'), { variant: 'error' });
        }
        // Set loading flag to false
        observer.next({});
        observer.complete(); // Stop the execution chain
      });
    }

    return forward(operation);
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(InMemoryCacheConfig),
        connectToDevTools: process.env.NODE_ENV === 'development',
        defaultOptions: {
          mutate: { errorPolicy: 'all' },
          query: {
            fetchPolicy: 'network-only',
          },
          watchQuery: {
            fetchPolicy: 'network-only',
          },
        },
        link: from([stopLink, cleanTypenameLink, errorLink, authLink, splitLink]),
      }),
    [],
  );

  const contextValue = useMemo(
    () => ({
      setIsInterpersonalized,
      setMutationsBlocked,
    }),
    [],
  );

  return (
    <ApolloContext.Provider value={contextValue}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
}

export function useApolloContext() {
  return useContext(ApolloContext);
}
