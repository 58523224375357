import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { namedOperations, useDeleteTimespaceMutation } from 'Generated/graphql-hooks';
import { useModal } from 'Lib/Hooks';
import { ModalCloseButton } from 'Components/Generic';

interface Props {
  timespaceId: string;
}

export default function RemoveTemplateModalButton({ timespaceId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, handleOpen, isOpen } = useModal();
  const [deleteTimespace, { loading }] = useDeleteTimespaceMutation({
    refetchQueries: [namedOperations.Query.Timespaces],
    variables: { where: { id: timespaceId } },
  });

  const handleOnClick = useCallback(
    () =>
      deleteTimespace()
        .then(({ data }) => {
          if (data?.deleteTimespace) {
            enqueueSnackbar(t('template.delete.success'), { variant: 'success' });
            handleClose();
          }
        })
        .catch(() => {
          enqueueSnackbar(t('errors.generic'), { variant: 'error' });
        }),
    [deleteTimespace],
  );
  return (
    <>
      <Dialog fullWidth onClose={handleClose} open={isOpen}>
        <DialogTitle>
          {t('template.delete.title')}
          <ModalCloseButton onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Typography>{t('template.delete.description')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('global.actions.no')}
          </Button>
          <LoadingButton loading={loading} onClick={handleOnClick} variant="contained">
            <span>{t('global.actions.yes')}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Tooltip placement="top" title={t('template.delete.title').toString()}>
        <IconButton aria-label="delete" color="error" onClick={handleOpen}>
          <Delete />
        </IconButton>
      </Tooltip>
    </>
  );
}
