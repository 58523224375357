import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFilterOptions } from '@mui/material';
import { UseControllerProps } from 'react-hook-form';
import { AutocompleteHF } from 'Components';
import { schoolAutocompleteUrl } from 'Config/UserConstants';

const filter = createFilterOptions<string>();

export default function SchoolAutocompleteHF({ control, name }: UseControllerProps<any, string>) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [fetchResult, setFetchResult] = useState<{
    data: string[];
    loading: boolean;
  }>({
    data: [],
    loading: false,
  });

  useEffect(() => {
    (async function () {
      if (!searchValue) {
        return;
      }

      setFetchResult({ data: [], loading: true });

      try {
        const data = await (await fetch(`${schoolAutocompleteUrl}?text=${searchValue}`)).json();
        setFetchResult({
          data: data.payload.map((item: { AutocompleteTitle: string }) => item.AutocompleteTitle),
          loading: false,
        });
      } catch {
        setFetchResult({ data: [], loading: false });
      }
    })();
  }, [searchValue]);

  const handleInputChange = useCallback((event, newValue) => {
    setSearchValue(newValue);
  }, []);

  return (
    <AutocompleteHF
      blurOnSelect
      clearOnBlur
      freeSolo
      ListboxProps={{ style: { maxHeight: 200 } }}
      control={control}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }
        return filtered;
      }}
      label={t('user.school')}
      loading={fetchResult.loading}
      loadingText={t('global.loading')}
      name={name}
      onInputChange={handleInputChange}
      options={fetchResult.data}
    />
  );
}
