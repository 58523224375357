import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, CardMedia, DialogTitle, Grid } from '@mui/material';
// eslint-disable-next-line import/named
import { Formik, FormikHelpers } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'Lib/Helpers/Number';
import { TimespacePersonObjectStatusType } from 'Generated/graphql-hooks';
import { SelectFormik } from 'Components/Input/SelectFormik';
import { getUpdatedTimespacePersonObject } from 'Lib/Helpers/TimespacePersonObject';
import { TimespacePersonObject } from 'Models';
import {
  FormikTrigger,
  LabeledText,
  ModalCloseButton,
  ObjectAttributesTable,
  ShowMoreTypography,
  TextFieldFormik,
} from 'Components';
import { IMAGE_STORAGE_URL } from 'Config/Constants';

interface Props {
  editableAttributes?: boolean;
  id?: string;
  onClose: () => void;
  onSubmit?: (
    values: TimespacePersonObject,
    formikHelpers: FormikHelpers<TimespacePersonObject>,
  ) => Promise<any> | void;
  open: boolean;
  readonly?: boolean;
  timespaceObject?: TimespacePersonObject | null;
}

export default function ObjectModal({
  editableAttributes,
  onClose,
  onSubmit = onClose,
  open,
  readonly,
  timespaceObject,
}: Props) {
  const { t } = useTranslation();
  const url = useMemo(
    () => `${IMAGE_STORAGE_URL}${timespaceObject?.object?.code}.jpg?t=${new Date().getTime()}`,
    [timespaceObject?.object?.id],
  );

  return !timespaceObject ? null : (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle>
        {timespaceObject?.name}
        <ModalCloseButton onClose={onClose} />
      </DialogTitle>
      <Formik<TimespacePersonObject> initialValues={timespaceObject} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <FormikTrigger<TimespacePersonObject>
                skipInitialRender
                delay={10}
                trigger={(object, formikHelpers) => {
                  formikHelpers.setValues(getUpdatedTimespacePersonObject(object));
                }}
                watchProperties={['timespacePersonObjectAttributes']}
              />
              <DialogContent>
                <Box display="flex" justifyContent="center">
                  <CardMedia
                    alt={values?.object?.name || 'image'}
                    component="img"
                    image={url}
                    onLoad={(e: any) => {
                      e.currentTarget.style.display = 'block';
                    }}
                    sx={{ boxShadow: 10, display: 'none', maxWidth: 250, mb: 3 }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ShowMoreTypography text={values?.description ?? ''} />
                  </Grid>
                  {editableAttributes ? (
                    <>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.customName')}
                          value={<TextFieldFormik id="customName" />}
                        />
                      </Grid>
                      <Grid item sx={{ marginBottom: 1 }} xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.customDescription')}
                          value={<TextFieldFormik id="customDescription" />}
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid item sx={{ marginBottom: editableAttributes ? 1 : 0 }} xs={12}>
                    <LabeledText
                      dense
                      endAdorment="h"
                      label={t('object.attr.timeRequired')}
                      value={values?.timeRequired}
                    />
                  </Grid>
                  {editableAttributes && (
                    <Grid container item spacing={1}>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.realizationDay')}
                          value={<TextFieldFormik id="realizationDay" type="number" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.status')}
                          value={
                            <SelectFormik<TimespacePersonObjectStatusType>
                              id="status"
                              options={
                                Object.values(TimespacePersonObjectStatusType).slice(
                                  0,
                                  4,
                                ) /*NOTE: Sorry*/
                              }
                              renderValue={(item) => t(`enums.${item}`)}
                            />
                          }
                        />
                        <Grid item xs={12}>
                          <LabeledText
                            dense
                            label={t('object.attr.priority')}
                            value={<TextFieldFormik id="priority" type="number" />}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.procurementRound')}
                          value={<TextFieldFormik id="procurementRound" type="number" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.discardmentRound')}
                          value={<TextFieldFormik id="discardmentRound" type="number" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.fullyUtilisedRound')}
                          value={<TextFieldFormik id="fullyUtilisedRound" type="number" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabeledText
                          dense
                          label={t('object.attr.utilisationRounds')}
                          value={<TextFieldFormik id="utilisationRounds" type="number" />}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    {values?.object?.utilisationMinRounds === 0 ? null : (
                      <LabeledText
                        dense
                        label={t('object.attr.utilisationMinRounds')}
                        value={values?.object?.utilisationMinRounds}
                      />
                    )}
                    <LabeledText
                      dense
                      label={t('object.attr.utilisationMaxRounds')}
                      value={values?.object?.utilisationMaxRounds}
                    />
                    <LabeledText
                      dense
                      label={t('object.attr.assetsImpactMaxRounds')}
                      value={values?.object?.assetsImpactMaxRounds}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography gutterBottom>{t('object.lifeQualityImpact')}</Typography>
                    <Box pl={4}>
                      <LabeledText
                        dense
                        label={t('object.attr.lifeQualityImpactOneTime')}
                        value={values?.lifeQualityImpactOneTime}
                      />
                      <LabeledText
                        dense
                        label={t('object.attr.lifeQualityImpactMaxCount')}
                        value={values?.lifeQualityImpactMaxCount}
                      />
                      <LabeledText
                        dense
                        label={t('object.attr.lifeQualityImpactMaxRounds')}
                        value={values?.lifeQualityImpactMaxRounds}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography gutterBottom>{t('object.assetsImpact')}</Typography>
                    <Box pl={4}>
                      <LabeledText
                        dense
                        label={t('object.attr.assetsImpactOneTime')}
                        value={formatPrice(values?.assetsImpactOneTime)}
                      />
                      <LabeledText
                        dense
                        label={t('object.attr.assetsImpactPeriodicalActive')}
                        value={formatPrice(values?.object?.assetsImpactPeriodicalActive)}
                      />
                      <LabeledText
                        dense
                        label={t('object.attr.assetsImpactPeriodicalPassive')}
                        value={formatPrice(values?.object?.assetsImpactPeriodicalPassive)}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <ObjectAttributesTable
                  code={values.object?.code}
                  id="timespacePersonObjectAttributes"
                  readOnly={!editableAttributes}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined">
                  {t('global.actions.cancel')}
                </Button>
                {!readonly && (
                  <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                    <span>{t('global.actions.save')}</span>
                  </LoadingButton>
                )}
              </DialogActions>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
}
